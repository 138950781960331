import React from 'react'
import { LoadingInstance, ErroredInstance, isLoading, isErrored } from 'rwwa-data-access'
import { DepositLimitsView } from '../DepositLimitsView/DepositLimitsView'
import { AccountHolders, AccountHoldersKey } from '@core/Data/Account/accountHolder'
import { Spinner, DesktopSpinner } from '@mobi/component-library/Common/Spinner'
import { ErrorMessage } from '@core/Components/Messages'
import { connect } from '@core/Components/HOCs'
import { DepositLimitsEdit } from '../DepositLimitsEdit/DepositLimitsEdit'

interface DepositLimitsProps {
  selectedAccountHolderNumber?: number | undefined
  mode?: 'view' | 'edit'
  isDesktop?: boolean
  saveSuccess?: boolean
}

export function DepositLimits(props: DepositLimitsProps): JSX.Element {
  const { selectedAccountHolderNumber, mode = 'view', isDesktop, saveSuccess = false } = props

  return (
    <ConnectDepositLimits
      accountHolders={AccountHoldersKey}
      selectedAccountHolderNumber={selectedAccountHolderNumber}
      isDesktop={isDesktop}
      mode={mode}
      saveSuccess={saveSuccess}
    />
  )
}

const ConnectDepositLimits = connect({ accountHolders: AccountHolders })(
  DepositLimitsLoader
) as unknown as React.ComponentClass<
  {
    accountHolders: string
  } & DepositLimitsProps
>

interface DepositLimitsLoaderProps {
  accountHolders: typeof LoadingInstance | typeof ErroredInstance | AccountHolders
  selectedAccountHolderNumber?: number | undefined
  mode?: 'view' | 'edit'
  isDesktop?: boolean
  saveSuccess?: boolean
}

export function DepositLimitsLoader(props: DepositLimitsLoaderProps): JSX.Element {
  const { accountHolders, selectedAccountHolderNumber, mode, isDesktop, saveSuccess } = props

  if (isLoading(accountHolders)) {
    return isDesktop ? <DesktopSpinner /> : <Spinner />
  }

  if (isErrored(accountHolders)) {
    return <ErrorMessage>An error occurred while loading the "Deposit Limits" page.</ErrorMessage>
  }

  if (mode === 'edit') {
    const { primaryAccountHolder, secondaryAccountHolder } = accountHolders

    const accountHolder =
      secondaryAccountHolder &&
      secondaryAccountHolder.BetAccountHolderNumber === selectedAccountHolderNumber
        ? secondaryAccountHolder
        : primaryAccountHolder

    return <DepositLimitsEdit accountHolder={accountHolder} isDesktop={isDesktop} />
  }

  return (
    <DepositLimitsView
      selectedAccountHolderNumber={selectedAccountHolderNumber}
      accountHolders={accountHolders}
      isDesktop={isDesktop}
      saveSuccess={saveSuccess}
    />
  )
}
