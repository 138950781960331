import React from 'react'
import { initializeWithCountIfNotInitialized } from '@mobi/account/Utils/depositCount'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '@core/Components/Modal'
import { QUICKBET_MODAL_ID } from '@core/Areas/Quickbet/constants'
import { OverlayClose } from '@core/Components/Overlay'
import { navigateToChangeTempPassword } from '@core/Areas/ChangePassword/ChangeTemporaryPassword'
import { isHome, navChange } from '@classic/Foundation/Navigation/Nav'
import { setIsKeepMeLoggedIn, setIsLoginShown } from '../Store'
import { getIsKeepMeLoggedIn, getLoginActions } from '../Store/selectors'
import { attemptLogin, getRememberAccountNumber } from '../helpers'
import { trackLoginSuccess } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { logUserId } from '@core/Services/Sentry'
import { getFromLocalStorage, setInLocalStorage } from '@mobi/utils/storage'
import { sendToNative } from '@mobi/web-native-comms/web'

const REMEMBER_ACCOUNT_STORAGE_KEY = 'remember_account_number'

export const useLoginState = (): UseLoginStateReturn => {
  const dispatch = useDispatch()
  const { onLoginSuccessCallback, onLoginSuccessRedirectUrl, onLoginCancelledRedirectUrl } =
    useSelector(getLoginActions)
  const isKeepMeLoggedIn = useSelector(getIsKeepMeLoggedIn)

  const [workflow, setWorkflow] = React.useState<Workflow>('Login')

  React.useEffect(() => {
    if (workflow === 'Password Reset' || workflow === 'Account Number Recovery') {
      setPassword('')
      setErrorMessage(undefined)
    }
  }, [workflow])

  const [accountNumber, setAccountNumber] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [rememberAccount, setRememberAccount] = React.useState(getRememberAccount())

  const [errorMessage, setErrorMessage] = React.useState<string>()
  const [isBusy, setIsBusy] = React.useState(false)

  React.useEffect(() => {
    getRememberAccountNumber().then(accountNumber => {
      if (!accountNumber) return
      setAccountNumber(accountNumber.toString())
      logUserId(accountNumber)
      initializeWithCountIfNotInitialized(accountNumber, 2)
    })
  }, [setAccountNumber])

  const resetErrorMessage = () => setErrorMessage(undefined)
  const toggleKeepLoggedIn = () => dispatch(setIsKeepMeLoggedIn(!isKeepMeLoggedIn))
  const toggleRememberAccount = () => setRememberAccount(val => !val)

  const handleCloseLogin = (shouldRedirectOnClose: boolean = true) => {
    if (shouldRedirectOnClose && onLoginCancelledRedirectUrl) navChange(onLoginCancelledRedirectUrl)
    dispatch(setIsLoginShown({ shouldShow: false }))
  }

  const performLoginSuccessActions = React.useCallback(
    ({ shouldToggleBiometrics }: LoginOptions = { shouldToggleBiometrics: false }) => {
      saveRememberAccount(rememberAccount)

      if (shouldToggleBiometrics) {
        sendToNative('REQUEST_TOGGLE_BIOMETRICS', {
          accountNumber: Number(accountNumber),
          password: password,
        })
      }

      onLoginSuccessRedirectUrl
        ? navChange(onLoginSuccessRedirectUrl)
        : isHome() && navChange(window.location.hash)

      if (onLoginSuccessCallback) onLoginSuccessCallback()
      dispatch(setIsLoginShown({ shouldShow: false }))
    },
    [
      accountNumber,
      password,
      rememberAccount,
      onLoginSuccessRedirectUrl,
      onLoginSuccessCallback,
      dispatch,
    ]
  )

  const handleLogin = async (
    { shouldToggleBiometrics }: LoginOptions = { shouldToggleBiometrics: false }
  ) => {
    setIsBusy(true)
    const authenticatingAccountNumber = Number(accountNumber)
    const { isSuccessful, errorMessage, mustChangePassword } = await attemptLogin({
      accountNumber: authenticatingAccountNumber,
      password: password,
      rememberAccount,
      keepLoggedIn: isKeepMeLoggedIn,
    })
    setIsBusy(false)

    if (!isSuccessful) {
      if (mustChangePassword) {
        navigateToChangeTempPassword({ accountNumber, password })
        return
      }
      errorMessage && setErrorMessage(errorMessage)
      return
    }

    trackLoginSuccess(accountNumber, isKeepMeLoggedIn, rememberAccount, false)

    // For existing users we want to initialize the successful count to be at least 2
    // See https://rwwa.atlassian.net/browse/MOBI-2467
    initializeWithCountIfNotInitialized(authenticatingAccountNumber, 2)

    performLoginSuccessActions({ shouldToggleBiometrics })
  }

  const handleSignUp = () => {
    // Close Quickbet & Betslip if open
    dispatch(closeModal({ id: QUICKBET_MODAL_ID })) && OverlayClose()
    navChange('/#SignUp')
    handleCloseLogin()
  }

  const onAccountNumberChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setAccountNumber(e.target.value)
    resetErrorMessage()
  }

  const onPasswordChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setPassword(e.target.value)
    resetErrorMessage()
  }

  return {
    state: {
      accountNumber,
      password,
      rememberAccount,
      keepLoggedIn: isKeepMeLoggedIn,
      isBusy,
      errorMessage,
      workflow,
    },
    handlers: {
      login: (shouldToggleBiometrics?: LoginOptions) => handleLogin(shouldToggleBiometrics),
      signUp: handleSignUp,
      close: handleCloseLogin,
      onAccountNumberChange,
      onPasswordChange,
      performLoginSuccessActions,
      toggleKeepLoggedIn,
      toggleRememberAccount,
      setWorkflow,
    },
  }
}

// =============
// Local Helpers
// =============

function getRememberAccount(): boolean {
  const defaultValue = true
  const store = getFromLocalStorage(REMEMBER_ACCOUNT_STORAGE_KEY)
  if (!store) return defaultValue

  return JSON.parse(store) ?? defaultValue
}

function saveRememberAccount(shouldRememberAccount: boolean): void {
  setInLocalStorage(REMEMBER_ACCOUNT_STORAGE_KEY, shouldRememberAccount.toString())
}

// =====
// Types
// =====
interface UseLoginStateReturn {
  state: {
    accountNumber: string
    password: string
    rememberAccount: boolean
    keepLoggedIn: boolean
    isBusy: boolean
    workflow: Workflow
    errorMessage?: string
  }
  handlers: {
    login(shouldToggleBiometrics?: LoginOptions): void
    signUp(): void
    close(): void
    onAccountNumberChange: React.ChangeEventHandler<HTMLInputElement>
    onPasswordChange: React.ChangeEventHandler<HTMLInputElement>
    performLoginSuccessActions(): void
    toggleKeepLoggedIn(): void
    toggleRememberAccount(): void
    setWorkflow: React.Dispatch<React.SetStateAction<Workflow>>
  }
}

type LoginOptions = { shouldToggleBiometrics: boolean }

type Workflow = 'Login' | 'Password Reset' | 'Account Number Recovery'
