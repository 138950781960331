import { useSelector } from 'react-redux'
import { useFeature } from '@core/Utils/hooks'
import { getNewBetSlipSetting } from '@core/Areas/Settings/Store/selectors'

export const useIsNewBetSlipFeatureActive = () => {
  const isNewBetSlipFeaturSettingActive = useSelector(getNewBetSlipSetting)
  const isNewBetSlipFeatureActive = useFeature('NEW_BET_SLIP_DESIGN')

  return isNewBetSlipFeaturSettingActive && isNewBetSlipFeatureActive
}
