import React from 'react'
import { Icon } from '@mobi/component-library/Common/Icon'
import { useAppDispatch, useAppSelector } from '@core/Store/hooks'
import { setBetSlipNewIsOpen, toggleIsAppHeaderVisible } from '@core/Areas/AppHeader/Store'
import { AppHeaderButtonStyled as Button } from '../../Buttons/Buttons.styles'
import {
  BetslipButtonBadgeStyled as Badge,
  BetslipButtonBadgeNumberStyled as Number,
  BadgeAnimation,
} from './BetslipButton/BetslipButton.styles'
import { store } from '@core/Store'
import { selectBetSlipItemCount } from '@mobi/betslip/Store/Bets/selectors'
import { BET_SLIP_BUTTON_ID } from '../constants'

export const BetslipButtonNew = () => {
  const dispatch = useAppDispatch()

  const count = useAppSelector(selectBetSlipItemCount)
  const prevCountRef = React.useRef<number>(count)

  const animation = getBadgeAnimation(count, prevCountRef.current)

  React.useEffect(() => {
    if (prevCountRef.current === count) return
    const hasCountIncreased = count > prevCountRef.current
    if (hasCountIncreased && window.screenY > 0) store.dispatch(toggleIsAppHeaderVisible(true))
  }, [count, dispatch])

  React.useEffect(() => {
    prevCountRef.current = count
  }, [count])

  return (
    <Button id={BET_SLIP_BUTTON_ID} onClick={() => dispatch(setBetSlipNewIsOpen(true))}>
      {!!count && (
        <Badge>
          <Number key={count} animation={animation}>
            <span aria-label='Betslip Item Count' data-testid='betslip-item-count'>
              {count}
            </span>
            {animation && <span aria-hidden>{count + (animation === 'up' ? -1 : 1)}</span>}
          </Number>
        </Badge>
      )}
      <Icon type='betslip' title='Betslip' size='inherit' />
    </Button>
  )
}

// =============
// Local Helpers
// =============

function getBadgeAnimation(count: number, prevCount: number): BadgeAnimation {
  if (prevCount === count) return null
  return count > prevCount ? 'up' : 'down'
}
