import React, { type FC, useMemo, useState } from 'react'
import { dayjs } from '@mobi/utils/date'
import { Input, Accordion } from '@mobi/component-library/Common/V2'
import { useVersions } from '../Hooks/useVersions'
import type { WebAppVersionDetails } from '@mobi/api-types'
import {
  LatestVersion,
  useVersionsSearch,
  sortByReleaseDateDescending,
} from '../Hooks/useVersionsSearch'
import {
  VersionSelectorHeader,
  VersionSelectorListStyled,
  VersionSelectorListItemStyled,
  BadgeStyled,
  AccordionStyled,
} from './VersionSelector.styled'

const GITLAB_TABTOUCH_MOBI_PROJECT_URL = `https://gitlab.com/rwwa/channels/tabtouch/tabtouch-mobi/`

type VersionSelectorProps = {
  currentVersion: string
  currentMajorVersion: number
}

export const VersionSelector: FC<VersionSelectorProps> = ({
  currentVersion,
  currentMajorVersion,
}) => {
  const results = useVersions([
    currentMajorVersion,
    currentMajorVersion + 1,
    currentMajorVersion - 1,
    currentMajorVersion - 2,
  ])
  const { searchLatest } = useVersionsSearch(results.data || {})
  const [filterValue, setFilterValue] = useState('')

  if (results.isLoading) {
    return <div>Loading...</div>
  }

  return (
    <>
      <VersionSelectorHeader>
        <Input
          name='filter'
          label=''
          autoCorrect='off'
          autoCapitalize='off'
          placeholder='e.g. mobi-1234, tabxxx'
          onChange={e => setFilterValue(e.target.value)}
        />
      </VersionSelectorHeader>

      <VersionSelectorListStyled addSpacing={true}>
        {searchLatest(filterValue).map(searchResult => (
          <LatestVersionSelectorListItem
            key={searchResult.version}
            isCurrentVersion={searchResult.version.startsWith(currentVersion)}
            details={searchResult}
          />
        ))}
      </VersionSelectorListStyled>
    </>
  )
}

type VersionSelectorListItemProps = {
  details: LatestVersion
  isCurrentVersion: boolean
}

const LatestVersionSelectorListItem: FC<VersionSelectorListItemProps> = ({
  details,
  isCurrentVersion,
}) => {
  const { version, gitRefName, releaseDate, previousVersions } = details
  return (
    <VersionSelectorListItemStyled highlightItem={isCurrentVersion}>
      <div>
        <strong>Branch:</strong> <BranchName gitRefName={gitRefName} />
      </div>
      <div>
        <strong>Version:</strong> <Version version={version} releaseDate={releaseDate} />
      </div>
      <AccordionStyled>
        <Accordion title={'Show more...'}>
          <VersionDetails details={details} />
          {previousVersions.length > 1 && <PreviousVersions previousVersions={previousVersions} />}
        </Accordion>
      </AccordionStyled>
    </VersionSelectorListItemStyled>
  )
}

const VersionDetails: FC<{ details: WebAppVersionDetails }> = ({ details }) => {
  const { username, releaseDate, gitCommitSha, gitPipelineId, gitPipelineIid } = details
  return (
    <span>
      <div>
        <strong>Username:</strong> {username}
      </div>
      <div>
        <strong>Release Date:</strong> <ReleaseDate value={releaseDate} />
      </div>
      <div>
        <strong>Gitlab Commit:</strong> <GitLabCommit gitCommitSha={gitCommitSha} />
      </div>
      <div>
        <strong>Gitlab Pipeline:</strong>{' '}
        <GitLabPipeline gitPipelineId={gitPipelineId} gitPipelineIid={gitPipelineIid} />
      </div>
    </span>
  )
}

const BranchName: FC<{ gitRefName: string }> = ({ gitRefName }) => {
  return gitRefName === 'master' ? (
    <BadgeStyled style={{ backgroundColor: 'black' }}>master</BadgeStyled>
  ) : (
    <span>{gitRefName}</span>
  )
}

const Version: FC<{ version: string; releaseDate: string }> = ({ version, releaseDate }) => {
  const versionOnly = version.split('-')[0]
  const releaseDateFromNow = dayjs(releaseDate).fromNow()
  return (
    <span>
      <a href={`/version/${version}`} target='_self' rel='noreferrer'>
        {versionOnly}
      </a>{' '}
      ({releaseDateFromNow})
    </span>
  )
}

const PreviousVersions: FC<{ previousVersions: WebAppVersionDetails[] }> = ({
  previousVersions,
}) => {
  const sortedPreviousVersions = useMemo(
    () => previousVersions.sort(sortByReleaseDateDescending).slice(0, 10),
    [previousVersions]
  )

  const header = previousVersions.length > 11 ? `Previous Versions (max. 10)` : `Previous Versions`

  return (
    <>
      <h1>{header}</h1>
      <VersionSelectorListStyled>
        {sortedPreviousVersions.map(otherVersion => (
          <li key={otherVersion.version}>
            <AccordionStyled>
              <Accordion
                title={
                  <Version version={otherVersion.version} releaseDate={otherVersion.releaseDate} />
                }
              >
                <VersionDetails details={otherVersion} />
              </Accordion>
            </AccordionStyled>
          </li>
        ))}
      </VersionSelectorListStyled>
    </>
  )
}

const ReleaseDate: FC<{ value: string }> = ({ value }) => {
  const formattedDate = dayjs(value).format('MMM D, YYYY h:mm A')
  return <span>{formattedDate}</span>
}

const GitLabCommit: FC<{ gitCommitSha: string }> = ({ gitCommitSha }) => {
  const shortSha = gitCommitSha.slice(0, 8)
  return (
    <span>
      <a
        href={`${GITLAB_TABTOUCH_MOBI_PROJECT_URL}/-/commit/${gitCommitSha}`}
        target='_blank'
        rel='noreferrer'
      >
        {shortSha}
      </a>
    </span>
  )
}

const GitLabPipeline: FC<{
  gitPipelineId: string
  gitPipelineIid: string
}> = ({ gitPipelineId, gitPipelineIid }) => {
  return (
    <span>
      <a
        href={`${GITLAB_TABTOUCH_MOBI_PROJECT_URL}/-/pipelines/${gitPipelineId}`}
        target='_blank'
        rel='noreferrer'
      >
        {gitPipelineIid}
      </a>
    </span>
  )
}
