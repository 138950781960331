import React from 'react'
import styled from '@emotion/styled'
import type { BetSlipItem } from '@mobi/betslip/types'
import { colors, font, spacing } from '@mobi/component-library/Theme/Common'

export const ReceiptDetail: React.FC<Pick<BetSlipItem, 'receipt'>> = ({ receipt }) => {
  if (!receipt) return null

  return (
    <WrapperStyled>
      <span>Receipt:</span> <span>{receipt.ticketNumber}</span>
    </WrapperStyled>
  )
}

const WrapperStyled = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minHeight: '2rem',
  paddingTop: spacing.smx1,
  fontWeight: font.weight.regular,
  fontSize: font.size.sm.fontSize,
  letterSpacing: font.size.sm.letterSpacing,
  lineHeight: font.size.sm.lineHeight,
  color: colors.neutral[800],

  '> span:first-of-type': {
    fontWeight: font.weight.medium,
    marginRight: spacing.smx1,
  },
})
