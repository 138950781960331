import React from 'react'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { useExpandToggle } from '@mobi/utils/hooks/useExpandToggle'
import { RaceCodes } from '@core/Areas/Racing/Types/MeetingInformation'
import { Form } from '@core/Areas/Racing/Components/Form'
import { FOOForm } from '@core/Areas/Racing/Components/Form'
import { BlackbookControl } from '@core/Areas/Blackbook/Components/BlackbookControl'
import type { BlackbookControlProps } from '@core/Areas/Blackbook/types'
import { useRaceList } from '@core/Areas/Racing/Hooks/useRaceList/useRaceList'

const enum LocalConstants {
  animationTiming = 400,
}

export const RunnerExpanded: React.FC<RunnerExpandedProps> = ({
  starterName,
  starterType,
  meetingId,
  meetingDate,
  raceNumber,
  starterNumber,
  selectionDate,
  isFormAvailable,
  isRunnerExpanded,
  jockeyName,
  source = 'racecard',
  isFixedOddsOnly = false,
  racecourseSeq,
}) => {
  const { wrapperRef, contentRef, shouldRender, isExpanded, toggleExpanded } = useExpandToggle({
    timeoutMs: LocalConstants.animationTiming,
  })

  React.useEffect(() => {
    toggleExpanded(isRunnerExpanded)
  }, [toggleExpanded, isRunnerExpanded])

  if (!(isExpanded || shouldRender)) return null

  return (
    <WrapperStyled ref={wrapperRef} data-testid='runner-expanded'>
      <div ref={contentRef}>
        <BlackbookControl
          starterName={starterName}
          starterType={starterType}
          source={source}
          jockeyName={jockeyName}
        />

        {isFormAvailable && !isFixedOddsOnly && meetingId && (
          <MatchedForm
            meetingId={meetingId}
            meetingDate={meetingDate}
            raceNumber={raceNumber}
            starterNumber={starterNumber}
            starterName={starterName}
          />
        )}

        {isFormAvailable && isFixedOddsOnly && (
          <FOOForm
            racecourseSeq={racecourseSeq}
            meetingDate={selectionDate}
            raceNumber={raceNumber}
            starterNumber={starterNumber}
          />
        )}
      </div>
    </WrapperStyled>
  )
}

function MatchedForm({
  meetingId,
  meetingDate,
  raceNumber,
  starterNumber,
  starterName,
}: {
  meetingId: string
  meetingDate: Date
  raceNumber: number
  starterNumber: number
  starterName: string
}) {
  const { meeting } = useRaceList(meetingDate?.toString() ?? '', meetingId)

  return (
    <Form
      meetingId={meetingId as string}
      meetingDate={meetingDate as Date}
      raceNumber={raceNumber}
      starterNumber={starterNumber}
      starterName={starterName}
      courseCondition={meeting?.courseCondition}
    />
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  position: 'relative',
  overflow: 'hidden',
  height: 0,
  transition: 'height ' + LocalConstants.animationTiming + 'ms ease',
  willChange: 'height',
  backgroundColor: hexColors.whiteSmoke,

  '& > div': {
    padding: '1rem',
    borderTop: '0.1rem solid ' + hexColors.veryLightGrey,
    borderBottom: '0.1rem solid ' + hexColors.veryLightGrey,
  },

  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
    pointerEvents: 'none',
    boxShadow: 'inset 0 1.5rem 1.5rem -1.5rem rgba(0, 0, 0, 0.25)',
  },
})

// =====
// Types
// =====

type RunnerExpandedProps = {
  starterName: string
  starterType: RaceCodes
  isRunnerExpanded: boolean
  jockeyName?: string
  isFixedOddsOnly?: boolean
  selectionDate?: string
  source?: BlackbookControlProps['source']
} & (
  | {
      isFormAvailable: true
      meetingDate: Date
      raceNumber: number
      starterNumber: number
      meetingId: string
      racecourseSeq?: never
      selectionDate?: never
    }
  | {
      isFormAvailable: boolean
      isFixedOddsOnly: true
      selectionDate: string
      raceNumber: number
      starterNumber: number
      racecourseSeq?: number
      meetingDate?: never
      meetingId?: never
    }
)
