import { post } from '@classic/Foundation/Services/ApiService'
import { getRacePageDataFromApi } from '@core/Areas/Racing/Hooks/useRacePageData'
import type { ReplayInfo, VideoEntitlementDto } from './types'

export async function getReplayInfoFromApi(
  fixtureId: string,
  fixtureDate: string,
  raceNumber: number
) {
  const raceReplay = await getRaceReplayDataFromApi(fixtureId, fixtureDate, raceNumber)

  if (!raceReplay || !raceReplay.HasVideo) {
    return null
  }

  const entitlement = await getEntitlementUrlFromApi(raceReplay.VideoKey)

  if (!entitlement) {
    return null
  }

  const result: ReplayInfo = {
    raceLocation: raceReplay.RaceLocation,
    raceDate: raceReplay.Date,
    videoSource: entitlement.VideoKey,
  }

  return result
}

export function getEntitlementUrlFromApi(videoKey: string) {
  return post<VideoEntitlementDto>({
    url: '/api/videoentitlement',
    body: { encrpytedVideoKey: videoKey },
  })
}

function getRaceReplayDataFromApi(fixtureId: string, fixtureDate: string, raceNumber: number) {
  if (!fixtureId) {
    return Promise.resolve(null)
  }

  return getRacePageDataFromApi(fixtureId, fixtureDate, raceNumber).then(meeting => {
    if ('RaceReplay' in meeting) {
      return meeting.RaceReplay
    } else {
      return null
    }
  })
}
