import { initializeWithCountIfNotInitialized } from '@mobi/account/Utils/depositCount'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { attemptLogin } from './helpers'
import { Icon } from '@mobi/component-library/Common/Icon'
import { useBodyScrollLock } from '@mobi/utils/hooks/useBodyScrollLock'
import { state$ as navigationState$ } from '@core/State/Navigation/driver'
import { setIsLoginShown } from './Store'
import { getIsLoginShown } from './Store/selectors'
import { LoginContainerStyled } from './Login.styles'
import { navigateToChangeTempPassword } from '@core/Areas/ChangePassword/ChangeTemporaryPassword'
import { trackLoginSuccess } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { useLoginState } from './hooks'
import { triggerBiometricsPrompt } from '@core/Areas/Login/helpers/'
import { LoginMain, PasswordReset, AccountNumberRecovery } from './Components'

export const Login: React.FC<{}> = () => {
  const isLoginShown = useSelector(getIsLoginShown)

  const dispatch = useDispatch()
  React.useEffect(() => {
    const subscription = navigationState$
      .map(navState => navState.toJS().currentUrl)
      .skip(1)
      .distinctUntilChanged()
      .subscribe(() => dispatch(setIsLoginShown({ shouldShow: false })))

    return () => subscription?.dispose()
  }, [dispatch])

  return isLoginShown ? <LoginOverlay /> : null
}

const LoginOverlay = React.memo(() => {
  const loginContainerRef = useBodyScrollLock()
  const { state, handlers } = useLoginState()
  const { performLoginSuccessActions } = handlers
  const [isAttemptingLogin, setIsAttemptingLogin] = React.useState(false)

  React.useEffect(() => {
    const disposeBiometricsPrompt = triggerBiometricsPrompt({
      onSuccess: (accountNumber: number, password: string) => {
        setIsAttemptingLogin(true)
        attemptLogin({
          accountNumber,
          password,
          rememberAccount: true,
          keepLoggedIn: true,
        }).then(res => {
          if (res.isSuccessful) {
            performLoginSuccessActions()
            initializeWithCountIfNotInitialized(accountNumber, 2)
            trackLoginSuccess(accountNumber.toString(), true, true, true)
          } else {
            setIsAttemptingLogin(false)
            if (res.mustChangePassword) {
              navigateToChangeTempPassword({ accountNumber: accountNumber.toString(), password })
            }
          }
        })
      },
    })

    return () => {
      disposeBiometricsPrompt()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // run once on mount but don't rerender with performLoginSuccessActions

  return (
    <LoginContainerStyled data-testid='login-overlay' ref={loginContainerRef}>
      <h1>
        <Icon type='person' size='1.8rem' /> {state.workflow}
        <button
          aria-label='Close button'
          type='button'
          onClick={handlers.close}
          disabled={isAttemptingLogin}
        >
          <Icon type='cross' title='Close' />
        </button>
      </h1>

      <div>
        {state.workflow === 'Login' && <LoginMain state={state} handlers={handlers} />}

        {state.workflow === 'Password Reset' && (
          <PasswordReset
            accountNumber={state.accountNumber}
            setWorkflowState={handlers.setWorkflow}
            handleSignUp={handlers.signUp}
          />
        )}

        {state.workflow === 'Account Number Recovery' && (
          <AccountNumberRecovery
            handleSignUp={handlers.signUp}
            handleReturnToLogin={() => handlers.setWorkflow('Login')}
          />
        )}
      </div>
    </LoginContainerStyled>
  )
})
