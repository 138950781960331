import React from 'react'
import styled from '@emotion/styled'
import { layering, measurements, spacing } from '@mobi/component-library/Theme/Common'
import { Toast } from './Components/Toast'
import { globalToastEmitter } from './helpers/events'
import type { ToastItem } from './types'

const enum LocalConstants {
  PositionAbsoluteClass = 'js-toasts--absolute',
}

export const Toasts: React.FC<ToastsProps> = ({
  toastEmitter = globalToastEmitter,
  isRelativeToParent,
}) => {
  const toastsMapRef = React.useRef<ToastMap>()
  const [toasts, setToasts] = React.useState<{ map: ToastMap }>(() => ({ map: new Map() }))
  toastsMapRef.current = toasts.map

  const toastElRefDictRef = React.useRef<ToastRefDict>(new Map())
  const registerToastRefEl = React.useCallback(({ id, ref }: RegisterToastArg) => {
    toastElRefDictRef.current.set(id, ref)
  }, [])

  React.useEffect(() => {
    toastEmitter.on('addToast', toast => {
      const currentSize = toastsMapRef.current?.size || 0
      if (currentSize > 3) return

      setToasts(({ map }) => ({ map: map.set(toast.id, toast) }))
    })

    toastEmitter.on('removeToast', ({ id }) => {
      if (!toastsMapRef.current?.has(id)) return
      const toastEl = toastElRefDictRef.current.get(id)?.current
      if (!toastEl) return

      toastEl.addEventListener(
        'animationend',
        () =>
          setToasts(({ map }) => {
            map.delete(id)
            toastElRefDictRef.current.delete(id)
            return { map }
          }),
        { once: true }
      )

      toastEl.style.zIndex = '0'
      toastEl.style.animationTimingFunction = 'cubic-bezier(0.550, 0.085, 0.680, 0.530)'
      toastEl.style.animationName = 'toast-exit'
    })
  }, [toastEmitter])

  return (
    <WrapperStyled className={isRelativeToParent ? LocalConstants.PositionAbsoluteClass : ''}>
      {Array.from(toasts.map).map(([id, toast]) => (
        <Toast key={id} toast={toast} onMount={registerToastRefEl} toastEmitter={toastEmitter} />
      ))}
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  position: 'fixed',
  zIndex: layering.modalOverlay,
  left: spacing.md,
  right: spacing.md,
  bottom: '6rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: spacing.smx2,
  margin: '0 auto',
  maxWidth: measurements.mobi.maxWidth,
  minWidth: measurements.mobi.minWidth,
  pointerEvents: 'none',

  '&:empty': { visibility: 'hidden' },

  '@keyframes toast-exit': {
    '0%': { opacity: 1, transform: 'translateY(0)' },
    '70%': { opacity: 1 },
    '100%': { opacity: 0, transform: 'translateY(150%)' },
  },

  [`&.${LocalConstants.PositionAbsoluteClass}`]: {
    position: 'absolute',
    bottom: spacing.md,
  },
})

// =====
// Types
// =====

type ToastsProps =
  | { toastEmitter?: never; isRelativeToParent?: never }
  | {
      toastEmitter: typeof globalToastEmitter
      /** Enables render of toasts relative to parent using position absolute */
      isRelativeToParent: boolean
    }

type ToastMap = Map<ToastItem['id'], ToastItem>
type ToastRefDict = Map<ToastItem['id'], React.RefObject<HTMLDivElement>>
type RegisterToastArg = { id: ToastItem['id']; ref: React.RefObject<HTMLDivElement> }
