import type { BetSlipItem } from '@mobi/betslip/types/betslip'
import { isReactNativeApp, sendToNative } from '@mobi/web-native-comms/web'
import { createImageUrlFromHtml } from '@core/Areas/AccountActivity/Components/Ticket/Components/ShareBet/Components/SharePreview/helpers/createImageUrlFromHtml'
import { postShareBetDataAndGenerateLink } from '@core/Services/ShareIncoming/ShareBet/helpers/api'
import { createLoadBetDetails } from './helpers/createLoadBetDetails'

export const handleShareBet = async (
  items: BetSlipItem[],
  data: { title: string; message: string },
  shareMyBetEl: HTMLDivElement | null
): Promise<void> => {
  if (!shareMyBetEl) throw Error('No HTML element to generate preview')

  if (isReactNativeApp) {
    const [{ link }, base64Image] = await Promise.all([
      createShareBetAndPostWithBetSlip(items),
      createImageUrlFromHtml(shareMyBetEl),
    ])

    return sendToNative('SHARE_SHEET_REQUEST', {
      url: base64Image,
      title: data.title,
      subject: data.title,
      message: data.message + link + ' 🤘',
    })
  } else {
    const { link } = await createShareBetAndPostWithBetSlip(items)

    const navigatorShareData: ShareData = {
      title: data.title,
      text: data.message + link + ' 🤘',
    }

    if (navigator.canShare?.(navigatorShareData)) {
      return await navigator.share(navigatorShareData)
    }

    throw Error('No capability to share')
  }
}

// =============
// Local Helpers
// =============

async function createShareBetAndPostWithBetSlip(items: BetSlipItem[]) {
  const betsToShare = items.flatMap(item => createLoadBetDetails(item) || [])

  if (betsToShare.length === 0) throw Error('Unable to create bet to share')

  const response = await postShareBetDataAndGenerateLink(betsToShare)

  if (!response.link) throw Error('ShareBet: Error ' + response.message)

  return response
}
