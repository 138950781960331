import React from 'react'
import { useHistory } from 'react-router-dom'
import { Banner } from '@mobi/component-library/Common/V2/Banner'
import { Button } from '@mobi/component-library/Common/V2/Buttons'
import { MIN_INVESTMENT_VALUE } from '@mobi/betslip/helpers/constants'
import { useAppDispatch } from '@core/Store/hooks'
import { setBetSlipNewIsOpen } from '@core/Areas/AppHeader/Store'
import { AppRoutes } from '@core/AppRoutes'
import { state$ as userAccountState$ } from '@core/State/UserAccount/userAccountDriver'

export const LowBalanceStatus: React.FC = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()

  const [shouldDisplay, setShouldDisplay] = React.useState(false)

  React.useEffect(() => {
    const subscription = userAccountState$
      .skipWhile(state => !state.isLoggedIn)
      .map(state => state.accountBalance)
      .distinctUntilChanged()
      .subscribe(accountBalance => {
        if (accountBalance) {
          setShouldDisplay(accountBalance < MIN_INVESTMENT_VALUE)
        }
      })
    return () => {
      subscription.dispose()
    }
  }, [])

  const handleDepositClick = () => {
    dispatch(setBetSlipNewIsOpen(false))
    history.push(AppRoutes.Deposit)
  }

  if (!shouldDisplay) return null

  return (
    <Banner
      button={
        <Button color='secondary_outline' onClick={handleDepositClick} size='sm'>
          Deposit
        </Button>
      }
      onClickDismiss={() => {
        setShouldDisplay(false)
      }}
      text='Low Balance.'
      type='warning'
    />
  )
}
