import React from 'react'
import styled from '@emotion/styled'
import { useAppDispatch, useAppSelector } from '@core/Store/hooks'
import { useServerConfig } from '@core/Data/ServerConfig/useServerConfig'
import { VideoPlayerControls } from './Components/VideoPlayerControls/VideoPlayerControls'
import { handleFullscreenChange, handleVolumeChange, useFullscreen, useLogout } from './helpers'
import { useEntitlement } from './helpers/useEntitlement'
import { Hls, useVideoStream } from '@core/Utils/hooks/Video/useVideoStream'
import {
  setIsPlaying,
  setCloseSkyVideoPlayer,
  type SkyVideoPlayerState,
  setRaceReplayDurationSeconds,
  setRaceReplayElapsedSeconds,
} from '../../Store'
import { logError } from '@core/Utils'

export const VIDEO_PLAYER_PARENT_ID = 'js-vision-video-player-parent'

const channelStreams: Record<SkyVideoPlayerState['selectedChannel'], string> = {
  Sky1: 'https://skyliverwwa.akamaized.net/hls/live/2036963/sky1/index.m3u8',
  Sky2: 'https://skyliverwwa.akamaized.net/hls/live/2036964/sky2/index.m3u8',
  RacingWA: '',
  Replay: '',
} as const

const VideoPlayerComponent: React.FC<{
  parentContainer: React.RefObject<HTMLDivElement>
  videoRef: React.RefObject<HTMLVideoElement>
}> = ({ parentContainer, videoRef }) => {
  const dispatch = useAppDispatch()
  const selectedChannel = useAppSelector(state => state.skyVideoPlayer.selectedChannel)
  const isMuted = useAppSelector(state => state.skyVideoPlayer.isMuted)
  const isFullscreen = useAppSelector(state => state.skyVideoPlayer.isFullscreen)
  const raceReplayUrl = useAppSelector(state => state.skyVideoPlayer.raceReplayUrl)
  const raceReplayUrlChanged = useAppSelector(state => state.skyVideoPlayer.raceReplayUrlChanged)
  const raceReplayElapsedSeconds = useAppSelector(
    state => state.skyVideoPlayer.raceReplayElapsedSeconds
  )

  const [channelStreamUrl, setChannelStreamUrl] = React.useState(channelStreams[selectedChannel])

  useLogout()

  const serverConfig = useServerConfig()
  React.useEffect(() => {
    channelStreams.RacingWA = serverConfig?.racingWAStreamUrl || ''
    setChannelStreamUrl(channelStreams[selectedChannel])
  }, [selectedChannel, serverConfig])

  React.useEffect(() => {
    channelStreams.Replay = raceReplayUrl ?? ''
    setChannelStreamUrl(channelStreams[selectedChannel])
  }, [raceReplayUrl, selectedChannel])

  // initialise the playback position when the video during the first render, e.g. allow transition from docked/undocked/fullscreen
  React.useEffect(() => {
    if (videoRef?.current) videoRef.current.currentTime = raceReplayElapsedSeconds
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // reset the playback position whenever the URL changes
  // - the url change accounts for race replay changes AND closing the video
  // - unfortunately we can't monitor raceReplayUrl because react is occasionally fires when there are no url changes :(
  React.useEffect(() => {
    if (videoRef?.current && raceReplayUrlChanged) {
      videoRef.current.currentTime = 0
    }
  }, [raceReplayUrlChanged, videoRef])

  const onVideoError = React.useCallback(
    (error, hlsInstance) => {
      if (error.type !== 'hlsError') return
      const { type, fatal } = error.error
      if (!fatal || !hlsInstance) return

      switch (type) {
        case Hls.ErrorTypes.NETWORK_ERROR:
          hlsInstance.startLoad()
          break
        case Hls.ErrorTypes.MEDIA_ERROR:
        case Hls.ErrorTypes.OTHER_ERROR:
          hlsInstance.recoverMediaError()
          break
        default:
          dispatch(setCloseSkyVideoPlayer())
          break
      }
    },
    [dispatch]
  )

  useVideoStream({
    videoRef,
    streamUrl: channelStreamUrl,
    onError: onVideoError,
    isStream: selectedChannel != 'Replay',
  })

  useFullscreen(isFullscreen)

  React.useEffect(() => {
    const videoEl = videoRef.current
    const videoParentEl = parentContainer.current

    if (!videoEl || !videoParentEl) return undefined

    videoEl.setAttribute('webkit-playsinline', '')
    videoEl.setAttribute('x5-playsinline', '')
    videoEl.setAttribute('disableRemotePlayback', '')
    videoEl.setAttribute('disablePictureInPicture', '')

    const playVideo = () => {
      dispatch(setRaceReplayDurationSeconds(videoEl.duration))
      dispatch(setIsPlaying(true))
    }
    const stopVideo = () => dispatch(setIsPlaying(false))
    const handleTimeUpdate = () => {
      if (!videoRef?.current || isNaN(videoRef.current.currentTime)) {
        return
      }

      dispatch(setRaceReplayElapsedSeconds(videoRef.current.currentTime))
    }

    let stopTimerId: number
    const stopVideoWithDelay = () => {
      stopTimerId = window.setTimeout(stopVideo, 300)
    }

    videoEl.addEventListener('play', playVideo)
    videoEl.addEventListener('pause', stopVideoWithDelay)
    videoEl.addEventListener('ended', stopVideo)
    videoEl.addEventListener('abort', stopVideo)
    videoEl.addEventListener('volumechange', handleVolumeChange)
    videoEl.addEventListener('timeupdate', handleTimeUpdate)

    videoParentEl.addEventListener('fullscreenchange', handleFullscreenChange)
    videoParentEl.addEventListener('webkitfullscreenchange', handleFullscreenChange)
    if (videoEl.webkitSupportsPresentationMode) {
      videoEl.addEventListener('webkitpresentationmodechanged', handleFullscreenChange)
    }

    return () => {
      videoEl.removeEventListener('play', playVideo)
      videoEl.removeEventListener('pause', stopVideoWithDelay)
      videoEl.removeEventListener('ended', stopVideo)
      videoEl.removeEventListener('abort', stopVideo)
      videoEl.removeEventListener('volumechange', handleVolumeChange)
      videoEl.removeEventListener('timeupdate', handleTimeUpdate)

      videoParentEl.removeEventListener('fullscreenchange', handleFullscreenChange)
      videoParentEl.removeEventListener('webkitfullscreenchange', handleFullscreenChange)
      if (videoEl.webkitSupportsPresentationMode) {
        videoEl.removeEventListener('webkitpresentationmodechanged', handleFullscreenChange)
      }

      stopTimerId && clearTimeout(stopTimerId)

      videoEl.pause()
    }
  }, [videoRef, parentContainer, channelStreamUrl, dispatch])

  return (
    <video
      ref={videoRef}
      style={{ flex: 1, maxHeight: '100%', width: '100%' }}
      preload='auto'
      autoPlay
      playsInline
      controls={false}
      muted={isMuted}
      disablePictureInPicture
    />
  )
}

// ==================================
// Main Export with Entitlement Check
// ==================================

export const VideoPlayer = React.memo(() => {
  const videoContainerRef = React.useRef<HTMLDivElement>(null)
  const videoRef = React.useRef<HTMLVideoElement>(null)

  const dispatch = useAppDispatch()
  const isPlaying = useAppSelector(state => state.skyVideoPlayer.isPlaying)
  const isReplay = useAppSelector(state => state.skyVideoPlayer.selectedChannel === 'Replay')

  useEntitlement()

  const handlePlayClick = () => {
    if (videoRef.current) {
      videoRef.current
        .play()
        .then(() => {
          dispatch(setIsPlaying(true))
        })
        .catch(error => {
          logError('Failed to play video:', error)
        })
    }
  }

  const handlePauseClick = () => {
    if (videoRef.current) {
      videoRef.current.pause()
      dispatch(setIsPlaying(false))
    }
  }

  const handleRestartClick = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0
      videoRef.current
        .play()
        .then(() => {
          dispatch(setIsPlaying(true))
        })
        .catch(error => {
          logError('Failed to restart video:', error)
        })
    }
  }

  const handleSeekClick = (seekSeconds: number) => {
    if (videoRef.current) {
      videoRef.current.currentTime = seekSeconds
      dispatch(setRaceReplayElapsedSeconds(seekSeconds))
    }
  }

  // Resume play on tab visibility and app foreground
  React.useEffect(() => {
    const playVideo = () => {
      dispatch(setIsPlaying(document.visibilityState === 'visible'))
    }
    document.addEventListener('visibilitychange', playVideo)
    return () => {
      document.removeEventListener('visibilitychange', playVideo)
    }
  }, [dispatch])

  return (
    <VideoPlayerRatioWrapperStyled>
      <VideoPlayerRatioInnerStyled ref={videoContainerRef} id={VIDEO_PLAYER_PARENT_ID}>
        {(isReplay || isPlaying) && (
          <VideoPlayerComponent parentContainer={videoContainerRef} videoRef={videoRef} />
        )}
        <VideoPlayerControls
          parentContainer={videoContainerRef}
          onPlayClick={handlePlayClick}
          onPauseClick={handlePauseClick}
          onRestartClick={handleRestartClick}
          onSeekClick={handleSeekClick}
        />
      </VideoPlayerRatioInnerStyled>
    </VideoPlayerRatioWrapperStyled>
  )
})

// ======
// Styles
// ======

const enum LocalConstants {
  AspectRatio = '16 / 9',
}

const VideoPlayerRatioWrapperStyled = styled.div({
  position: 'relative',
  overflow: 'hidden',

  height: 0,
  paddingTop: 'min(56.25%, 100vh)', // 16:9

  '@supports (aspect-ratio: 16 / 9)': {
    height: 'auto',
    aspectRatio: LocalConstants.AspectRatio,
    paddingTop: 0,
  },
})

const VideoPlayerRatioInnerStyled = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  aspectRatio: LocalConstants.AspectRatio,
  height: '100%',
  margin: '0 auto',
  background: '#000 url("/images/vision/logo-sky-racing.png") no-repeat center center',
  backgroundSize: 'contain',
})
