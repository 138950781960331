import type { BetSlipItem, FobPropositionSelection } from '@mobi/betslip/types'
import {
  isFobDetails,
  isFobMatchedSelection,
  isFobPropositionSelection,
  isFobSportsDetails,
  isFobSportsSelection,
  isRaceDetails,
  isSameRaceMultiSelection,
  isToteSelection,
} from '@mobi/betslip/helpers/typeGuards'
import type {
  BetDetails,
  FOOBetDetails,
  LoadBetDetails,
  SportDetails,
} from '@core/Utils/betting/loadBet/types'
import { getPlanSequence } from './getPlanSequence'

export const createLoadBetDetails = (item: BetSlipItem): LoadBetDetails | null => {
  const planSeq = getPlanSequence(item)

  if (isFobPropositionSelection(item.selection) && isFobDetails(item.selectionDetails)) {
    const fooBetDetails: FOOBetDetails = {
      competitionName: item.selectionDetails.competitionName as string,
      competitionSeq: item.selectionDetails.event.toString(),
      propositionSeq: +item.selection.propositionSeq,
      betType: item.selectionDetails.marketName,
      sportName: item.selectionDetails.sportName as string,
      eventStartDateTime: item.selectionDetails.eventStartTime.toString(),
    }
    return fooBetDetails
  }

  if (isFobSportsSelection(item.selection) && isFobSportsDetails(item.selectionDetails)) {
    const sportDetails: SportDetails = {
      eventId: item.selectionDetails.eventId,
      externalBetId: item.selection.externalBetId,
    }
    return sportDetails
  }

  if (isToteSelection(item.selection) && isRaceDetails(item.selectionDetails)) {
    const selectionString = item.selection.selectionString
    const numberOfCombinations = item.selection.numberOfCombinations

    const toteBetDetails: BetDetails = {
      fixtureId: item.selectionDetails.races[0].meetingId,
      fixtureDate: new Date(item.selectionDetails.races[0].meetingDate),
      races:
        item.selectionDetails.races.length === 1
          ? [item.selectionDetails.races[0].raceNumber]
          : item.selectionDetails.races.map(race => race.raceNumber),
      selectionString,
      planSeq,
      numberOfCombinations,
      betType: item.selection.betType,
    }
    return toteBetDetails
  }

  // TODO: Add Same Race Multi
  if (isSameRaceMultiSelection(item.selection)) {
    return null
  }

  if (isFobMatchedSelection(item.selection) && isRaceDetails(item.selectionDetails)) {
    const selectionString = item.selection.acceptorNumber.toString()
    const numberOfCombinations = 1
    const betType = `${(item.selection as FobPropositionSelection).marketCode || 'Win & Place'} - Fixed`

    const fixedMatchedBetDetails: BetDetails = {
      fixtureId: item.selectionDetails.races[0].meetingId,
      fixtureDate: new Date(item.selectionDetails.races[0].meetingDate),
      races: [item.selectionDetails.races[0].raceNumber],
      selectionString,
      planSeq,
      numberOfCombinations,
      betType,
    }
    return fixedMatchedBetDetails
  }

  return null
}
