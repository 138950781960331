import React from 'react'

export const CookieManagement = () => {
  return (
    <>
      <h3>Set a Cookie</h3>
      <p>
        <label>
          Presets{' '}
          <select data-bind='value: selectedCookieName'>
            <option value=''>Custom</option>
            <option value='webapp-version'>webapp-version</option>
            <option value='tt-sg'>tt-sg</option>
            <option value='CanarySegment'>CanarySegment</option>
            <option value='androidapp'>androidapp</option>
            <option value='iosapp'>iosapp</option>
          </select>
        </label>
      </p>

      <p data-bind='visible: !selectedCookieName()'>
        <label>
          Cookie Name: <input data-bind='value: customCookieName' />{' '}
        </label>
      </p>

      <p>
        <label>
          Cookie Value: <input data-bind='value: cookieValue' />
        </label>
      </p>

      <p>
        <label>
          Cookie Expiry
          <select data-bind="options: cookieExpiryOptions, optionsText: 'label', optionsValue: 'value', value: selectedExpiryPreset"></select>
        </label>
      </p>

      <p data-bind='visible: customExpirySelected'>
        <label>
          Custom
          <input type='datetime-local' data-bind='value: customCookieExpiry' />{' '}
        </label>
      </p>

      <p>
        <button data-bind='click: clearCookie' className='fz-12 nue-button nue-secondary'>
          Clear Cookie
        </button>
        &nbsp;&nbsp;
        <button data-bind='click: setCookie' className='fz-12 nue-button nue-primary'>
          Set Cookie
        </button>
      </p>

      <p data-bind='visible: cookieResultMessage, text: cookieResultMessage'></p>

      <h3>Cookie Information</h3>
      <dl className='fz-14 pl1em'>
        <dt>Device</dt>
        <dd className='ml1em'>
          <span data-bind='text: deviceId'></span>
        </dd>
        <dt>First Load</dt>
        <dd className='ml1em'>
          <span data-bind='text: firstLoad'></span>
        </dd>
        <dt>Segment:</dt>
        <dd className='ml1em'>
          <span data-bind='text: segment'></span>
        </dd>
      </dl>
    </>
  )
}
