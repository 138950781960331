import styled from '@emotion/styled'
import { fontFamilies, fontFamiliesBold } from '@core/Settings'
import { hexColors } from '@mobi/settings'

export const ACC_ACT_NAV_ACTIVE_CLASS = 'account-activity__nav--active'
export const ACC_ACT_FLEX_1 = 'account-activity__flex-1'

export const AccountActivityHeaderContainerStyled = styled.div({
  display: 'block',
  top: 0,
  backfaceVisibility: 'hidden',
  boxShadow:
    '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)',
  margin: 0,
  width: '100%',
  maxWidth: '80em',
  transform: 'translateZ(0)',
  zIndex: 9,
})

const KAMBI_BET_HISTORY_BAR_HEIGHT = '4.8rem' // 48px on Kambi
const KAMBI_BET_HISTORY_BAR_COLOUR = '#29193d'

export const AccountActivityNavStyled = styled.div({
  display: 'flex',
  alignItems: 'center',
  height: KAMBI_BET_HISTORY_BAR_HEIGHT,
  padding: '0 0.8rem',
  backgroundColor: KAMBI_BET_HISTORY_BAR_COLOUR,

  '& > a': {
    display: 'flex',
    alignItems: 'center',
    height: KAMBI_BET_HISTORY_BAR_HEIGHT,
    fontSize: '1.3rem',
    fontFamily: fontFamilies.brand,
    textDecoration: 'none',

    '& > span': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 1rem',
      minWidth: 'auto',
      height: '2.4rem',
      color: 'rgba(255, 255, 255, 0.7)',
      background: 'transparent',
      borderRadius: '1.6rem',
      marginRight: '0',

      '&:hover': {
        background: 'rgba(255, 255, 255, 0.12)',
      },

      [`&.${ACC_ACT_NAV_ACTIVE_CLASS}`]: {
        fontFamily: fontFamiliesBold.brand,
        color: hexColors.white,
        background: 'rgba(255, 255, 255, 0.12)',
        pointerEvents: 'none',
      },
    },
  },
})

export const AccountActivitySecondaryHeaderStyled = styled.div({
  display: 'flex',
  position: 'relative',
  height: '4rem',
  fontSize: '1.2rem',
  background: hexColors.white,

  [`& .${ACC_ACT_FLEX_1}`]: {
    position: 'relative',
    flex: 1,
    display: 'flex',
  },
})

export const AccountActivityDateLabelStyled = styled.span({
  display: 'flex',
  alignItems: 'center',
  marginLeft: '1rem',
  marginRight: '1rem',
  fontSize: '1.4rem',
  color: 'grey',
})

export const AccountActivityPendingSortContainerStyled = styled.div({
  minWidth: '9.5rem',
  border: 0,
  margin: 0,
  paddingLeft: '1rem',
  display: 'inline-flex',
  position: 'relative',
  flexDirection: 'column',
  verticalAlign: 'top',

  '& > select': {
    display: 'block',
    outline: 'none',
    cursor: 'pointer',
    boxSizing: 'border-box',

    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,

    appearance: 'none',
    MozAppearance: 'none',
    MozUserSelect: 'none',
    WebkitAppearance: 'none',
    borderRadius: 0,
    border: 0,
    background: 'none',

    margin: 0,
    padding: '1rem',
    maxHeight: '4em',
    width: '100%',

    fontSize: '1.4rem',
    color: hexColors.studio,
  },

  '& > svg': {
    position: 'absolute',
    right: 0,
    width: '2.5rem',
    top: '50%',
    transform: 'translateY(-50%)',
    pointerEvents: 'none',
    fill: hexColors.studio,
  },
})

export const SecondayHeaderIconStyled = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: 'auto',
  width: '4rem',

  svg: {
    fill: hexColors.nero,
  },
})

export const SecondaryDateSelectStyled = styled.select({
  position: 'absolute',
  left: 0,
  right: 0,
  top: '50%',
  width: '100%',
  minHeight: '4rem',
  transform: 'translateY(-50%)',
  height: '100%',
  opacity: 0.01,
  fontSize: '1.4rem',
})

export const DateWrapperStyled = styled.div({
  position: 'relative',
  flex: 1,
  display: 'flex',
  alignItems: 'center',
})

export const RefreshButtonStyled = styled(SecondayHeaderIconStyled)({
  padding: 0,
  border: 0,
  background: 'none',
  fontSize: 'inherit',
  textDecoration: 'none',
}).withComponent('button')

export const RebetCountBadgeStyled = styled.span<{ isMaxCount?: boolean }>(
  {
    display: 'inline-block',
    marginLeft: '0.5rem',
    marginRight: 'auto',
    width: '2rem',
    minWidth: '2rem',
    height: '2rem',
    borderRadius: '50%',
    lineHeight: '2rem',
    textAlign: 'center',
    backgroundColor: hexColors.freeSpeechRed,
    color: hexColors.white,
  },
  ({ isMaxCount = false }) => ({
    fontSize: isMaxCount ? '1rem' : '1.2rem',
    ...(isMaxCount && {
      '&::after': {
        content: '"+"',
        letterSpacing: '-2px',
      },
    }),
  })
)
