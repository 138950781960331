import styled from '@emotion/styled'
import React from 'react'
import dayjs from '@mobi/utils/date/extendedDayjs'
import { font } from '@mobi/component-library/Theme/Common'
import { useBodyScrollLock } from '@mobi/utils/hooks/useBodyScrollLock'

export const ProgressBar: React.FC<{
  elapsedSeconds: number
  durationSeconds: number
  isFullscreen: boolean
  onClick?: (seekSeconds: number) => void
  onInteractionStart?: () => void
  onInteractionEnd?: () => void
}> = ({
  elapsedSeconds,
  durationSeconds,
  isFullscreen,
  onClick,
  onInteractionStart,
  onInteractionEnd,
}) => {
  const progressBarRef = useBodyScrollLock(isFullscreen)

  // make sure progressPercentage is a number
  const progressPercentage = (elapsedSeconds / durationSeconds) * 100 || 0

  const handleSeek = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation()
    if (onClick) onClick((durationSeconds * e.currentTarget.valueAsNumber) / 100)
  }

  const handleInteractionStart = (e: React.SyntheticEvent<HTMLInputElement>) => {
    e.stopPropagation()
    if (onInteractionStart) onInteractionStart()
  }

  const handleInteractionEnd = (e: React.SyntheticEvent<HTMLInputElement>) => {
    e.stopPropagation()
    if (onInteractionEnd) onInteractionEnd()
  }

  const formatSeconds = (seconds: number): string =>
    dayjs.duration(seconds, 'seconds').format('m:ss')

  return (
    <ProgressBarContainerStyled ref={progressBarRef}>
      <ProgressBarDurationStyled>{formatSeconds(elapsedSeconds)}</ProgressBarDurationStyled>
      <ProgressBarStyled
        type='range'
        min='0'
        max='100'
        step='0.1'
        value={progressPercentage}
        onChange={handleSeek}
        onMouseDown={handleInteractionStart}
        onMouseUp={handleInteractionEnd}
        onTouchStartCapture={handleInteractionStart}
        onTouchEndCapture={handleInteractionEnd}
        data-js-allow-touch-move
      />
      <ProgressBarDurationStyled>{formatSeconds(durationSeconds)}</ProgressBarDurationStyled>
    </ProgressBarContainerStyled>
  )
}

const ProgressBarContainerStyled = styled.div({
  display: 'flex',
  flex: 1,
  gap: '1rem',
  margin: '0 1rem',
  zIndex: 2,
  position: 'relative',
  pointerEvents: 'auto',
  alignItems: 'center',
})

const ProgressBarDurationStyled = styled.div({
  fontSize: font.size.sm.fontSize,
  fontFamily: font.family.primary,
  fontWeight: font.weight.medium,
})

const ProgressBarStyled = styled.input({
  width: '100%',
  height: '2rem',
  appearance: 'none',
  background: 'transparent',
  cursor: 'pointer',
  overflow: 'hidden',
  '::-webkit-slider-runnable-track': {
    width: '100%',
    height: '0.5rem',
    borderRadius: '0.25rem',
    backgroundColor: 'rgba(160, 160, 160, 1)',
  },
  '::-moz-range-track': {
    width: '100%',
    height: '0.75rem',
    borderRadius: '0.5rem',
    backgroundColor: 'rgba(160, 160, 160, 1)',
  },
  '::-moz-range-progress': {
    height: '0.75rem',
    borderRadius: '0.5rem',
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
  '::-webkit-slider-thumb': {
    height: '1.5rem',
    width: '1.5rem',
    background: 'rgba(255, 255, 255, 1)',
    borderImage: 'linear-gradient(to right, rgb(255,255,255), rgb(160,160,160))',
    borderImageSlice: '0 1',
    borderImageWidth: '0.5vh 100vw',
    borderImageOutset: '0 100vw',
    borderImageRepeat: 'stretch',
    borderRadius: '50%',
    WebkitAppearance: 'none',
    appearance: 'none',
    marginTop: '-0.5rem',
    transition: '0.3s',
  },
  '::-moz-range-thumb': {
    height: '1.5rem',
    width: '1.5rem',
    background: 'rgba(255, 255, 255, 1)',
    borderImage: 'linear-gradient(to right, rgb(255,255,255), rgb(160,160,160))',
    borderImageSlice: '0 1',
    borderImageWidth: '0.5vh 100vw',
    borderImageOutset: '0 100vw',
    borderImageRepeat: 'stretch',
    borderRadius: '50%',
    WebkitAppearance: 'none',
    appearance: 'none',
    transition: '0.3s',
  },
})
