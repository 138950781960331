import React from 'react'
import { meetingIdToContestType } from '@core/Areas/Racing/helpers'
import type { Tabs, Ticket } from '@core/Areas/AccountActivity/types'
import {
  getTicketLayoutType,
  isFobRacingWithIncorrectStarterNumbers,
} from '@core/Areas/AccountActivity/Components/Ticket/helpers'
import { BlackbookButton } from './Components/BlackbookButton'
import { BlackbookJockey } from './Components/BlackbookJockey'
import styled from '@emotion/styled'
import { colors, font, spacing } from '@mobi/component-library/Theme/Common'
import { Icon } from '@mobi/component-library/Common/V2'

export const BlackbookButtons: React.FC<{
  ticket: Ticket
  activeTab: Tabs
}> = ({ ticket, activeTab }) => {
  const ticketLayoutType = getTicketLayoutType(ticket)

  const shouldDisplayBlackbookControl =
    ticketLayoutType === 'single-single' &&
    !isFobRacingWithIncorrectStarterNumbers(ticket.planSeq, ticket.legs[0].betType)

  if (!shouldDisplayBlackbookControl) return null

  const leg = ticket.legs[0]
  const starterName = leg.selections[0].selectionDetails[0].acceptorDetails[0]?.name.toUpperCase()
  const starterType = meetingIdToContestType(leg.fixtureId, true)

  if (!(starterName && starterType)) return null

  return (
    <WrapperStyled data-testid='blackbook-buttons'>
      <h4>
        <Icon name='LineBookmarkAdd' size='1.6rem' /> Blackbook
      </h4>

      <div>
        <BlackbookButton
          starterName={starterName}
          starterType={starterType}
          entryType='Runner'
          isReady
          source={activeTab as Extract<Tabs, 'pending' | 'results'>}
        />

        {starterType === 'Races' && (
          <BlackbookJockey
            starterName={starterName}
            fixtureId={leg.fixtureId}
            fixtureDate={leg.fixtureDate}
            raceNumber={leg.raceNumber}
            source={activeTab as Extract<Tabs, 'pending' | 'results'>}
          />
        )}
      </div>
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',

  h4: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.smx2,
    margin: 0,
    padding: `${spacing.smx1} ${spacing.smx2}`,
    textAlign: 'unset',
    fontSize: font.size.md.fontSize,
    letterSpacing: font.size.md.letterSpacing,
    lineHeight: font.size.md.lineHeight,
    fontWeight: font.weight.regular,
    color: colors.black,
  },

  '> div': {
    display: 'flex',
    justifyContent: 'space-between',
    gap: spacing.smx1,
  },
})
