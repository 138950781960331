import styled from '@emotion/styled'
import { Tab, TabList } from 'react-tabs'
import { hexColors } from '@mobi/settings'
import { InputField } from '@mobi/component-library/Common/Input'
import { DepositLimitComponentTheme } from '../../themes'
import { ButtonBlockStyled } from '@mobi/component-library/Common/Buttons'

interface Theme {
  theme?: DepositLimitComponentTheme
}

export const DepositLimitsContainerStyled = styled('div')<Theme>(
  {
    boxSizing: 'border-box',
    height: '100%',
    textAlign: 'left',
  },
  ({ theme }) => ({
    fontSize: theme.container.text.size,
    padding: theme.container.padding,
    margin: theme.container.margin,
    width: theme.container.width,
  })
)

export const ViewTitleStyled = styled('div')({
  padding: '1rem 0 0 0',
  color: hexColors.dimGrey,
})

export const ViewValueStyled = styled('div')({
  padding: '0.5rem 0 0.75rem 0',
  fontSize: '1.8rem',
})

export const EditButtonContainer = styled('div')({
  margin: '3rem 0',
})

export const FieldSetStyled = styled('fieldset')({
  borderStyle: 'none',
})

export const OrderedListStyled = styled('ol')({
  fontSize: 'inherit',
  listStyleType: 'none',
  padding: 0,
})

interface OrderListItemStyledProps {
  hasUnderline?: boolean
}

export const OrderedListItemStyled = styled('li')<OrderListItemStyledProps>(
  {
    margin: '0.5rem 0',
  },

  ({ hasUnderline }) => ({
    borderBottom: hasUnderline ? `1px solid ${hexColors.veryLightGrey}` : undefined,
  })
)

export const ListItemSubtitleStyled = styled('span')<Theme>(
  {
    paddingLeft: '3rem',
    display: 'inline-block',
  },
  ({ theme }) => ({
    paddingLeft: theme.radio.paddingLeft,
  })
)

export const AmountInputStyled = styled(InputField)<Theme>(
  {
    fontWeight: 'bold',
    textAlign: 'left',
  },
  ({ theme }) => ({
    fontSize: theme.input.fontSize,
    padding: theme.input.padding,
  })
)

export const ButtonBlockThemed = styled(ButtonBlockStyled)<Theme>(({ theme }) => ({
  fontSize: theme.button.fontSize,
  height: theme.button.height,
}))

export const AccountHolderTabList = styled(TabList)({
  borderBottom: `0.1rem solid ${hexColors.chocolate}`,
  display: 'flex',
  flexWrap: 'nowrap',
  margin: '0 0 2rem',
  padding: 0,
})
// Unfortunately typings for react-tabs are out of date, but `tabsRole` is required to use
// react-tabs in combination with emotion (styled components).
// See: https://github.com/reactjs/react-tabs#custom-components
// eslint-disable-next-line @typescript-eslint/no-explicit-any
;(AccountHolderTabList as any).tabsRole = 'TabList'

const tabPadding = 1 // rem
const selectedTabBorderWidth = 0.4 // rem

export const AccountHolderTab = styled(Tab)({
  color: hexColors.dimGrey,
  cursor: 'pointer',
  flex: '1 0',
  fontSize: '1.2rem',
  listStyle: 'none',
  padding: `${tabPadding}rem`,
  textAlign: 'center',
  width: '50%',

  '&.selected': {
    paddingBottom: `${tabPadding - selectedTabBorderWidth}rem`,
    borderBottom: `${selectedTabBorderWidth}rem solid ${hexColors.chocolate}`,
    color: hexColors.chocolate,
  },
})
// Adding a custom class name for the `select` state in order to style it above.
AccountHolderTab.defaultProps = { selectedClassName: 'selected' }

// Unfortunately typings for react-tabs are out of date, but `tabsRole` is required to use
// react-tabs in combination with emotion (styled components).
// See: https://github.com/reactjs/react-tabs#custom-components
// eslint-disable-next-line @typescript-eslint/no-explicit-any
;(AccountHolderTab as any).tabsRole = 'Tab'

export const TabLabel = styled('strong')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
})
