import React from 'react'
import { useQuery } from 'react-query'
import styled from '@emotion/styled'
import type { ToteSportsSelection, ToteSportsDetails } from '@mobi/betslip/types'
import { QuickbetSelection } from '@core/Areas/Quickbet/signals'
import { BettingDrawer } from '@core/Components/BettingDrawer'
import { useObservable } from '@core/Utils/hooks'
import { state$, defaultState, Selections } from '../../driver'
import { getPool, queryDefaults, ToteSportsPool } from '../../data/toteSports'
import { calculateSelectionString } from '../../helpers/calculator'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { handleBetSelection } from '@core/Utils/betting/handleBetSelection'

const EmptyBettingDrawerStyled = styled.div({ height: '8rem' })

export const TippingBettingDrawer = ({ poolKey }: { poolKey: string }): JSX.Element | null => {
  const { selections } = useObservable(state$, defaultState)

  const {
    isLoading,
    isError,
    data: pool,
  } = useQuery<ToteSportsPool>({
    ...queryDefaults,
    queryKey: [queryKeys.toteSportsPool, poolKey],
    queryFn: () => getPool(poolKey),
  })

  if (isLoading || isError || !pool || !selections) return null

  const onQuickbetClick = (): void => {
    handleBetSelection({
      betFlow: { location: 'Quickbet' },
      selection: createSelection(pool, selections),
    })
  }

  const isValidBet =
    Object.keys(selections).length === pool.games.length &&
    Object.values(selections).every(
      selection => selection.isHome || selection.isDraw || selection.isAway
    )

  return (
    <>
      <BettingDrawer open={isValidBet} handleQuickbetClick={onQuickbetClick} />
      {isValidBet && <EmptyBettingDrawerStyled />}
    </>
  )
}

const createSelection = (pool: ToteSportsPool, selections: Selections): QuickbetSelection => {
  const selection: ToteSportsSelection = {
    poolSequence: pool.seq,
    betSelections: calculateSelectionString(selections),
  }

  const selectionDetails: ToteSportsDetails = {
    poolName: `${pool.name} ${pool.nameSuffix} ${pool.numberOfGames}`,
    games: pool.games.map(game => ({
      homeTeamName: game.homeTeamName,
      awayTeamName: game.awayTeamName,
    })),
  }

  return {
    bettingType: 'tote-sports-tipping',
    selection,
    selectionDetails,
    isEachWayAvailable: false,
    shouldAllowPlaceInvestment: false,
  }
}
