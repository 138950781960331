/**
 * Standard colours as defined in Figma
 *
 * @todo Implement remaining colours
 * @see apps-shared/component-library/src/Theme/ColorScheme.stories.tsx
 */
export const colors = {
  white: '#FFF',
  black: '#000',
  greyDark: '#333',
  transparent: 'transparent',
  neutral: {
    100: '#F7F6F7',
    200: '#F0EFF1',
    300: '#E1DFE3',
    400: '#D2CFD5',
    500: '#B4B0B9',
    600: '#9D99A2',
    700: '#7E7A82',
    800: '#5E5C61',
    900: '#3F3D41',
  },
  // Some of these are the same, question with UX team as to why
  surface: {
    50: '#FAFBFB',
    100: '#FAFBFB',
    200: '#F6F6F7',
    300: '#F1F2F3',
    400: '#EDEEEF',
    500: '#F1F2F3',
  },
  studio: {
    50: '#F3EDF7',
    100: '#E6DAEF',
    200: '#CDB5DF',
    300: '#B490CF',
    400: '#9B6BBF',
    500: '#8246AF',
    600: '#68388C',
    700: '#4E2A69',
    800: '#341C46',
    900: '#1A0E23',
  },
  lavender: {
    50: '#F6EFFC',
    100: '#ECDFF8',
    200: '#DABFF1',
    300: '#C7A0EB',
    400: '#B580E4',
    500: '#A260DD',
    600: '#824DB1',
    700: '#613A85',
    800: '#412658',
    900: '#20132C',
  },
  mariner: {
    50: '#EAF1F9',
    100: '#D5E3F2',
    200: '#AAC8E5',
    300: '#80ACD9',
    400: '#5591CC',
    500: '#2B75BF',
    600: '#225E99',
    700: '#1A4673',
    800: '#112F4C',
    900: '#091726',
  },
  error: {
    50: '#FDEBEA',
    100: '#FFEBE7',
    200: '#FFDDD6',
    300: '#FF9B88',
    400: '#F75C46',
    500: '#EA3829',
    600: '#D31510',
    700: '#B40000',
    800: '#930000',
  },
  success: {
    50: '#E6F3E6',
    100: '#CCE8CC',
    200: '#99D099',
    300: '#66B966',
    400: '#33A133',
    500: '#008A00',
    600: '#006E00',
    700: '#005300',
    800: '#003700',
  },
  warning: {
    50: '#FFF6EB',
    100: '#FFECCC',
    200: '#FFDFAD',
    300: '#FDD291',
    400: '#FFBB63',
    500: '#FFA037',
    600: '#F68511',
    700: '#E46F00',
    800: '#CB5D00',
  },
  bondi: {
    50: '#E6F7F7',
    100: '#CCEFEF',
    200: '#9ADFDF',
    300: '#67D0CE',
    400: '#35C0BE',
    500: '#028D8B',
    600: '#028D8B',
    700: '#016A68',
    800: '#014646',
    900: '#002323',
  },
  orange: {
    50: '#FEF6E6',
    100: '#FCEECC',
    200: '#FADD99',
    300: '#F7CB66',
    400: '#F5BA33',
    500: '#F2A900',
    600: '#C28700',
    700: '#916500',
    800: '#614400',
    900: '#302200',
  },
  chocolate: {
    50: '#FCF0E8',
    100: '#F8E2D1',
    200: '#F1C5A3',
    300: '#EAA774',
    400: '#E38A46',
    500: '#DC6D18',
    600: '#B05713',
    700: '#84410E',
    800: '#582C0A',
    900: '#2C1605',
  },
  sushi: {
    50: '#F0F5EB',
    100: '#E1ECD7',
    200: '#C3D9AF',
    300: '#A4C588',
    400: '#86B260',
    500: '#689F38',
    600: '#537F2D',
    700: '#3E5F22',
    800: '#2A4016',
    900: '#15200B',
  },
  celery: {
    50: '#E6F4E7',
    100: '#CDFCBF',
    200: '#96EE85',
    300: '#72E06A',
    400: '#27BB36',
    500: '#009112',
    600: '#007C0F',
    700: '#00670F',
    800: '#00530D',
    900: '#003007',
  },
} as const
