import React from 'react'
import { BetSlipItem } from '@mobi/betslip/types'
import { getHostHelpers } from '@mobi/betslip/helpers/hostHelpers'
import { ShareButton } from '@mobi/betslip/Components/Common/BetCard/Components/FooterButtons/FooterButtons'
import { ShareMyBetTray } from './Components/ShareMyBetTray'

export const ShareMyBet: React.FC<{ items: BetSlipItem[]; isBetPlaced: boolean }> = ({
  items,
  isBetPlaced,
}) => {
  const [isShareMyBetTrayOpen, setIsShareMyBetTrayOpen] = React.useState(false)

  const hostHelpers = getHostHelpers()
  const isShareBetAllowed = hostHelpers.canShareBet(items)

  if (!isShareBetAllowed) {
    return null
  }

  const handleOpen = () => setIsShareMyBetTrayOpen(true)
  const handleClose = () => setIsShareMyBetTrayOpen(false)

  return (
    <>
      <ShareButton onClick={handleOpen} />

      <ShareMyBetTray
        isOpen={isShareMyBetTrayOpen}
        headerTitle='Share my bet'
        onDismiss={handleClose}
        items={items}
        isBetPlaced={isBetPlaced}
      />
    </>
  )
}
