import React from 'react'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { Icon } from '@mobi/component-library/Common/Icon'
import { font } from '@mobi/component-library/Theme/Common'
import type { RacesFormRaceResponse } from '@core/Areas/Racing/Hooks/useRaceFormData/api'
import { RaceReplayKnockout } from './RaceReplayKnockout'
import { useFeature } from '@core/Utils/hooks'
import { RaceCodesLowerCase } from '@core/Areas/Racing/Types/MeetingInformation'

export let HistoryHeader: React.FC<
  {
    onClick: () => void
    isExpanded: boolean
  } & Pick<
    RacesFormRaceResponse['Forms'][0]['RaceHistories'][0],
    | 'Place'
    | 'RaceNumber'
    | 'TrackName'
    | 'FixtureDate'
    | 'Distance'
    | 'RaceReplay'
    | 'StarterName'
    | 'StarterNumber'
  > &
    Partial<Pick<RacesFormRaceResponse['Forms'][0]['RaceHistories'][0], 'FieldSize'>> & {
      RaceCode: RaceCodesLowerCase
    }
>
HistoryHeader = ({
  onClick,
  isExpanded,
  Place,
  FieldSize,
  RaceNumber,
  TrackName,
  FixtureDate,
  Distance,
  RaceReplay,
  StarterName,
  StarterNumber,
  RaceCode,
}) => {
  const stopClickBubblingUp = (event: React.MouseEvent) => {
    event.stopPropagation()
  }
  const hasRaceReplayImprovements = useFeature('RACE_REPLAY_IMPROVEMENTS')
  return (
    <HistoryHeaderStyled onClick={onClick} data-testid='runner-form-history-header'>
      <span className='place'>
        {Place}
        {FieldSize ? `/${FieldSize}` : ''}
      </span>
      <span className='track-name'>
        {RaceNumber} {TrackName}
      </span>
      <span>{FixtureDate}</span>
      <span>{Distance}</span>
      <div className='replay-button' onClick={stopClickBubblingUp}>
        {hasRaceReplayImprovements && RaceReplay.HasVideo && (
          <RaceReplayKnockout
            RaceReplay={RaceReplay}
            RaceOverlay={{
              Place,
              RaceNumber,
              TrackName,
              StarterName,
              StarterNumber,
              FixtureDate,
            }}
            RaceCode={RaceCode}
          />
        )}
      </div>
      <Icon size='1.5rem' type={isExpanded ? 'arrowup' : 'arrowdown'} />
    </HistoryHeaderStyled>
  )
}

const HistoryHeaderStyled = styled.h6({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '4rem',
  background: 'white',
  boxShadow: '0rem 0.1rem 0.2rem rgba(26, 14, 35, 0.05)',
  borderLeft: '0.1rem solid hexColors.nero',
  borderRight: '0.1rem solid hexColors.nero',
  borderBottom: '0.05rem solid hexColors.nero',
  margin: 0,
  padding: '0 1rem',
  fontSize: font.size.sm.fontSize,
  fontFamily: font.family.primary,
  lineHeight: font.size.sm.lineHeight,
  border: '0.1rem solid ' + hexColors.grey3,
  borderTop: 0,

  span: {
    textAlign: 'left',
    '&.place': {
      flexBasis: '10%',
    },
    '&.track-name': {
      textOverflow: 'ellipsis',
      maxWidth: '20%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      flexBasis: '30%',
    },
  },
  '.place, .track-name': {
    color: 'black',
    fontSize: font.size.sm.fontSize,
    fontWeight: font.weight.semibold,
    lineHeight: font.size.sm.lineHeight,
    wordWrap: 'break-word',
  },
  '.replay-button': {
    display: 'flex',
    flexBasis: '20%',
    justifyContent: 'flex-end',
  },
  '&:first-of-type': {
    borderTop: '0.1rem solid ' + hexColors.grey3,
  },
})
