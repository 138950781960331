import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export const HeaderSelectionInfoStyled = styled.div({
  display: 'flex',
  flex: 1,
  wordBreak: 'break-word',
  fontSize: '1.2rem',

  '& > div': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  h3: {
    display: 'flex',
    background: 'revert',
    backgroundColor: 'transparent',
    padding: 0,
    margin: 0,
    color: hexColors.nero,
    fontSize: '1.4rem',
    fontWeight: 'bold',
    lineHeight: '1.8rem',
    textTransform: 'none',
    paddingBottom: '0.2rem',

    // Number for Blackbook Ind
    span: {
      padding: '0 0.5rem',
      marginRight: '0.3rem',
    },
  },

  'span:not(h3 span), ul li': {
    display: 'block',
    lineHeight: 1.2,
    color: hexColors.grey,
  },

  'ul li.same-race-multi-acceptor span': {
    display: 'inline',
  },

  'ul li.same-race-multi-acceptor span:first-of-type': {
    display: 'inline-block',
    width: '4rem',
  },

  // Used by Multi legs in minimised view
  ul: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },

  'span + ul': {
    paddingTop: '0.5rem',
  },
})

export const HeaderSelectionIconBoxStyled = styled.div({
  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '3rem',
  height: '3rem',
  borderRadius: '50%',
  marginRight: '1rem',
  background: hexColors.gainsboro,
  color: hexColors.dimGrey,
})
