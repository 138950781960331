import React, { forwardRef } from 'react'
import styled from '@emotion/styled'
import { Accordion } from '@mobi/component-library/Common/V2/Accordion'
import { spacing } from '@mobi/component-library/Theme/Common'
import { useAppSelector } from '@mobi/betslip/Store/hooks'
import type { BetSlipItem } from '@mobi/betslip/types'
import { SingleItem } from './Components/SingleItem'
import { filterItemsForWorkflow } from './helpers/filterItemsForWorkflow'

export const Singles = forwardRef<HTMLDivElement, { items: BetSlipItem[] }>(({ items }, ref) => {
  const workflowStatus = useAppSelector(state => state.betslip.workflow.currentStatus)

  const filteredItems: BetSlipItem[] = filterItemsForWorkflow(items, workflowStatus)

  if (!filteredItems || filteredItems.length === 0) return null

  return (
    <Accordion
      title={`Singles (${filteredItems.length})`}
      shouldStartExpanded
      shouldUseDefaultTheme={false}
      ref={ref}
    >
      <ListStyled>
        {filteredItems.map(item => (
          <SingleItem key={item.id} item={item} />
        ))}
      </ListStyled>
    </Accordion>
  )
})

// ======
// Styles
// ======

const ListStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.smx1,
  paddingBottom: spacing.smx1,
})
