import React from 'react'

export const VersionInfo = () => {
  const versionInfo = getVersionInfo()

  if (!versionInfo) return null

  return (
    <>
      <h3>Version Info</h3>
      <p>Version: {versionInfo.version}</p>
      <p>Git Commit SHA: {versionInfo.gitCommitShaShort}</p>
      <p>User: {versionInfo.gitUsername}</p>
      <p>
        Git Pipeline:{' '}
        <a href={versionInfo.gitPipelineUrl} target='_blank' rel='noreferrer'>
          {versionInfo.gitPipelineIid}
        </a>
      </p>
      <p>
        <a href={versionInfo.gitProjectUrl} target='_blank' rel='noreferrer'>
          GitLab Project
        </a>
      </p>
    </>
  )
}

const getVersionInfo = () => {
  return window?.TABTOUCH?.versionInfo
}
