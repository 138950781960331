import React from 'react'

import { MainContainer } from '@core/Components/Containers'
import { ContentHeader } from '@core/Components/ContentHeader'
import { useBiometricsStatus } from '@mobi/account/Areas/Verification/Biometrics/Hooks/useBiometricsStatus'
import {
  BiometricsPageSubHeaderStyled,
  ContentBodyStyled,
  GeneralSettingStyled,
} from './BiometricsPage.styles'
import { BiometricsToggle } from '@mobi/account/Areas/Verification/Biometrics/Components/BiometricsToggle'
import { trackBiometricsToggle } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { keys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'
import { sendToNative } from '@mobi/web-native-comms/web'
import styled from '@emotion/styled'

export function BiometricsPage(): JSX.Element {
  const testId = { 'data-tid-biometrics-page-container': '' }
  const { isBiometricsStatusReady, isBiometricsEnabled } = useBiometricsStatus()
  const isBiometricsToggleOn = isBiometricsStatusReady ? isBiometricsEnabled : false

  return (
    <MainContainer {...testId}>
      <ContentHeader title='Enable PIN/Biometrics' />
      <ContentBodyStyled>
        <GeneralSettingStyled>
          <BiometricsPageSubHeaderStyled>General Settings</BiometricsPageSubHeaderStyled>
          {
            <BiometricsToggleWrapper>
              <BiometricsToggle
                toggleState={isBiometricsToggleOn}
                text='Use Face ID, Fingerprint or PIN to login'
                isDisabled={!isBiometricsStatusReady}
                onToggle={() => {
                  sendToNative('REQUEST_TOGGLE_BIOMETRICS')
                  trackBiometricsToggle(keys.biometricsTogglePressed, {
                    isEnabled: !isBiometricsToggleOn,
                  })
                }}
                toggleTitle='Enable/Disable'
              />
            </BiometricsToggleWrapper>
          }
        </GeneralSettingStyled>
      </ContentBodyStyled>
    </MainContainer>
  )
}

const BiometricsToggleWrapper = styled.div({
  '> div': { justifyContent: 'space-between' },
})
