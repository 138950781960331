import anime from 'animejs'
import { colors } from '@mobi/component-library/Theme/Common'
import { BET_SLIP_BUTTON_ID } from '@core/Areas/AppHeader/Components/BetslipButton/constants'

export async function animateSelectionButtonToBetslip(element: HTMLButtonElement) {
  const targetEl = document.getElementById(BET_SLIP_BUTTON_ID)
  if (!targetEl) return

  const elementBox = element.getBoundingClientRect()
  const elementCenter = {
    x: elementBox.left + elementBox.width / 2,
    y: elementBox.top + elementBox.height / 2,
  }

  const targetBox = targetEl.getBoundingClientRect()
  const targetCenter = {
    x: targetBox.left + targetBox.width / 2,
    y: targetBox.top + targetBox.height / 2,
  }

  const clonedButton = element.cloneNode(true) as HTMLButtonElement
  document.body.appendChild(clonedButton)

  anime.set(clonedButton, {
    backgroundColor: colors.lavender[600],
    position: 'fixed',
    zIndex: 2,
    top: `${elementBox.top}px`,
    left: `${elementBox.left}px`,
    width: `${element.clientWidth}px`,
    height: `${element.clientHeight}px`,
  })
  anime.set(clonedButton.querySelectorAll('*'), { color: 'white' })

  anime({
    targets: clonedButton,
    translateY: [0, targetCenter.y - elementCenter.y],
    translateX: [0, targetCenter.x - elementCenter.x],
    rotate: [0, 45],
    scale: [1, 0.1],
    opacity: { value: [1, 0.1], delay: 100 },
    easing: 'easeInQuart',
    duration: 500,
    complete: () => clonedButton.remove(),
  })
}
