import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type {
  BetSlipItem,
  BetErrorType,
  MultiInvestment,
  InvestmentType,
  FobBetReceiptResponse,
  SportsDetails,
  FobSportsSelection,
} from '@mobi/betslip/types'
import {
  isFobSportsSelection,
  isFobSelection,
  isFobSportsDetails,
  isAllUpSelection,
} from '@mobi/betslip/helpers/typeGuards'
import {
  confirmAllBets,
  proposeAllBets,
  refreshAllBets,
} from '@mobi/betslip/Store/Workflow/asyncActions'
import { mapResponse } from './helpers/apiResponse/mapItemsWithResponse'
import { updateItemInvestment } from './helpers/investment/updateItem'
import { getMultiBetResponse } from './helpers/apiResponse/multi'
import { getMultibetErrorDescription } from './helpers/apiResponse/errors'
import {
  getBetsInMulti,
  getMultiLegEventOrRaceKey,
  hasNoFatalErrors,
  hasTooFewMultiLegs,
  hasTooManyMultiLegs,
  isSpecialUsed,
} from '@mobi/betslip/helpers/state'

export const defaultBetSlipBetsState: BetSlipBetsState = {
  items: [],
  isSingleExpanded: true,
  isMultiExpanded: true,
  multiBetError: null,
  multiInvestment: {
    value: 0,
    f1: 0,
    f2: 0,
    f3: 0,
    f4: 0,
    f5: 0,
    bonusBetId: null,
    isBonusBet: false,
  },
  multiReceipt: undefined,
}

const betsSlice = createSlice({
  name: 'betslip/bets',
  initialState: defaultBetSlipBetsState,
  reducers: {
    addItemsToBetSlip(state, { payload: items }: PayloadAction<BetSlipItem[]>) {
      let itemIdsToDeselectIsInMulti: string[] = []
      const hasItemsEligibleForMulti = items.some(item => item.isInMulti)

      if (hasItemsEligibleForMulti) {
        const newMultiItems = items.flatMap(item => (item.isInMulti ? [item] : []))
        const currMultiKey = getBetsInMulti(state.items).map(item =>
          getMultiLegEventOrRaceKey(item.selectionDetails)
        )
        itemIdsToDeselectIsInMulti = newMultiItems.flatMap(item =>
          currMultiKey.includes(getMultiLegEventOrRaceKey(item.selectionDetails)) ? [item.id] : []
        )
      }
      if (itemIdsToDeselectIsInMulti.length > 0) {
        items.forEach(item => {
          if (itemIdsToDeselectIsInMulti.includes(item.id)) {
            item.isInMulti = false
          }
        })
      }
      state.items.push(...items)
    },

    clearAllBetSlipItems(state) {
      state.items = []
      state.multiBetError = null
      state.multiInvestment = { ...defaultBetSlipBetsState.multiInvestment }
    },

    removeItemFromBetSlipById(state, { payload }: PayloadAction<BetSlipOrSportId>) {
      state.items = state.items.filter(item => {
        if (payload.id) {
          return item.id !== payload.id
        } else if (payload.externalBetId) {
          return (
            isFobSportsSelection(item.selection) &&
            item.selection.externalBetId !== payload.externalBetId
          )
        }
        return true
      })
      if (hasTooFewMultiLegs(state.items) || hasTooManyMultiLegs(state.items)) {
        state.multiInvestment = { ...defaultBetSlipBetsState.multiInvestment }
      }
    },

    setInvestmentAmount(state, { payload }: SetInvestmentAmountArgs) {
      const { investmentType, itemId, value } = payload
      updateItemInvestment({ investmentType, value, state, betId: itemId })
    },

    setSelectedSpecialOffer(
      state,
      {
        payload,
      }: PayloadAction<{
        id: BetSlipItem['id']
        specialOffer: BetSlipItem['selectedSuperPickOffer']
      }>
    ) {
      const itemToUpdate = state.items.find(item => item.id === payload.id)
      if (itemToUpdate) {
        itemToUpdate.selectedSuperPickOffer =
          itemToUpdate.selectedSuperPickOffer?.specialSeq === payload.specialOffer?.specialSeq
            ? null
            : payload.specialOffer

        if (isSpecialUsed(itemToUpdate, state.items)) {
          state.items.map(item => {
            if (item.id === payload.id) return item
            if (
              item.selectedSuperPickOffer?.tokenId === itemToUpdate.selectedSuperPickOffer?.tokenId
            ) {
              item.selectedSuperPickOffer = null
            }
            return item
          })
        }
      }
    },

    clearAllReceiptsFromItems(state) {
      state.items.forEach(item => {
        item.receipt = undefined
        item.investment = {
          bonusBet: undefined,
          win: {
            value: 0,
            isBonusBet: false,
            isBonusCash: false,
          },
          place: {
            value: 0,
            isBonusBet: false,
            isBonusCash: false,
          },
        }
      })

      state.multiBetError = null
      state.multiReceipt = undefined
      state.multiInvestment.value = state.multiInvestment.isBonusBet
        ? 0
        : state.multiInvestment.value
      state.multiInvestment.bonusBetId = null
      state.multiInvestment.isBonusBet = false
    },

    removeItemsWithReceipt(state) {
      const hasMultiBeenPlaced = !!state.multiReceipt
      state.items = state.items.filter(
        item => !item.receipt || (hasMultiBeenPlaced && !item.isInMulti)
      )
      state.multiReceipt = undefined
      state.multiInvestment = { ...defaultBetSlipBetsState.multiInvestment }
    },

    removeItemsWithFatalError(state) {
      state.items = state.items.filter(hasNoFatalErrors)
    },

    updateBetSlipFobRacingItemPrice(
      state,
      action: PayloadAction<{
        propositionId: number
        winPrice: number | null
        placePrice: number | null
      }>
    ) {
      const { propositionId, winPrice: newWinPrice, placePrice: newPlacePrice } = action.payload
      state.items.map(item => {
        if (
          isFobSelection(item.selection) &&
          item.selection.propositionSeq === propositionId.toString()
        ) {
          if (newWinPrice) item.selection.winPrice = newWinPrice
          item.selection.placePrice = newPlacePrice
        }
      })
    },

    updateBetSlipSportsItemPrice(
      state,
      action: PayloadAction<
        {
          winPrice: number | null
        } & Pick<SportsDetails, 'outcomeId' | 'marketId' | 'eventId' | 'marketTypeCode'>
      >
    ) {
      const { winPrice, eventId, marketId, outcomeId } = action.payload
      if (!winPrice) return

      state.items.map(item => {
        if (!isFobSportsDetails(item.selectionDetails)) return

        if (
          isFobSportsSelection(item.selection) &&
          item.selectionDetails.eventId === eventId &&
          item.selectionDetails.marketId === marketId &&
          item.selectionDetails.outcomeId === outcomeId
        ) {
          item.selection.winPrice = winPrice
        }
      })
    },

    toggleIsItemInMulti(
      state,
      { payload }: PayloadAction<Pick<BetSlipItem, 'id'> & { isInGroup?: boolean }>
    ) {
      const item = state.items.find(item => item.id === payload.id)
      if (!item) return

      const isToggleItemNowInMulti = !item.isInMulti

      if (isToggleItemNowInMulti && payload.isInGroup) {
        const groupKey = getMultiLegEventOrRaceKey(item.selectionDetails)
        const multiItems = getBetsInMulti(state.items)

        multiItems.map(item => {
          if (item.id === payload.id) return
          if (getMultiLegEventOrRaceKey(item.selectionDetails) === groupKey) {
            item.isInMulti = false
          }
        })
      }

      item.isInMulti = isToggleItemNowInMulti

      if (hasTooFewMultiLegs(state.items) || hasTooManyMultiLegs(state.items)) {
        state.multiInvestment = defaultBetSlipBetsState.multiInvestment
      }
    },

    toggleMultiItemBetLegType(state, { payload }: PayloadAction<Pick<BetSlipItem, 'id'>>) {
      const item = state.items.find(item => item.id === payload.id)
      if (!item) return
      item.multiLegBetType = item.multiLegBetType === 'W' ? 'P' : 'W'
    },

    toggleAllUpFormula(
      state,
      { payload }: PayloadAction<Pick<BetSlipItem, 'id'> & { formula: number }>
    ) {
      const selectedBet = state.items.find(item => item.id === payload.id)
      if (!selectedBet || !isAllUpSelection(selectedBet.selection)) return

      selectedBet.selection.formulas.map(formula => {
        if (formula.formula === payload.formula) {
          formula.isSelected = !formula.isSelected
          return formula
        }
        return formula
      })

      if (selectedBet.selection.formulas.every(formula => !formula.isSelected)) {
        selectedBet.selection.formulas[0].isSelected = true
      }
    },
  },
  extraReducers: builder => {
    // =======
    // Refresh
    // =======
    builder.addCase(refreshAllBets.fulfilled, (state, action) => {
      const data = action.payload
      const multiBetResponse = getMultiBetResponse(data)
      const newItems = state.items.map(item => {
        return mapResponse(item, data, multiBetResponse, true, true)
      })

      const multiBetError = getMultibetErrorDescription(multiBetResponse)
      state.items = newItems
      state.multiBetError = multiBetError
    })

    // =======
    // Propose
    // =======
    builder.addCase(proposeAllBets.fulfilled, (state, action) => {
      const data = action.payload
      const multiBetResponse = getMultiBetResponse(data)
      const newItems = state.items.map(item => mapResponse(item, data, multiBetResponse))
      const multiBetError = getMultibetErrorDescription(multiBetResponse)

      state.items = newItems
      state.multiBetError = multiBetError
      state.multiInvestment =
        multiBetError && multiBetError.betErrorType === 'Unspecified'
          ? defaultBetSlipBetsState.multiInvestment
          : state.multiInvestment
    })

    // =======
    // Confirm
    // =======
    builder.addCase(confirmAllBets.fulfilled, (state, action) => {
      const data = action.payload
      const multiBetResponse = getMultiBetResponse(data)
      const newItems = state.items.map(item => mapResponse(item, data, multiBetResponse))
      const multiBetError = getMultibetErrorDescription(multiBetResponse)

      state.items = newItems
      state.multiBetError = multiBetError
      state.multiInvestment =
        multiBetError && multiBetError.betErrorType === 'Unspecified'
          ? defaultBetSlipBetsState.multiInvestment
          : state.multiInvestment

      let newMultiReceipt: FobBetReceiptResponse | undefined = undefined
      if (multiBetResponse && multiBetResponse.success) {
        newMultiReceipt = multiBetResponse.receipt as FobBetReceiptResponse
      }

      const singleBetCampaignActivatedInd = newItems.some(x =>
        x.receipt ? !!x.receipt.campaignActivatedInd : false
      )
      const multiBetCampaignActivatedInd = newMultiReceipt && newMultiReceipt.campaignActivatedInd
      if (singleBetCampaignActivatedInd || multiBetCampaignActivatedInd) {
        // fetchCampaignsAsync()
      }

      state.multiReceipt = newMultiReceipt
    })
  },
})

// =============
// Slice Exports
// =============

export const {
  addItemsToBetSlip,
  clearAllBetSlipItems,
  removeItemFromBetSlipById,
  setInvestmentAmount,
  setSelectedSpecialOffer,
  clearAllReceiptsFromItems,
  removeItemsWithReceipt,
  updateBetSlipFobRacingItemPrice,
  updateBetSlipSportsItemPrice,
  removeItemsWithFatalError,
  toggleIsItemInMulti,
  toggleMultiItemBetLegType,
  toggleAllUpFormula,
} = betsSlice.actions

const allowPersistForKeys: (keyof BetSlipBetsState)[] = ['items']

export default persistReducer(
  {
    version: 1,
    key: 'betslip/bets',
    storage,
    debug: !PRODUCTION,
    whitelist: allowPersistForKeys,
  },
  betsSlice.reducer
)

// =====
// Types
// =====

export interface BetSlipBetsState {
  items: BetSlipItem[]

  isMultiExpanded: boolean
  isSingleExpanded: boolean
  multiBetError: { betErrorType: BetErrorType; errorMessage: string } | null
  multiInvestment: MultiInvestment
  multiReceipt?: FobBetReceiptResponse
}

type SetInvestmentAmountArgs = PayloadAction<{
  itemId: BetSlipItem['id']
  investmentType: InvestmentType
  value: string
}>

type BetSlipOrSportId = Partial<Pick<BetSlipItem, 'id'> & Pick<FobSportsSelection, 'externalBetId'>>
