import React from 'react'
import dayjs from 'dayjs'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { PlanSeq } from '@mobi/api-types'
import type { Ticket, TicketStatus } from '@core/Areas/AccountActivity/types'
import { RaceCountdown } from '@core/Components/RaceCountdown'
import {
  getStatusText,
  isTippingTicket,
} from '@core/Areas/AccountActivity/Components/Ticket/helpers'
import { StatusSilks } from './Components/StatusSilks/StatusSilks'
import { SelectionInfo } from './Components'
import { StatusPillStyled } from '../Common/StatusPill.styles'
import { TicketDisplayContext } from '@core/Areas/AccountActivity/context'

export const Header: React.FC<HeaderProps> = ({ ticket, status }) => {
  const isPreviewMode = React.useContext(TicketDisplayContext).type === 'share-preview'

  const legs = ticket.legs
  if (legs.length === 0 || legs[0].selections.length === 0) {
    return <strong data-testid='empty-list'>Sections list empty!</strong>
  }

  const isPendingTab = ticket.status === 'Pending'
  const isTipping = isTippingTicket(ticket)
  const isMulti = !isTipping && legs.length > 1
  const isFavouriteNumbers = ticket.planSeq === PlanSeq.FavouriteNumbers

  const countdownDate =
    (isPendingTab || isPreviewMode) &&
    !isFavouriteNumbers &&
    !isTipping &&
    getCountdownDate(ticket, isMulti)

  return (
    <div data-testid='ticket-header'>
      <TicketHeaderStyled>
        <div data-testid='ticket-header-selection'>
          <SelectionInfo ticket={ticket} />
        </div>

        <HeaderStatusStyled>
          <div>
            {countdownDate &&
              (isPreviewMode ? (
                !isMulti && (
                  <div data-testid='share-bet-event-time'>
                    <span>{dayjs(countdownDate).format('ddd D MMM YYYY')}</span>
                    <br />
                    <span>{dayjs(countdownDate).format('h:mm A')}</span>
                  </div>
                )
              ) : (
                <RaceCountdown displaySize='1.2rem' raceStartTime={new Date(countdownDate)} />
              ))}
          </div>

          {status !== 'Pending' && (
            <div>
              <StatusPillStyled status={status} size='large' data-testid='ticket-status'>
                {getStatusText(status)}
              </StatusPillStyled>
            </div>
          )}
        </HeaderStatusStyled>
      </TicketHeaderStyled>

      {!isPreviewMode && <StatusSilks ticket={ticket} />}
    </div>
  )
}

// =======
// Helpers
// =======

function getCountdownDate(ticket: Ticket, isMulti: boolean): string {
  if (!isMulti) return ticket.legs[0].advertisedStartTime

  const currentTime = dayjs()
  const timesFromNow = ticket.legs.map(leg => {
    const diff = currentTime.diff(leg.advertisedStartTime)
    // Allow time if within 2min
    if (diff < 1000 * 60 * 2) return diff
    return -Infinity
  })

  const closestTime = Math.max(...timesFromNow)
  const indexOfClosest = timesFromNow.findIndex(time => time === closestTime)

  if (indexOfClosest === -1) return ticket.legs[0].advertisedStartTime

  return ticket.legs[indexOfClosest].advertisedStartTime
}

// ======
// Styles
// ======

const TicketHeaderStyled = styled.div({
  display: 'flex',
  userSelect: 'none',

  '& > div:first-of-type': {
    flex: 1,
  },
})

const HeaderStatusStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  lineHeight: 1.2,
  paddingLeft: '1rem',

  '& > div:empty': { display: 'none' },

  '& > div': {
    display: 'flex',
    alignItems: 'center',
    minHeight: '2rem',
    marginBottom: '0.5rem',
    fontSize: '1.2rem',
    textAlign: 'right',

    span: {
      color: hexColors.grey,
    },

    strong: {
      fontSize: '1.2rem',
    },

    '&:first-of-type > svg': {
      marginLeft: '0.5rem',
    },
  },
})

// =====
// Types
// =====

type HeaderProps =
  | {
      ticket: Ticket
      status: TicketStatus
      isPreviewMode?: never
    }
  // Preview
  | {
      ticket: Ticket
      status: TicketStatus
    }
