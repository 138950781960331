import { setSkyVideoPlayerStatus } from '@core/Areas/SkyVideoPlayer/Store'
import { useAppDispatch, useAppSelector } from '@core/Store/hooks'
import { isReactNativeApp } from '@mobi/web-native-comms/web'
import { useEffect } from 'react'

export const useAutoFullscreenOnNativeLandscape = () => {
  const dispatch = useAppDispatch()
  const playerStatus = useAppSelector(state => state.skyVideoPlayer.playerStatus)

  useEffect(() => {
    if (!isReactNativeApp) return

    const setFullscreen = (isFullscreen: boolean) =>
      dispatch(
        setSkyVideoPlayerStatus({
          playerStatus: isFullscreen ? 'fullscreen' : 'docked',
        })
      )

    const portrait = window.matchMedia('(orientation: portrait)')

    const handleOrientationChange = (e: MediaQueryListEvent) => {
      if (playerStatus !== 'docked') return

      // Landscape
      if (!e.matches) {
        setFullscreen(true)
      } else {
        // Portrait
        setFullscreen(false)
      }
    }
    portrait.addEventListener('change', handleOrientationChange)

    return () => {
      portrait.removeEventListener('change', handleOrientationChange)
    }
  }, [dispatch, playerStatus])
}
