import React from 'react'
import { FeatureFlags, Constants } from '@mobi/settings'
import { ContentHeader } from '@core/Components/ContentHeader'
import { close as modalClose } from '@classic/AppUtils/Framework/Intent/modal'
import {
  AccountListStyled as List,
  AccountListItemStyled as Item,
  AccountLinkStyled as Link,
  AccountButtonStyled as LinkButton,
  AccountNumberBadgeStyled as Badge,
  AccountBalanceStyled,
  BonusBalanceStyled,
  AccountListWrapperStyled as ListWrapper,
  AccountSubHeading as SubHeading,
  BonusBalanceTitleStyled,
  BonusBalanceValueStyled,
  BonusItemStyled,
} from './Account.styles'
import { MainContainerStyled } from '@core/Components/Containers/MainContainer.styles'
import { Button } from '@mobi/component-library/Common/Buttons'
import { Icon } from '@mobi/component-library/Common/Icon'
import { useGetAccountDetails } from './helpers/useGetAccountDetails'
import { InvalidateAccountDetails } from './driver'
import { debounceFn } from '@mobi/utils'
import { SpinnerInlineStyled } from '@mobi/component-library/Common/Spinner/Spinner.styles'
import { isReactNativeApp } from '@mobi/web-native-comms/web'
import { FeatureFlag } from '@core/State/LaunchDarklyFeatures/Components/FeatureFlag'
import { TAKE_A_BREAK_ROUTE } from '@core/Areas/Account/TakeABreak/constants'
import { openDeposit } from '@core/Areas/Deposit'
import { useObservableImmutable } from '@core/Utils/hooks/useObservableImmutable'
import { state$ as userAccountState$ } from '@core/State/UserAccount/userAccountDriver'
import { Money } from '@core/Components/Text/Money'
import { ACTIVITY_STATEMENTS_ROUTE } from './ActivityStatements'
import { trackActivityStatementsSelected } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { trackOptimoveEvent } from '@core/Services/Optimove/optimove'

const invalidateAccountDetailsDebounced = debounceFn(InvalidateAccountDetails, 1000)

export const Account = (): JSX.Element | null => {
  const [isLoading, setIsLoading] = React.useState(true)
  const { accountNumber, bonusBetBalance, bonusCashBalance } = useObservableImmutable(
    userAccountState$,
    ['accountNumber', 'bonusBetBalance', 'bonusCashBalance']
  )

  React.useEffect(() => {
    // Deposit Modal - Update Balance On Close
    const legacyModalSubscription = modalClose.signal$.subscribe(InvalidateAccountDetails)
    return () => {
      legacyModalSubscription.dispose()
    }
  }, [])

  const {
    balance,
    blackBookAlertCount = 0,
    activeCampaignsCount = 0,
  } = useGetAccountDetails(true)?.toJS() || {}

  React.useEffect(() => {
    balance && setIsLoading(false)
  }, [balance])

  const handleRefresh = () => {
    setIsLoading(true)
    invalidateAccountDetailsDebounced()
  }

  const bonusCash = bonusCashBalance ?? 0
  const bonusBet = bonusBetBalance ?? 0

  return (
    <MainContainerStyled background='grey'>
      <ContentHeader title='My Account' refreshCallback={handleRefresh} />

      <AccountBalanceStyled>
        <span data-tid-my-account-balance>
          {isLoading ? <SpinnerInlineStyled color='dark' /> : balance}
        </span>
        <Button
          data-tid-my-account-deposit-plus-button
          onClick={() => openDeposit()}
          aria-label='Deposit'
        >
          <Icon type='plus' size='2rem' />
        </Button>
      </AccountBalanceStyled>

      {!isLoading && (
        <BonusBalanceStyled>
          {bonusBet > 0 && (
            <BonusItemStyled>
              <BonusBalanceTitleStyled>{'Bonus Bets'}</BonusBalanceTitleStyled>
              <BonusBalanceValueStyled>
                <Money amount={bonusBet} decimalPlaces={2} data-tid-my-bonusbet-balance />
              </BonusBalanceValueStyled>
            </BonusItemStyled>
          )}

          {bonusCash > 0 && (
            <BonusItemStyled>
              <BonusBalanceTitleStyled>{'Bonus Cash'}</BonusBalanceTitleStyled>
              <BonusBalanceValueStyled>
                <Money amount={bonusCash} decimalPlaces={2} data-tid-my-bonuscash-balance />
              </BonusBalanceValueStyled>
            </BonusItemStyled>
          )}
        </BonusBalanceStyled>
      )}

      <ListWrapper>
        <SubHeading>Racing</SubHeading>
        <List>
          <Item>
            <Link href='/#account/activity/pending' data-tid-my-account-pending>
              <Icon type='pending' /> Pending Bets
            </Link>
          </Item>

          <Item>
            <Link href='/#account/activity/results' data-tid-my-account-resulted>
              <Icon type='resulted' /> Resulted Bets
            </Link>
          </Item>
        </List>
      </ListWrapper>
      <ListWrapper>
        <SubHeading>Sports</SubHeading>
        <List>
          <Item>
            <Link href='/#tabtouch-sports/bethistory/open' data-tid-my-account-pending>
              <Icon type='pending' /> Pending Bets
            </Link>
          </Item>

          <Item>
            <Link href='/#tabtouch-sports/bethistory/settled' data-tid-my-account-resulted>
              <Icon type='resulted' /> Resulted Bets
            </Link>
          </Item>
        </List>
      </ListWrapper>

      <ListWrapper>
        <SubHeading>Account</SubHeading>
        <List>
          <Item>
            <LinkButton data-tid-my-account-deposit onClick={() => openDeposit()}>
              <Icon type='plus' /> Deposit
            </LinkButton>
          </Item>

          <Item>
            <Link href='/#account/withdrawal' data-tid-my-account-withdrawal>
              <Icon type='minus' /> Withdrawal
            </Link>
          </Item>

          <Item>
            <Link href='/#account/activity/transactions' data-tid-my-account-credits-debits>
              <Icon type='statement' /> Credits / Debits
            </Link>
          </Item>

          {activeCampaignsCount > 0 && (
            <Item>
              <Link href='/#account/offer' data-tid-my-account-bonus>
                <Icon type='offer' /> Bonus Offers <Badge>{activeCampaignsCount}</Badge>
              </Link>
            </Item>
          )}

          <Item>
            <Link href='/#account/blackbook' data-tid-my-account-blackbook>
              <Icon type='blackbook' /> Blackbook{' '}
              {blackBookAlertCount > 0 && <Badge>{blackBookAlertCount}</Badge>}
            </Link>
          </Item>

          <Item>
            <Link href='/#account/bankaccount' data-tid-my-account-bank-account>
              <Icon type='bankAccount' /> Bank Account
            </Link>
          </Item>
        </List>
      </ListWrapper>

      <ListWrapper>
        <SubHeading>Responsible Wagering</SubHeading>
        <List>
          <Item>
            <Link href='/#account/deposit-limits' data-tid-my-account-deposit-limits>
              <Icon type='depositLimits' /> Deposit Limits
            </Link>
          </Item>

          <Item>
            <Link href='/#account/precommitment' data-tid-my-account-bet-limits>
              <Icon type='errorCircled' /> Bet Limits
            </Link>
          </Item>

          <FeatureFlag flag={FeatureFlags.NCPF_ACTIVITY_STATEMENTS.key}>
            <Item>
              <Link
                href={`/#${ACTIVITY_STATEMENTS_ROUTE}`}
                onClick={() => trackActivityStatementsSelected(accountNumber as number)}
              >
                <Icon type='statement' /> Activity Statements
              </Link>
            </Item>
          </FeatureFlag>

          <FeatureFlag flag={FeatureFlags.TAKE_A_BREAK.key}>
            <Item>
              <Link href={`/#${TAKE_A_BREAK_ROUTE}`} data-tid-my-account-take-a-break>
                <Icon type='calendar' /> Take a Break
              </Link>
            </Item>
          </FeatureFlag>

          <Item>
            <Link href='/#account/close' data-tid-my-account-close-account>
              <Icon type='closeAccount' /> Close Account
            </Link>
          </Item>
        </List>
      </ListWrapper>

      <ListWrapper>
        <SubHeading>Details</SubHeading>
        <List>
          <Item>
            <Link href='/#account/change-password' data-tid-my-account-change-password>
              <Icon type='asterisk' /> Change Password
            </Link>
          </Item>
          <Item>
            <Link href='/#account/settings' data-tid-my-account-settings>
              <Icon type='personMinimal' /> Settings
            </Link>
          </Item>
          <Item>
            <Link href='/#account/contactdetails' data-tid-my-account-contact-details>
              <Icon type='phone' /> Contact Details
            </Link>
          </Item>
          <Item>
            <Link href='/#account/marketingcommunications' data-tid-my-account-subscription>
              <Icon type='envelopeOpen' /> Subscription
            </Link>
          </Item>
          {isReactNativeApp && (
            <Item>
              <Link href='/#account/biometrics' data-tid-my-account-biometrics>
                <Icon type='depositLimits' /> Enable PIN/Biometrics
              </Link>
            </Item>
          )}
          <Item>
            <Link href='/#contactus' data-tid-my-account-contactus>
              <Icon type='tabtouchLogoCompact' /> Contact Us
            </Link>
          </Item>
          <Item>
            <Link
              onClick={() => {
                trackOptimoveEvent({
                  eventName: 'set_page_visit',
                  data: {
                    customURL: '/',
                    pageTtile: 'HELP',
                  },
                })
              }}
              href='https://help.tabtouch.com.au/'
              data-tid-my-account-help
              rel='noopener'
              target='_blank'
            >
              <Icon type='infoCircled' /> Help & Info
            </Link>
          </Item>
        </List>
      </ListWrapper>

      <p style={{ marginBottom: 0 }}>
        Gamble responsibly. <br /> Call Gambling Help on {Constants.GamblingHelpPhoneNumber}.
      </p>
    </MainContainerStyled>
  )
}
