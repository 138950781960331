import type { BetSelection, EventDetails } from '@mobi/betslip/types'
import type { QuickbetSelection } from '@core/Areas/Quickbet/signals'
import type { Race, RaceDetails, FobDetails } from '@mobi/betslip/types'
import { getRaceLink } from '@core/Areas/Racing/helpers'

export function convertToBetSelection(selection: QuickbetSelection): BetSelection {
  const selectionDetails = selection.selectionDetails || {}

  if (selectionDetails) {
    if ('races' in selectionDetails) {
      const raceDetails = selectionDetails as RaceDetails
      if (Array.isArray(raceDetails.races) && raceDetails.races.length > 0) {
        const { meetingId, raceNumber, raceTime } = raceDetails.races[0] as Race
        selection.selectionDetails = {
          ...raceDetails,
          urlPath: getRaceLink({
            isFOO: false,
            meetingId,
            raceNumber,
            meetingDate: raceTime,
          }),
          acceptors: raceDetails.acceptors,
        }
      }
    } else if ('meetingSeq' in selectionDetails) {
      const fobDetails = selectionDetails as FobDetails
      selection.selectionDetails = {
        ...fobDetails,
        urlPath: getRaceLink({
          isFOO: true,
          product: 'foo',
          code: fobDetails.sportIconCode,
          raceSeq: fobDetails.event,
          meetingDate: fobDetails.eventStartTime.toString(),
          meetingSeq: fobDetails.meetingSeq,
        }),
      }
    }
  }
  return {
    bettingType: selection.bettingType,
    selection: selection.selection,
    selectionDetails: selection.selectionDetails as EventDetails,
    isEachWayAvailable: selection.isEachWayAvailable,
    shouldAllowPlaceInvestment: selection.shouldAllowPlaceInvestment,
    tags: selection.tags,
    betSource: selection.betSource,
  }
}
