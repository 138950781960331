import { isAllUpSelection } from '@mobi/betslip/helpers/typeGuards'
import type {
  Acceptor,
  AcceptorBase,
  AllUpSelection,
  Race,
  ToteSelection,
} from '@mobi/betslip/types'

export function addAcceptorNameToSingleSelection(
  selectionForRace: string,
  acceptorsForRace: AcceptorBase[]
): string {
  if (/^\d+$/.test(selectionForRace)) {
    const acceptor = getAcceptorName(selectionForRace, acceptorsForRace)
    if (acceptor) return `${selectionForRace} - ${acceptor}`
  }
  return selectionForRace
}

export function getAcceptorTextForSingleSelection(acceptor: AcceptorBase): string | null {
  if (!acceptor) return null
  return acceptor.number + ' - ' + acceptor.name
}

/** Create selection map for tote novelty bet types within single race */
export function createNoveltyMultipleSelectionMap(
  { selectionString, isAllways, isLegIn, isRovingBanker }: ToteSelection,
  acceptorsForRace: Acceptor[]
) {
  const acceptors = selectionString.split('/').map(selection => {
    const regexForNumbersSeparatedByDots = /^\d{1,2}.[\d{1,2}.?]*\d$/

    if (regexForNumbersSeparatedByDots.test(selection)) {
      return selection.split('.').map(selectionNumber => {
        const acceptor = acceptorsForRace.find(
          acceptor => acceptor.number.toString() === selectionNumber
        )
        return acceptor
      })
    }

    const acceptor = acceptorsForRace.find(acceptor => acceptor.number.toString() === selection)
    return [acceptor]
  })

  const noveltyPlacedMap = new Map()

  if (isAllways) {
    noveltyPlacedMap.set('Allways', acceptors[0])
    return noveltyPlacedMap
  }

  if (isRovingBanker) {
    noveltyPlacedMap.set('RB', acceptors[0])
    noveltyPlacedMap.set('With', acceptors[1])
    return noveltyPlacedMap
  }

  if (isLegIn) {
    noveltyPlacedMap.set('Leg-In', acceptors[0])
    noveltyPlacedMap.set('With', acceptors[1])
    return noveltyPlacedMap
  }

  noveltyPlacedMap.set('1st', acceptors[0])
  noveltyPlacedMap.set('2nd', acceptors[1])
  noveltyPlacedMap.set('3rd', acceptors[2])
  noveltyPlacedMap.set('4th', acceptors[3])
  return noveltyPlacedMap
}

export function createMultipleRaceMultipleSelectionMap(
  selection: AllUpSelection | ToteSelection,
  races: Race[],
  allAcceptors: Acceptor[]
) {
  const multipleSelectionMap = new Map()
  const isAllUp = isAllUpSelection(selection)
  const validSelectionCount = isAllUp
    ? selection.details.length
    : selection.selectionString.split('/').length

  if (validSelectionCount !== races.length) return multipleSelectionMap

  races.forEach(race => {
    const currentPoolType = isAllUp
      ? selection.details.find(detail => detail.raceNum === race.raceNumber)?.poolType
      : ''

    multipleSelectionMap.set(
      `R${race.raceNumber}${currentPoolType ? ' ' + poolTypeName[currentPoolType] || '' : ''}`,
      allAcceptors.filter(acceptor => acceptor.key.includes(race.key))
    )
  })

  return multipleSelectionMap
}

// =============
// Local Helpers
// =============

function getAcceptorName(acceptorNumber: string, acceptorsForRace: AcceptorBase[]): string {
  const acceptor = acceptorsForRace.find(x => `${x.number}` === acceptorNumber)
  return acceptor ? acceptor.name : ''
}

const poolTypeName: Record<AllUpSelection['details'][0]['poolType'], string> = {
  W: 'Win',
  P: 'Place',
  EW: 'Eachway',
  Q: 'Quinella',
}
