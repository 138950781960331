import { Currency } from '@mobi/utils/money'
import type { BetSlipItem, BetSpecialOffer, FobSelection } from '../types'
import { roundDownDecimal } from './calculator/misc'

export function calculateProjectedReward(
  specialOffer: BetSpecialOffer,
  stake: BetSlipItem['investment'],
  priceDetails: Pick<FobSelection, 'winPrice' | 'placePrice'>
): number {
  if (
    isSuperPickEligible(specialOffer, { win: stake.win.value, place: stake.place.value }) &&
    specialOffer.elements
  ) {
    const nonBoostElement = specialOffer.elements?.find(element => !element.isBoost)
    const win = stake.win.value || 0
    const place = stake.place.value || 0

    if (nonBoostElement) {
      const winReward = calculateLegReward(
        nonBoostElement,
        win,
        priceDetails.winPrice,
        specialOffer.legTypeCode,
        'W'
      )
      const placeReward = calculateLegReward(
        nonBoostElement,
        place,
        priceDetails.placePrice || 0,
        specialOffer.legTypeCode,
        'P'
      )
      let totalReward = new Currency(winReward).add(placeReward).value

      if (nonBoostElement.maxReward && nonBoostElement.maxReward > 0) {
        totalReward = Math.min(nonBoostElement.maxReward, totalReward)
      }

      return roundDownDecimal(totalReward)
    }
  }

  return 0
}

export function isSuperBoost(offer: BetSpecialOffer) {
  const superBoostTitles: string[] = ['boost plus+', 'boostplus+']
  return superBoostTitles.some(title => title.toLowerCase() === offer.title?.trim().toLowerCase())
}

export function calculateProjectedBoostReward(
  specialOffer: BetSpecialOffer,
  stake: { win: number; place: number | null }
): number {
  const win = stake.win || 0
  const place = stake.place || 0
  const legTypeCode = specialOffer.legTypeCode

  if (isSuperPickEligible(specialOffer, stake) && specialOffer.elements) {
    const boostElement = specialOffer.elements?.find(element => element.isBoost)
    if (boostElement) {
      const winReward = calculateLegBoostReward(boostElement, win, legTypeCode, 'W')
      const placeReward = calculateLegBoostReward(boostElement, place, legTypeCode, 'P')
      let totalReward = new Currency(winReward).add(placeReward).value

      if (boostElement.maxReward && boostElement.maxReward > 0) {
        totalReward = Math.min(boostElement.maxReward, totalReward)
      }

      return roundDownDecimal(totalReward)
    }
  }

  return 0
}

// =============
// Local Helpers
// =============

export function isSuperPickEligible(
  specialOffer: BetSpecialOffer,
  stake: { win: number; place: number | null }
): boolean {
  const win = stake.win || 0
  const place = stake.win || 0
  if (specialOffer.isExclusiveLegType) {
    return (
      (specialOffer.legTypeCode === 'W' && place === 0) ||
      (specialOffer.legTypeCode === 'P' && win === 0) ||
      (specialOffer.legTypeCode === 'WP' && (win === 0 || place === 0))
    )
  } else {
    return (
      (specialOffer.legTypeCode === 'W' && (!!win || place === 0)) ||
      (specialOffer.legTypeCode === 'P' && (win === 0 || !!place)) ||
      specialOffer.legTypeCode === 'WP'
    )
  }
}

function calculateLegReward(
  specialOfferElement: NonNullable<BetSpecialOffer['elements']>[0],
  individualStake: number,
  dollarReturn: number,
  offerLegTypeCode: BetSpecialOffer['legTypeCode'],
  calculationLegTypeCode: BetSpecialOffer['legTypeCode']
): number {
  if (legTypeIsEligible(offerLegTypeCode, calculationLegTypeCode)) {
    const stake = validateStake(specialOfferElement, individualStake)

    const profit = new Currency(dollarReturn).add(-1.0)
    const actualProfitMultiplier = new Currency(profit).multiply(
      specialOfferElement.profitMultiplier || 0
    )
    const combinedMultiplier = new Currency(actualProfitMultiplier).add(
      specialOfferElement.stakeMultiplier || 0
    ).value

    return new Currency(stake).multiply(combinedMultiplier).value
  }

  return 0
}

function calculateLegBoostReward(
  specialOfferElement: NonNullable<BetSpecialOffer['elements']>[0],
  individualStake: number,
  offerLegTypeCode: BetSpecialOffer['legTypeCode'],
  calculationLegTypeCode: BetSpecialOffer['legTypeCode']
): number {
  if (legTypeIsEligible(offerLegTypeCode, calculationLegTypeCode)) {
    const stake = validateStake(specialOfferElement, individualStake)

    const priceIncreased = specialOfferElement.priceIncrease
      ? calculationLegTypeCode === 'P'
        ? specialOfferElement.priceIncrease.place || 0
        : specialOfferElement.priceIncrease.win || 0
      : 0

    return new Currency(stake).multiply(priceIncreased).value
  }

  return 0
}

function legTypeIsEligible(
  offerlegTypeCode: BetSpecialOffer['legTypeCode'],
  legTypeCode: BetSpecialOffer['legTypeCode']
): boolean {
  return offerlegTypeCode === 'WP' || offerlegTypeCode === legTypeCode
}

function validateStake(
  specialOfferElement: NonNullable<BetSpecialOffer['elements']>[0],
  individualStake: number
): number {
  let stake = individualStake
  const maxStake = specialOfferElement.maxStake || 0

  if (maxStake > 0) {
    stake = Math.min(maxStake, stake)
  }
  return stake
}
