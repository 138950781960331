import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@mobi/component-library/Common/Buttons'
import { getRaceLink } from '@core/Areas/Racing/helpers/getRaceLink'
import { MeetingsAPIResponse } from '@core/Areas/Racing/Hooks/useMeetings/types'
import { getCleanMeetingName } from '../helpers/getCleanMeetingName'

export const DropdownLink: React.FC<{ meeting: MeetingsAPIResponse[0]['meetings'][0] }> = ({
  meeting,
}) => {
  const history = useHistory()

  const nextRaceUrl = getRaceLink({
    meetingId: meeting.id,
    raceNumber: meeting.nextRaceNumber || 1,
    meetingDate: meeting.date,
    code: meeting.code,
    meetingSeq: meeting.fixed?.competitionId,
    product: meeting.fixed?.racingProduct,
    raceSeq: meeting.fixed?.nextRaceEventId,
    isFOO: Number.isInteger(Number(meeting.id)),
  })
  const onLinkClick = () => {
    history.push(nextRaceUrl)
  }

  return (
    <Button color='link' data-testid='meeting-dropdown-link' onClick={onLinkClick}>
      {getCleanMeetingName(meeting.name)}
    </Button>
  )
}
