import React from 'react'
import { BetCard } from '@mobi/betslip/Components/Common/BetCard'
import { ShareMyBet } from '@mobi/betslip/Components/Common/ShareMyBet'
import { isAllUpSelection } from '@mobi/betslip/helpers/typeGuards'
import type { BetSlipItem } from '@mobi/betslip/types'
import { useAppSelector } from '@mobi/betslip/Store/hooks'
import { BetInfo } from '@mobi/betslip/Components/Common/BetInfo'
import { isFatalErrorType } from '@mobi/betslip/helpers/state'
import { AllUpFormula, BetInvestment, ItemMessage, ReceiptDetail, RemoveButton } from './Components'

export const SingleItem: React.FC<{ item: BetSlipItem }> = ({ item }) => {
  const workflowStatus = useAppSelector(state => state.betslip.workflow.currentStatus)
  const wrapperRef = React.createRef<HTMLDivElement>()

  const isAllUp = isAllUpSelection(item.selection)
  const hasFatalError = isFatalErrorType(item.betErrorType) || false
  const isEditable = workflowStatus === 'ready'

  return (
    <div ref={wrapperRef}>
      <BetCard.Container key={item.id} topComponent={<ItemMessage item={item} />}>
        <BetInfo item={item} />

        {!hasFatalError && <BetInvestment item={item} />}

        {(isEditable || item.receipt) && (
          <BetCard.Footer
            expandableText={isAllUp ? 'View Formula' : undefined}
            expandableContent={isAllUp ? <AllUpFormula item={item} /> : undefined}
            leftSection={item.receipt && <ReceiptDetail receipt={item.receipt} />}
            rightSection={
              <>
                {!hasFatalError && <ShareMyBet items={[item]} isBetPlaced={!!item.receipt} />}

                {!item.receipt && (
                  <RemoveButton
                    id={item.id}
                    betErrorType={item.betErrorType}
                    wrapperRef={wrapperRef}
                  />
                )}
              </>
            }
          />
        )}
      </BetCard.Container>
    </div>
  )
}
