import React from 'react'
import { ReplayVideoPlayer as Player } from '@core/Areas/Racing/Components/RaceReplay/ReplayVideoPlayer'
import { useAppSelector } from '@core/Store/hooks'

export const RaceReplayVideoPlayer: React.FC<
  { ticketId: number } & React.ComponentProps<typeof Player>
> = ({ ticketId, ...props }) => {
  const activeReplayTicketId = useAppSelector(state => state.accountActivity.activeReplayTicketId)

  if (activeReplayTicketId && ticketId != activeReplayTicketId) {
    const { onCloseTapped } = props
    onCloseTapped && onCloseTapped()
  }

  return <Player {...props} />
}
