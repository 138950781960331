import React, { forwardRef, ReactNode } from 'react'
import { BannerContainerStyled, BannerContainerInnerStyled } from '../Banner.styles'
import { BannerArea } from '../types'

export const BannerContainer = forwardRef<
  HTMLDivElement,
  { areaName: BannerArea; children?: ReactNode }
>(({ areaName, children }, ref) => (
  <BannerContainerStyled isDesktop={areaName === 'Desktop'} ref={ref}>
    <BannerContainerInnerStyled>{children}</BannerContainerInnerStyled>
  </BannerContainerStyled>
))
