import React from 'react'
import styled from '@emotion/styled'
import { useExpandToggle } from '@mobi/utils/hooks/useExpandToggle'
import { colors, spacing } from '@mobi/component-library/Theme/Common'
import { ExpandableContentButton } from '../FooterButtons/FooterButtons'

export const Footer: React.FC<FooterProps> = ({
  leftSection,
  rightSection,
  expandableContent,
  expandableText,
}) => {
  const { wrapperRef, contentRef, shouldRender, isExpanded, toggleExpanded } = useExpandToggle({
    timeoutMs: 400,
  })

  return (
    <WrapperStyled>
      <div>
        <div>
          {expandableContent && (
            <ExpandableContentButton onClick={() => toggleExpanded()} isExpanded={isExpanded}>
              {expandableText}
            </ExpandableContentButton>
          )}

          {leftSection}
        </div>

        <div>{rightSection}</div>
      </div>

      {(isExpanded || shouldRender) && (
        <div ref={wrapperRef}>
          <div ref={contentRef}>{expandableContent}</div>
        </div>
      )}
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  '> div:first-of-type': {
    display: 'flex',
    justifyContent: 'space-between',
    gap: spacing.sm,
    paddingTop: spacing.smx1,
    borderTop: '0.5px solid ' + colors.neutral[200],

    '> div': {
      display: 'flex',
      alignItems: 'center',
      gap: spacing.smx2,
    },
  },

  '> div:nth-of-type(2)': {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    transition: 'height 400ms ease',
    willChange: 'height',
    height: 0,
  },
})

// =====
// Types
// =====

type FooterProps = {
  leftSection?: React.ReactNode
  rightSection?: React.ReactNode
} & (
  | { expandableContent?: never; expandableText?: never }
  | { expandableContent?: React.ReactNode; expandableText?: string }
)
