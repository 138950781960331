import React from 'react'
import { ToggleWithLabels } from '@mobi/component-library/Selection&Input/ToggleWithLabels'
import type { BetSlipItem } from '@mobi/betslip/types'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '@mobi/betslip/Store/hooks'
import { toggleMultiItemBetLegType } from '@mobi/betslip/Store/Bets'

export const MultiLegTypeToggle: React.FC<Pick<BetSlipItem, 'multiLegBetType' | 'id'>> = ({
  multiLegBetType,
  id,
}) => {
  const dispatch = useDispatch()

  const workflowStatus = useAppSelector(state => state.betslip.workflow.currentStatus)
  const isBusy = useAppSelector(state => state.betslip.workflow.isBusy)

  if (workflowStatus != 'ready' || isBusy) return null

  return (
    <ToggleWithLabels
      id={id}
      onToggle={() => dispatch(toggleMultiItemBetLegType({ id }))}
      leftValue='Win'
      rightValue='Place'
      isLeftValueActive={multiLegBetType === 'W'}
    />
  )
}
