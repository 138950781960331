import React from 'react'
import dayjs from 'dayjs'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import { spacing } from '@mobi/component-library/Theme/Common'
import {
  getPendingSortOrder,
  getResultsSortOrder,
} from '@core/Areas/AccountActivity/Store/selectors'
import { Header } from '@core/Areas/AccountActivity/Components/Header'
import { Button } from '@mobi/component-library/Common/Buttons'
import type {
  Tabs,
  Ticket,
  TicketResponseData,
  TransactionsResponseData,
} from '@core/Areas/AccountActivity/types'
import { Pages } from '@core/Areas/AccountActivity/Components/Pages'
import {
  getIdFromSortedString,
  isFauxTicketTransaction,
  isTransactionResponse,
} from '@core/Areas/AccountActivity/helpers'
import { CenterContentStyled as Center } from './AccountActivityMain.styles'
import { trackAccountActivityTab } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { useAccountActivityData } from '@core/Areas/AccountActivity/Hooks'
import { useRefreshOnBetPlaced } from './hooks'

let timeSinceLastRefresh: number | null = null

export const AccountActivityMain: React.FC<{ activeTab: Tabs }> = ({ activeTab }) => {
  const pendingSortOrder = useSelector(getPendingSortOrder)
  const resultsSortOrder = useSelector(getResultsSortOrder)

  const [selectedDate, setSelectedDate] = React.useState<string>(() => getCurrentMonth())

  useRefreshOnBetPlaced()

  // Reset selected date on tab change & tab anlaytics
  React.useEffect(() => {
    if (!activeTab) return
    setSelectedDate(getCurrentMonth())
    trackAccountActivityTab({ activeTab })
  }, [activeTab, setSelectedDate])

  const isOrderByEventStart =
    (activeTab === 'pending' && pendingSortOrder === 'eventStart') ||
    (activeTab === 'results' && resultsSortOrder === 'eventStart') ||
    activeTab === 'rebet'

  const {
    data,
    isLoading,
    isError,
    isFinalPage,
    isEmptyState,
    LoadMoreButton,
    SkeletonLoader,
    refetch,
  } = useAccountActivityData({ activeTab, isOrderByEventStart, selectedDate })

  const firstDate =
    data && data.pages.length && data.pages[0].sortedIds.length
      ? getFirstDate(data.pages[0], isOrderByEventStart)
      : isEmptyState
        ? dayjs().format('ddd, DD MMM YYYY')
        : ''

  const onRefresh = () => {
    if (timeSinceLastRefresh && Date.now() - timeSinceLastRefresh < 2000) return
    setSelectedDate(getCurrentMonth())
    refetch()
    timeSinceLastRefresh = Date.now()
  }

  return (
    <>
      <Header
        firstDate={firstDate}
        setSelectedDate={setSelectedDate}
        selectedDate={selectedDate}
        onRefresh={onRefresh}
      />

      <AccountActivityContainerStyled data-tid-transaction-container>
        {isLoading ? (
          <SkeletonLoader />
        ) : (
          data && (
            <>
              <Pages
                key={activeTab + firstDate}
                pages={data.pages}
                isOrderByEvent={isOrderByEventStart}
              />

              {isFinalPage ? (
                <Center>
                  {isEmptyState ? (
                    <strong data-testid='transactions-empty-notice'>
                      You have no {getTicketsTransactionsLabel(activeTab)}
                    </strong>
                  ) : (
                    <strong data-testid='transactions-end-notice'>
                      End of {getTicketsTransactionsLabel(activeTab)}
                    </strong>
                  )}
                </Center>
              ) : (
                <LoadMoreButton />
              )}
            </>
          )
        )}

        {isError && (
          <Center>
            <Button
              color='secondary'
              data-testid='transactions-try-again'
              onClick={() => refetch()}
            >
              Try again
            </Button>
          </Center>
        )}
      </AccountActivityContainerStyled>
    </>
  )
}

// =============
// Local Helpers
// =============
function getTicketsTransactionsLabel(activeTab: Tabs) {
  return activeTab === 'transactions' ? 'Transactions' : 'Tickets'
}

function getFirstDate(
  data: TransactionsResponseData | TicketResponseData,
  isOrderByEvent: boolean
): string {
  const firstId = data.sortedIds[0]
  const item = isTransactionResponse(data)
    ? data.transactionsById[getIdFromSortedString(firstId)]
    : data.ticketsById[getIdFromSortedString(firstId)]

  const firstDate =
    isFauxTicketTransaction(item) || !isOrderByEvent
      ? item.timestamp
      : (item as Ticket).eventStartDateTime

  return dayjs(firstDate).isValid() ? dayjs(firstDate).format('ddd, DD MMM YYYY') : ''
}

function getCurrentMonth() {
  return dayjs().date(1).format('YYYY-MM-DD')
}

// ======
// Styles
// ======

const AccountActivityContainerStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.smx1,
  position: 'relative',
  margin: `1rem ${spacing.md}`,
})
