import React from 'react'
import styled from '@emotion/styled'
import { Drawer } from '@mobi/component-library/Overlays/Drawer'
import { BetSlip } from '@mobi/betslip/App'
import { setHostHelpers } from '@mobi/betslip/helpers/hostHelpers'
import { setWorkflowStatusToReady } from '@mobi/betslip/Store/Workflow'
import { selectWorkflowStatus } from '@mobi/betslip/Store/Workflow/selectors'
import { removeItemsWithFatalError, removeItemsWithReceipt } from '@mobi/betslip/Store/Bets'
import { AppRoutes } from '@core/AppRoutes'
import { startListening } from '@core/Store/listenerMiddleware'
import { useAppDispatch, useAppSelector } from '@core/Store/hooks'
import { setBetSlipNewIsOpen } from '../AppHeader/Store'
import { HeaderPanel } from './Components/HeaderPanel'
import { LowBalanceStatus } from './Components/LowBalanceStatus'
import { TakeABreakStatus } from './Components/TakeABreakStatus'
import { InducementSync } from './Components/InducementSync'
import {
  postAllBetsToServer,
  handleShareBet,
  showLogin,
  canShareBet,
  toggleBetSlipDrawer,
} from './helpers'
import {
  useRealtimeFobPriceChanges,
  useRefreshBalanceOnBetsPlaced,
  useAccountBalanceSync,
  useHapticFeedbackOnBetsSuccess,
  useIsNewBetSlipFeatureActive,
} from './hooks'

setHostHelpers({
  postAllBetsToServer,
  toggleBetSlipDrawer,
  handleShareBet,
  canShareBet,
  redux: { startListening },
  hostRoutes: {
    Meetings: AppRoutes.Meetings,
    Sports: AppRoutes.Sports,
    Deposit: AppRoutes.Deposit,
  },
  showLogin,
})

export const BetSlipNew: React.FC = () => {
  const dispatch = useAppDispatch()

  const isNewBetSlipDesignActive = useIsNewBetSlipFeatureActive()
  const isOpen = useAppSelector(state => state.appHeader.isBetSlipNewOpen)
  useHapticFeedbackOnBetsSuccess(isNewBetSlipDesignActive && isOpen)
  const workflowStatus = useAppSelector(selectWorkflowStatus)

  useRealtimeFobPriceChanges(isNewBetSlipDesignActive && isOpen)
  useRefreshBalanceOnBetsPlaced()
  useAccountBalanceSync(isNewBetSlipDesignActive)

  React.useEffect(() => {
    if (!isOpen) {
      dispatch(removeItemsWithFatalError())

      if (workflowStatus !== 'ready') {
        dispatch(setWorkflowStatusToReady())

        if (workflowStatus === 'bets-placed') {
          dispatch(removeItemsWithReceipt())
        }
      }
    }
  }, [dispatch, isOpen, workflowStatus])

  if (!isNewBetSlipDesignActive) return null

  return (
    <Drawer
      isOpen={isOpen}
      shouldUseBodyScrollLock={false}
      onDismiss={() => dispatch(setBetSlipNewIsOpen(false))}
    >
      <InducementSync />

      <TakeABreakStatus />

      <HeaderPanel />

      <LowBalanceStatus />

      <BetSlipParentWrapperStyled>
        <BetSlip />
      </BetSlipParentWrapperStyled>
    </Drawer>
  )
}

// ======
// Styles
// ======

const BetSlipParentWrapperStyled = styled.div({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  overflow: 'hidden',
})
