import type {
  SameRaceMultiSelection,
  AllUpSelection,
  FobSportsSelection,
  SportsDetails,
} from '@mobi/betslip/types'

export type LoadBetDetails = BetDetails | FOOBetDetails | SportDetails

export const enum LoadBetErrors {
  Default = "Sorry, we're unable to create bet",
  BettingClosed = 'Betting Closed',
}

export interface BetDetails {
  fixtureId: string
  fixtureDate: Date
  races: number[]
  selectionString: string
  planSeq: number
  numberOfCombinations?: number
  betType: string | null

  // All Up
  details?: AllUpSelection['details']
  formulas?: AllUpSelection['formulas']

  // Same Race Multi
  srmSelections?: SameRaceMultiSelection['acceptors']
}

export interface FOOBetDetails {
  competitionName: string
  competitionSeq: string
  propositionSeq: number
  betType: string | null
  sportName: string | null
  eventStartDateTime: string
}

export type SportDetails = Pick<SportsDetails, 'eventId'> &
  Pick<FobSportsSelection, 'externalBetId'>
