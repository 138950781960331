import * as gtm from './GoogleTagManagerService'
import * as dataLayer from './AnalyticsDataLayer'
import { keys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'
import { deepMerge } from '@core/Utils/object/deepMerge'

/**
 * @deprecated The method should not be used for new events
 * It requires you to update the javascript in Tag Manager to pick up any new data from the dataLayer
 * Use GoogleTagManagerService/trackEvent instead to pass both the event name and any associated data
 */
export function track(name: string, data?: DeepPartial<dataLayer.AnalyticsData>): void {
  /*
   * deep merge is used to preserve the data layer.
   * Preserving the data layer is currently *required* because its properties are directly accessed and used
   * to write data to via object keys in the legacy code (see `GoogleTagManagerService`).
   */
  updateDataLayer(data)
  dataLayer.addToHistory(name, JSON.stringify(data || {}))
  if (data) {
    gtm.trackEvent(name as Parameters<typeof gtm.trackKey>[0], data)
  } else {
    gtm.trackKey(name as Parameters<typeof gtm.trackKey>[0])
  }
}

export function updateDataLayer(data?: DeepPartial<dataLayer.AnalyticsData>): void {
  if (data) {
    // Make sure we were sent something that can be merged
    deepMerge(dataLayer.data, data)
    dataLayer.saveTrackData()
  }
}

export function assignStructuredSearch(data: dataLayer.StructuredSearchData): void {
  dataLayer.data.structuredSearch = { ...dataLayer.data.structuredSearch, ...data }
  dataLayer.saveTrackData()
}

export function trackAccountNumber(accountNumber?: string): void {
  updateDataLayer({
    user: {
      accountNumber: accountNumber ?? '',
    },
  })
}

export function trackActionInvoked(actionTaken: string): void {
  // Call the action invoked direct call rule
  track(keys.actionInvoked, { actionInvoked: actionTaken })
}

export function trackModalState(modalDisplayed: boolean, modalName: string): void {
  updateDataLayer({
    navigation: {
      modalDisplayed: modalDisplayed,
      modalName: modalName,
    },
  })
}

export function giddyUpViewed(): void {
  updateDataLayer({
    giddyUp: {
      giddyUpViewed: true,
    },
  })
}

export function setGiddyUpQuaddieValue(value: boolean): void {
  updateDataLayer({
    giddyUp: {
      quaddieValueSelected: value,
    },
  })
}

export function setGiddyUpTab(tab: string): void {
  updateDataLayer({
    giddyUp: {
      selectedTab: tab,
    },
  })
}

// Types
type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>
}
