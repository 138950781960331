import { ILegacyBetAdapter } from './ILegacyBetAdapter'
import { LegacyBetModelBuilder } from './LegacyBetModelBuilder'
import { LegacyMultiLegModel } from './LegacyMultiLegModel'
import { BettingInformation } from './BettingInformation'
import { injectable, inject } from 'inversify'
import type { IBettingService } from '../Services/IBettingService'
import type { IBetEnquiryService } from '../Services/IBetEnquiryService'
import type { IErrorHandler } from '../Utils/IErrorHandler'
import type { IEventAggregator } from '../../AppUtils/Framework/Messaging/IEventAggregator'
import BetEnquiryResponseDataTransferObject from '../DataTransferObjects/BetEnquiryResponseDataTransferObject'
import { BetType } from './Betting/BetType'

import * as nav from '@classic/Foundation/Navigation/Nav'
import ObservableMeetingInformation from './Observables/ObservableMeetingInformation'

@injectable()
export class LegacyBetAdapter implements ILegacyBetAdapter {
  private bettingService: IBettingService
  private betEnquiryService: IBetEnquiryService
  private errorHandler: IErrorHandler
  private eventAggregator: IEventAggregator

  constructor(
    @inject('IBettingService') bettingService: IBettingService,
    @inject('IBetEnquiryService') betEnquiryService: IBetEnquiryService,
    @inject('IErrorHandler') errorHandler: IErrorHandler,
    @inject('IEventAggregator') eventAggregator: IEventAggregator
  ) {
    this.bettingService = bettingService
    this.betEnquiryService = betEnquiryService
    this.errorHandler = errorHandler
    this.eventAggregator = eventAggregator
  }

  public doBetEnquiry(
    bettingInformation: BettingInformation,
    callback: (betEnquiryResponse: BetEnquiryResponseDataTransferObject) => void
  ): void {
    const betViewModelBuilder = new LegacyBetModelBuilder(bettingInformation, true, false)
    let betEnquiryPromise: Promise<BetEnquiryResponseDataTransferObject>
    if (bettingInformation.selectedBetType().betType() === BetType.AllUp) {
      betEnquiryPromise = this.betEnquiryService.enquireAllUpBet(betViewModelBuilder.getModel())
    } else {
      betEnquiryPromise = this.betEnquiryService.enquireBet(betViewModelBuilder.getModel())
    }

    betEnquiryPromise
      .then(success => {
        if (success.ExceptionMessage != undefined || success.Message != undefined) {
          const scratchedErrorCallback = () => {
            // If selected starter is scratched, refresh starters
            if (success.ExceptionMessage && success.ExceptionMessage.includes('scratched')) {
              let raceNumbersToRefresh = [
                (
                  bettingInformation.meetingInformation as ObservableMeetingInformation
                ).selectedRace.raceNumber(),
              ]
              this.eventAggregator.publish('race-refresh-command', raceNumbersToRefresh)
            }
          }

          this.errorHandler.showErrorMessage(
            success.Message,
            success.ExceptionMessage,
            scratchedErrorCallback
          )
        } else {
          callback(success)
        }
      })
      .catch(error => {
        if (error.status === 400) {
          const caughtException = jQuery.parseJSON(error.responseText)
          this.errorHandler.showErrorMessage(
            caughtException.Message,
            caughtException.ExceptionMessage
          )
        } else {
          this.eventAggregator.publish('bet.account')

          this.errorHandler.handleErrorAsIs(0, 'Unknown')
        }
      })
  }

  public proposeBet(
    bettingInformation: BettingInformation,
    callback: (result: string) => void,
    callbackFail?: () => void
  ): void {
    var betViewModelBuilder = new LegacyBetModelBuilder(bettingInformation, false, false)
    var request = this.bettingService.propose(betViewModelBuilder.getModel())

    request.done(success => {
      callback(success)
      this.eventAggregator.publish('bet.single.review')
    })
    request.fail(error => {
      callbackFail?.()
      this.errorHandler.handleErrorAsIs(error.status, error.responseText)
    })
  }

  public placeBet(
    bettingInformation: BettingInformation,
    updatedWinPrice: number,
    updatedPlacePrice: number,
    callback: (result: string) => void
  ): void {
    var betViewModelBuilder = new LegacyBetModelBuilder(bettingInformation, false, false)
    var betViewModel = betViewModelBuilder.getModel()
    if (bettingInformation.isFixed()) {
      betViewModel.FixedUpdatedWinPrice = updatedWinPrice
      betViewModel.FixedUpdatedPlacePrice = updatedPlacePrice
      if (updatedWinPrice > 0) {
        betViewModel.FixedWinPrice = updatedWinPrice
      }
      if (updatedPlacePrice > 0) {
        betViewModel.FixedPlacePrice = updatedPlacePrice
      }
    }
    var request = this.bettingService.commit(betViewModel)

    request.done((data, status, xhr) => {
      callback(data)

      this.eventAggregator.publish('bet.single.receipt', {
        betType: xhr.getResponseHeader('betType'),
        ticket: xhr.getResponseHeader('ticket'),
        toteBetValue: xhr.getResponseHeader('toteBetValue'),
        fobWinValue: xhr.getResponseHeader('fobWinValue'),
        fobPlaceValue: xhr.getResponseHeader('fobPlaceValue'),
        specialOffer: xhr.getResponseHeader('specialOffer'),
      })
    })
    request.fail(error => {
      this.errorHandler.handleErrorAsIs(error.status, error.responseText)
    })
  }

  public addLegToMultiBet(bettingInformation: BettingInformation, legType: string): void {
    let multiLegModel = new LegacyMultiLegModel(bettingInformation, legType)
    const request = this.bettingService.addLegToMultiBet(multiLegModel)

    request.done(success => {
      if (success && success.RedirectUrl) {
        nav.navChange(success.RedirectUrl)
      }
    })
    request.fail(error => {
      this.errorHandler.handleErrorAsIs(error.status, error.responseText)
    })
  }
}
