import React from 'react'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { RadioButton } from '@mobi/component-library/Selection&Input/RadioButton'
import { colors, font, radius, spacing } from '@mobi/component-library/Theme/Common'
import { Icon } from '@mobi/component-library/Common/Icon'
import type { PromoType, PromoTypeClass } from '../types'
import { promoTypeColorThemes } from '../constants'

export const PromoSelection: React.FC<{
  itemId: string
  promoType: PromoType
  title: string
  isSelected: boolean
  hasActiveSelection: boolean
  onClick: () => void
  setSelectionHeight: React.Dispatch<React.SetStateAction<number>>
  isPromoSelectorExpanded: boolean
  renderPosition: number
  descriptionSlots?: React.ReactNode[]
}> = ({
  itemId,
  promoType,
  title,
  isSelected,
  hasActiveSelection,
  onClick,
  setSelectionHeight,
  isPromoSelectorExpanded,
  renderPosition,
  descriptionSlots,
}) => {
  const ref = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (!ref.current) return

    const selectionHeight = ref.current.getBoundingClientRect().height
    if (isSelected) {
      setSelectionHeight(selectionHeight)
    }

    if (!isPromoSelectorExpanded) {
      ref.current.style.transition = hasActiveSelection
        ? 'transform 0.4s ease, opacity 0.2s ease'
        : ''
      ref.current.style.transform = `translateY(${isSelected ? `-${selectionHeight * renderPosition}px` : '0px'})`
      ref.current.style.opacity = !hasActiveSelection || isSelected ? '1' : '0'
      ref.current.style.borderColor = isSelected ? colors.white : ''
    } else {
      ref.current.style.transform = 'translateY(0px)'
      ref.current.style.transition = hasActiveSelection
        ? 'transform 0.4s ease, opacity 0.3s ease 0.2s'
        : ''
      ref.current.style.opacity = '1'
      ref.current.style.borderColor = ''
    }
  }, [isSelected, setSelectionHeight, isPromoSelectorExpanded, renderPosition, hasActiveSelection])

  const promoTypeClass: PromoTypeClass = `PromoType-${promoType}`

  return (
    <WrapperStyled className={promoTypeClass} ref={ref}>
      <div>
        <span>
          {promoType === 'SuperPick' && (
            <Icon type='specials' color={hexColors.lightOrange} size='1.6rem' />
          )}
        </span>
      </div>

      <div>
        <h4>{title}</h4>
        {descriptionSlots?.map((slot, slotNum) => slot && <span key={slotNum}>{slot}</span>)}
      </div>

      <div>
        <RadioButton
          id={itemId + title}
          isChecked={isSelected}
          type='Tick'
          size='md'
          onClick={onClick}
        />
      </div>
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div(
  {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.sm,
    paddingTop: spacing.sm,
    paddingBottom: spacing.sm,
    borderBottom: '0.1rem solid var(--main-color)',
    fontFamily: font.family.primary,
    backgroundColor: colors.white,

    transition: 'transform 0.4s ease',
    transform: 'translateY(0px)',
    opacity: 1,

    ':last-of-type': { border: 0 },

    // Icon
    '> div:nth-of-type(1)': {
      '> span': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '3.2rem',
        height: '3.2rem',
        borderRadius: radius.full,
        backgroundColor: 'var(--main-color)',
      },
    },

    // Title
    '> div:nth-of-type(2)': {
      display: 'flex',
      flexDirection: 'column',
      gap: spacing.smx2,

      '> h4': {
        margin: 0,
        padding: 0,
        color: colors.black,
        fontSize: font.size.md.fontSize,
        letterSpacing: font.size.md.letterSpacing,
        lineHeight: font.size.md.lineHeight,
        fontWeight: font.weight.medium,
      },

      '> span': {
        fontSize: font.size.sm.fontSize,
        letterSpacing: font.size.sm.letterSpacing,
        lineHeight: font.size.sm.lineHeight,
        fontWeight: font.weight.regular,
        color: colors.neutral[800],
      },
    },

    // Radio Button
    '> div:nth-of-type(3)': {
      marginLeft: 'auto',
    },
  },
  promoTypeColorThemes
)
