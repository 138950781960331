import React from 'react'
import { TabButton, Tabs } from '@mobi/component-library/Navigation/Tabs'
import { useAppSelector } from '@mobi/betslip/Store/hooks'
import { selectBetSlipItems } from '@mobi/betslip/Store/Bets/selectors'
import { hasInvestment, isSelectionAllowedInMulti, isValidMulti } from '@mobi/betslip/helpers/state'
import { MIN_LEGS_IN_MULTI } from '@mobi/betslip/helpers/constants'
import { selectVisibleBettingSection } from '@mobi/betslip/Store/Workflow/selectors'
import { useListenForDesiredBettingSection } from './hooks/useListenForDesiredBettingSection'

export const TabButtons: React.FC<{
  containerRef: React.RefObject<HTMLDivElement>
  singlesRef: React.RefObject<HTMLDivElement>
  multiRef: React.RefObject<HTMLDivElement>
}> = ({ containerRef, singlesRef, multiRef }) => {
  const items = useAppSelector(selectBetSlipItems)
  const visibleBettingSection = useAppSelector(selectVisibleBettingSection)

  const workflowStatus = useAppSelector(state => state.betslip.workflow.currentStatus)
  const multiInvestment = useAppSelector(state => state.betslip.bets.multiInvestment)
  const multiBetError = useAppSelector(state => state.betslip.bets.multiBetError)

  const multiItems = items.filter(item => isSelectionAllowedInMulti(item.selection))
  const activeMultiItems = multiItems.filter(item => item.isInMulti)

  const isMultiValid = isValidMulti(multiInvestment, multiBetError, activeMultiItems)
  const isEditable = workflowStatus === 'ready'
  const shouldRenderSinglesTab = isEditable || (!isEditable && items.some(hasInvestment))
  const hasEnoughMultiItems = multiItems.length >= MIN_LEGS_IN_MULTI

  const handleTabClick = React.useCallback(
    (elRef: React.RefObject<HTMLDivElement>) => {
      const top = getRelativeScrollTopOffset(elRef.current as HTMLDivElement)
      containerRef.current?.scrollTo({ top, behavior: 'smooth' })
    },
    [containerRef]
  )

  const scrollToMulti = React.useCallback(() => {
    handleTabClick(multiRef)
  }, [handleTabClick, multiRef])

  const scrollToSingles = React.useCallback(() => {
    handleTabClick(singlesRef)
  }, [handleTabClick, singlesRef])

  useListenForDesiredBettingSection({ scrollToMulti, scrollToSingles })

  return (
    <Tabs>
      {shouldRenderSinglesTab && (
        <TabButton
          name={`Singles (${items.length})`}
          onClick={scrollToSingles}
          isSelected={visibleBettingSection === 'Singles'}
          shouldFillSpace={false}
        />
      )}

      {((isEditable && hasEnoughMultiItems) || (!isEditable && isMultiValid)) && (
        <TabButton
          name='Multi (1)'
          onClick={scrollToMulti}
          isSelected={visibleBettingSection === 'Multi'}
          shouldFillSpace={false}
        />
      )}
    </Tabs>
  )
}

// =============
// Local Helpers
// =============

function getRelativeScrollTopOffset(el: HTMLDivElement): number {
  const parent = el.parentElement as HTMLDivElement
  return el.offsetTop - parent.offsetTop
}
