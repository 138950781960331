import React from 'react'
import ReactDOM from 'react-dom'
import {
  state$ as structuredSearchState$,
  StructuredSearchFields,
  StructuredSearchValueChanged,
  StructuredSearchSetState,
  defaultState,
} from '../Driver/StructuredSearchDriver'
import { CodeSelectionFilter, Races, Trots, Dogs } from '@core/Components/CodeSelector/CodeSelector'
import { GridRow, Grid, GridCell } from '@mobi/component-library/Common/Grid'
import { useObservableImmutable } from '@core/Utils/hooks'
import {
  StructuredSearchContainerStyled,
  StructureSearchGroup,
  CriteriaFixedFooterStyled,
  StructureSearchGroupHeader,
  CriteriaSubtitleLabelStyled,
  ToggleBlockStyled,
  ToggleLabelStyled,
  ToggleSubLabelStyled,
  ToggleFirstBlockStyled,
  ToggleNoLabelBlockStyled,
} from '../StructuredSearch.styles'

import { RangeInput } from '../Components/RangeInput/RangeInput'
import {
  ButtonGroupStyled,
  ButtonGroupItemStyled,
} from '@core/Components/Buttons/ButtonGroup.styles'
import { ButtonBlock } from '@mobi/component-library/Common/Buttons'
import { RangeBoundaries } from '../RangeBoundaries'
import { StructuredSearchLocation } from '../Components/StructuredSearchLocation/StructuredSearchLocation'
import { Toggle } from '@mobi/component-library/Common/Input'
import { FeatureFlag } from '@core/State/LaunchDarklyFeatures/Components/FeatureFlag'
import { FeatureFlags } from '@mobi/settings'

export function StructuredSearchCriteria({
  selectTabIndex,
}: {
  selectTabIndex: (x: number) => void
}): JSX.Element {
  const { searchCriteria, searchResults } = useObservableImmutable(structuredSearchState$, [
    'searchCriteria',
    'searchResults',
  ])
  const totalResultCount = searchResults?.get('totalResultCount')

  // ideally move/hide the defaultState handling within the driver and/or side effects
  // - may require a re-think about side-effects assigning default values before this component subscribes to handle Rx.DistinctUntilChanged from useObservableImmutable
  const sc = searchCriteria ? searchCriteria.toJS() : defaultState.searchCriteria

  const onChangeCallback = (
    field: keyof StructuredSearchFields,
    value: string | boolean | Date | number
  ) => {
    StructuredSearchValueChanged({ field, value })
  }

  const resetCallback = () => {
    StructuredSearchSetState(defaultState.searchCriteria)
  }

  const contestTypeChangedCallback = ({ field, value }: { field: string; value: boolean }) => {
    let driverField: keyof StructuredSearchFields | null = null
    switch (field) {
      case Races:
        driverField = 'contestTypeRace'
        break
      case Trots:
        driverField = 'contestTypeTrot'
        break
      case Dogs:
        driverField = 'contestTypeDogs'
        break
    }
    if (driverField) {
      StructuredSearchValueChanged({ field: driverField, value })
    }
  }

  return (
    <StructuredSearchContainerStyled>
      <Grid>
        <GridRow>
          <GridCell padding='0.5rem 0 0 0'>
            <StructureSearchGroup>
              <StructureSearchGroupHeader>Racing Code</StructureSearchGroupHeader>
              <CodeSelectionFilter
                racesSelected={sc.contestTypeRace}
                trotsSelected={sc.contestTypeTrot}
                dogsSelected={sc.contestTypeDogs}
                selectionChangedCallback={contestTypeChangedCallback}
                testId='structured-search'
              />
            </StructureSearchGroup>
          </GridCell>
        </GridRow>

        <GridRow>
          <GridCell>
            <StructureSearchGroup>
              <StructureSearchGroupHeader>Date</StructureSearchGroupHeader>
              <ButtonGroupStyled>
                <ButtonGroupItemStyled
                  id='date-today'
                  data-tid-date-today=''
                  selected={sc.dateToday}
                  onClick={() => onChangeCallback('dateToday', !sc.dateToday)}
                >
                  Today
                </ButtonGroupItemStyled>
                <ButtonGroupItemStyled
                  id='date-tomorrow'
                  data-tid-date-tomorrow=''
                  selected={sc.dateTomorrow}
                  onClick={() => onChangeCallback('dateTomorrow', !sc.dateTomorrow)}
                >
                  Tomorrow
                </ButtonGroupItemStyled>
                <ButtonGroupItemStyled
                  id='date-beyond-tomorrow'
                  data-tid-date-beyond-tomorrow=''
                  selected={sc.dateBeyondTomorrow}
                  onClick={() => onChangeCallback('dateBeyondTomorrow', !sc.dateBeyondTomorrow)}
                >
                  Later
                </ButtonGroupItemStyled>
              </ButtonGroupStyled>
            </StructureSearchGroup>
          </GridCell>
        </GridRow>

        <GridRow>
          <GridCell>
            <StructureSearchGroup>
              <StructuredSearchLocation />
            </StructureSearchGroup>
          </GridCell>
        </GridRow>

        <GridRow>
          <GridCell>
            <StructureSearchGroup>
              <StructureSearchGroupHeader>Last Start</StructureSearchGroupHeader>

              <ToggleNoLabelBlockStyled>
                <div>
                  <ToggleLabelStyled>Won Last Start</ToggleLabelStyled>
                </div>
                <Toggle
                  data-tid-won-last-start={sc.wonLastStart}
                  toggledOn={sc.wonLastStart}
                  onToggle={() => onChangeCallback('wonLastStart', !sc.wonLastStart)}
                  isDisabled={false}
                  type='inverted'
                />
              </ToggleNoLabelBlockStyled>

              <ToggleNoLabelBlockStyled>
                <div>
                  <ToggleLabelStyled>Placed Last Start</ToggleLabelStyled>
                </div>
                <Toggle
                  data-tid-placed-last-start={sc.placedLastStart}
                  toggledOn={sc.placedLastStart}
                  onToggle={() => onChangeCallback('placedLastStart', !sc.placedLastStart)}
                  isDisabled={false}
                  type='inverted'
                />
              </ToggleNoLabelBlockStyled>

              <ToggleNoLabelBlockStyled>
                <div>
                  <ToggleLabelStyled>Favourite Last Start</ToggleLabelStyled>
                </div>
                <Toggle
                  data-tid-favourite-last-start={sc.favouriteLastStart}
                  toggledOn={sc.favouriteLastStart}
                  onToggle={() => onChangeCallback('favouriteLastStart', !sc.favouriteLastStart)}
                  isDisabled={false}
                  type='inverted'
                />
              </ToggleNoLabelBlockStyled>

              <CriteriaSubtitleLabelStyled>Days Since Last Start</CriteriaSubtitleLabelStyled>
              <RangeInput
                id='days-since-last-start'
                testId='days-since-last-start'
                minRange={RangeBoundaries.minDaysSinceLastStart}
                maxRange={RangeBoundaries.maxDaysSinceLastStart}
                values={[sc.minDaysSinceLastStart, sc.maxDaysSinceLastStart]}
                step={1}
                minChangedCallback={(value: number) => {
                  onChangeCallback('minDaysSinceLastStart', value)
                }}
                maxChangedCallback={(value: number) => {
                  onChangeCallback('maxDaysSinceLastStart', value)
                }}
                isMaxInfinite={true}
              />

              <CriteriaSubtitleLabelStyled>Days Since Last Win</CriteriaSubtitleLabelStyled>
              <RangeInput
                id='days-since-last-win'
                testId='days-since-last-win'
                step={1}
                minRange={RangeBoundaries.minDaysSinceLastWin}
                maxRange={RangeBoundaries.maxDaysSinceLastWin}
                values={[sc.minDaysSinceLastWin, sc.maxDaysSinceLastWin]}
                minChangedCallback={(value: number) => {
                  onChangeCallback('minDaysSinceLastWin', value)
                }}
                maxChangedCallback={(value: number) => {
                  onChangeCallback('maxDaysSinceLastWin', value)
                }}
                isMaxInfinite={true}
              />
              <CriteriaSubtitleLabelStyled>Starts Since Last Spell</CriteriaSubtitleLabelStyled>
              <RangeInput
                id='starts-since-last-spell'
                testId='starts-since-last-spell'
                step={1}
                minRange={RangeBoundaries.minStartsSinceLastSpell}
                maxRange={RangeBoundaries.maxStartsSinceLastSpell}
                values={[sc.minStartsSinceLastSpell, sc.maxStartsSinceLastSpell]}
                minChangedCallback={(value: number) => {
                  onChangeCallback('minStartsSinceLastSpell', value)
                }}
                maxChangedCallback={(value: number) => {
                  onChangeCallback('maxStartsSinceLastSpell', value)
                }}
                isMaxInfinite={true}
              />
            </StructureSearchGroup>

            <StructureSearchGroup>
              <StructureSearchGroupHeader>This Start</StructureSearchGroupHeader>
              <FeatureFlag flag={FeatureFlags.RACING_SMART_FORM_FIXED_ODDS.key}>
                <CriteriaSubtitleLabelStyled>Fixed Odds Price</CriteriaSubtitleLabelStyled>
                <RangeInput
                  id='fixed-odds-price-range'
                  testId='fixed-odds-price-range'
                  step={1}
                  isCurrency={true}
                  minRange={RangeBoundaries.minFixedOddsPrice}
                  maxRange={RangeBoundaries.maxFixedOddsPrice}
                  values={[sc.minFixedOddsPrice, sc.maxFixedOddsPrice]}
                  minChangedCallback={(value: number) => {
                    onChangeCallback('minFixedOddsPrice', value)
                  }}
                  maxChangedCallback={(value: number) => {
                    onChangeCallback('maxFixedOddsPrice', value)
                  }}
                  isMaxInfinite={true}
                />
              </FeatureFlag>

              <ToggleFirstBlockStyled>
                <div>
                  <ToggleLabelStyled>First Up</ToggleLabelStyled>
                  <ToggleSubLabelStyled>First start since a spell</ToggleSubLabelStyled>
                </div>
                <Toggle
                  data-tid-first-up={sc.firstUp}
                  toggledOn={sc.firstUp}
                  onToggle={() => onChangeCallback('firstUp', !sc.firstUp)}
                  isDisabled={false}
                  type='inverted'
                />
              </ToggleFirstBlockStyled>

              <ToggleBlockStyled>
                <div>
                  <ToggleLabelStyled>Second Up</ToggleLabelStyled>
                  <ToggleSubLabelStyled>Second start since a spell</ToggleSubLabelStyled>
                </div>
                <Toggle
                  data-tid-second-up={sc.secondUp}
                  toggledOn={sc.secondUp}
                  onToggle={() => onChangeCallback('secondUp', !sc.secondUp)}
                  isDisabled={false}
                  type='inverted'
                />
              </ToggleBlockStyled>

              <ToggleBlockStyled>
                <div>
                  <ToggleLabelStyled>Gear Change</ToggleLabelStyled>
                  <ToggleSubLabelStyled>Gear changed since last start</ToggleSubLabelStyled>
                </div>
                <Toggle
                  data-tid-gear-change={sc.gearChange}
                  toggledOn={sc.gearChange}
                  onToggle={() => onChangeCallback('gearChange', !sc.gearChange)}
                  isDisabled={false}
                  type='inverted'
                />
              </ToggleBlockStyled>

              <ToggleBlockStyled>
                <div>
                  <ToggleLabelStyled>Apprentice</ToggleLabelStyled>
                  <ToggleSubLabelStyled>Apprentice riding</ToggleSubLabelStyled>
                </div>
                <Toggle
                  data-tid-apprentice={sc.apprentice}
                  toggledOn={sc.apprentice}
                  onToggle={() => onChangeCallback('apprentice', !sc.apprentice)}
                  isDisabled={false}
                  type='inverted'
                />
              </ToggleBlockStyled>

              <CriteriaSubtitleLabelStyled>Position (Races)</CriteriaSubtitleLabelStyled>

              <ButtonGroupStyled>
                <ButtonGroupItemStyled
                  id='barrier-number-one-to-five'
                  data-tid-barrier-number-one-to-five=''
                  selected={sc.barrierNumberOneToFive}
                  onClick={() =>
                    onChangeCallback('barrierNumberOneToFive', !sc.barrierNumberOneToFive)
                  }
                >
                  Barrier 1-5
                </ButtonGroupItemStyled>

                <ButtonGroupItemStyled
                  id='barrier-number-six-plus'
                  data-tid-barrier-number-six-plus=''
                  selected={sc.barrierNumberSixPlus}
                  onClick={() => onChangeCallback('barrierNumberSixPlus', !sc.barrierNumberSixPlus)}
                >
                  Barrier 6+
                </ButtonGroupItemStyled>
              </ButtonGroupStyled>

              <CriteriaSubtitleLabelStyled>Position (Dogs)</CriteriaSubtitleLabelStyled>

              <ButtonGroupStyled>
                <ButtonGroupItemStyled
                  id='box-number-one-to-four'
                  data-tid-box-number-one-to-four=''
                  selected={sc.boxNumberOneToFour}
                  onClick={() => onChangeCallback('boxNumberOneToFour', !sc.boxNumberOneToFour)}
                >
                  Box 1-4
                </ButtonGroupItemStyled>

                <ButtonGroupItemStyled
                  id='box-number-five-plus'
                  data-tid-box-number-five-plus=''
                  selected={sc.boxNumberFivePlus}
                  onClick={() => onChangeCallback('boxNumberFivePlus', !sc.boxNumberFivePlus)}
                >
                  Box 5-8
                </ButtonGroupItemStyled>
              </ButtonGroupStyled>

              <CriteriaSubtitleLabelStyled>Weight Change</CriteriaSubtitleLabelStyled>

              <ButtonGroupStyled>
                <ButtonGroupItemStyled
                  id='weight-changed-up'
                  data-tid-weight-changed-up=''
                  selected={sc.weightChangedUp}
                  onClick={() => onChangeCallback('weightChangedUp', !sc.weightChangedUp)}
                >
                  Up
                </ButtonGroupItemStyled>

                <ButtonGroupItemStyled
                  id='weight-changed-down'
                  data-tid-weight-changed-down=''
                  selected={sc.weightChangedDown}
                  onClick={() => onChangeCallback('weightChangedDown', !sc.weightChangedDown)}
                >
                  Down
                </ButtonGroupItemStyled>

                <ButtonGroupItemStyled
                  id='weight-not-changed'
                  data-tid-weight-not-changed=''
                  selected={sc.weightNotChanged}
                  onClick={() => onChangeCallback('weightNotChanged', !sc.weightNotChanged)}
                >
                  Same
                </ButtonGroupItemStyled>
              </ButtonGroupStyled>

              <CriteriaSubtitleLabelStyled>Distance Change</CriteriaSubtitleLabelStyled>

              <ButtonGroupStyled>
                <ButtonGroupItemStyled
                  id='distance-changed-up'
                  data-tid-distance-changed-up=''
                  selected={sc.distanceChangedUp}
                  onClick={() => onChangeCallback('distanceChangedUp', !sc.distanceChangedUp)}
                >
                  Up
                </ButtonGroupItemStyled>

                <ButtonGroupItemStyled
                  id='distance-changed-down'
                  data-tid-distance-changed-down=''
                  selected={sc.distanceChangedDown}
                  onClick={() => onChangeCallback('distanceChangedDown', !sc.distanceChangedDown)}
                >
                  Down
                </ButtonGroupItemStyled>

                <ButtonGroupItemStyled
                  id='distance-not-changed'
                  data-tid-distance-not-changed=''
                  selected={sc.distanceNotChanged}
                  onClick={() => onChangeCallback('distanceNotChanged', !sc.distanceNotChanged)}
                >
                  Same
                </ButtonGroupItemStyled>
              </ButtonGroupStyled>

              <br />

              <StructureSearchGroupHeader>Lifetime</StructureSearchGroupHeader>

              <CriteriaSubtitleLabelStyled>Conditions</CriteriaSubtitleLabelStyled>
              <ButtonGroupStyled>
                <ButtonGroupItemStyled
                  id='win-track-condition-good'
                  data-tid-win-track-condition-good=''
                  selected={sc.winTrackConditionGood}
                  onClick={() =>
                    onChangeCallback('winTrackConditionGood', !sc.winTrackConditionGood)
                  }
                >
                  Good
                </ButtonGroupItemStyled>

                <ButtonGroupItemStyled
                  id='win-track-condition-firm'
                  data-tid-win-track-condition-firm=''
                  selected={sc.winTrackConditionFirm}
                  onClick={() =>
                    onChangeCallback('winTrackConditionFirm', !sc.winTrackConditionFirm)
                  }
                >
                  Firm
                </ButtonGroupItemStyled>

                <ButtonGroupItemStyled
                  id='win-track-condition-soft'
                  data-tid-win-track-condition-soft=''
                  selected={sc.winTrackConditionSoft}
                  onClick={() =>
                    onChangeCallback('winTrackConditionSoft', !sc.winTrackConditionSoft)
                  }
                >
                  Soft
                </ButtonGroupItemStyled>

                <ButtonGroupItemStyled
                  id='win-track-condition-heavy'
                  data-tid-win-track-condition-heavy=''
                  selected={sc.winTrackConditionHeavy}
                  onClick={() =>
                    onChangeCallback('winTrackConditionHeavy', !sc.winTrackConditionHeavy)
                  }
                >
                  Heavy
                </ButtonGroupItemStyled>

                <ButtonGroupItemStyled
                  id='win-track-condition-synthetic'
                  data-tid-win-track-condition-synthetic=''
                  selected={sc.winTrackConditionSynthetic}
                  onClick={() =>
                    onChangeCallback('winTrackConditionSynthetic', !sc.winTrackConditionSynthetic)
                  }
                >
                  Synthetic
                </ButtonGroupItemStyled>
              </ButtonGroupStyled>
            </StructureSearchGroup>
          </GridCell>
        </GridRow>

        <GridRow>
          <GridCell>
            <StructureSearchGroup>
              <CriteriaSubtitleLabelStyled>Career Win Percentage</CriteriaSubtitleLabelStyled>
              <RangeInput
                id='career-win-percentage'
                testId='career-win-percentage'
                step={1}
                minRange={RangeBoundaries.minPercentage}
                maxRange={RangeBoundaries.maxPercentage}
                values={[sc.careerWinPercentage]}
                minChangedCallback={(value: number) => {
                  onChangeCallback('careerWinPercentage', value)
                }}
                isMaxInfinite={false}
                isPercentage={true}
              />

              <CriteriaSubtitleLabelStyled>Track Win Percentage</CriteriaSubtitleLabelStyled>
              <RangeInput
                id='track-win-percentage'
                testId='track-win-percentage'
                step={1}
                minRange={RangeBoundaries.minPercentage}
                maxRange={RangeBoundaries.maxPercentage}
                values={[sc.trackWinPercentage]}
                minChangedCallback={(value: number) => {
                  onChangeCallback('trackWinPercentage', value)
                }}
                isMaxInfinite={true}
                isPercentage={true}
              />

              <CriteriaSubtitleLabelStyled>Distance Win Percentage</CriteriaSubtitleLabelStyled>
              <RangeInput
                id='distance-win-percentage'
                testId='distance-win-percentage'
                step={1}
                minRange={RangeBoundaries.minPercentage}
                maxRange={RangeBoundaries.maxPercentage}
                values={[sc.distanceWinPercentage]}
                minChangedCallback={(value: number) => {
                  onChangeCallback('distanceWinPercentage', value)
                }}
                isMaxInfinite={true}
                isPercentage={true}
              />

              <CriteriaSubtitleLabelStyled>
                Track/Distance Win Percentage
              </CriteriaSubtitleLabelStyled>
              <RangeInput
                id='track-distance-win-percentage'
                testId='track-distance-win-percentage'
                step={1}
                minRange={RangeBoundaries.minPercentage}
                maxRange={RangeBoundaries.maxPercentage}
                values={[sc.trackdistanceWinPercentage]}
                minChangedCallback={(value: number) => {
                  onChangeCallback('trackdistanceWinPercentage', value)
                }}
                isMaxInfinite={true}
                isPercentage={true}
              />
            </StructureSearchGroup>
          </GridCell>
        </GridRow>
      </Grid>

      {ReactDOM.createPortal(
        <CriteriaFixedFooterStyled>
          <Grid maxWidth='40rem'>
            <GridRow padding='0.5rem'>
              <GridCell>
                <ButtonBlock
                  color='secondary'
                  onClick={resetCallback}
                  data-tid-structred-search-reset-all
                  testId='structured-search-clear-button'
                >
                  Clear
                </ButtonBlock>
              </GridCell>
              <GridCell>
                <ButtonBlock
                  color='primary'
                  onClick={() => selectTabIndex(1)}
                  testId='structured-search-submit-button'
                >
                  Results ({totalResultCount})
                </ButtonBlock>
              </GridCell>
            </GridRow>
          </Grid>
        </CriteriaFixedFooterStyled>,
        document.body
      )}

      <br />
      <br />
      <br />
      <br />
      <br />
    </StructuredSearchContainerStyled>
  )
}
