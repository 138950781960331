import { ChannelType } from '@mobi/component-library/Common/Svg/SkyTv/SkyTvSvg'
import { SkyVideoPlayerState } from '../Store'

/** Defaults to Sky2 */
export function getVisionChannelForDisplay(
  channelId: string,
  isRacingWA: boolean,
  hasRaceReplayUrl: boolean = false
): SkyVideoPlayerState['selectedChannel'] {
  if (hasRaceReplayUrl) return 'Replay'
  else if (isRacingWA) return 'RacingWA'
  else if (channelId == 'SR1') return 'Sky1'
  else return 'Sky2'
}

export function isMatchingVideoPlaying(
  isPlaying: boolean,
  activeChannel: ChannelType,
  desiredChannel: ChannelType,
  isSameRace: boolean
) {
  return isPlaying && activeChannel == desiredChannel && (activeChannel != 'Replay' || isSameRace)
}
