import React from 'react'
import * as QueryString from 'query-string'
import { useParams, useLocation } from 'react-router-dom'
import { GridCell } from '@mobi/component-library/Common/Grid'
import { useFeature } from '@core/Utils/hooks'
import { MainContainer } from '@core/Components/Containers'
import { isThoroughbredRaceInWA as isThoroughbredMeetingRaceInWA } from '@core/Areas/Racing/helpers/liveVideoFeedHelpers'
import { RaceListHeader } from '@core/Components/RaceListHeader'
import { RaceListContainerStyled as Container } from './RaceList.styles'
import { NoticeBoxSingle, NoticeBoxTypes } from '@core/Components/NoticeBox'
import { LoadingPlaceholder } from '@mobi/component-library/Common/LoadingPlaceholder'
import { useRaceList } from '../Racing/Hooks/useRaceList/useRaceList'
import { RaceElements } from './Components/RaceElements'
import { radius } from '@mobi/component-library/Theme/Common'

export const ToteMeetingsRegex = '^tote/meetings/([^?]+)\\?.*date=(.*)?'
export const ToteRacesRegex = '^tote/races'

interface RaceListParams {
  meetingId?: string // tote/meetings/__CRO__?date=2021-01-21
  compSeq?: string // fobracing/foo/races/__912696__/2021-03-19?selectiondate=2021-03-19
  product?: string
  code?: string
  date?: string
}

export const RaceListPage: React.FC<{}> = () => {
  const { meetingId, date, product, compSeq, geoLocationCode } = useGetParams()
  const { meeting, isLoading, isError } = useRaceList(
    date,
    meetingId != '-' ? meetingId : '',
    compSeq,
    product
  )

  const hasNoRaceData = !meeting || !meeting.races || meeting.races.length === 0 || isError

  const isRacingWAFeatureActive = useFeature('RACING_WA_VISION_CHANNEL')
  const isThoroughbredRaceInWA =
    isRacingWAFeatureActive && isThoroughbredMeetingRaceInWA(geoLocationCode, meeting?.meetingCode)

  return (
    <MainContainer>
      <RaceListHeader
        title={meeting?.courseName || ''}
        code={meeting?.meetingCode || ''}
        weather={meeting?.courseWeather || ''}
        condition={meeting?.courseCondition || ''}
        eventDate={date}
      />
      <Container>
        {isLoading ? (
          <GridCell padding='0 1rem'>
            {Array(5)
              .fill(0)
              .map((_, i: number) => (
                <div key={i} data-testid='race-list-loading'>
                  <LoadingPlaceholder width='100%' height='3.75rem' borderRadius={radius.lg} />
                  <br />
                </div>
              ))}
          </GridCell>
        ) : hasNoRaceData ? (
          <NoticeBoxSingle
            data-tid-notice-box='noracesfound'
            hasBorder={true}
            noticeBoxType={NoticeBoxTypes.Warning}
            title='No races found.'
          />
        ) : (
          <RaceElements raceData={meeting} isThoroughbredRaceInWA={isThoroughbredRaceInWA} />
        )}
      </Container>
    </MainContainer>
  )
}

// must be able to extract params for:
// always:
//  tote/meetings/CRO?date=2021-01-21
//  tote/races?date=2021-03-22&meetingId=PRX&product=foo&compSeq=911218
//  fobracing/foo/races/912696/2021-03-19?selectiondate=2021-03-19
function useGetParams() {
  const location = useLocation<{ geoLocationCode: string } | undefined>()
  const params = useParams<RaceListParams>()
  const search = QueryString.parse(location.search)
  if (search.selectionDate && !search.date) search.date = search.selectionDate

  return {
    meetingId: params.meetingId || search.meetingId,
    date: search.date || search.selectiondate,
    product: params.product || search.product,
    compSeq: params.compSeq || search.compSeq,
    code: params.code || search.code,
    geoLocationCode: location.state?.geoLocationCode || '',
  }
}
