import { RootState } from '@core/Store'

export const getEnhancedBetslipSettings = ({ settings }: RootState) => {
  return {
    enhancedBetslip: settings.enhancedBetslip.enabled,
    hasUserTouchedEnhancedBetslip: settings.enhancedBetslip.touched,
  }
}
export const getHasUserTouchedEnhancedBetslipSetting = ({ settings }: RootState) =>
  settings.enhancedBetslip.touched
export const getEnhancedBetslipSetting = ({ settings }: RootState) =>
  settings.enhancedBetslip.enabled
export const isHapticFeedbackSettingEnabled = ({ settings }: RootState) =>
  settings.hapticFeedback.enabled
export const getNewSportsSetting = ({ settings }: RootState) => settings.newSports.enabled
export const getNewBetSlipSetting = ({ settings }: RootState) => settings.newBetSlip.enabled
