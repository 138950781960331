import type { MysterySelection, MysteryDetails, Race } from '@mobi/betslip/types'
import {
  QuickbetSelection,
  SetMysteryQuickPickPresetInvestment,
} from '@core/Areas/Quickbet/signals'
import { logError } from '@classic/Foundation/Services/LoggingService'
import { MysteryQuickPickOptions, CreateMysterySelection } from './MysteryTypes'
import { handleBetSelection } from '@core/Utils/betting/handleBetSelection'

export function createMysterySelection({
  productCode,
  mysteryOption,
  mysteryType,
  isAlways,
  investmentWin,
  investmentPlace,
  betTypeName,
  raceInformation,
  betType,
}: CreateMysterySelection): QuickbetSelection {
  const race = raceInformation

  const selection: MysterySelection = {
    fixtureId: race.meetingId,
    fixtureDate: race.meetingDate,
    raceNumber: race.raceNumber,
    mysteryType,
    isAlways,
    productCode,
    mysteryOption,
    bets: null,
    metaData: null,
  }
  const selectionDetails: MysteryDetails = {
    race,
    investmentWin,
    investmentPlace,
    productCode,
    mysteryOption,
    betTypeName,
    betType,
  }

  return {
    bettingType: betType,
    selection,
    isEachWayAvailable: false,
    shouldAllowPlaceInvestment: false,
    presetInvestment: betType === 'mystery-quick-pick' ? true : undefined,
    selectionDetails,
  }
}

export const betFromRaceBettingPageQuickPick = (
  selectedKey: string | null,
  raceInformation: Race
): void => {
  if (!selectedKey) {
    return
  }

  const quickPick = MysteryQuickPickOptions.find(qp => qp.uniqueKey === selectedKey)

  if (!quickPick) {
    if (!PRODUCTION) {
      logError('Mystery Quick Pick details not found')
    }
    return
  }
  const {
    productCode,
    mysteryType,
    mysteryOption,
    investment: investmentWin,
    uniqueKey: betTypeName,
    displayPrice,
    isAlways,
  } = quickPick

  const betSelection = createMysterySelection({
    productCode,
    mysteryOption,
    mysteryType,
    isAlways,
    investmentWin,
    investmentPlace: 0,
    betTypeName,
    raceInformation,
    betType: 'mystery-quick-pick',
  })

  openQuickBetMystery(betSelection, displayPrice)
}

export function openQuickBetMystery(selection: QuickbetSelection, displayPrice: number): void {
  handleBetSelection({
    betFlow: {
      location: 'Quickbet',
      options: {
        setUp: () => {
          SetMysteryQuickPickPresetInvestment(displayPrice)
        },
        quickbetProps: { shouldShowSuperPicks: false },
      },
    },
    selection,
  })
}

export interface MysteryProductPlanBetLine {
  template: string
  poolType: string
  defaultInvestment: number
  isMultiLeg: boolean
}
