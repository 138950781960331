import { createSelector } from '@reduxjs/toolkit'
import {
  isFobSelection,
  isFobSportsDetails,
  isFobSportsSelection,
} from '@mobi/betslip/helpers/typeGuards'
import type { AppState } from '..'
import { isSelectionAllowedInMulti } from '@mobi/betslip/helpers/state'

export const selectBetSlipItems = (state: AppState) => state.betslip.bets.items

export const selectBetSlipItemCount = createSelector([selectBetSlipItems], items => {
  return items.length
})

export const selectBetSlipMultiLegCount = createSelector([selectBetSlipItems], items => {
  return items.filter(item => isSelectionAllowedInMulti(item.selection)).length
})

export const selectBetSlipItemExternalBetIds = createSelector([selectBetSlipItems], items => {
  return items.flatMap(item =>
    isFobSportsSelection(item.selection) ? [item.selection.externalBetId] : []
  )
})

/** Retrieve fob selection info for price change push subscriptions */
export const selectBetSlipFobSelectionsIds = createSelector([selectBetSlipItems], items => {
  const fobPriceItems: FobPriceSubscriptionItem[] = items.flatMap(item => {
    if (!isFobSelection(item.selection)) return []

    if (isFobSportsDetails(item.selectionDetails)) {
      const { marketId, marketTypeCode, outcomeId } = item.selectionDetails
      return [
        {
          type: 'sports',
          details: { marketId, marketTypeCode, outcomeId },
        } as FobPriceSubscriptionItem,
      ]
    }

    return [
      {
        type: 'racing',
        details: { id: item.selection.propositionSeq },
      } as FobPriceSubscriptionItem,
    ]
  })
  return fobPriceItems
})

// =====
// Types
// =====

type FobPriceSubscriptionItem =
  | { type: 'racing'; details: { id: string } }
  | { type: 'sports'; details: { marketId: number; marketTypeCode: string; outcomeId: number } }
