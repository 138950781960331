import * as ko from 'knockout'
import { injectable, inject } from 'inversify'
import { IObservableFinisher } from '@classic/Betting-v2/Model/Observables/IObservableFinisher'
import type { IEventAggregator } from '@classic/AppUtils/Framework/Messaging/IEventAggregator'
import { Disposable } from '@classic/AppUtils/Framework/Disposable/Disposable'
import Guard from '@classic/AppUtils/Framework/Guard'
import { IFinisherPlaceViewModel } from './IFinisherPlaceViewModel'
import { getOrdinalSuffix } from '@mobi/utils/number/ordinal'

@injectable()
export class FinisherPlaceViewModel extends Disposable implements IFinisherPlaceViewModel {
  public finisher?: IObservableFinisher
  public ordinal?: ko.PureComputed<string>

  constructor(@inject('IEventAggregator') eventAggregator: IEventAggregator) {
    super(eventAggregator)
  }

  init(params: { finisher: IObservableFinisher }) {
    Guard.notNull(params.finisher)

    this.finisher = params.finisher
    this.ordinal = ko.pureComputed<string>(() => {
      const placing = (this.finisher as IObservableFinisher).placing()
      return getOrdinalSuffix(placing, true)
    })
  }
}
