import { Currency } from '@mobi/utils/money'
import type {
  BetSlipItem,
  FobPropositionSelection,
  FobSelection,
  MultiInvestment,
  MultiInvestmentKey,
} from '@mobi/betslip/types'
import { getBetsInMulti, hasTooManyMultiLegs, isValidMulti } from '@mobi/betslip/helpers/state'
import {
  MAX_LEGS_FOR_MULTI_FORMULA,
  MIN_LEGS_IN_MULTI,
  MULTI_FORMULA_COMBINATIONS,
} from '@mobi/betslip/helpers/constants'
import { roundDownDecimal } from './misc'

export function calculateMultiReturn(
  items: BetSlipItem[],
  { shouldRound }: { shouldRound: boolean } = { shouldRound: false }
): number {
  const multiItems = getBetsInMulti(items)
  const tooManyBets = hasTooManyMultiLegs(multiItems)
  const isValidNumberOfMultiLegs = multiItems.length >= MIN_LEGS_IN_MULTI && !tooManyBets

  if (!isValidNumberOfMultiLegs) {
    return 0
  }

  const multiReturn = new Currency(
    multiItems.reduce((total: number, item: BetSlipItem) => {
      const { winPrice, placePrice } = item.selection as FobSelection
      const price = item.multiLegBetType === 'P' ? placePrice || 0 : winPrice || 0
      return new Currency(total).multiply(price).value
    }, 1)
  ).value

  return shouldRound ? roundDownDecimal(multiReturn) : multiReturn
}

export function calclulateCombinedMultiInvestment(
  multiItems: BetSlipItem[],
  multiInvestment: MultiInvestment
) {
  if (!isValidMulti(multiInvestment, null, multiItems)) {
    return 0
  }

  let formulaTotal = 0
  const multiItemsCount = multiItems.length

  if (multiItemsCount >= MIN_LEGS_IN_MULTI && multiItemsCount <= MAX_LEGS_FOR_MULTI_FORMULA) {
    for (let i = 1; i <= Math.max(1, Math.min(multiItemsCount - 1, 5)); i++) {
      const multiInvestmentKey = `f${i}` as MultiInvestmentKey
      const legKey =
        `${multiItemsCount}` as keyof (typeof MULTI_FORMULA_COMBINATIONS)[typeof multiInvestmentKey]

      formulaTotal += new Currency(multiInvestment[multiInvestmentKey]).multiply(
        MULTI_FORMULA_COMBINATIONS[multiInvestmentKey][legKey]
      ).value
    }
  }
  return formulaTotal + multiInvestment.value
}

export function calculateMultiFormulaReturn(
  prices: number[],
  formulaNumber: number,
  currentInvestment: number
) {
  return generateMultiFormulaCombos(prices, formulaNumber).reduce((total, combo) => {
    const amount = combo.reduce((prev, cur) => new Currency(prev).multiply(cur).value, 1)
    const comboCalc = new Currency(amount).multiply(100).multiply(currentInvestment).value

    return new Currency(Math.floor(comboCalc)).divide(100).add(total).value
  }, 0)
}

export function calculateMultiProjectedPay(
  multiItems: BetSlipItem[],
  multiInvestment: MultiInvestment,
  shouldExcludeFormulas?: boolean
): number {
  const multiItemsCount = multiItems.length
  if (!isValidMulti(multiInvestment, null, multiItems)) {
    return 0
  }

  // Calculate multi projected pay
  const multiProjectedPay =
    multiInvestment.value > 0
      ? new Currency(calculateMultiReturn(multiItems))
          .multiply(multiInvestment.value)
          .subtract(multiInvestment.bonusBetId ? multiInvestment.value : 0).value
      : 0
  const finalMultiProjectedPay = roundDownDecimal(multiProjectedPay)

  if (shouldExcludeFormulas) {
    return finalMultiProjectedPay
  }

  // Calculate formula projected pay total
  const prices = multiItems.map(item =>
    item.multiLegBetType === 'W'
      ? (item.selection as FobPropositionSelection).winPrice
      : (item.selection as FobPropositionSelection).placePrice
  ) as number[]

  let formulaProjectedPay = 0

  const isValidLegsForFormula =
    multiItemsCount >= MIN_LEGS_IN_MULTI && multiItemsCount <= MAX_LEGS_FOR_MULTI_FORMULA
  if (isValidLegsForFormula) {
    for (let i = 1; i <= Math.max(1, Math.min(multiItemsCount - 1, 5)); i++) {
      const multiInvestmentKey = `f${i}` as MultiInvestmentKey
      const currentInvestment = multiInvestment[multiInvestmentKey]
      if (currentInvestment > 0) {
        formulaProjectedPay += calculateMultiFormulaReturn(prices, i, currentInvestment)
      }
    }
  }

  const finalFormulaProjectedPay = new Currency(formulaProjectedPay).value

  return finalMultiProjectedPay + finalFormulaProjectedPay
}

// =============
// Local Helpers
// =============

function generateMultiFormulaCombos(prices: number[], formulaNumber: number) {
  const combos: number[][] = []
  function doGenerateCombinations(offset: number, combo: number[]) {
    if (combo.length === formulaNumber) {
      combos.push(combo)
      return
    }
    for (let i = offset; i < prices.length; i++) {
      doGenerateCombinations(i + 1, combo.concat(prices[i]))
    }
    return
  }
  doGenerateCombinations(0, [])
  return combos
}
