import React from 'react'
import { useQuery } from 'react-query'

import { get } from '@classic/Foundation/Services/ApiService'

import { MainContainer } from '@core/Components/Containers'
import { ContentHeader } from '@core/Components/ContentHeader'
import { Spinner } from '@mobi/component-library/Common/Spinner'
import { queryKeys } from '@core/Data/ReactQuery/constants'

import { PrecommitmentForm } from './PrecommitmentForm'
import { PrecommitmentResponse } from './PrecommitmentResponse'
import { PRECOMMITMENT_API_PATH_PREFIX } from './constants'

export const PrecommitmentPage = (): JSX.Element | null => {
  const { isLoading, isError, data } = useQuery<PrecommitmentResponse>(
    queryKeys.precommitmentDetails,
    () => get({ url: `${PRECOMMITMENT_API_PATH_PREFIX}getprecommitmentdetails` }),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  )

  return (
    <>
      {isLoading && <Spinner />}
      <MainContainer background='white' data-tid-precommitment>
        <ContentHeader title='Bet Limits' />
        <PrecommitmentForm data={data} hasDataFetchFailed={isError} />
      </MainContainer>
    </>
  )
}
