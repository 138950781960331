import React from 'react'
import { useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { PlanSeq } from '@mobi/api-types'
import { colors, spacing } from '@mobi/component-library/Theme/Common'
import type { Tabs, Ticket } from '@core/Areas/AccountActivity/types'
import {
  formatDateTime,
  getTicketLayoutType,
  isTippingTicket,
} from '@core/Areas/AccountActivity/Components/Ticket/helpers'
import { DetailsSecondaryInfoStyled as SecondaryInfo } from './Details.styles'
import { TicketDisplayContext } from '@core/Areas/AccountActivity/context'
import {
  BettingInformationOverrides,
  FixedOddsMarketDisplayNames,
} from '@core/Areas/RaceCard/constants'
import {
  BlackbookButtons,
  BonusBet,
  ReBetButton,
  TicketInvestment,
  Specials,
  SelectionInfo,
} from './Components'

const enum LocalConstants {
  PreviewModeClass = 'js-details--preview',
  ShortcutSectionClass = 'js-details__shortcuts',
}

export const Details: React.FC<{ ticket: Ticket }> = ({ ticket }) => {
  let { tab: activeTab } = useParams<{ tab: Tabs }>()
  const ticketDisplayContext = React.useContext(TicketDisplayContext)

  const isPreviewMode = ticketDisplayContext.type === 'share-preview'
  const shouldHideCost = isPreviewMode && ticketDisplayContext.data.shouldHideCost

  const selection = ticket.legs[0].selections[0]

  if (!selection || selection.selectionDetails.length === 0) return null

  const ticketLayoutType = getTicketLayoutType(ticket)
  const isFavouriteNumbers = ticket.planSeq === PlanSeq.FavouriteNumbers
  const isSameRaceMulti = ticketLayoutType === 'same-race-multi'

  const shouldHideStartTime =
    isFavouriteNumbers || isTippingTicket(ticket) || (ticket.legs.length > 1 && !isSameRaceMulti)
  const shouldDisplayNewStartTime: boolean =
    activeTab === 'pending' &&
    !!ticket.updatedEventStartDateTime &&
    ticket.eventStartDateTime !== ticket.updatedEventStartDateTime

  return (
    <DetailsStyled
      className={isPreviewMode ? LocalConstants.PreviewModeClass : ''}
      data-testid='ticket-detail'
    >
      {!isPreviewMode && (
        <section className={LocalConstants.ShortcutSectionClass}>
          <ReBetButton ticket={ticket} activeTab={activeTab} />

          <BlackbookButtons ticket={ticket} activeTab={activeTab} />
        </section>
      )}

      <SelectionInfo ticket={ticket} />

      {!isPreviewMode && (
        <SecondaryInfo data-testid='ticket-detail-secondary-info'>
          <dl>
            {!shouldHideStartTime && (
              <div data-testid='ticket-detail-start-time'>
                <dt>Start Time:</dt>
                <dd>
                  {formatDateTime(ticket.updatedEventStartDateTime)}

                  {shouldDisplayNewStartTime && (
                    <strong style={{ color: hexColors.summerSky }}> New</strong>
                  )}
                </dd>
              </div>
            )}

            <div data-testid='ticket-detail-bet-placed'>
              <dt>Bet Placed:</dt>
              <dd>{formatDateTime(ticket.timestamp)}</dd>
            </div>

            <div data-testid='ticket-detail-ticket-id'>
              <dt>Ticket ID:</dt>
              <dd>{ticket.ticketSequence}</dd>
            </div>
          </dl>

          {ticket.bettingInformation && !isSameRaceMulti && (
            <div data-testid='ticket-detail-betting-information'>
              {getBettingInformationWithOverrides(ticket)?.map(b => (
                <p key={b.title}>
                  {b.title}: {b.detail.join('. ')}
                </p>
              ))}
              {ticket.specialInformation?.map(s => (
                <p key={s.title}>
                  {s.title} : {s.description}
                </p>
              ))}
            </div>
          )}
        </SecondaryInfo>
      )}

      {ticket.specialInformation && (
        <Specials specials={ticket.specialInformation} shouldHideCost={shouldHideCost} />
      )}

      {!shouldHideCost && (
        <>
          <TicketInvestment ticket={ticket} />
          <BonusBet investment={ticket.investment} />
        </>
      )}
    </DetailsStyled>
  )
}

// =============
// Local Helpers
// =============

function getBettingInformationWithOverrides(ticket: Ticket) {
  if (ticket.productName === FixedOddsMarketDisplayNames.SP) {
    return BettingInformationOverrides.StartingPrice
  } else {
    return ticket.bettingInformation
  }
}

// ======
// Styles
// ======

const DetailsStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.sm,
  paddingTop: spacing.smx1,

  '> div:empty': { display: 'none' },
  '> div:not(:last-of-type)': { marginBottom: '1rem' },

  [`.${LocalConstants.ShortcutSectionClass}`]: {
    '> div': {
      paddingTop: spacing.smx1,
      paddingBottom: spacing.smx1,
      borderTop: `0.5px solid ${colors.neutral[200]}`,
    },
  },

  table: {
    fontSize: '1.2rem',
    color: hexColors.nero,
    width: '100%',
    padding: 0,
    border: 0,
    borderSpacing: 0,
  },

  [`.${LocalConstants.PreviewModeClass}`]: {
    paddingTop: 0,
  },
})
