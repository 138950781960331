import { useEffect } from 'react'
import { startListening } from '@core/Store/listenerMiddleware'
import { triggerHapticFeedback } from '@core/Utils/hapticFeedback/hapticFeedback'
import { confirmAllBets } from '@mobi/betslip/Store/Workflow/asyncActions'

export const useHapticFeedbackOnBetsSuccess = (shouldEnable: boolean) => {
  useEffect(() => {
    if (!shouldEnable) return undefined

    const stopListener = startListening({
      actionCreator: confirmAllBets.fulfilled,
      effect: action => {
        if (action.payload.some(responseItem => !!responseItem.receipt)) {
          triggerHapticFeedback('success')
        }
      },
    })

    return () => stopListener()
  }, [shouldEnable])
}
