import React from 'react'
import { useParams } from 'react-router-dom'
import { BetCard } from '@mobi/betslip/Components/Common/BetCard'
import type { ReplayInfo } from '@core/Areas/Racing/Components/RaceReplay/types'
import type { Tabs, Ticket as TicketType } from '@core/Areas/AccountActivity/types'
import { useFeature } from '@core/Utils/hooks'
import { Header } from './Components/Header/Header'
import { Details } from './Components/Details/Details'
import { ShareBet } from './Components/ShareBet/ShareBet'
import { getTicketStatus } from './helpers/getTicketStatus'
import { FooterInvestment } from './Components/Investment'
import { TicketDisplayContext } from '@core/Areas/AccountActivity/context'
import { RaceReplayVideoPlayer } from './Components/RaceReplayVideoPlayer'
import { RaceReplayButton } from './Components/RaceReplayButton'

export const Ticket: React.FC<{
  ticket: TicketType
}> = ({ ticket }) => {
  let { tab: activeTab } = useParams<{ tab: Tabs }>()

  const ticketDisplayContext = React.useContext(TicketDisplayContext)

  const [status] = React.useState(() => getTicketStatus(ticket, activeTab))
  const [activeReplayInfo, setActiveReplayInfo] = React.useState<ReplayInfo | null>(null)

  const isReplayTicketFeatureActive = useFeature('RESULTED_TICKET_REPLAYS')

  const isPreviewMode = ticketDisplayContext.type === 'share-preview'
  const shouldHideCost = isPreviewMode && ticketDisplayContext.data.shouldHideCost

  return (
    <BetCard.Container topComponent={null} data-ticket-seq={ticket.ticketSequence}>
      <Header ticket={ticket} status={status} />

      {!shouldHideCost && <FooterInvestment status={status} ticket={ticket} />}

      <BetCard.Footer
        expandableText='See More'
        expandableContent={<Details ticket={ticket} />}
        rightSection={
          <>
            <ShareBet ticket={ticket} />

            {isReplayTicketFeatureActive && !activeReplayInfo && (
              <RaceReplayButton ticket={ticket} onPlayTapped={info => setActiveReplayInfo(info)} />
            )}
          </>
        }
      />

      {activeReplayInfo && (
        <RaceReplayVideoPlayer
          ticketId={ticket.ticketSequence}
          raceLocation={activeReplayInfo.raceLocation}
          raceDate={activeReplayInfo.raceDate}
          videoSource={activeReplayInfo.videoSource}
          onCloseTapped={() => setActiveReplayInfo(null)}
          onFinished={() => setActiveReplayInfo(null)}
        />
      )}
    </BetCard.Container>
  )
}
