import React from 'react'
import { useQuery } from 'react-query'
import { MainContainer } from '@core/Components/Containers'
import { ContentHeader } from '@core/Components/ContentHeader'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { RaceDayAlertPreference, RunnerList } from '@core/Areas/Blackbook/Components'
import { getBlackbookDetails } from '@core/Areas/Blackbook/helpers/api'

export const Blackbook: React.FC<{}> = () => {
  const { isLoading, isError, data, refetch } = useQuery({
    queryKey: queryKeys.blackbookDetails,
    queryFn: getBlackbookDetails,
    retry: false,
    cacheTime: 0,
    refetchOnWindowFocus: false,
  })

  return (
    <MainContainer data-testid='blackbook-page' background='grey'>
      <ContentHeader title='Blackbook' iconCode='blackbook' shouldShowHome={false} />

      <RaceDayAlertPreference
        isEmailAlertActive={!!data?.SendEmail}
        isSmsAlertActive={!!data?.SendSms}
        isLoading={isLoading}
        shouldDisable={isError}
      />

      <RunnerList
        isError={isError}
        isLoading={isLoading}
        entries={data?.Entries}
        refetch={refetch}
      />
    </MainContainer>
  )
}
