import React from 'react'
import { isReactNativeApp, sendToNative, subscribeToNative } from '@mobi/web-native-comms/web'

export function useBiometricsStatus() {
  const [isBiometricsStatusReady, setIsBiometricsStatusReady] = React.useState(false)
  const [isBiometricsEnabled, setIsBiometricsEnabled] = React.useState(false)

  React.useEffect(() => {
    if (!isReactNativeApp) return

    const { dispose } = subscribeToNative('RESPONSE_BIOMETRICS_STATUS', ({ isEnabled }) => {
      setIsBiometricsEnabled(isEnabled)
      setIsBiometricsStatusReady(true)
    })

    sendToNative('REQUEST_BIOMETRICS_STATUS')

    return () => {
      dispose()
    }
  }, [])

  return {
    isBiometricsStatusReady,
    isBiometricsEnabled,
  }
}
