let isBrowserShareSupported: boolean | null = null

export const doesBrowserSupportShare = (): boolean => {
  if (isBrowserShareSupported !== null) {
    return isBrowserShareSupported
  }

  if (!navigator.canShare) {
    return false
  }

  isBrowserShareSupported = !!navigator.canShare({
    title: 'sample',
    text: 'testing if the text param is accepted',
  })

  return isBrowserShareSupported
}
