import React from 'react'
import styled from '@emotion/styled'
import { toTitleCase } from '@mobi/utils/string'
import { colors, layering, spacing } from '@mobi/component-library/Theme/Common'
import { SkyTvSvg } from '@mobi/component-library/Common/Svg'
import { Text } from '@core/Components/Text'
import { useAppSelector } from '@core/Store/hooks'
import { useFeature } from '@core/Utils/hooks/useFeature'
import { useRacePageData } from '@core/Areas/Racing/Hooks/useRacePageData'
import { isThoroughbredRacePageInWA } from '@core/Areas/Racing/helpers/liveVideoFeedHelpers'
import { getVisionChannelForDisplay } from '@core/Areas/SkyVideoPlayer/helpers'
import { RaceNotificationButton } from '@core/Areas/Racing/Components/RaceNotificationButton'
import { RaceInfoWrapperWithTextExpand } from '@core/Areas/Racing/Components/Shared/RaceInfoWrapperWithTextExpand'
import { PayoutInformation } from '@core/Areas/Racing/Components/Navigation/Components/PayoutInformation'
import { WeatherIcon } from '@core/Areas/Racing/Components/WeatherIcon'
import { SkeletonLoader } from './Components/SkeletonLoader'
import { getIsSelectedRaceScrolledOutOfView } from '../../Store/selectors'
import type { NextSkyRace } from '../../types'
import {
  SkyVideoButton,
  SkyVideoButtonRace,
} from '@core/Areas/Racing/Components/Navigation/Components/RaceInfoHeader/Components/SkyVideoButton'
import { isResultsPageDto, ResultsPageDataTransferObject } from '@mobi/api-types'
import { meetingIdToContestType } from '@core/Areas/Racing/helpers'

export const RaceInfoSlim: React.FC<{
  meetingDate: string
  meetingId: string
  raceNumber: number
  skyTvChannelId: NextSkyRace['SkyTvChannelId']
  shouldForceLoading?: boolean
}> = ({ meetingDate, meetingId, raceNumber, skyTvChannelId, shouldForceLoading = false }) => {
  const { race, isLoading } = useRacePageData({ meetingDate, meetingId, raceNumber })
  const isCurrentRaceScrolledOutOfView = useAppSelector(getIsSelectedRaceScrolledOutOfView)

  const isRacingWAFeatureActive = useFeature('RACING_WA_VISION_CHANNEL')

  const hasRaceReplayImprovements = useFeature('RACE_REPLAY_IMPROVEMENTS')

  const hasRaceReplayVideo = race && isResultsPageDto(race) && race.RaceReplay.HasVideo

  if (isLoading || shouldForceLoading) return <SkeletonLoader />

  if (!race) return null

  const shouldDisplayRacingWA = isRacingWAFeatureActive && isThoroughbredRacePageInWA(race)

  const isTrackConditionKnown = race.MeetingInformation.TrackCondition.toLowerCase() !== 'unknown'
  const currentRace = race.MeetingInformation.SelectedRace

  const isRaceOpen =
    race.Tag === 'Starters' && race.MeetingInformation.SelectedRace.RaceStatus === 'Open'
  const raceStartTime = new Date(currentRace.RaceTime)

  const raceCode = meetingIdToContestType(meetingId) ?? 'races'

  const selectedRace = race as ResultsPageDataTransferObject

  const raceProp: SkyVideoButtonRace = {
    ...(hasRaceReplayVideo && {
      raceReplay: {
        raceNumber: selectedRace.MeetingInformation.SelectedRace.RaceNumber,
        trackName: selectedRace.MeetingInformation.MeetingName,
        raceReplayKey: selectedRace.RaceReplay.VideoKey,
        raceCode,
        meetingDate: selectedRace.MeetingInformation.MeetingDate,
      },
    }),
    advertisedStartTime: selectedRace.MeetingInformation.SelectedRace.RaceTime,
    raceStatus: selectedRace.MeetingInformation.SelectedRace.RaceStatusText,
    skyTvChannelId: skyTvChannelId,
  }

  return (
    <>
      <WrapperStyled>
        <RaceInfoWrapperWithTextExpand
          isOnSkyRacePage
          titlePrimary={`R${currentRace.RaceNumber} ${toTitleCase(
            race.MeetingInformation.MeetingName
          )}`}
          titleSecondary={toTitleCase(currentRace.RaceName)}
          shouldSwitchTitles={isCurrentRaceScrolledOutOfView}
          subTitle={
            <>
              {currentRace.Distance !== 0 && `${currentRace.Distance}m · `}
              <span>{currentRace.RaceType}</span>
            </>
          }
        >
          <>
            {isTrackConditionKnown && (
              <Text data-testid='track-condition'>
                {toTitleCase(race.MeetingInformation.TrackCondition)}
              </Text>
            )}

            <WeatherIcon courseWeather={race.MeetingInformation.WeatherAtLocation} />

            {skyTvChannelId && (
              <div>
                {hasRaceReplayImprovements ? (
                  <SkyVideoButton race={raceProp} isHeaderPinned={false} openLocation='sky-page' />
                ) : (
                  <SkyTvSvg
                    channel={getVisionChannelForDisplay(
                      skyTvChannelId ?? '',
                      !!shouldDisplayRacingWA
                    )}
                    width='3rem'
                  />
                )}
              </div>
            )}
            {isRaceOpen && (
              <RaceNotificationButton
                raceStatus='Open'
                raceKey={race.MeetingInformation.SelectedRace.Key}
                raceTime={raceStartTime}
                meetingId={meetingId}
                raceNumber={raceNumber}
                meetingName={race.MeetingInformation.MeetingName}
                meetingDate={meetingDate}
              />
            )}
          </>
        </RaceInfoWrapperWithTextExpand>
      </WrapperStyled>

      <PayoutInformation
        isOnSkyRacePage
        totePayoutInformation={currentRace.PayoutInformation || ''}
        fixedPayoutInformation={currentRace.FixedOddsInfo?.PayoutInformation || ''}
      />

      <hr />
    </>
  )
}

const WrapperStyled = styled.div({
  padding: `${spacing.smx1} 0`,
  position: 'relative',
  zIndex: layering.stickyPageElements - 1,

  '> *': { flexShrink: 0 },

  '& ~ hr:first-of-type': {
    boxSizing: 'border-box',
    margin: 0,
    padding: 0,
    border: 0,
    height: '0.05rem',
    borderBottom: '0.05rem solid ' + colors.neutral[200],
  },
})
