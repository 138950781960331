import React from 'react'
import { Grid, GridCell } from '@mobi/component-library/Common/Grid'
import { DogsFormRaceResponse } from '@core/Areas/Racing/Hooks/useRaceFormData/api'
import { FormDataStyled } from '../Common/Common.styles'
import { FormHistories } from '../Common/Histories'
import { DogsHistorySingle } from './DogsHistorySingle'

export const Dogs: React.FC<{
  form?: DogsFormRaceResponse['Forms'][0]
  starterName?: string
}> = ({ form, starterName }) => {
  if (!form) return null

  return (
    <>
      <FormDataStyled>
        <Grid>
          <GridCell width='50%'>
            <div data-testid='runner-form-last-starts'>
              <strong>Last Starts</strong> {form.LastTenStarts}
            </div>
            <div data-testid='runner-form-career'>
              <strong>Career</strong> {form.Career}
            </div>
            <div data-testid='runner-form-prize'>
              <strong>Prize</strong> {form.PrizeMoneyWon}
            </div>
            <div data-testid='runner-form-rating'>
              <strong>Rating</strong> {form.RatingDry}
            </div>
            <div data-testid='runner-form-rec-price'>
              <strong>Rec. Price</strong> {form.RecommendedPrice}
            </div>
            <div data-testid='runner-form-best-time'>
              <strong>Best Time</strong> {form.BestTime}
            </div>
            <div data-testid='runner-form-last-time'>
              <strong>Last Time</strong> {form.LastTime}
            </div>
          </GridCell>

          <GridCell width='50%'>
            <div data-testid='runner-form-sex'>
              <strong>Sex</strong> {form.Sex}
            </div>
            <div data-testid='runner-form-age'>
              <strong>Age</strong> {form.Age}
            </div>
            <div data-testid='runner-form-colour'>
              <strong>Colour</strong> {form.Color}
            </div>
            <div data-testid='runner-form-sire'>
              <strong>Sire</strong> {form.SireName}
            </div>
            <div data-testid='runner-form-dam'>
              <strong>Dam</strong> {form.DamName}
            </div>
            <div data-testid='runner-form-trk-dist'>
              <strong>Trk/Dist</strong> {form.TrackDistance}
            </div>
          </GridCell>

          <div data-testid='runner-form-owners'>
            <strong>Owners</strong> {form.OwnerName}
          </div>
        </Grid>
      </FormDataStyled>

      <FormHistories>
        {form.Histories.map(history => (
          <DogsHistorySingle
            key={history.FixtureDate}
            history={history}
            StarterName={starterName}
            StarterNumber={form.StarterNumber}
          />
        ))}
      </FormHistories>
    </>
  )
}
