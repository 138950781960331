import React from 'react'
import styled from '@emotion/styled'
import { Toggle } from '@mobi/component-library/Common/Input'

interface BiometricsToggleProp {
  text: string
  isDisabled?: boolean
  toggleState?: boolean
  toggleTitle?: string
  onToggle: () => void
}

export function BiometricsToggle({
  text,
  isDisabled = false,
  toggleState = false,
  toggleTitle,
  onToggle,
  ...props
}: BiometricsToggleProp) {
  return (
    <BiometricsToggleStyled {...props}>
      <TextContainerStyled>
        <TextStyled data-tid-notification-text>{text}</TextStyled>
      </TextContainerStyled>
      <SwitchStyled data-tid-notification-toggle={toggleState}>
        <Toggle
          toggledOn={toggleState}
          isDisabled={isDisabled}
          onToggle={onToggle}
          title={toggleTitle}
        />
      </SwitchStyled>
    </BiometricsToggleStyled>
  )
}

const BiometricsToggleStyled = styled('div')({
  marginTop: '1rem',
  display: 'flex',
})

const TextContainerStyled = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const TextStyled = styled('span')({
  fontSize: '1.4rem',
  lineHeight: '1.6rem',
})

const SwitchStyled = styled('div')({
  WebkitTapHighlightColor: 'transparent',
})
