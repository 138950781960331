import React, { CSSProperties } from 'react'
import styled from '@emotion/styled'
import type { Acceptor } from '@mobi/betslip/types'

export const SilkImage: React.FC<{
  acceptor?: Acceptor
  /** Size in pixels, @default 30 */
  size?: number
}> = ({ acceptor, size = 30 }) => {
  if (!acceptor) return null

  const { imageUrlWithSizes, number: acceptorNumber } = acceptor
  if (!imageUrlWithSizes || !imageUrlWithSizes.large) return null

  // We always use the large size in order to scale it down for retina devices
  const { url, size: dimensions } = imageUrlWithSizes.large
  if (!(url && dimensions.width && dimensions.height)) return null

  // Starter numbers start at 1, but the offset has to begin at 0
  const xOffset = acceptorNumber - 1

  const { width, height, backgroundPosition } = calculateAspectRatioFit({
    srcWidth: dimensions.width,
    srcHeight: dimensions.height,
    maxWidth: size,
    maxHeight: size,
    xOffset,
  })

  return (
    <SilkImageFlexibleStyled
      style={{
        backgroundImage: `url(${url})`,
        backgroundPosition,
        width,
        height,
      }}
    />
  )
}

/**
 * Silk sizes in pixels:
 *   Races: 35 x 42 -> Ratio: 5:6
 *   Trots: 36 x 36 -> Ratio: 1:1
 *   Dogs:  35 x 42 -> Ratio: 5:6
 *
 * We use the `large` size of the sprite and scale it down in order for them to look OK on retina devices.
 * The silks are displayed within a fixed size container span `size`.
 */

const SilkImageFlexibleStyled = styled.span({
  display: 'inline-flex',
  alignItems: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
})

// =============
// Local Helpers
// =============

function calculateAspectRatioFit({
  srcWidth,
  srcHeight,
  maxWidth,
  maxHeight,
  xOffset,
  padding = 0,
}: {
  srcWidth: number
  srcHeight: number
  maxWidth: number
  maxHeight: number
  xOffset: number
  padding?: number
}): Pick<CSSProperties, 'width' | 'height' | 'backgroundPosition'> {
  const ratio = Math.min((maxWidth - padding) / srcWidth, (maxHeight - padding) / srcHeight)
  const newWidth = srcWidth * ratio
  const newHeight = srcHeight * ratio
  return {
    width: newWidth,
    height: newHeight,
    backgroundPosition: `${-newWidth * xOffset}px center`,
  }
}
