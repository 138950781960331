import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { KAMBI_ROOT_ROUTE } from '@core/Areas/Kambi/constants'
import { useIsFobSportsFeatureActive } from './hooks/useIsFobSportsFeatureActive'

const SportsLazyRoute = React.lazy(() => import('./Components/MainSportsPage'))

/** NEW in-house FOB Sports */
export const SportsPage = () => {
  const history = useHistory()
  const isSportsFeatureActive = useIsFobSportsFeatureActive()

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!isSportsFeatureActive) {
        history.push(`/${KAMBI_ROOT_ROUTE}/home`)
      }
    }, 6000)

    return () => clearTimeout(timeoutId)
  }, [isSportsFeatureActive, history])

  return (
    <React.Suspense fallback={null}>
      <SportsLazyRoute />
    </React.Suspense>
  )
}
