import React, { StrictMode } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom'
import { QueryClientProvider } from 'react-query'

import { persistor, store } from '@core/Store'
import { queryClient } from '@core/Data/ReactQuery'

import { AppRoutes } from './AppRoutes'
import { Home } from './Areas/Home/Home'
import { Jackpots } from './Areas/Jackpots'
import { MeetingsPage } from './Areas/Meetings'
import { NextEventsPage } from './Areas/NextEvents/NextEventsPage'
import { FavouriteNumbersPage } from './Areas/FavouriteNumbers/FavouriteNumbersPage'
import { SkyVideoPlayer } from './Areas/SkyVideoPlayer'
import { FloatingTabRadioButton } from './Areas/Navigation/FloatingTabRadioButton'
import { StructuredSearchPage } from './Areas/StructuredSearch'
import { AppHeader } from './Areas/AppHeader/AppHeader'
import { RaceListPage } from './Areas/RaceList/RaceList'
import { RaceEvents } from './Areas/Racing/Components/RaceEvents/RaceEvents'
import { WithdrawPage } from './Areas/Withdraw'
import { BankAccount } from './Areas/BankAccount'
import { ContactUsPage } from './Areas/ContactUs/ContactUsPage'
import { BankAccountEdit } from './Areas/BankAccount'
import { SpecialsPage } from './Areas/Specials/SpecialsPage'
import { TippingPage } from './Areas/Tipping'
import { Blackbook } from './Areas/Blackbook'
import { AccountActivity } from './Areas/AccountActivity'
import { ReverificationPopupMobi } from './Areas/Reverification/ReverificationPopup'
import { KambiClient, KambiRouteEffects, useKambiClient } from './Areas/Kambi'
import { SportsPage } from './Areas/Sports'
import { Overlay } from '@core/Components/Overlay/Overlay'
import { GlobalCss } from './Settings/Components/GlobalCss'
import { MyPreferences } from './Areas/Account/MyPreferences'
import { MysteryBetPage } from './Areas/MysteryBet'
import { SignUp } from './Areas/Account/SignUp'
import { SignUpComplete } from './Areas/Account/SignUp/SignUpComplete'
import { Toolbox, DevTools } from './Areas/Toolbox'
import { ChangePassword, ChangeTemporaryPassword } from './Areas/ChangePassword'
import { RequireAuth } from './Components/RequireAuth/RequireAuth'
import { ContactDetailsEditMobiWrapper, ContactDetailsMobiWrapper } from './Areas/ContactDetails'
import { CloseAccount } from './Areas/CloseAccount'
import { BiometricsPage } from './Areas/Biometrics/BiometricsPage'
import { ModalContainer } from './Components/Modal'
import { useAndroidBackButton } from '@core/NativeServices/Navigation/useAndroidBackButton'
import {
  Account,
  BetOfferPage,
  PrecommitmentPage,
  TakeABreakPage,
  Logout,
  VerificationBanner,
} from './Areas/Account'
import { ActivityStatements } from './Areas/Account/ActivityStatements'
import { TABLocatorWeb } from './Areas/TABLocatorWeb'
import { RaceCardFixedOnly } from './Areas/RaceCardFixedOnly'
import { Login } from './Areas/Login'
import { useInitAnalyticsDataLayer } from './Services/Analytics'
import { RaceCard } from './Areas/RaceCard/RaceCard'
import { RaceCardSky } from './Areas/RaceCardSky'
import { GoodThingsSlice } from './Areas/Racing/Components/RacingFormSliceJS/GoodThings'
import { Settings } from './Areas/Settings'
import { Deposit } from './Areas/Deposit'
import { Verification } from './Areas/Account/Verification'
import { useOptimoveTrackingEvents } from './Services/Optimove/hooks'
import { useNativeAppLaunchState } from './NativeServices/AppLaunchState'
import { TabNavigation } from './Areas/Navigation/Components/TabNavigation'
import { Popup as PopupLegacy } from '@core/Components/Popup/Popup'
import { HostContextMobile } from '@core/Contexts/HostContextMobile'
import { BetSlipNew } from './Areas/BetSlipNew'
import { Toasts } from '@mobi/component-library/Feedback/Toast'
import { DepositLimitsEditPage, DepositLimitsPage } from './Areas/DepositLimits'
import { useIsNewBetSlipFeatureActive } from './Areas/BetSlipNew/hooks/useIsNewBetSlipFeatureActive'
import { useNavigationObserver } from './State/Navigation/hooks/useNavigationObserver'
import { useBiometricsStatus } from '@mobi/account/Areas/Verification/Biometrics/Hooks/useBiometricsStatus'
import { updateDataLayer } from '@classic/Foundation/Analytics/Analytics'

const App = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={null}>
      <QueryClientProvider client={queryClient}>
        <HashRouter hashType='noslash'>
          <HostContextMobile>
            <AppWithRoutes />
          </HostContextMobile>
        </HashRouter>
      </QueryClientProvider>
    </PersistGate>
  </Provider>
)

const AppWithRoutes = () => {
  useNavigationObserver()
  useKambiClient()
  useAndroidBackButton()
  useInitAnalyticsDataLayer()
  useOptimoveTrackingEvents()
  useNativeAppLaunchState()

  const { isBiometricsStatusReady, isBiometricsEnabled } = useBiometricsStatus()
  if (isBiometricsStatusReady) {
    updateDataLayer({
      user: { isBiometricsEnabled },
    })
  }

  return (
    <>
      <AppHeader />

      <Login />
      <Overlay />
      <Toasts />
      <ModalContainer />
      <FloatingTabRadioButton visibleRoutes={[{ path: '/', exact: true }]} />
      <DevTools />

      <ReverificationPopupMobi />
      <PopupLegacy />

      <GlobalCss />

      <SkyVideoPlayer />

      <BetSlipNewIfActive />

      <div id='js-react-app-routes' className='mobi__z-stack-content'>
        <VerificationBanner />
        <Switch>
          <Route path={AppRoutes.Home} exact>
            <Home />
          </Route>

          <Route path={AppRoutes.SignUp}>
            <SignUp />
          </Route>

          <Route path={AppRoutes.Verification}>
            <RequireAuth>
              <StrictMode>
                <Verification />
              </StrictMode>
            </RequireAuth>
          </Route>

          <Route path={AppRoutes.CompletedSignUp}>
            <SignUpComplete />
          </Route>

          <Route path={AppRoutes.RaceCard}>
            <RaceCard />
          </Route>

          <Route path={AppRoutes.RaceCardSky}>
            <RaceCardSky />
          </Route>

          <Route path={AppRoutes.Jackpots}>
            <Jackpots />
          </Route>

          <Route path={AppRoutes.NextEvents}>
            <NextEventsPage />
          </Route>

          <Route path={AppRoutes.SportsRoot}>
            <KambiRouteEffects />
          </Route>

          <Route path={AppRoutes.Sports}>
            <SportsPage />
          </Route>

          <Route path={AppRoutes.Meetings} exact>
            <MeetingsPage />
          </Route>

          <Route path={AppRoutes.Account} exact>
            <RequireAuth>
              <Account />
            </RequireAuth>
          </Route>

          <Route path={AppRoutes.ChangePassword} exact>
            <RequireAuth>
              <ChangePassword />
            </RequireAuth>
          </Route>

          <Route path={AppRoutes.ChangeTemporaryPassword} exact>
            <ChangeTemporaryPassword />
          </Route>

          <Route path={AppRoutes.ContactDetails} exact>
            <RequireAuth>
              <ContactDetailsMobiWrapper />
            </RequireAuth>
          </Route>

          <Route path={AppRoutes.MarketingCommunications}>
            <RequireAuth>
              <MyPreferences />
            </RequireAuth>
          </Route>

          <Route path={AppRoutes.CloseAccount}>
            <CloseAccount />
          </Route>

          <Route path={AppRoutes.ContactDetailsEdit}>
            <RequireAuth>
              <ContactDetailsEditMobiWrapper />
            </RequireAuth>
          </Route>

          <Route path={AppRoutes.FavouriteNumbers} exact>
            <FavouriteNumbersPage />
          </Route>

          <Route path={AppRoutes.Biometrics}>
            <BiometricsPage />
          </Route>

          <Route exact path='/favouritenumbers'>
            <Redirect to='/favourite-numbers' />
          </Route>

          <Route path={AppRoutes.AccountActivity}>
            <RequireAuth>
              <AccountActivity />
            </RequireAuth>
          </Route>

          <Route path={AppRoutes.StructuredSearch} exact>
            <StructuredSearchPage />
          </Route>

          <Route path={AppRoutes.Withdraw} exact>
            <RequireAuth>
              <WithdrawPage />
            </RequireAuth>
          </Route>

          <Route path={AppRoutes.BankAccount} exact>
            <RequireAuth>
              <BankAccount />
            </RequireAuth>
          </Route>

          <Route path={AppRoutes.ContactUs} exact>
            <ContactUsPage />
          </Route>

          <Route path={AppRoutes.BankAccountEdit}>
            <RequireAuth>
              <BankAccountEdit />
            </RequireAuth>
          </Route>

          <Route path={AppRoutes.Precommitment} exact>
            <RequireAuth>
              <PrecommitmentPage />
            </RequireAuth>
          </Route>

          <Route path={AppRoutes.TakeABreak} exact>
            <RequireAuth>
              <TakeABreakPage />
            </RequireAuth>
          </Route>

          <Route path={AppRoutes.MysteryBet} exact>
            <MysteryBetPage />
          </Route>

          <Route path={AppRoutes.GoodThings} exact>
            <GoodThingsSlice />
          </Route>

          <Route path={AppRoutes.Specials}>
            <RequireAuth>
              <SpecialsPage />
            </RequireAuth>
          </Route>

          <Route path={AppRoutes.ActivityStatements} exact>
            <RequireAuth>
              <ActivityStatements />
            </RequireAuth>
          </Route>

          <Route path={AppRoutes.RaceList} exact>
            <RaceListPage />
          </Route>

          {/* List Of Race Events
           from meetings - fobracing/FR/Races/2021-01-21?selectiondate=2021-01-21
           back from race event item - fobracing/FR/Races?selectiondate=2021-01-21
           date path param therefore is made optional (not used by MVC)
           todo: remove optional date once we reactify race events item mvc
           */}
          <Route path={AppRoutes.RaceEvents} exact>
            <RaceEvents />
          </Route>

          <Route path={AppRoutes.RaceCardFixedOnly} exact>
            <RaceCardFixedOnly />
          </Route>

          <Route path={AppRoutes.Tipping}>
            <TippingPage />
          </Route>

          <Route path={AppRoutes.Blackbook} exact>
            <RequireAuth>
              <Blackbook />
            </RequireAuth>
          </Route>

          <Route path={AppRoutes.Offers} exact>
            <RequireAuth>
              <BetOfferPage />
            </RequireAuth>
          </Route>

          <Route path={AppRoutes.TABLocatorWeb} exact>
            <TABLocatorWeb />
          </Route>

          <Route path={AppRoutes.Toolbox} exact>
            <Toolbox />
          </Route>

          <Route path={AppRoutes.AccountLogout} exact>
            <Logout />
          </Route>

          <Route path={AppRoutes.Settings} exact>
            <RequireAuth>
              <Settings />
            </RequireAuth>
          </Route>
        </Switch>

        <Route path={AppRoutes.Deposit} exact>
          <RequireAuth>
            <Deposit flow='account-deposit' />
          </RequireAuth>
        </Route>

        <Route path={AppRoutes.DepositLimits} exact>
          <RequireAuth>
            <DepositLimitsPage />
          </RequireAuth>
        </Route>

        <Route path={AppRoutes.DepositLimitsEdit} exact>
          <RequireAuth>
            <DepositLimitsEditPage />
          </RequireAuth>
        </Route>
      </div>

      <KambiClient key='kambi-client' />

      <TabNavigation />
    </>
  )
}

//
// This is a fix for https://rwwa.atlassian.net/browse/MVPS-772 required for MCUP
// This bug appears to be already fixed in v237
//
function BetSlipNewIfActive() {
  const isNewBetSlipDesignActive = useIsNewBetSlipFeatureActive()
  return isNewBetSlipDesignActive ? <BetSlipNew /> : null
}

export default App
