import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import * as QueryString from 'query-string'
import { AccountHolders, AccountHoldersKey } from '@core/Data/Account/accountHolder'
import { State } from 'rwwa-data-access'
import { DepositLimits } from './Components/DepositLimits/DepositLimits'

export const DepositLimitsPage = () => {
  const { saveSuccess, accountHolderNumber } = QueryString.parse(
    useLocation().search
  ) as SearchParams

  useEffect(() => {
    AccountHolders.hardInvalidate(null as unknown as State<AccountHolders>, AccountHoldersKey)
  }, [])

  const finalAccountHolderNumber: number | undefined = /^\d+$/.test(accountHolderNumber as string)
    ? Number(accountHolderNumber)
    : undefined

  const finalSaveSuccess: boolean = finalAccountHolderNumber && saveSuccess == 'true' ? true : false

  return (
    <DepositLimits
      saveSuccess={finalSaveSuccess}
      selectedAccountHolderNumber={finalAccountHolderNumber}
    />
  )
}

// =====
// Types
// =====

type SearchParams = {
  saveSuccess?: string
  accountHolderNumber?: string
}
