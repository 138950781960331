import React from 'react'
import { subscribeToNative, isReactNativeApp, sendToNative } from '@mobi/web-native-comms/web'
import { navigateToSkyRacePage } from '@core/Areas/RaceCardSky/helpers/navigateToSkyRacePage'
import { useAppSelector } from '@core/Store/hooks'

export const useNativeInterop = (isPlayerOpen: boolean): void => {
  const playerStatus = useAppSelector(state => state.skyVideoPlayer.playerStatus)

  // Handle "Sky Racing" bottom tab click
  React.useEffect(() => {
    if (!isReactNativeApp) return undefined
    const { dispose } = subscribeToNative('VISION_FLOAT_TOGGLE', navigateToSkyRacePage)
    return () => {
      dispose()
    }
  })

  // Send "Sky Racing" bottom tab status to native
  React.useEffect(() => {
    if (!isReactNativeApp) return
    sendToNative('VISION_FLOAT_STATUS', isPlayerOpen ? 'Open' : 'Closed')
  }, [isPlayerOpen])

  // Send "Fake Fullscreen" status to native
  React.useEffect(() => {
    if (!isReactNativeApp) return
    sendToNative('FULLSCREEN_ACTIVE', isPlayerOpen && playerStatus === 'fullscreen')
  }, [playerStatus, isPlayerOpen])
}
