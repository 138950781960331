import { trackEvent } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { keys as analyticsKeys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'

export interface BannerAnalyticsData {
  bannerName: string
  internalTrackingCode?: string
  accountNumber: number | null
  bannerLocation: string
  bannerPosition: number
  clickUrl: string
}

const mapBannerDataToEcommerceItems = (data: BannerAnalyticsData) => {
  const itemBrand = data.clickUrl.includes('tabtouch-sports')
    ? 'pre'
    : data.clickUrl.includes('races')
      ? 'Racing'
      : undefined
  const itemIdMatch = data.clickUrl.match(/\/event\/(\d+)$/)
  const itemId = itemIdMatch ? itemIdMatch[1] : undefined

  return {
    promotion_name: data.bannerName,
    internalTrackingCode: data.internalTrackingCode,
    item_id: itemId,
    index: data.bannerPosition,
    creative_slot: data.bannerLocation,
    item_brand: itemBrand,
    item_variant: 'Feature Banner',
  }
}

export const trackBannerClicked = (data?: BannerAnalyticsData) => {
  if (!data) return
  const newData = mapBannerDataToEcommerceItems(data)
  trackEvent(analyticsKeys.featureBannerClicked, {
    ecommerce: {
      items: [newData],
    },
  })
}

export const trackBannerViewed = (data?: BannerAnalyticsData) => {
  if (!data) return
  const newData = mapBannerDataToEcommerceItems(data)
  trackEvent(analyticsKeys.featureBannerViewed, {
    ecommerce: {
      items: [newData],
    },
  })
}
