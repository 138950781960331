import React from 'react'
import styled from '@emotion/styled'
import { toTitleCase } from '@mobi/utils/string'
import { Currency } from '@mobi/utils/money'
import { colors, font, radius, spacing } from '@mobi/component-library/Theme/Common'
import { Price } from '@mobi/betslip/Components/Common/Price'
import type { LinkInfo } from '@mobi/betslip/Components/Common/BetCard/types'
import { LinkWrapper } from '../Common/LinkWrapper'

export const SelectionInfo: React.FC<{
  heading: string
  subHeading: string
  iconComponent?: React.ReactNode
  rightAlignedComponent?: React.ReactNode
  linkInfo?: LinkInfo
  priceWin?: string | number | null
  pricePlace?: string | number | null
}> = ({
  heading,
  subHeading,
  priceWin,
  pricePlace,
  iconComponent,
  rightAlignedComponent,
  linkInfo,
}) => {
  const priceWinMoney = new Currency(priceWin || 0)
  const pricePlaceMoney = new Currency(pricePlace || 0)

  const hasWinValue = priceWinMoney.value > 0
  const hasPlaceValue = pricePlaceMoney.value > 0

  return (
    <LinkWrapper linkInfo={linkInfo} data-testid='BetCard.SelectionInfo'>
      <WrapperStyled>
        <div>
          {iconComponent && <span>{iconComponent}</span>}
          <h3>
            <span>{toTitleCase(heading)}</span>
            <span>{subHeading}</span>
          </h3>
        </div>

        <div>
          {hasWinValue && <Price value={priceWinMoney.value} />}

          {hasWinValue && hasPlaceValue && <span> / </span>}

          {hasPlaceValue && (
            <Price
              value={pricePlaceMoney.value}
              isSecondaryDisplay={hasWinValue && hasPlaceValue}
            />
          )}
        </div>

        {rightAlignedComponent && <div>{rightAlignedComponent}</div>}
      </WrapperStyled>
    </LinkWrapper>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: spacing.sm,
  padding: spacing.smx1,
  borderRadius: radius.md,
  backgroundColor: colors.surface[200],

  '> div': {
    display: 'flex',
    alignItems: 'center',
  },

  // Selection text info + icon
  '> div:nth-of-type(1)': {
    flex: 1,

    '> span': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '3.6rem',
      height: '3.6rem',
      marginRight: spacing.sm,
      borderRadius: radius.smx1,
      backgroundColor: colors.surface[400],
    },

    '> h3': {
      display: 'flex',
      flexDirection: 'column',
      background: 0,
      padding: 0,
      margin: 0,
      textTransform: 'none',

      '> span:first-of-type': {
        fontSize: font.size.lg.fontSize,
        fontWeight: font.weight.medium,
        letterSpacing: font.size.lg.letterSpacing,
        lineHeight: font.size.lg.lineHeight,
        color: colors.black,
      },

      '> span:last-of-type': {
        fontSize: font.size.md.fontSize,
        fontWeight: font.weight.regular,
        letterSpacing: font.size.md.letterSpacing,
        lineHeight: font.size.md.lineHeight,
        color: colors.neutral[800],
      },
    },
  },

  // Price
  '> div:nth-of-type(2)': {
    display: 'flex',
    alignItems: 'flex-end',
    gap: spacing.smx2,
    marginLeft: 'auto',

    span: {
      fontSize: font.size.lg.fontSize,
      fontWeight: font.weight.medium,
      letterSpacing: font.size.lg.letterSpacing,
      lineHeight: font.size.lg.lineHeight,
      color: colors.black,
    },
  },

  // Right Aligned Content
  '> div:nth-of-type(3):empty': {
    display: 'none',
  },
})
