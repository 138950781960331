// @ts-expect-error No types for library
import * as awsIot from 'aws-iot-device-sdk'
import { CognitoIdentityCredentials, AWSError } from 'aws-sdk/global'
import Rx from 'rx'
import { PushEvent } from 'tabtouch-push-contract'

export type EventNotificationApiSettings = {
  url: string
  region: string
  identityPoolId: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let mqttClient: any
let environmentConfig: EventNotificationApiSettings
let cognitoCredentials: CognitoIdentityCredentials | null
let logging: boolean = false

const topicRefCount: { [key: string]: number } = {}

export const internalEvent$ = new Rx.Subject<EventData>()

export const event$ = internalEvent$
  .groupBy(event => event.topic)
  .flatMap(group =>
    group
      .scan((acc: EventData, curr: EventData) => {
        if (
          !acc ||
          (curr.payload.sequence && curr.payload.sequence > acc.payload.sequence) ||
          (curr.payload.emitterTimestamp &&
            curr.payload.emitterTimestamp > acc.payload.emitterTimestamp)
        ) {
          return curr
        }

        return acc
      })
      .distinctUntilChanged()
      .debounce(200)
  )

const cachedTopics: string[] = []

export interface EventData {
  topic: string
  payload: PushEvent
}

const decodeMessage = (message: unknown) => {
  if (!message) {
    return null
  }

  const payload = JSON.parse(message.toString())
  return payload
}

const generateClientId = () => {
  return `mqttjs_${Math.random().toString(16).substr(2, 8)}`
}

let connectionCloseCounter: number = 0
let connectionCloseLastSeen: number = Number.MAX_SAFE_INTEGER
let resetMqttClient = false
let clientId: string

const startMqtt = (accessKeyId: string, secretAccessKey: string, sessionToken: string) => {
  clientId = generateClientId()
  mqttClient = awsIot.device({
    region: environmentConfig.region,
    host: environmentConfig.url,
    protocol: 'wss',
    maximumReconnectTimeMs: 32000,
    debug: false,
    accessKeyId,
    secretKey: secretAccessKey,
    sessionToken,
    clientId,
  })

  mqttClient.on('connect', () => {
    if (resetMqttClient) {
      resetMqttClient = false
      for (const topic in topicRefCount) {
        if (topicRefCount.hasOwnProperty(topic)) {
          mqttClient.subscribe(topic)
        }
      }
    }

    if (cachedTopics.length > 0) {
      iotSubscribeTopics(cachedTopics)
      cachedTopics.length = 0
    }
  })

  mqttClient.on('reconnect', () => logError('Iot reconnect'))

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mqttClient.on('message', (topic: string, payload: any) => {
    const parsedPayload = decodeMessage(payload)
    logInfo('Received', topic, parsedPayload)
    internalEvent$.onNext({ topic, payload: parsedPayload })
  })

  // FIXME: Close is getting called very often
  mqttClient.on('close', () => {
    if (Date.now() - connectionCloseLastSeen < 30000) {
      connectionCloseLastSeen = Date.now()
    } else {
      connectionCloseLastSeen = Number.MAX_SAFE_INTEGER
      connectionCloseCounter = 0
    }
    connectionCloseCounter++

    if (connectionCloseCounter === 3) {
      logError('IoT connection closes too often, try to reset')
      mqttClient.end(true)
      resetMqttClient = true
      getCredentialsForIot(cognitoCredentials!, (key: string, secret: string, session: string) => {
        startMqtt(key, secret, session)
      })
    }

    if (connectionCloseCounter === 4) {
      // if close still happens after resetMqttClient, log it
      logError('IoT connection closes too often, fail to fix by resetting')
    }
  })

  mqttClient.on('error', (error: Event) => {
    getCredentialsForIot(cognitoCredentials!, (key: string, secret: string, session: string) => {
      mqttClient.updateWebSocketCredentials(key, secret, session)
    })

    logError(`Iot error: ${error.currentTarget && error.currentTarget.toString()}`)
  })
}

const getCredentialsForIot = (
  credentials: CognitoIdentityCredentials,
  callback: (accesskeyId: string, secretAccessKey: string, sessionToken: string) => void
) => {
  if (!credentials) {
    logError('credentials object is null')
    return
  }

  credentials.get((err: AWSError) => {
    if (!err) {
      callback(credentials.accessKeyId, credentials.secretAccessKey, credentials.sessionToken)
    } else {
      logError(err.toString())
    }
  })
}

export const iotInitClient = (eventNotificationApiSettings: EventNotificationApiSettings) => {
  if (mqttClient) return

  environmentConfig = eventNotificationApiSettings
  logInfo('Init')
  cognitoCredentials = new CognitoIdentityCredentials(
    {
      IdentityPoolId: environmentConfig.identityPoolId,
    },
    {
      region: environmentConfig.region,
    }
  )
  getCredentialsForIot(cognitoCredentials, startMqtt)
}

export const iotCloseClient = () => {
  if (!mqttClient) return

  mqttClient.end()
  mqttClient = null
}

const iotRemoveTopicsfromCache = (topics: string[]) => {
  topics.forEach(topic => {
    const index = cachedTopics.findIndex(v => v === topic)
    if (index >= 0) {
      cachedTopics.splice(index, 1)
    }
  })
}

export const iotSubscribeTopics = (topics: string[]): Rx.Observable<EventData> => {
  if (!mqttClient) {
    topics.forEach(topic => cachedTopics.push(topic))
  } else {
    logInfo('Subscribing topics', topics)
    topics.forEach(topic => {
      topicRefCount[topic] = (topicRefCount[topic] || 0) + 1
      if (topicRefCount[topic] === 1) {
        mqttClient.subscribe(topic)
      }
    })
  }
  return event$
}

export const iotUnsubscribeTopics = (topics: string[]): void => {
  iotRemoveTopicsfromCache(topics)
  if (mqttClient) {
    logInfo('Unsubscribing topics', topics)
    topics.forEach(topic => {
      if (topicRefCount[topic] > 0) {
        topicRefCount[topic]--
      }

      if (topicRefCount[topic] === 0) {
        mqttClient.unsubscribe(topic)
        delete topicRefCount[topic]
      }
    })
  }
}

/*
 * Utils
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const logInfo = (message: string, ...optionalParams: any[]) => {
  if (logging) {
    // eslint-disable-next-line no-console
    console.log(`iot: ${message}`, ...optionalParams)
  }
}

// eslint-disable-next-line no-console
const logError = (err: Error | string): void => console?.error(err)

if (window) {
  window.iot = window.iot || {
    getClientId: () => clientId,
    enableLogging: () => (logging = true),
    // eslint-disable-next-line no-console
    getSubscriptions: () => console.table(topicRefCount),
  }
}
