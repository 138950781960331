import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import dayjs from 'dayjs'

import { NextRaceDrawer } from '@core/Components/BettingDrawer/NextRaceDrawer'
import { MainContainer } from '@core/Components/Containers'
import { ContentHeader } from '@core/Components/ContentHeader'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'
import { Spinner } from '@mobi/component-library/Common/Spinner'
import {
  getToteRaceTopic,
  ToteRaceAbandonedPushEvent,
  ToteRaceStatusChangedPushEvent,
} from '@core/State/PushData'
import { iotSubscribeTopics, iotUnsubscribeTopics } from '@mobi/utils/awsiot'
import { useObservableImmutable } from '@core/Utils/hooks'
import { useIsAuthenticated } from '@core/Utils/hooks/useIsAuthenticated'
import { fetchCampaignsAsync } from '@core/State/UserAccount/async-signals'

import { SetGoToMeeting, SetGoToNextRace, SetIsRaceClosed, SetMysteryData, state$ } from './driver'
import { MysteryBetTabs } from './Components/MysteryBetTabs'
import { MeetingInformation } from './Components/MeetingInformation'
import { useMysteryBetDetails } from '@core/Areas/MysteryBet/Hooks/useMysteryBetDetails'

const NEXT_RACE_PATH = '/tote/mystery'

export const MysteryBetPage = (): JSX.Element | null => {
  const history = useHistory()
  const location = useLocation()
  const { meetingId, raceNumber } = useParams<{ meetingId: string; raceNumber: string }>()
  const date = new URLSearchParams(location.search).get('date') || undefined
  const { isRaceClosed, isBetConfirmed } = useObservableImmutable(state$, [
    'isRaceClosed',
    'isBetConfirmed',
  ])
  const isAuthenticated = useIsAuthenticated()

  const isForSpecificRace = !!meetingId && !!raceNumber && !!date

  const { isError, data, isFetching, refetch } = useMysteryBetDetails({
    meetingId,
    date,
    raceNumber: raceNumber ? +raceNumber : undefined,
  })

  React.useEffect(() => {
    SetMysteryData(data)
  }, [data])

  React.useEffect(() => {
    if (isAuthenticated) {
      fetchCampaignsAsync()
    }
  }, [isAuthenticated])

  React.useEffect(() => {
    SetIsRaceClosed(data?.Race != null && data.Race.RaceStatusText !== 'Open')
    const raceKey = data?.RaceKeyForLiveUpdates
    let subscription: Rx.IDisposable
    if (raceKey) {
      subscription = iotSubscribeTopics(getTopics(raceKey)).subscribe(e => {
        if (e.topic?.includes(raceKey)) {
          if (e.payload?.eventType === 'ToteRaceStatusChanged') {
            const statusChangedPayload = e.payload as ToteRaceStatusChangedPushEvent
            SetIsRaceClosed(statusChangedPayload.status !== 'open')
          } else if (e.payload?.eventType === 'ToteRaceAbandonChanged') {
            const abandonChangedPayload = e.payload as ToteRaceAbandonedPushEvent
            if (abandonChangedPayload.status !== 'unabandoned') {
              SetIsRaceClosed(true)
            }
          }
        }
      })
    }

    return function cleanUp(): void {
      subscription?.dispose()
      if (raceKey) {
        iotUnsubscribeTopics(getTopics(raceKey))
      }
    }
  }, [data])

  const formattedDate = dayjs(data?.Meeting?.MeetingDate).format('YYYY-MM-DD')
  const goToNextRace = React.useCallback(
    () => (location.pathname === NEXT_RACE_PATH ? refetch() : history.push(NEXT_RACE_PATH)),
    [history, location.pathname, refetch]
  )
  const goToMeeting = React.useCallback(
    () => history.push(`/tote/meetings/${data?.Meeting?.MeetingId}?date=${formattedDate}`),
    [data?.Meeting?.MeetingId, formattedDate, history]
  )
  SetGoToNextRace(goToNextRace)
  SetGoToMeeting(goToMeeting)

  if (isFetching) {
    return (
      <div data-testid='Spinner'>
        <Spinner />
      </div>
    )
  }

  if (isError || !data) {
    return (
      <NoticeBox
        title='Unable to place a mystery bet at this time. Please try again later.'
        noticeBoxType={NoticeBoxTypes.Error}
      />
    )
  }

  return (
    <MainContainer data-tid-blackbook background='grey'>
      <ContentHeader title='Mystery Bet' refreshCallback={() => !isForSpecificRace && refetch()} />
      <MeetingInformation />
      <MysteryBetTabs />
      {isRaceClosed && !isBetConfirmed && (
        <NextRaceDrawer open onGoToMeeting={goToMeeting} onNextRace={goToNextRace} />
      )}
    </MainContainer>
  )
}

function getTopics(raceKey: string): string[] {
  return [
    getToteRaceTopic(raceKey, { type: 'statusChanged' }),
    getToteRaceTopic(raceKey, { type: 'abandonedChanged' }),
  ]
}
