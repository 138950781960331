import { SetResidentialDetail, state$ } from './userAccountDriver'
import { state$ as navigationState$ } from '@core/State/Navigation'
import { trackAccountNumber } from '@classic/Foundation/Analytics/Analytics'
import { navigateHome } from '@classic/AppUtils/Framework/Intent/navigation'
import { queryClient } from '@core/Data/ReactQuery/queryClient'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { store } from '@core/Store'
import { toggleIsDropDownOpen } from '@core/Areas/AppHeader/Store'
import { fetchCampaignsAsync, fetchAccountBalanceAsync } from './async-signals'
import { register as registerUserAccountPushData } from './push-data'
import { BetAccountKey, BetAccountRecord, BetAccountRepo } from '@core/Data/Account/betAccount'
import { syncLoginStateWithNativeApp } from '@core/NativeServices/LoginStateRegister/LoginStateRegister'
import { updateMetadata } from '@core/NativeServices/MetadataService'
import { RemoveBonusBetsFromBetslip } from '@core/Areas/Betslip/signals'
import { trackOptimoveEvent } from '@core/Services/Optimove/optimove'
import { trackLogout } from '@classic/Foundation/Analytics/GoogleTagManagerService'

export function registerUserAccountSideEffects(): void {
  registerUserAccountPushData()

  // Handle Side Effects Based on State Changes
  state$
    .pairwise()
    .distinctUntilChanged()
    .subscribe(([prevState, newState]) => {
      const hasLoggedOut = prevState.isLoggedIn === true && newState.isLoggedIn === false
      const hasLoggedIn = !prevState.isLoggedIn && newState.isLoggedIn === true
      const hasLogInStateChanged = prevState.isLoggedIn !== newState.isLoggedIn

      if (hasLoggedIn) {
        fetchAccountBalanceAsync()
        fetchCampaignsAsync()
        updateMetadata()
        trackAccountNumber(`${newState.accountNumber || ''}`)

        BetAccountRepo.getPromise(BetAccountKey).then((betAccount: BetAccountRecord) => {
          syncLoginStateWithNativeApp(betAccount)
          const { ResidentialCountry, ResidentialState } = betAccount
          SetResidentialDetail([ResidentialCountry, ResidentialState])

          if (typeof betAccount.IsIDVerified === 'boolean') {
            trackOptimoveEvent({
              eventName: 'account_verification_status',
              data: { verified: betAccount.IsIDVerified },
            })
          }
        })
      }

      if (hasLoggedOut) {
        trackLogout()
        queryClient.removeQueries(queryKeys.accountBase)
        store.dispatch(toggleIsDropDownOpen(false))

        navigationState$.take(1).subscribe(navState => {
          if (navState.get('currentUrl')?.includes('account')) {
            navigateHome()
          }
        })
      }

      if (hasLogInStateChanged) {
        RemoveBonusBetsFromBetslip()
      }
    })
}
