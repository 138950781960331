import React from 'react'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { Icon } from '@mobi/component-library/Common/Icon'
import { font, radius, spacing } from '@mobi/component-library/Theme/Common'
import type { PromoType } from '../PromoSelector/types'
import { promoTypeColorThemes } from '../PromoSelector/constants'

export const PromoBanner: React.FC<{ promoType: PromoType; text: string }> = ({
  promoType,
  text,
}) => {
  const promoTypeClass = `PromoType-${promoType}`
  return (
    <WrapperStyled className={promoTypeClass}>
      {promoType === 'SuperPick' && (
        <span>
          <Icon type='specials' color={hexColors.lightOrange} size='1.6rem' />
        </span>
      )}

      <span>{text}</span>
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div(
  {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.smx1,
    padding: `${spacing.smx1} ${spacing.sm}`,
    fontFamily: font.family.primary,
    fontSize: font.size.md.fontSize,
    letterSpacing: font.size.md.letterSpacing,
    lineHeight: font.size.md.lineHeight,
    fontWeight: font.weight.medium,
    backgroundColor: 'var(--main-color)',
    borderRadius: radius.md,
    overflow: 'hidden',

    '> span:first-of-type': {
      flexShrink: 0,
    },

    '> span:last-of-type': {
      flex: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  promoTypeColorThemes
)
