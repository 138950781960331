import anime from 'animejs'
import React from 'react'
import { useAppDispatch } from '@core/Store/hooks'
import { toggleIsDropDownOpen } from '@core/Areas/AppHeader/Store'
import {
  FobSpecialToken,
  getSpecialTokenUrl,
} from '@core/Areas/AppHeader/Components/AccountDropDown/Components/helpers/useGetSpecialTokens'
import { SpinnerInlineStyled } from '@mobi/component-library/Common/Spinner/Spinner.styles'
import { useGetSpecialTokens } from '@core/Areas/AppHeader/Components/AccountDropDown/Components/helpers/useGetSpecialTokens'
import {
  AccountPromoWrapperStyled as Wrapper,
  AccountPromoLabelStyled as Label,
  AccountPromoButtonRowStyled as ButtonRow,
  AccountPromoButtonStyled as Button,
  AccountPromoLinkStyled as Link,
  AccountSuperPickBadgeStyled as Badge,
  AccountPromoCountStyled as Count,
  AccountPromoSuperpicksWrapStyled as SuperpicksWrap,
  AccountPromoSuperpicksListStyled as SuperpicksList,
  AccountPromoSuperpicksItemStyled as SuperpicksItem,
  AccountPromoSuperpicksButtonStyled as SuperpicksButton,
} from './OffersPanel.styles'
import { logError } from '@core/Utils/logging/logging'
import { RegisterToast, RemoveToast } from '@core/Components/Toast/ToastDriver'
import { SetSuperpickStatus } from '@core/State/UserAccount/userAccountDriver'

const NSW_LEGISLATION_ERROR_CODE = 190
const NSW_LEGISLATION_ERROR_ID = 'NSW_SUPERPICK_ERROR'

export const OffersPanel = ({
  bonusBetCount = 0,
  isOpen,
}: {
  bonusBetCount: number
  isOpen: boolean
}): JSX.Element => {
  const dispatch = useAppDispatch()
  const superpicksWrapRef = React.useRef<HTMLDivElement>(null)
  const [isBonusSuperpicksExanded, setIsBonusSuperpicksExpanded] = React.useState(false)

  const { tokens, errorMessage, errorCode, isLoading: isLoadingSuperPicks } = useGetSpecialTokens()

  React.useEffect(() => {
    if (!superpicksWrapRef.current) return
    anime({
      targets: superpicksWrapRef.current,
      height: isBonusSuperpicksExanded ? superpicksWrapRef.current.scrollHeight : '0px',
      easing: 'easeInOutQuad',
      duration: 300,
    })
  }, [isBonusSuperpicksExanded])

  React.useEffect(() => {
    if (!isOpen || isLoadingSuperPicks) {
      setIsBonusSuperpicksExpanded(false)
    }
  }, [isOpen, isLoadingSuperPicks])

  React.useEffect(() => {
    if (!isOpen && errorCode) RemoveToast(NSW_LEGISLATION_ERROR_ID)
  }, [errorCode, isOpen])

  const handleSuperPickNav = (tokenId: number) => {
    if (tokenId) {
      SetSuperpickStatus({
        isLoading: true,
        tokens,
        errorMessage,
        errorCode,
      })

      getSpecialTokenUrl(tokenId)
        .then(result => {
          if (!result?.success || !result.data.SpecialUrl) {
            return
          }
          window.location.href = result.data.SpecialUrl
          dispatch(toggleIsDropDownOpen(false))
          SetSuperpickStatus({
            isLoading: false,
            tokens,
            errorMessage,
            errorCode,
          })
        })
        .catch(err => {
          SetSuperpickStatus({
            isLoading: false,
            tokens: [],
            errorMessage: 'Currently unable to retrieve SuperPicks',
            errorCode: null,
          })
          logError(err)
        })
    }
  }

  const standardSuperPicks = tokens.filter(token => !token.IsBonusToken)
  const bonusSuperPicks = tokens.filter(token => token.IsBonusToken)

  const isStandardSuperPickAvailable =
    !errorMessage && standardSuperPicks && standardSuperPicks.length > 0
  const isBonusSuperPicksAvailable = !errorMessage && bonusSuperPicks && bonusSuperPicks.length > 0

  const isNSWSuperPickError = errorMessage && errorCode === NSW_LEGISLATION_ERROR_CODE

  return (
    <Wrapper>
      <ButtonRow data-testid='dropdown-offers-row'>
        <div>
          <Button
            color='secondary'
            data-testid='account-dropdown-superpicks'
            shouldDisable={!isNSWSuperPickError && !isStandardSuperPickAvailable}
            onClick={() => {
              if (isNSWSuperPickError) {
                RegisterToast({
                  message: errorMessage,
                  position: 'top',
                  type: 'error',
                  timeout: 0,
                  id: NSW_LEGISLATION_ERROR_ID,
                })
                return
              }
              isStandardSuperPickAvailable && handleSuperPickNav(standardSuperPicks[0].TokenId)
            }}
          >
            <Label data-tid-account-dropdown-superpicks-label>SuperPicks</Label>
            <Count shouldDisable={!isStandardSuperPickAvailable}>
              <span data-testid='account-dropdown-superpicks-count'>
                {isLoadingSuperPicks ? (
                  <SpinnerInlineStyled />
                ) : (
                  (isStandardSuperPickAvailable && standardSuperPicks[0].BetsAvailable) ||
                  (isNSWSuperPickError ? '!' : 0)
                )}
              </span>
            </Count>
          </Button>
        </div>

        <div>
          <Button
            color='secondary'
            data-tid-account-dropdown-bonus-superpicks
            shouldDisable={!isBonusSuperPicksAvailable}
            onClick={() => {
              isBonusSuperPicksAvailable &&
                !isLoadingSuperPicks &&
                setIsBonusSuperpicksExpanded(state => !state)
            }}
          >
            <Label data-tid-account-dropdown-bonus-superpicks-label>
              Bonus
              <br />
              SuperPicks
            </Label>
            <Count shouldDisable={!isBonusSuperPicksAvailable}>
              <span data-testid='account-dropdown-bonus-superpicks-count'>
                {isLoadingSuperPicks ? (
                  <SpinnerInlineStyled />
                ) : (
                  (isBonusSuperPicksAvailable && calculateTotalBonusSuperPicks(bonusSuperPicks)) ||
                  0
                )}
              </span>
            </Count>
          </Button>
        </div>

        <div>
          <Link
            color='secondary'
            href='/#account/offer'
            data-tid-account-dropdown-bonus-bets
            shouldDisable={!bonusBetCount}
          >
            <Label data-tid-account-dropdown-bonus-bets-label>Bonus Offers</Label>
            <Count shouldDisable={!bonusBetCount}>
              <span data-testid='account-dropdown-bonus-bets-count'>{bonusBetCount || 0}</span>
            </Count>
          </Link>
        </div>
      </ButtonRow>

      <SuperpicksWrap ref={superpicksWrapRef}>
        {bonusSuperPicks.length > 0 && (
          <SuperpicksList data-testid='account-dropdown-superpicks-list'>
            {bonusSuperPicks.map(({ TokenName, TokenId, BetsAvailable = 0 }) => (
              <SuperpicksItem key={TokenId}>
                <SuperpicksButton
                  onClick={() => {
                    handleSuperPickNav(TokenId)
                  }}
                >
                  {TokenName} <Badge>{BetsAvailable}</Badge>
                </SuperpicksButton>
              </SuperpicksItem>
            ))}
          </SuperpicksList>
        )}
      </SuperpicksWrap>
    </Wrapper>
  )
}

// =======
// Helpers
// =======
function calculateTotalBonusSuperPicks(array: FobSpecialToken[]): number {
  return array.reduce((count, { BetsAvailable = 0 }) => BetsAvailable + count, 0)
}
