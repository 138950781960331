import { inject, injectable } from 'inversify'
import type { IEventAggregator } from '@classic/AppUtils/Framework/Messaging/IEventAggregator'
import type { IAppWindow } from '@classic/AppUtils/Framework/WindowManagement/IAppWindow'
import type { SortOption } from '@classic/Betting-v2/Sorting/types'
import { timed } from '@core/Utils'
import { ModelSorter } from '../Sorting/ModelSorter'
import { BetType } from './Betting/BetType'
import BetTypeInformation from './Betting/BetTypeInformation'
import BetTypeInformationBuilder from './Betting/BetTypeInformationBuilder'
import { BettingInformation } from './BettingInformation'
import type { IBettingInformationManager } from './IBettingInformationManager'
import ObservableRacePage from './Observables/ObservableRacePage'

@injectable()
export default class BettingInformationManager implements IBettingInformationManager {
  constructor(
    @inject('IEventAggregator') eventAggregator: IEventAggregator,
    @inject('IAppWindow') applicationWindow: IAppWindow
  ) {
    this.eventAggregator = eventAggregator
    this.applicationWindow = applicationWindow
  }

  public init(raceInformation: ObservableRacePage, bettingInformation: BettingInformation) {
    this.raceInformation = raceInformation
    this.bettingInformation = bettingInformation
    this.reapplySort()
  }

  private setSortFor({ property, direction }: SortOption) {
    if (this.bettingInformation.sortOption != null) {
      this.bettingInformation.sortOption.property = property
      this.bettingInformation.sortOption.direction = direction
    }
  }

  private reapplySort() {
    const sortOption = this.bettingInformation.sortOption
    const startersForRace = this.raceInformation.getStartersForRace(
      this.raceInformation.meetingInformation.selectedRace.raceNumber()
    )

    if (startersForRace().length > 0 && this.bettingInformation.sortOption != null) {
      const isFixedAvailable =
        this.raceInformation.meetingInformation.selectedRace.fixedOddsInfo?.isRaceAvailable()

      if (
        !this.bettingInformation.selectedBetType().supportsSort(sortOption.property) ||
        (!isFixedAvailable && sortOption.property === 'FixedPrice')
      ) {
        this.bettingInformation.sortOption.property = 'Number'
        this.bettingInformation.sortOption.direction = 'Ascending'
      }
      ModelSorter.sortBy(startersForRace, sortOption)
    }
  }

  public processSort(params: { raceNumber: number; option: SortOption }) {
    ModelSorter.sortBy(this.raceInformation.getStartersForRace(params.raceNumber), params.option)
    this.setSortFor(params.option)
  }

  public update(raceChanged: boolean) {
    this.bettingInformation.mergeDone(false)

    const shouldResetToWinPlace = this.shouldResetBackToWinPlace()
    if (raceChanged || shouldResetToWinPlace) {
      shouldResetToWinPlace && this.bettingInformation.lastSelectionBetType(null)

      const betTypeToReset = shouldResetToWinPlace
        ? BetType.WinPlace
        : this.bettingInformation.lastSelectionBetType() || BetType.WinPlace

      this.reset(betTypeToReset, false)
    }
    this.reapplySort()

    this.bettingInformation.mergeDone(true)
  }

  public processBetTypeChange(betType: BetType) {
    if (betType === BetType.Mystery) {
      this.eventAggregator.publish('load-mystery-bet', {
        raceNumber: this.bettingInformation.raceNumber,
      })
    } else {
      this.applicationWindow.requestAnimationFrame(() => {
        timed('BettingInformationManager::processBetTypeChange', () => {
          this.bettingInformation.mergeDone(false)
          this.reset(betType)
          this.bettingInformation.mergeDone(true)
        })
        this.applicationWindow.requestAnimationFrame(() => {
          this.applicationWindow.requestAnimationFrame(() => {
            this.eventAggregator.publish('process-bet-type-completed')
          })
        })
      })
    }
  }

  private shouldResetBackToWinPlace() {
    return !this.raceInformation
      .availableBetTypes()
      .some(
        (betType: BetTypeInformation) =>
          betType.betType() === this.bettingInformation.selectedBetType().betType()
      )
  }

  public reset(betType: BetType, preserveSelections: boolean = false) {
    timed('BettingInformationManager::reset', () => {
      if (!preserveSelections) {
        this.bettingInformation.clearAll()
      }
      this.bettingInformation.selectedBetType(
        BetTypeInformationBuilder.fromUnTyped(BetType[betType]).build()
      )
      this.bettingInformation.selections.setUpForLegs(this.raceInformation, preserveSelections)
      this.bettingInformation.isFixed(false)
      if (!preserveSelections) {
        this.bettingInformation.setUpFields()
        this.bettingInformation.sizeSameAsToBetType()
      }
    })
  }

  private applicationWindow: IAppWindow
  private eventAggregator: IEventAggregator
  private raceInformation!: ObservableRacePage
  private bettingInformation!: BettingInformation
}
