import React from 'react'
import { dayjs } from '@mobi/utils'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { PlanSeq } from '@mobi/api-types'
import { isFobMatchedSelection } from '@mobi/betslip/helpers/typeGuards'
import { hexColors } from '@mobi/settings'
import { SpinnerInlineStyled } from '@mobi/component-library/Common/Spinner/Spinner.styles'
import { toTitleCase } from '@mobi/utils/string'
import { toDisplayPrice } from '@mobi/utils/money'
import { Icon } from '@mobi/component-library/Common/Icon'
import { RaceCountdown } from '@core/Components/RaceCountdown/RaceCountdown'
import { SilkImageContained } from '@core/Components/SilkImage/SilkImageContained'
import { WinPlaceSelection } from '@core/Components/PriceChangeDisplay'
import { buildBetItem, getErrorMessage, type LoadBetDetails } from '@core/Utils/betting/loadBet'
import { RemoveSingleBet } from '@core/Areas/Betslip/signals'
import { type BetslipItem, state$ as betslipState$ } from '@core/Areas/Betslip/driver'
import { RegisterToast } from '@core/Components/Toast/ToastDriver'
import type { BlackbookEntryRacingToday } from '@core/Areas/Blackbook/types'
import { getIconName } from '@core/Areas/Blackbook/helpers'
import {
  trackBlackbookNavigateToRace,
  trackBlackbookOddsClicked,
} from '@core/Areas/Blackbook/analytics'
import { handleBetSelection } from '@core/Utils/betting/handleBetSelection'

const enum LocalConstants {
  DogsSilkClassName = 'bb__silk--dogs',
}

export const RunningSoonHeader: React.FC<{
  runner: BlackbookEntryRacingToday
  isSelected: boolean
  shouldAutoAddToBetslip: boolean
  onClick?: () => void
  isExpanded?: boolean
}> = React.memo(
  ({ runner, onClick, isExpanded, isSelected, shouldAutoAddToBetslip }) => {
    const [isBetBuildLoading, setIsBetBuildLoading] = React.useState(false)

    const {
      AdvertisedStartTime,
      Barrier,
      ContestNumber,
      FixtureDate,
      FixtureId,
      GroundDescription,
      PlaceDividend,
      PropositionSeq,
      RiderName,
      TrainerName,
      StarterNumber,
      WinDividend,
    } = runner.RacingTodayDetails

    const raceHashRoute = getRaceLink({ FixtureId, FixtureDate, ContestNumber })
    const runnerStatus = getStatus(runner.RacingTodayDetails)

    const isJockey = runner.Code === 'Jockeys'
    const shouldRenderBarrier = runner.Code === 'Races' || isJockey

    const handleBetButtonClick: React.MouseEventHandler<HTMLButtonElement> = e => {
      e.stopPropagation()

      if (isSelected) {
        removeRunnerFromBetslip(PropositionSeq.toString())
        return
      }

      const betItem: LoadBetDetails = {
        fixtureId: FixtureId,
        fixtureDate: new Date(FixtureDate),
        races: [ContestNumber],
        selectionString: StarterNumber.toString(),
        planSeq: PlanSeq.FOBRacing,
        betType: 'Win & Place',
      }
      setIsBetBuildLoading(true)

      buildBetItem(betItem)
        .then(selection => {
          handleBetSelection({
            betFlow: { location: shouldAutoAddToBetslip ? 'Betslip' : 'Quickbet' },
            selection: { ...selection, betSource: 'blackbook' },
          })
        })
        .catch(displayAddToBetslipError)
        .finally(() => setIsBetBuildLoading(false))

      trackBlackbookOddsClicked({
        code: runner.Code,
        name: runner.StarterName,
        action: shouldAutoAddToBetslip ? 'betslip' : 'quickbet',
      })
    }

    return (
      <RunnerHeaderStyled onClick={onClick} data-testid='blackbook-runner-racing-soon-header'>
        <div data-testid='blackbook-runner-silk-wrapper'>
          <figure className={runner.Code === 'Dogs' ? LocalConstants.DogsSilkClassName : ''}>
            <SilkImageContained
              fixtureDate={FixtureDate.toString()}
              fixtureId={FixtureId}
              raceNumber={ContestNumber}
              acceptorNumber={StarterNumber}
            />
          </figure>
        </div>

        <div>
          <div>
            <h3 data-testid='blackbook-runner-name'>
              {StarterNumber} - {toTitleCase(runner.StarterName)}{' '}
              {shouldRenderBarrier && Barrier && `(${Barrier})`}
            </h3>

            {runnerStatus === 'Open' ? (
              dayjs(AdvertisedStartTime).isToday() ? (
                <RaceCountdown
                  displaySize='1.2rem'
                  raceStartTime={dayjs(AdvertisedStartTime).toDate()}
                />
              ) : (
                <div data-testid='blackbook-runner-full-ast'>
                  <strong>{dayjs(AdvertisedStartTime).format('ddd DD MMM hh:mm A')}</strong>
                </div>
              )
            ) : (
              runnerStatus !== 'Suspended' &&
              runnerStatus !== 'Scratched' && (
                <span data-testid='blackbook-runner-race-status'>{runnerStatus}</span>
              )
            )}

            {!isJockey && <Icon type={isExpanded ? 'arrowup' : 'arrowdown'} size='1.4rem' />}
          </div>

          <div>
            <div>
              <Link
                to={raceHashRoute}
                data-testid='blackbook-runner-race-link'
                onClick={trackBlackbookNavigateToRace}
              >
                <Icon type={getIconName(runner.Code, FixtureId)} size='1.8rem' /> R{ContestNumber} -{' '}
                {toTitleCase(GroundDescription)}
              </Link>

              <div>
                <span data-testid='blackbook-runner-rider-trainer-driver-name'>
                  <strong>{getRiderAbbreviation(runner.Code)}:</strong>{' '}
                  {toTitleCase(RiderName || '-')}
                </span>

                {TrainerName && runner.Code !== 'Dogs' && (
                  <span data-testid='blackbook-runner-rider-trainer-name'>
                    <strong>T:</strong> {toTitleCase(TrainerName)}
                  </span>
                )}

                {runnerStatus === 'Scratched' && (
                  <div data-testid='blackbook-runner-scratched-ind'>
                    <strong>SCRATCHED</strong>
                  </div>
                )}
              </div>

              {!isJockey && !!runner.LatestNoteLine && (
                <div>
                  <strong data-testid='blackbook-runner-note'>
                    <em>{runner.LatestNoteLine}</em>
                  </strong>
                </div>
              )}
            </div>

            {!!WinDividend && runnerStatus === 'Open' && (
              <div data-testid='blackbook-runner-bet-button'>
                <WinPlaceSelection
                  isVisible
                  priceWin={toDisplayPrice(WinDividend)}
                  pricePlace={PlaceDividend ? toDisplayPrice(PlaceDividend) : ''}
                  starterNumber={StarterNumber}
                  isSelected={isSelected}
                  isFavourite={false}
                  isDisabled={isBetBuildLoading}
                  onClick={handleBetButtonClick}
                />

                {isBetBuildLoading && (
                  <span aria-hidden>
                    <SpinnerInlineStyled color='dark' size={2} />
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
      </RunnerHeaderStyled>
    )
  },
  (prevProps, nextProps) =>
    nextProps.isExpanded === prevProps.isExpanded &&
    nextProps.runner.LatestNoteLine === prevProps.runner.LatestNoteLine &&
    nextProps.isSelected == prevProps.isSelected &&
    nextProps.shouldAutoAddToBetslip == prevProps.shouldAutoAddToBetslip
)

// =============
// Local Helpers
// =============

const getStatus = ({
  AbandonedInd,
  ResultedInd,
  ScratchedInd,
  CloseTime,
  SuspendedInd,
}: BlackbookEntryRacingToday['RacingTodayDetails']) => {
  if (AbandonedInd) return 'Abandoned'
  if (ResultedInd) return 'Resulted'
  if (ScratchedInd) return 'Scratched'
  if (CloseTime) return 'Closed'
  if (SuspendedInd) return 'Suspended'
  return 'Open'
}

const getRaceLink = ({ FixtureId, FixtureDate, ContestNumber }: RaceLinkParams): string =>
  `/tote/meetings/${FixtureId}/${ContestNumber}?date=${dayjs(FixtureDate).format('YYYY-MM-DD')}`

const getRiderAbbreviation = (code: BlackbookEntryRacingToday['Code']) =>
  (
    ({
      Dogs: 'T',
      Trots: 'D',
      Races: 'J',
      Jockeys: 'J',
    }) as Record<BlackbookEntryRacingToday['Code'], string>
  )[code]

const removeRunnerFromBetslip = (propositionSeq: string) => {
  let matchedItem: BetslipItem | undefined
  betslipState$.take(1).subscribe(({ items }) => {
    matchedItem = items.find(item =>
      isFobMatchedSelection(item.selection)
        ? item.selection.propositionSeq === propositionSeq
        : false
    )
  })
  matchedItem && RemoveSingleBet(matchedItem)
}

const displayAddToBetslipError = (err: unknown | Error) => {
  RegisterToast({
    message: getErrorMessage(err),
    type: 'error',
    position: 'bottom',
    id: 'blackbook-bet-error',
    timeout: 0,
  })
}

// ======
// Styles
// ======

const RunnerHeaderStyled = styled.div({
  display: 'flex',
  paddingBottom: '0.5rem',

  // Silk & Icon
  '& > div:nth-of-type(1)': {
    display: 'flex',
    width: '3rem',
    paddingRight: '1rem',

    figure: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '3rem',
      height: '3rem',
      margin: 0,
      padding: 0,
      borderRadius: '50%',
      background: hexColors.gainsboro,
      color: hexColors.dimGrey,
    },

    ['figure.' + LocalConstants.DogsSilkClassName]: {
      background: 0,
    },
  },

  // Runner Info
  '& > div:nth-of-type(2)': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',

    '*': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },

    '& > div': {
      display: 'flex',
      justifyContent: 'space-between',

      '& > div': {
        flexShrink: 0,
      },
    },

    // Header & Countdown
    '& > div:nth-of-type(1)': {
      display: 'flex',
      alignItems: 'center',
      minHeight: '3rem',

      h3: {
        background: 'revert',
        backgroundColor: 'transparent',
        padding: 0,
        margin: 0,
        marginRight: 'auto',
        color: hexColors.nero,
        lineHeight: 'normal',
        fontSize: '1.4rem',
        fontWeight: 'bold',
        textTransform: 'none',
      },

      // Expand arrow
      '& > svg': {
        flexShrink: 0,
        marginLeft: '0.5rem',
      },
    },

    // Race Link, Rider Info & Notes
    '& > div:nth-of-type(2)': {
      '& > div:first-of-type': {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
        paddingRight: '0.5rem',
        lineHeight: 1.2,
        fontSize: '1.2rem',

        '& > *': {
          minHeight: '2rem',
        },

        // Race Link
        '& > a': {
          display: 'flex',
          width: 'fit-content',
          lineHeight: 1,
          color: hexColors.studio,

          svg: {
            transform: 'translateY(-0.3rem)',
            marginRight: '0.3rem',
          },
        },

        // RiderNames, Comment (Generic)
        '& > div': {
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          paddingBottom: '0.5rem',

          'span:nth-of-type(odd)': {
            paddingRight: '0.5rem',
          },

          div: {
            marginLeft: 'auto',
          },
        },
      },

      // Bet Button Container
      '& > div:nth-of-type(2)': {
        position: 'relative',

        '> span': {
          position: 'absolute',
          zIndex: 1,
          inset: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: 'rgba(255,255,255,0.7)',
        },
      },
    },
  },
})

// =====
// Types
// =====

type RaceLinkParams = Pick<
  BlackbookEntryRacingToday['RacingTodayDetails'],
  'FixtureId' | 'FixtureDate' | 'ContestNumber'
>
