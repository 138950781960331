import { useQueries, UseQueryResult } from 'react-query'
import { WebAppVersionDetails, WebAppVersions, webAppVersionsSchema } from '@mobi/api-types'

/**
 * Retrieve available web app versions matching the specified major versions
 */
export const useVersions = (majorVersions: number[]) => {
  const results = useQueries(
    majorVersions.map(version => ({
      queryKey: `webapp/versions/${version}`,
      queryFn: () => queryFn(version),
      staleTime: 1000 * 60 /* 1 minute */,
    }))
  )

  if (results.some(result => result.isLoading)) {
    return {
      isLoading: true,
      isError: false,
      data: undefined,
    }
  }

  if (results.some(result => result.isError)) {
    return {
      isLoading: false,
      isError: true,
      data: undefined,
    }
  }

  return {
    isLoading: false,
    isError: false,
    data: merge(results),
  }
}

const queryFn = async (majorVersion: number) => {
  const response = await fetch(`api/support/webapp/versions?majorVersion=${majorVersion}`)
  const json = await response.json()
  return webAppVersionsSchema.parse(json)
}

/**
 * Merge versions from multiple queries into a single dictionary
 */
const merge = (
  responses: UseQueryResult<WebAppVersions>[]
): Record<string, WebAppVersionDetails> => {
  return responses
    .flatMap(response => response.data || [])
    .reduce((accumulator, data) => {
      return Object.assign(accumulator, data.versions)
    }, {})
}
