import React from 'react'
import { useSelector } from 'react-redux'
import { selectBetSlipFobSelectionsIds } from '@mobi/betslip/Store/Bets/selectors'
import { selectWorkflowStatus } from '@mobi/betslip/Store/Workflow/selectors'
import {
  updateBetSlipFobRacingItemPrice,
  updateBetSlipSportsItemPrice,
} from '@mobi/betslip/Store/Bets'
import { iotSubscribeTopics, iotUnsubscribeTopics } from '@mobi/utils/awsiot'
import { store } from '@core/Store'
import {
  event$,
  type FobPriceChangedPushEvent,
  getFobPriceChangeByPropositionTopic,
  isFobPriceChangedPushEvent,
  getFobSportsPriceChangeTopic,
  isSportsPriceChangedPushEvent,
  SportsPriceChangedPushEvent,
  PushEvent,
} from '@core/State/PushData'

export const useRealtimeFobPriceChanges = (shouldEnable: boolean) => {
  const subscriptionItems = useSelector(selectBetSlipFobSelectionsIds)
  const workflowStatus = useSelector(selectWorkflowStatus)

  const currentSubscriptionsRef = React.useRef(new Set<string>())
  const workflowStatusRef = React.useRef(workflowStatus)

  workflowStatusRef.current = workflowStatus

  // ==================================
  // Handle fob selection subscriptions
  // ==================================
  React.useEffect(() => {
    if (!shouldEnable) return

    subscriptionItems.forEach(item => {
      const topic = getSubscriptionItemTopic(item)
      if (currentSubscriptionsRef.current.has(topic)) return
      iotSubscribeTopics([topic])
      currentSubscriptionsRef.current.add(topic)
    })

    Array.from(currentSubscriptionsRef.current)
      .filter(topic => !subscriptionItems.some(item => getSubscriptionItemTopic(item) === topic))
      .forEach(topic => {
        iotUnsubscribeTopics([topic])
        currentSubscriptionsRef.current.delete(topic)
      })
  }, [subscriptionItems, shouldEnable])

  // ===========================================
  // Listen for price changes, update selections
  // ===========================================
  React.useEffect(() => {
    if (!shouldEnable) return

    const fobPriceChangedSubscription = event$
      .filter(e => isPriceChangeEvent(e.payload))
      .subscribe(e => {
        if (workflowStatusRef.current !== 'ready' || !currentSubscriptionsRef.current.has(e.topic))
          return

        if (isFobPriceChangedPushEvent(e.payload)) {
          store.dispatch(updateBetSlipFobRacingItemPrice(e.payload))
        }

        if (isSportsPriceChangedPushEvent(e.payload)) {
          store.dispatch(updateBetSlipSportsItemPrice(e.payload))
        }
      })

    return () => {
      fobPriceChangedSubscription.dispose()
    }
  }, [shouldEnable])
}

// =============
// Local Helpers
// =============

function getSubscriptionItemTopic(item: FobPriceSubscriptionItem) {
  if (item.type === 'sports') {
    const { marketId, outcomeId, marketTypeCode } = item.details
    return getFobSportsPriceChangeTopic(marketId, outcomeId, marketTypeCode)
  }
  return getFobPriceChangeByPropositionTopic(item.details.id)
}

function isPriceChangeEvent(event: PushEvent): event is PriceChangeEvent {
  return isFobPriceChangedPushEvent(event) || isSportsPriceChangedPushEvent(event)
}

// =====
// Types
// =====

type FobPriceSubscriptionItem =
  | { type: 'racing'; details: { id: string } }
  | { type: 'sports'; details: { marketId: number; marketTypeCode: string; outcomeId: number } }

type PriceChangeEvent = FobPriceChangedPushEvent | SportsPriceChangedPushEvent
