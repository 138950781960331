import React, { MouseEvent, ReactChild, TouchEvent } from 'react'
import { Link } from 'react-router-dom'
import { Icon, type IconProps } from '@mobi/component-library/Common/Icon'
import { KAMBI_HOME_HASH_ROUTE } from '@core/Areas/Kambi/constants'
import { StructuredSearchRoute } from '@core/Areas/StructuredSearch/constants'
import { AppRoutes } from '@core/AppRoutes'
import { HomeNavigationContainerStyled } from './HomeNavigation.styles'
import { trackTopNavigationClicked } from '../../analytics'
import { useIsFobSportsFeatureActive } from '@core/Areas/Sports/hooks/useIsFobSportsFeatureActive'

type NavigationLinkProps = {
  to: string
  icon: IconProps['type']
  iconSize: IconProps['size']
  children: ReactChild
  testId: string
  onClick?: (event: MouseEvent | TouchEvent) => void
}

const NavigationLink: React.FC<NavigationLinkProps> = ({
  icon,
  iconSize,
  testId,
  children,
  to,
  onClick,
}) => {
  const testAttributes = {
    [`data-tid-${testId}`]: true,
  }

  return (
    <Link to={to} onClick={onClick} {...testAttributes}>
      <div>
        <Icon size={iconSize} type={icon} />
      </div>
      <span>{children}</span>
    </Link>
  )
}

export const HomeNavigation: React.FC = () => {
  const isNewSportsFeatureActive = useIsFobSportsFeatureActive()

  return (
    <HomeNavigationContainerStyled>
      <div>
        <NavigationLink
          to='#tote?code=races'
          icon='races'
          iconSize='5rem'
          testId='home-races'
          onClick={onNavButtonClick}
        >
          Races
        </NavigationLink>

        <NavigationLink
          to='#tote?code=trots'
          icon='trots'
          iconSize='5rem'
          testId='home-trots'
          onClick={onNavButtonClick}
        >
          Trots
        </NavigationLink>

        <NavigationLink
          to='#tote?code=dogs'
          icon='dogs'
          iconSize='5rem'
          testId='home-dogs'
          onClick={onNavButtonClick}
        >
          Dogs
        </NavigationLink>

        <NavigationLink
          to={isNewSportsFeatureActive ? AppRoutes.Sports : KAMBI_HOME_HASH_ROUTE}
          icon='sports'
          iconSize='5rem'
          testId='home-sports'
          onClick={onNavButtonClick}
        >
          Sports
        </NavigationLink>
      </div>

      <div>
        <Link to='#next-events' data-tid-home-next-events>
          <div>
            <Icon size='3rem' type='arrowright' />
          </div>
          <span>
            Next <br />
            Races
          </span>
        </Link>

        <Link to='#jackpots' data-tid-home-jackpots>
          <div>
            <Icon size='3rem' type='jackpot' />
          </div>
          <span>Jackpots</span>
        </Link>

        <Link to='/#tote/mystery' data-tid-home-mystery-bets>
          <div>
            <Icon size='3rem' type='mystery' />
          </div>
          <span>
            Mystery <br />
            Bet
          </span>
        </Link>

        <Link to='#goodthings' data-tid-good-things>
          <div>
            <Icon size='3rem' type='giddyup' />
          </div>
          <span>
            Good <br />
            Things
          </span>
        </Link>

        <Link to={`/#${StructuredSearchRoute}`} data-tid-structured-search-tile-btn>
          <div>
            <Icon size='3rem' type='racesall' />
          </div>
          <span>
            Smart <br />
            Form
          </span>
        </Link>

        <Link to='#favourite-numbers' data-tid-home-favourite-numbers>
          <div>
            <Icon size='3rem' type='favouriteNumbers' />
          </div>
          <span>
            Favourite <br />
            Numbers
          </span>
        </Link>
      </div>
    </HomeNavigationContainerStyled>
  )
}

// Helpers
function onNavButtonClick(e: MouseEvent | TouchEvent) {
  const text = (e.currentTarget.textContent ?? '').trim()

  if (text) {
    trackTopNavigationClicked(text)
  }
}
