import React from 'react'
import styled from '@emotion/styled'
import { colors, font, spacing } from '@mobi/component-library/Theme/Common'
import { LinkWrapper } from '../Common/LinkWrapper'
import type { LinkInfo } from '../../types'

export const EventDetails: React.FC<{
  heading: React.ReactNode
  subHeading: string
  iconComponent: React.ReactNode
  statusComponent: React.ReactNode
  linkInfoPrimary?: LinkInfo
  linkInfoSecondary?: LinkInfo
}> = ({
  heading,
  subHeading,
  statusComponent,
  iconComponent,
  linkInfoPrimary,
  linkInfoSecondary,
}) => {
  return (
    <WrapperStyled>
      <div>
        <span>{iconComponent}</span>

        <span>
          <LinkWrapper linkInfo={linkInfoPrimary}>
            <span>{heading}</span>
          </LinkWrapper>{' '}
          {subHeading && (
            <>
              • <LinkWrapper linkInfo={linkInfoSecondary}>{subHeading}</LinkWrapper>
            </>
          )}
        </span>
      </div>

      <div>{statusComponent}</div>
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: spacing.smx2,
  fontSize: font.size.md.fontSize,
  fontWeight: font.weight.regular,
  letterSpacing: font.size.md.letterSpacing,
  lineHeight: font.size.md.lineHeight,
  color: colors.neutral[800],

  '> div:first-of-type': {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.smx1,

    span: { display: 'flex', alignItems: 'center', gap: spacing.smx2 },

    '> span:first-of-type': { flexShrink: 0 },
  },

  '> div:last-of-type': {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.smx2,
  },
})
