export const checkIfIsInIPad = () => {
  const ua = navigator.userAgent || navigator.vendor

  // Check explicitly for "iPad" in the user agent for older iPads
  if (/iPad/.test(ua)) {
    return true
  }

  // Handle newer iPads running iOS 13+ (identifies as "Macintosh")
  if (
    /Macintosh/.test(ua) && // Looks like a Mac
    'ontouchend' in document // Has touch support
  ) {
    return true // Likely an iPad running iOS 13+
  }

  return false
}
