import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { AppRoutes } from '@core/AppRoutes'
import { DepositLimits } from './Components/DepositLimits/DepositLimits'

export const DepositLimitsEditPage = () => {
  const history = useHistory()
  const { depositLimitId } = useParams<{ depositLimitId: string }>()

  const accountHolderNumberPattern = /\d+$/
  const accountHolderNumber = accountHolderNumberPattern.test(depositLimitId)
    ? Number(depositLimitId)
    : undefined

  if (!accountHolderNumber) {
    history.push(AppRoutes.Home)
  }

  return <DepositLimits selectedAccountHolderNumber={accountHolderNumber} mode='edit' />
}
