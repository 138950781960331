import React from 'react'
import styled from '@emotion/styled'
import cookies from 'js-cookie'

const COOKIE_NAME = 'webapp-version'

export const InternalVersionNumber = ({
  version,
  onClick,
}: {
  version: string
  onClick?: VoidFunction
}) => {
  const onRevertVersion = () => {
    cookies.remove(COOKIE_NAME)
    window.location.reload()
  }
  return (
    <>
      {cookies.get(COOKIE_NAME) ? (
        <>
          <InternalVersionNumberStyled
            style={{
              borderRadius: '1rem 0 0 1rem',
            }}
            onClick={onClick}
          >
            Version {version}
          </InternalVersionNumberStyled>{' '}
          <InternalVersionNumberStyled
            style={{
              borderRadius: '0 1rem 1rem 0',
            }}
            onClick={onRevertVersion}
          >
            &times; Revert
          </InternalVersionNumberStyled>
        </>
      ) : (
        <InternalVersionNumberStyled onClick={onClick}>
          Version {version}
        </InternalVersionNumberStyled>
      )}
    </>
  )
}

const InternalVersionNumberStyled = styled.span({
  display: 'inline-block',
  backgroundColor: 'black',
  color: 'white',
  margin: '0.2rem 0',
  borderRadius: '1rem',
  padding: '0.6rem',
})
