import React from 'react'
import type { BetSlipItem } from '@mobi/betslip/types'
import {
  isFobDetails,
  isFobSportsDetails,
  isRaceDetails,
  isFobSportsSelection,
} from '@mobi/betslip/helpers/typeGuards'
import { FobBetInfo, RaceBetInfo, SportBetInfo } from './Components'
import type { BetInfoDisplayType } from './types'

export const BetInfo: React.FC<{
  item: BetSlipItem
  isMultiItem?: boolean
  /** Used primarily for multi grouping @default 'full' */
  displayMode?: BetInfoDisplayType
}> = ({ item, isMultiItem, displayMode = 'full' }) => {
  if (isFobDetails(item.selectionDetails)) {
    return <FobBetInfo item={item} isMultiItem={isMultiItem} displayMode={displayMode} />
  }

  if (isRaceDetails(item.selectionDetails)) {
    return <RaceBetInfo item={item} isMultiItem={isMultiItem} displayMode={displayMode} />
  }

  if (isFobSportsDetails(item.selectionDetails) && isFobSportsSelection(item.selection)) {
    return <SportBetInfo item={item} isMultiItem={isMultiItem} displayMode={displayMode} />
  }

  return null
}
