import type { State } from 'rwwa-data-access'
import { createSignal, attachDriver, Signal } from 'rwwa-rx-state-machine'
import { TypedRecord, makeTypedFactory } from 'typed-immutable-record'
import {
  ReverificationDetails,
  ReverificationDetailKey,
  ReverificationDetailRecord,
  confirmAllReverificationDetails,
} from '@core/Data/Account/reverificationDetails'
import { HasLoggedIn, HasLoggedOut } from '@core/State/UserAccount/userAccountDriver'
import { isHomeUrl } from '@classic/Foundation/Navigation/Nav'
import { getFromLocalStorage, setInLocalStorage } from '@mobi/utils/storage'
import { NavigatedTo } from '@core/State/Navigation/driver'

export interface ReverificationState {
  betAccountHolderNumber: number | null
  isFeatureActive: boolean
  numDaysRemaining: number | null
  requiresReverification: boolean
  reverificationDue: boolean
  hasAttemptedReverification: boolean
  onHomePage: boolean
  userInitiatedReverification: boolean
  remindMeLater: boolean
  isLoading: boolean
  isReverificationSuccess: boolean
  isReverificationFailed: boolean
  isReverificationCompleted: boolean
}

export const defaultReverificationState: ReverificationState = {
  betAccountHolderNumber: null,
  isFeatureActive: true,
  numDaysRemaining: null,
  requiresReverification: false,
  reverificationDue: false,
  hasAttemptedReverification: false,
  onHomePage: false,
  userInitiatedReverification: false,
  remindMeLater: false,
  isLoading: false,
  isReverificationSuccess: false,
  isReverificationFailed: false,
  isReverificationCompleted: false,
}

export const localStorageRemindMeLaterKeyPart = 'tabtouch-reverification-remindmelater-dateiso'

export const GetReverificationDetailDidSucceed = createSignal<ReverificationDetailRecord>(
  'GetReverificationDetailDidSucceed'
)
const GetReverificationDetailDidFail = createSignal('GetReverificationDetailDidFail')
export const ShowReverificationPopup = createSignal('ShowReverificationPopup')
export const ConfirmReverificationDetails = createSignal<{ betAccountHolderNumber: number }>(
  'ConfirmReverificationDetails'
)
const ReverificationSucceeded = createSignal('ReverificationSucceeded')
const ReverificationFailed = createSignal('ReverificationFailed')
export const ReverificationCompleted = createSignal('ReverificationCompleted')
export const RemindMeLater = createSignal('RemindMeLater')
export const ReinitialiseReverificationDetails = createSignal('ReinitialiseReverificationDetails')

export interface ReverificationStateRecord
  extends TypedRecord<ReverificationStateRecord>,
    ReverificationState {}
const ReverificationStateFactory = makeTypedFactory<ReverificationState, ReverificationStateRecord>(
  defaultReverificationState
)

export function reverificationDriver(
  state = ReverificationStateFactory(),
  signal: Signal
): ReverificationStateRecord {
  switch (signal.tag) {
    case HasLoggedIn: {
      reinitialise()
      return state
    }

    case HasLoggedOut: {
      state = ReverificationStateFactory()
      return state
    }

    case ReinitialiseReverificationDetails: {
      if (state.get('isFeatureActive')) {
        reinitialise()
      }
      // reset betAccountHolderNumber so that we don't show popup for the primary when the secondary should be displayed
      return state.set('betAccountHolderNumber', null)
    }

    case NavigatedTo: {
      const onHomePage = isHomeUrl(signal.data.url)
      if (!onHomePage) {
        return state.merge({
          onHomePage,
        })
      }

      // if reverification is due, then clear remind me later on every navigation
      if (state.get('reverificationDue')) {
        return state.merge({
          onHomePage,
          remindMeLater: false,
        })
      }

      const remindMeLaterLastSetDate = getRemindMeLaterLastSetDate(state)
      if (remindMeLaterLastSetDate === null) {
        // remind me later is not stored on the device, so fall back to the one we're tracking locally
        return state.merge({
          onHomePage,
        })
      }

      const remindMeLater = shouldRemindMeLater(remindMeLaterLastSetDate)
      return state.merge({
        onHomePage,
        remindMeLater,
      })
    }

    case GetReverificationDetailDidSucceed: {
      const data: ReverificationDetailRecord = signal.data

      let newState = state.merge(data)

      // For joint account holders, resetting the values after primary is complete
      if (newState.get('isReverificationCompleted') && newState.get('requiresReverification')) {
        newState = newState.merge({
          isReverificationCompleted: false,
          isReverificationSuccess: false,
          isReverificationFailed: false,
        })
      }

      if (data.reverificationDue) {
        return newState.set('remindMeLater', false)
      }
      const remindMeLaterLastSetDate = getRemindMeLaterLastSetDate(newState)
      const remindMeLater = shouldRemindMeLater(remindMeLaterLastSetDate)
      return newState.set('remindMeLater', remindMeLater)
    }

    case GetReverificationDetailDidFail: {
      return state
    }

    case ShowReverificationPopup: {
      return state.merge({
        userInitiatedReverification: true,
        // for joint bet account holders, resetting values
        isReverificationCompleted: false,
        isReverificationFailed: false,
      })
    }

    case ConfirmReverificationDetails: {
      if (state.get('isLoading')) {
        return state
      }

      const betAccountHolderNumber = signal.data

      confirmAllReverificationDetails(betAccountHolderNumber)
        .then(({ isReverificationSuccess }) => {
          if (isReverificationSuccess) {
            ReverificationSucceeded()
          } else {
            ReverificationFailed()
          }
        })
        .catch(ReverificationFailed)

      return state.merge({
        isLoading: true,
      })
    }

    case ReverificationSucceeded: {
      return state.merge({
        isReverificationSuccess: true,
        isLoading: false,
      })
    }

    case ReverificationFailed: {
      return state.merge({
        isLoading: false,
        isReverificationFailed: true,
      })
    }

    case ReverificationCompleted: {
      // not re-intialising when there is a failure in re-verification
      if (!state.get('isReverificationFailed')) {
        reinitialise()
      }

      return state.merge({
        isReverificationCompleted: true,
      })
    }

    case RemindMeLater: {
      const dateIso = new Date().toISOString().substring(0, 10)
      const localStorageRemindMeLaterKey = getRemindMeLaterKey(state)
      setInLocalStorage(localStorageRemindMeLaterKey, dateIso)

      return state.merge({
        userInitiatedReverification: false,
        remindMeLater: true,
      })
    }

    default: {
      return state
    }
  }
}

function reinitialise() {
  ReverificationDetails.hardInvalidate(
    null as unknown as State<ReverificationDetailRecord>,
    ReverificationDetailKey
  )
  beginGetReverificationDetail()
}

function beginGetReverificationDetail() {
  ReverificationDetails.getPromise(ReverificationDetailKey)
    .then(reverificationDetail => GetReverificationDetailDidSucceed(reverificationDetail))
    .catch(() => GetReverificationDetailDidFail())
}

function getRemindMeLaterKey(state: ReverificationStateRecord): string {
  return `${localStorageRemindMeLaterKeyPart}-${state.get('betAccountHolderNumber')}`
}

function getRemindMeLaterLastSetDate(state: ReverificationStateRecord): string {
  const localStorageRemindMeLaterKey = getRemindMeLaterKey(state)
  return getFromLocalStorage(localStorageRemindMeLaterKey) as unknown as string
}

function shouldRemindMeLater(remindMeLaterLastSetDate: string): boolean {
  // if the stored date is today, then we don't show them, otherwise we do
  const nowIso = new Date().toISOString()
  return nowIso.indexOf(remindMeLaterLastSetDate) === 0
}

export const state$ = attachDriver<ReverificationStateRecord>({
  path: 'reverification',
  driver: reverificationDriver,
})
