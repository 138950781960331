import { fromJS } from 'immutable'
import { createSignal, attachDriver, Signal } from 'rwwa-rx-state-machine'
import { StateMap } from 'typings/immutable'
import { isHomeUrl } from '@classic/Foundation/Navigation/Nav'
import { HashRoute, RouteStack } from '.'
import { isRaceRoute } from './helpers/isRaceRoute'

const enum LocalConstants {
  meetingsHashRoute = '#tote',
}

interface RouteState {
  currentUrl: HashRoute
  routeStack: RouteStack
  backUrl: HashRoute
  lastRacesPath: HashRoute
}

export const defaultState: RouteState = {
  currentUrl: (location.hash as HashRoute) || '#',
  routeStack: ['#'],
  backUrl: '#',
  lastRacesPath: LocalConstants.meetingsHashRoute,
}

/** ONLY used where legacy router (Nav.js) successfully updates route */
export const NavigatedTo = createSignal<{ url: string }>('NavigatedTo')

export type RouteDriverStateMap = StateMap<RouteState>

export function routeDriver(
  state: RouteDriverStateMap = fromJS(defaultState),
  signal: Signal
): RouteDriverStateMap {
  switch (signal.tag) {
    case NavigatedTo: {
      const payloadUrl = signal.data.url.replace('#', '') as string
      const isHome = isHomeUrl(payloadUrl)
      const newRoute = isHome ? '#' : (`#${payloadUrl}` as const)

      const currentStack = state.get('routeStack').toJS()
      const lastPath = currentStack[currentStack.length - 1]

      if (newRoute === lastPath) return state

      const newStack = getNewStack(newRoute, currentStack)
      const currentUrl = newStack[newStack.length - 1]

      const lastRacesPath = getRacePathValues({
        currentUrl,
        lastRacesPath: state.get('lastRacesPath'),
      })

      return state.merge({
        currentUrl,
        routeStack: newStack,
        backUrl: getBackUrlFromStack(newStack),
        lastRacesPath,
      })
    }

    default:
      return state
  }
}

export const state$ = attachDriver<RouteDriverStateMap>({
  path: 'routeDriver',
  driver: routeDriver,
})

// Local Helpers

function getRacePathValues({
  lastRacesPath,
  currentUrl,
}: Pick<RouteState, 'lastRacesPath' | 'currentUrl'>): HashRoute {
  const cleanedCurrentUrlPath = currentUrl.replace('#', '/').split('?')[0]
  const isCurrentUrlRacePath = isRaceRoute(cleanedCurrentUrlPath)
  const isCurrentUrlMeetingsPath = isCurrentUrlRacePath && cleanedCurrentUrlPath === '/tote'

  const newLastRacesPath = isCurrentUrlRacePath
    ? isCurrentUrlMeetingsPath
      ? LocalConstants.meetingsHashRoute
      : currentUrl
    : lastRacesPath

  return newLastRacesPath
}

/** Creates new stack, allowing registered middleware to modify - for use ONLY in driver */
function getNewStack(newRoute: HashRoute, currentStack: RouteStack): RouteStack {
  // Reset stack if home
  if (newRoute === '#') return ['#']

  // Remove last route when user navigates back
  if (newRoute === getBackUrlFromStack(currentStack) && currentStack.length > 1) {
    return currentStack.slice(0, -1) as RouteStack
  }

  // Add new route to stack
  return [...currentStack, newRoute]
}

function getBackUrlFromStack(stack: RouteStack): HashRoute {
  return stack[stack.length - 2] || '#'
}
