import { trackEvent } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { keys as analyticsKeys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'

interface FeatureItemAnalyticsData {
  featureItemTitle: string
  featureItemPosition: number
  accountNumber: number | null
  internalTrackingCode: string | null
  clickUrl: string
}

const mapBannerDataToEcommerceItems = (data: FeatureItemAnalyticsData) => {
  const itemBrand = data.clickUrl.includes('tabtouch-sports')
    ? 'pre'
    : data.clickUrl.includes('races')
      ? 'Racing'
      : undefined
  const itemIdMatch = data.clickUrl.match(/\/event\/(\d+)$/)
  const itemId = itemIdMatch ? itemIdMatch[1] : undefined

  return {
    promotion_name: data.featureItemTitle,
    internalTrackingCode: data.internalTrackingCode,
    item_id: itemId,
    index: data.featureItemPosition,
    item_brand: itemBrand,
    item_variant: 'Feature Tile',
  }
}

export const trackFeatureItemClicked = (data?: FeatureItemAnalyticsData) => {
  if (!data) return
  const newData = mapBannerDataToEcommerceItems(data)
  trackEvent(analyticsKeys.featureItemClicked, {
    ecommerce: {
      items: [newData],
    },
  })
}

export const trackFeaturedBannerViewed = (data?: FeatureItemAnalyticsData) => {
  if (!data) return
  const newData = mapBannerDataToEcommerceItems(data)
  trackEvent(analyticsKeys.featureItemBannerViewed, {
    ecommerce: {
      items: [newData],
    },
  })
}
