import React from 'react'
import styled from '@emotion/styled'
import { useDispatch } from 'react-redux'
import { Button } from '@mobi/component-library/Common/V2'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import { colors, font, spacing, radius, shadow } from '@mobi/component-library/Theme/Common'
import { removeToast } from '@mobi/component-library/Feedback/Toast'
import { useRenderTimeoutControl } from '@mobi/utils/hooks/useRenderTimeoutControl'
import type { BetSlipItem } from '@mobi/betslip/types'
import { useAppSelector } from '@mobi/betslip/Store/hooks'
import { selectBetSlipItems } from '@mobi/betslip/Store/Bets/selectors'
import { getHostHelpers } from '@mobi/betslip/helpers/hostHelpers'
import { getBetsInMulti, getMultiLegEventOrRaceKey } from '@mobi/betslip/helpers/state'
import { calculateMultiReturn } from '@mobi/betslip/helpers/calculator/multis'
import { setDesiredBettingSection } from '@mobi/betslip/Store/Workflow'

const enum LocalConstants {
  BackdropClass = 'js-toast-backdrop',
  BackdropAnimateOutClass = 'js-toast-backdrop--out',
  ToastContainerClass = 'js-multi-toast',
}

export const MULTI_BUILDER_TOAST_ID = 'multi-builder-toast'

export const MultiBuilderToastContent: React.FC<{ item: BetSlipItem }> = ({ item }) => {
  const dispatch = useDispatch()
  const hostHelpers = getHostHelpers()
  const items = useAppSelector(selectBetSlipItems)

  const newItemKey = getMultiLegEventOrRaceKey(item.selectionDetails)
  const hasIncompatibleLeg = items.some(
    currItem =>
      currItem.id !== item.id && newItemKey === getMultiLegEventOrRaceKey(currItem.selectionDetails)
  )

  const shouldRenderIncompatibleLegBanner = useRenderTimeoutControl({
    timeoutMs: 300,
    shouldRender: hasIncompatibleLeg,
  })

  const multiItems = getBetsInMulti(items)
  const multiReturn = calculateMultiReturn(multiItems, { shouldRound: true })

  return (
    <WrapperStyled>
      {shouldRenderIncompatibleLegBanner && (
        <div
          className={`${LocalConstants.BackdropClass} ${hasIncompatibleLeg ? '' : LocalConstants.BackdropAnimateOutClass}`}
        >
          <div>
            <Icon name='DuocolorCheckCircle' size='1.6rem' color='inherit' />
            <span>Added as a single due to incompatible legs</span>
          </div>
        </div>
      )}

      <div className={LocalConstants.ToastContainerClass}>
        <div>
          <div>
            <span>{multiItems.length}</span>
          </div>

          <div>
            <span>Multi</span>
            <span>{multiReturn}</span>
          </div>
        </div>

        <Button
          color='primary'
          size='xs'
          onClick={() => {
            hostHelpers.toggleBetSlipDrawer?.(true)
            removeToast({ id: MULTI_BUILDER_TOAST_ID })
            dispatch(setDesiredBettingSection('Multi'))
          }}
        >
          Bet Now
        </Button>
      </div>
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  fontFamily: font.family.primary,

  [`.${LocalConstants.BackdropClass}`]: {
    position: 'absolute',
    bottom: '100%',
    left: 0,
    right: 0,
    zIndex: 1,
    display: 'flex',
    alignItems: 'flex-start',
    gap: spacing.smx1,
    padding: `0 ${spacing.md} ${spacing.bigx2}`,
    color: colors.white,
    backgroundColor: colors.mariner[500],
    borderRadius: radius.lgx2,
    transform: `translateY(${spacing.bigx2})`,
    animation: 'bounce-up 0.3s ease forwards',

    [`&.${LocalConstants.BackdropAnimateOutClass}`]: {
      animation: 'fade-down 0.3s ease forwards',
    },

    '@keyframes bounce-up': {
      '0%': { transform: 'translateY(100%)' },
      '60%': { transform: 'translateY(35%)' },
      '100%': { transform: `translateY(${spacing.bigx2})` },
    },

    '@keyframes fade-down': {
      '0%': { transform: 'translateY(40%)' },
      '100%': { transform: 'translateY(100%)', opacity: 0 },
    },

    '> div': {
      display: 'flex',
      alignItems: 'center',
      gap: spacing.smx1,
      padding: '0.6rem 0',
      fontSize: font.size.md.fontSize,
      letterSpacing: font.size.md.letterSpacing,
      lineHeight: font.size.md.lineHeight,
      fontWeight: font.weight.regular,
      color: colors.lavender[50],
      textOverflow: 'ellipsis',
    },
  },

  [`.${LocalConstants.ToastContainerClass}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: spacing.smx1,
    position: 'relative',
    zIndex: 2,
    width: '100%',
    padding: `${spacing.sm} ${spacing.sm}`,
    borderRadius: radius.lgx2,
    boxShadow: shadow.md,
    fontSize: font.size.lg.fontSize,
    letterSpacing: font.size.lg.letterSpacing,
    lineHeight: font.size.lg.lineHeight,
    border: `1px solid ${colors.studio[800]}`,
    backgroundColor: colors.studio[900],
    color: colors.white,

    '> div': {
      display: 'flex',
      gap: spacing.smx1,

      // Badge Count
      '> div:nth-of-type(1)': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '2.4rem',
        width: '2.4rem',
        backgroundColor: colors.white,
        color: colors.studio[900],
        borderRadius: '100%',

        '> span': {
          fontWeight: font.weight.semibold,
          fontSize: font.size.xl.fontSize,
          lineHeight: font.size.xl.lineHeight,
          letterSpacing: font.size.xl.letterSpacing,
        },
      },

      // Text
      '> div:nth-of-type(2)': {
        display: 'flex',
        gap: spacing.smx2,
        alignItems: 'center',
        fontWeight: font.weight.semibold,
        fontSize: font.size.lg.fontSize,

        '> span:nth-of-type(2)': {
          fontWeight: font.weight.regular,
        },
      },
    },
  },
})
