import type { MultiInvestmentKey } from '@mobi/betslip/types'

export const MIN_LEGS_IN_MULTI = 2

export const MAX_LEGS_IN_RACE_MULTI = 10
export const MAX_LEGS_IN_SPORT_MULTI = 16

export const MAX_LEGS_FOR_MULTI_FORMULA = 6

export const MULTI_FORMULA_COMBINATIONS: Readonly<
  Record<MultiInvestmentKey, Record<`${number}`, number>>
> = {
  f1: { '2': 2, '3': 3, '4': 4, '5': 5, '6': 6 },
  f2: { '3': 3, '4': 6, '5': 10, '6': 15 },
  f3: { '4': 4, '5': 10, '6': 20 },
  f4: { '5': 5, '6': 15 },
  f5: { '6': 6 },
}

export const MIN_INVESTMENT_VALUE = 0.5
export const MAX_INVESTMENT_VALUE = 99_999.99
