import React from 'react'
import { useExpandToggle } from '@mobi/utils/hooks/useExpandToggle'
import type { BlackbookEntryRacingToday } from '@core/Areas/Blackbook/types'
import { trackBlackbookEntryToggled } from '@core/Areas/Blackbook/analytics'
import { useIncomingIndicator } from '@core/Areas/Blackbook/Hooks/useIncomingIndicator'
import { RunnerCardStyled, RunningSoonHeader as Header, BlackbookEntryDetails } from '../Common'

export const RunningSoon: React.FC<{
  runner: BlackbookEntryRacingToday
  incomingIdRef: React.MutableRefObject<string | undefined>
  shouldAutoAddToBetslip: boolean
  isSelected: boolean
}> = ({ runner, incomingIdRef, shouldAutoAddToBetslip, isSelected }) => {
  const { wrapperRef, contentRef, shouldRender, isExpanded, toggleExpanded } = useExpandToggle({
    timeoutMs: 400,
  })

  const incomingElRef = useIncomingIndicator(incomingIdRef, runner.Id)

  return (
    <RunnerCardStyled data-testid='blackbook-runner-racing-soon' ref={incomingElRef}>
      <Header
        runner={runner}
        onClick={() => {
          trackBlackbookEntryToggled(!isExpanded)
          toggleExpanded()
        }}
        isExpanded={isExpanded}
        shouldAutoAddToBetslip={shouldAutoAddToBetslip}
        isSelected={isSelected}
      />

      {(isExpanded || shouldRender) && (
        <BlackbookEntryDetails
          isJockey={false}
          id={runner.Id}
          type={runner.Code}
          name={runner.StarterName}
          hasComments={!!runner.LatestNoteSeq}
          wrapperRef={wrapperRef}
          contentRef={contentRef}
        />
      )}
    </RunnerCardStyled>
  )
}
