import React from 'react'
import { BetslipButton as BetslipButtonLegacy } from './Components/BetslipButton/BetslipButton'
import { BetslipButtonNew } from './Components/BetslipButtonNew'
import { useIsNewBetSlipFeatureActive } from '@core/Areas/BetSlipNew/hooks/useIsNewBetSlipFeatureActive'

export const BetslipButton: React.FC = () => {
  const isNewBetSlipFeatureActive = useIsNewBetSlipFeatureActive()

  return isNewBetSlipFeatureActive ? <BetslipButtonNew /> : <BetslipButtonLegacy />
}
