import { FeatureFlags } from '@mobi/settings'
import React, { type ReactNode, createContext, useContext } from 'react'
import { DepositFlow } from '../../Utils/types'

/**
 * Callbacks allowing the hosted component to report events to the host
 */
export type HostEventApi = {
  onDepositSuccess: (flow: DepositFlow, amount?: number) => void
  findTab: () => void
}

export type HostFeaturesApi = {
  hasFeature: (featureName: keyof typeof FeatureFlags | (string & {})) => Promise<boolean>
}

export type Channel = 'mobile' | 'desktop'

/**
 * HostApi serves two purposes:
 *
 * 1) Provide capabilities to the hosted component, i.e. navigation, feature toggles, analytics tracking
 * 2) Allow the hosted component to communicate with the host, e.g. notify the host when an event occurs
 *
 * The host should communicate state changes to the hosted component via props.
 *
 * Implementation to be provided by the channel host, i.e. Mobi, Desktop, other
 */
export type HostApi = {
  features?: HostFeaturesApi
  events?: HostEventApi
  channel: Channel
}

const HostContext = createContext<HostApi>({ channel: 'mobile' })

export const useHostContext = (): HostApi => {
  return useContext(HostContext)
}

type Props = {
  api: HostApi
  children?: ReactNode
}

export const HostProvider = ({ api, children }: Props): JSX.Element => {
  return <HostContext.Provider value={api}>{children}</HostContext.Provider>
}
