import React from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '@mobi/betslip/Store/hooks'
import { setDesiredBettingSection, type BettingSection } from '@mobi/betslip/Store/Workflow'

export const useListenForDesiredBettingSection = ({
  scrollToSingles,
  scrollToMulti,
}: ScrollToHandlers) => {
  const dispatch = useDispatch()
  const desiredBettingSection = useAppSelector(
    state => state.betslip.workflow.desiredBettingSection
  )

  React.useEffect(() => {
    if (!desiredBettingSection) return
    if (desiredBettingSection === 'Multi') scrollToMulti()
    if (desiredBettingSection === 'Singles') scrollToSingles()
    dispatch(setDesiredBettingSection(null))
  }, [desiredBettingSection, scrollToMulti, scrollToSingles, dispatch])
}

type ScrollToHandlers = Record<`scrollTo${BettingSection}`, () => void>
