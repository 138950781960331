import React from 'react'
import dayjs from 'dayjs'
import { Observable } from 'rx'
import { isLoading, isErrored } from 'rwwa-data-access'
import { navigateToDepositLimitsEdit } from '@classic/AppUtils/Framework/Intent/navigation'
import type { BetAccount, BamAccountHolder } from '@mobi/api-types'
import { BetAccountRepo, BetAccountKey } from '@core/Data/Account/betAccount'
import {
  updateDepositLimitAnniversary,
  UpdateDepositLimitAnniversaryRequest,
} from '@core/Data/Account/accountHolder'
import { AnniversaryPopupContent } from '../AnniversaryPopupContent/AnniversaryPopupContent'
import {
  ErrorPopupNoticeBox,
  SuccessPopupNoticeBox,
} from '../DepositLimitsComponents/DepositLimitsComponents'
import { PopupContainerStyled, PopupTitleStyled } from '@core/Components/Popup/Popup.styles'
import { AnniversaryPopup } from './DepositLimitsAnniversaryPopup.styles'
import { TABLocation } from '@core/Settings/constants'

import { state$ as UserAccountState$ } from '@core/State/UserAccount/userAccountDriver'
import { state$ as RouteState$ } from '@core/State/Navigation'

const DAYS_BEFORE_ANNIVERSARY_REMINDER = 14

export interface DepositLimitsAnniversaryPopupProps {
  isDesktop?: boolean
}

export enum DisplayState {
  HideAll,
  Prompt,
  Success,
  Error,
}

export interface DepositLimitsAnniversaryPopupState {
  displayState: DisplayState
  betAccountNumber: number | null
  accountHolder: BamAccountHolder | null
}

export function DepositLimitsAnniversaryPopup(props: DepositLimitsAnniversaryPopupProps) {
  const { isDesktop } = props

  return <DepositLimitsAnniversaryPopupComponent isDesktop={isDesktop} />
}

export class DepositLimitsAnniversaryPopupComponent extends React.Component<
  DepositLimitsAnniversaryPopupProps,
  DepositLimitsAnniversaryPopupState
> {
  private subscription?: Rx.Disposable

  constructor(props: DepositLimitsAnniversaryPopupProps) {
    super(props)
    this.state = {
      displayState: DisplayState.HideAll,
      betAccountNumber: null,
      accountHolder: null,
    }
  }

  public componentDidMount() {
    const allState = Observable.combineLatest(
      BetAccountRepo.data$,
      UserAccountState$,
      RouteState$,
      (betAccountRepoState, userAccountState, routeState) => ({
        betAccountRepoState,
        userAccountState,
        routeState,
      })
    ).distinctUntilChanged(
      true,
      (x, y) =>
        Object.is(x.betAccountRepoState, y.betAccountRepoState) &&
        Object.is(x.routeState, y.routeState) &&
        Object.is(x.userAccountState, y.userAccountState)
    )

    const setHideAll = () =>
      this.setState({
        displayState: DisplayState.HideAll,
        betAccountNumber: null,
        accountHolder: null,
      })

    this.subscription = allState.subscribe(s => {
      if (!s.userAccountState.isLoggedIn) {
        BetAccountRepo.hardInvalidate(s.betAccountRepoState, BetAccountKey)
        setHideAll()
      } else {
        const betAccountLoader = BetAccountRepo.getFrom(s.betAccountRepoState, BetAccountKey)

        // conscious decision to not display error or loading as we render on the home page.
        if (isLoading(betAccountLoader) || isErrored(betAccountLoader)) {
          setHideAll()
          return
        }

        const betAccount = betAccountLoader as BetAccount

        if (betAccount.AccountHolderList.length === 0) {
          setHideAll()
          return
        }

        this.setState({ betAccountNumber: betAccount.BetAccountNumber })

        const getActiveAccountHolder = (accountHolders: BamAccountHolder[]) => {
          const isInDateRange = (anniversary: Date | null) =>
            !!(
              anniversary &&
              dayjs(anniversary).diff(dayjs(), 'days', true) <= DAYS_BEFORE_ANNIVERSARY_REMINDER
            )

          const primaryAccountHolder =
            accountHolders.find(ah => ah.IsPrimaryAccount) || accountHolders[0]
          const primaryAccountHolderIsInDateRange = isInDateRange(
            primaryAccountHolder.PreCommitmentAnniversaryDate
          )
          const secondaryAccountHolder =
            accountHolders.find(ah => !ah.IsPrimaryAccount) || accountHolders.length === 2
              ? accountHolders[1]
              : null
          const secondaryAccountHolderIsInDateRange =
            secondaryAccountHolder &&
            isInDateRange(secondaryAccountHolder.PreCommitmentAnniversaryDate)

          if (primaryAccountHolderIsInDateRange && !secondaryAccountHolderIsInDateRange) {
            return primaryAccountHolder
          }

          if (!primaryAccountHolderIsInDateRange && secondaryAccountHolderIsInDateRange) {
            return secondaryAccountHolder
          }

          if (primaryAccountHolderIsInDateRange && secondaryAccountHolderIsInDateRange) {
            return primaryAccountHolder.PreCommitmentAnniversaryDate &&
              secondaryAccountHolder &&
              secondaryAccountHolder.PreCommitmentAnniversaryDate &&
              primaryAccountHolder.PreCommitmentAnniversaryDate <=
                secondaryAccountHolder.PreCommitmentAnniversaryDate
              ? primaryAccountHolder
              : secondaryAccountHolder
          }

          return null
        }

        const accountHolder = getActiveAccountHolder(betAccount.AccountHolderList)
        this.setState({ accountHolder })

        const isLoggedIn = s.userAccountState.isLoggedIn
        const isOnHomePage = s.routeState.get('currentUrl') === '#'
        const displayState: DisplayState =
          isLoggedIn && isOnHomePage && accountHolder !== null
            ? DisplayState.Prompt
            : DisplayState.HideAll

        this.setState({ displayState })
      }
    })
  }

  public componentWillUnmount() {
    this.subscription?.dispose()
  }

  public render() {
    if (this.state.displayState === DisplayState.HideAll) {
      return null
    }

    if (this.state.displayState === DisplayState.Error) {
      return <ErrorPopupNoticeBox onClose={() => this.closeAll(false)} />
    }

    if (this.state.displayState === DisplayState.Success) {
      return <SuccessPopupNoticeBox onClose={() => this.closeAll(true)} />
    }

    if (this.state.displayState !== DisplayState.Prompt || !this.state.accountHolder) {
      return null
    }

    return (
      <PopupContainerStyled>
        <AnniversaryPopup isDesktop={this.props.isDesktop}>
          <PopupTitleStyled isDesktop={this.props.isDesktop} data-tid-anniversary-deposit-limit=''>
            Deposit Limit
          </PopupTitleStyled>
          <AnniversaryPopupContent
            isDesktop={this.props.isDesktop}
            accountHolder={this.state.accountHolder}
            onKeepLimit={this.keepLimit}
            onChangeLimit={this.changeLimit}
          />
        </AnniversaryPopup>
      </PopupContainerStyled>
    )
  }

  private closeAll = (invalidate: boolean) => {
    if (invalidate) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      BetAccountRepo.hardInvalidate(null as any, BetAccountKey)
    }

    this.setState({ displayState: DisplayState.HideAll })
  }

  private respondToDepositLimitAnniversary = (keep: boolean) => {
    // deal with being invoked before all relevant state is available.
    if (!this.state.betAccountNumber) {
      return Promise.resolve({})
    }

    const request: UpdateDepositLimitAnniversaryRequest = {
      AccountHolderId: (this.state.accountHolder as BamAccountHolder).AccountHolderId,
      BetAccountNumber: this.state.betAccountNumber,
      Keep: keep,
      Location: !this.props.isDesktop ? TABLocation.Mobi : TABLocation.Desktop,
    }

    return updateDepositLimitAnniversary(request)
  }

  private keepLimit = () => {
    this.respondToDepositLimitAnniversary(true)
      .then(() => {
        this.setState({ displayState: DisplayState.Success, betAccountNumber: null })
      })
      .catch(() => {
        this.setState({ displayState: DisplayState.Error })
      })
  }

  private changeLimit = () => {
    this.respondToDepositLimitAnniversary(false)
      .then(() => {
        const accountHolderNumber = this.state.accountHolder
          ? (this.state.accountHolder as BamAccountHolder).AccountHolderId
          : undefined
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        BetAccountRepo.hardInvalidate(null as any, BetAccountKey)
        navigateToDepositLimitsEdit({ isDesktop: this.props.isDesktop, accountHolderNumber })
      })
      .catch(() => {
        this.setState({ displayState: DisplayState.Error })
      })
  }
}
