import { isFobSelection } from '@mobi/betslip/helpers/typeGuards'
import { iotSubscribeTopics, iotUnsubscribeTopics } from '@mobi/utils/awsiot'
import { getFobPriceChangeByPropositionTopic } from '@mobi/utils/awsiot/topics'
import { BetslipItem } from '../driver'

export const startIotSubscription = (item: BetslipItem): BetslipItem => {
  if (item.selection && isFobSelection(item.selection) && !item.hasIotSubscription) {
    iotSubscribeTopics([getFobPriceChangeByPropositionTopic(item.selection.propositionSeq)])
    return { ...item, hasIotSubscription: true }
  }
  return item
}

export const stopIotSubscription = (item: BetslipItem): BetslipItem => {
  if (item.selection && isFobSelection(item.selection) && item.hasIotSubscription) {
    iotUnsubscribeTopics([getFobPriceChangeByPropositionTopic(item.selection.propositionSeq)])
    return { ...item, hasIotSubscription: false }
  }
  return item
}
