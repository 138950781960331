/* eslint-disable @typescript-eslint/no-explicit-any */
import jQuery from 'jquery'
import * as NOTE from '../Notification'
import * as CMN from '../Common'
import dotIcon from './images/blue-dot.png'
import pinShadow from './images/pin-shadow.png'
import sl1Purple from './images/sl1-purple.png'
import sl2Yellow from './images/sl2-yellow.png'
import sl3Red from './images/sl3-red.png'

var getPositionTimeOut: ReturnType<typeof setTimeout>
var geoCodeWatchId: number
var loadingOverlay: JQuery
var currentLocationRequestInProgress: boolean
var canUpdateLocation = true
var newPosition: { coords: { latitude: number; longitude: number } }
var followingGPS = false
var hasGoogleMapsBeenFetched = false

var introNotification: any
var canCentreScreen: boolean

var map: any = null
var googleTabmarkers: any = []
var googleMarkerIcons: any = []

var geocoder: any
var usersGeoLocation: any
var currentGeoLocation: any
var infowindow: any
var iconShadow: any
var sl1CategoryCode = 'SL1'
var sl2CategoryCode = 'SL2'
var sl3CategoryCode = 'SL3'
var currentLocationMarker: any

var markerIconSize: any
var markerAnchor: any
var markerOrigin: any

const slColorDict = {
  ['sl1-purple']: sl1Purple,
  ['sl2-yellow']: sl2Yellow,
  ['sl3-red']: sl3Red,
}

export function initPage() {
  // @ts-expect-error No types
  if (!window.TABLocatorWebInit) {
    // @ts-expect-error No types
    window.TABLocatorWebInit = initLocator
  }

  if (hasGoogleMapsBeenFetched) {
    initLocator()
  } else {
    jQuery.ajax(
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyCS2iWIkf3XzqFKGKm1aQY3EJs5svhKn8U&callback=TABLocatorWebInit',
      { method: 'GET', cache: true, dataType: 'script' }
    )
    hasGoogleMapsBeenFetched = true
  }
}

export function initLocator() {
  loadingOverlay = jQuery('#loading-overlay')
  addHandlers()

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(function (geoLocation) {
      usersGeoLocation = geoLocation

      if (canUpdateLocation) {
        updateLocationOnMap(
          // @ts-expect-error No types
          new google.maps.LatLng(geoLocation.coords.latitude, geoLocation.coords.longitude)
        )
        followingGPS = true
        geoCodeWatchId = navigator.geolocation.watchPosition(handlePositionChange)
        monitorCurrentLocation()
      }
    })

    setTimeout(function () {
      canUpdateLocation = false
    }, 10000)

    loadingOverlay.hide()
  }

  setMapHeight()
  initialize()
  resizeGoogleMap()

  jQuery('#tabtouch-notification, #tablocator-intro-notification').css({
    height: jQuery(document).height(),
  })
}

function setMapHeight() {
  var maxAllowedHeight =
    jQuery(window).height() - jQuery('#tabTypeSelectionContainer').height() - 50
  jQuery('#map_canvas').height(Math.min(600, maxAllowedHeight))
  resizeGoogleMap()
}

function addHandlers() {
  jQuery('#SL1Item').click(function () {
    monitorChangeLocationRequest(function () {
      boxclick('SL1')
    })
  })
  jQuery('#SL2Item').click(function () {
    monitorChangeLocationRequest(function () {
      boxclick('SL2')
    })
  })
  jQuery('#SL3Item').click(function () {
    monitorChangeLocationRequest(function () {
      boxclick('SL3')
    })
  })

  var addressTextBox = jQuery('#address')

  jQuery('#currentLocation').click(function () {
    addressTextBox.val('')
    addressTextBox.blur()
    if (!currentLocationRequestInProgress) {
      currentLocationRequestInProgress = true
      monitorChangeLocationRequest(changeLocationToCurrentLocation)
    }
  })

  var goButton = jQuery('#go_button')

  goButton.click(function () {
    addressTextBox.blur()
    monitorChangeLocationRequest(function () {
      if (!addressTextBox.hasClass('watermark-textbox')) {
        clearLocationWatch()
        followingGPS = false
        var query = addressTextBox.val()

        infowindow.close()
        // @ts-expect-error No types
        geocoder = new google.maps.Geocoder()
        if (jQuery.trim(query).length > 0) {
          var address = query + ', Western Australia, Australia'

          geocoder.geocode(
            {
              address: address,
              partialmatch: true,
            },
            processGeocodeResult
          )
        }
      }
    })
  })

  addressTextBox.keydown(function (e) {
    if (e.keyCode == 13) {
      e.preventDefault()
      goButton.click()
    }
  })

  jQuery(window).resize(handleResize)
}

function handleResize() {
  if (jQuery('#map_canvas').length == 0) {
    jQuery(window).unbind('resize', handleResize)
    return
  }
  setMapHeight()
  jQuery('#tabtouch-notification, #tablocator-intro-notification').css({
    height: jQuery(document).height(),
  })
  loadingOverlay.css({ height: jQuery(document).height() })
}

function changeLocationToCurrentLocation() {
  loadingOverlay.show()
  clearLocationWatch()
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(handleSuccessfulLocationFind)
    clearTimeout(getPositionTimeOut)
    getPositionTimeOut = setTimeout(handleGetCurrentPositionError, 10000)
  }
  loadingOverlay.hide()
}

function clearLocationWatch() {
  navigator.geolocation.clearWatch(geoCodeWatchId)
}

function handleSuccessfulLocationFind(pos: any) {
  currentLocationRequestInProgress = false
  clearTimeout(getPositionTimeOut)
  // @ts-expect-error No types
  updateLocationOnMap(new google.maps.LatLng(pos.coords.latitude, pos.coords.longitude))
  followingGPS = true
  geoCodeWatchId = navigator.geolocation.watchPosition(handlePositionChange)
  monitorCurrentLocation()
}

function handlePositionChange(position: any) {
  newPosition = position
}

function monitorCurrentLocation() {
  if (
    newPosition &&
    (newPosition.coords.latitude !== currentGeoLocation.latitude ||
      newPosition.coords.longitude !== currentGeoLocation.longitude) &&
    !currentLocationRequestInProgress &&
    followingGPS
  ) {
    updateLocationOnMap(
      // @ts-expect-error No types
      new google.maps.LatLng(newPosition.coords.latitude, newPosition.coords.longitude),
      true
    )
  }

  if (jQuery('#map_canvas').length > 0) {
    setTimeout(monitorCurrentLocation, 5000)
  } else {
    navigator.geolocation.clearWatch(geoCodeWatchId)
  }
}

export function monitorChangeLocationRequest(callback: () => void) {
  callback()
  loadingOverlay.hide()
}

function handleGetCurrentPositionError() {
  currentLocationRequestInProgress = false
  clearTimeout(getPositionTimeOut)
  CMN.showPopup(jQuery('#tabtouch-notification'))
}

function initialize() {
  // @ts-expect-error No types
  markerIconSize = new google.maps.Size(15, 30)
  // @ts-expect-error No types
  markerAnchor = new google.maps.Point(9, 30)
  // @ts-expect-error No types
  markerOrigin = new google.maps.Point(0, 0)
  canCentreScreen = false
  addWaterMark('address', 'Other Location')
  var position = {
    coords: {
      latitude: -31.9554,
      longitude: 115.85859,
    },
  }
  if (usersGeoLocation) {
    position = usersGeoLocation
  }
  setCurrentLocation(position)
  addToRadFunctionToNumber()
  loadMarkerImages()
  introNotification = NOTE.initTabLocatorNotification(() => {
    canCentreScreen = true
    centreScreenOnMap()
  })

  initGoogleMapObjects()
  createGoogleMap()
  currentLocationMarker = createUsersLocationMarker()

  // Get Locations from File
  downloadUrl('../Content/tab-location.xml', parseXmlFlieForLocations)

  // @ts-expect-error No types
  var latlng = new google.maps.LatLng(currentGeoLocation.latitude, currentGeoLocation.longitude)
  map?.setCenter(latlng)
}

function setCurrentLocation(position: any) {
  if (position.coords) {
    currentGeoLocation = position.coords
  } else if (position.lat) {
    currentGeoLocation = {
      latitude: position.lat(),
      longitude: position.lng(),
    }
  } else {
    currentGeoLocation = position
  }
}

function loadMarkerImages() {
  googleMarkerIcons[sl1CategoryCode] = getMarkerImage(getCssClassForCategory(sl1CategoryCode))
  googleMarkerIcons[sl2CategoryCode] = getMarkerImage(getCssClassForCategory(sl2CategoryCode))
  googleMarkerIcons[sl3CategoryCode] = getMarkerImage(getCssClassForCategory(sl3CategoryCode))
}

function addToRadFunctionToNumber() {
  // @ts-expect-error No type
  if (!Number.prototype.toRad) {
    // @ts-expect-error No type
    Number.prototype.toRad = function () {
      // @ts-expect-error No type
      return (this * Math.PI) / 180
    }
  }
}

function createGoogleMap() {
  var myOptions = {
    panControl: false,
    zoom: 12,
    minZoom: 5,
    maxZoom: 18,
    // @ts-expect-error No types
    center: new google.maps.LatLng(currentGeoLocation.latitude, currentGeoLocation.longitude),
    mapTypeControl: false,
    mapTypeControlOptions: {
      // @ts-expect-error No types
      style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
    },
    streetViewControl: false,
    navigationControl: true,
    // @ts-expect-error No types
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    zoomControl: true,
    styles: [{ featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'off' }] }],
  }

  var mapCanvas = jQuery('#map_canvas')
  // @ts-expect-error No types
  map = new google.maps.Map(mapCanvas[0], myOptions)

  // @ts-expect-error No types
  google.maps.event.addListener(map, 'click', function () {
    infowindow.close()
  })
  // @ts-expect-error No types
  google.maps.event.addListenerOnce(map, 'tilesloaded', function () {
    centreScreenOnMap()
    introNotification.show()
  })
}

function createUsersLocationMarker() {
  // @ts-expect-error No types
  return new google.maps.Marker({
    // @ts-expect-error No types
    position: new google.maps.LatLng(currentGeoLocation.latitude, currentGeoLocation.longitude),
    icon: {
      url: dotIcon,
      // @ts-expect-error No types
      anchor: new google.maps.Point(25, 25),
    },
    map,
    title: name,
    zIndex: 999900000,
  })
}

function parseXmlFlieForLocations(doc: any) {
  var xmlDoc = new DOMParser().parseFromString(doc, 'text/xml')
  var markers = xmlDoc.documentElement.getElementsByTagName('marker')

  googleTabmarkers = []
  for (var i = 0; i < markers.length; i++) {
    var category = markers[i].getAttribute('category')

    var html =
      "<div id='infoWindowContent'>" +
      "<div style='width:220px;'><strong><span>" +
      markers[i].getAttribute('name') +
      "<div style='width:200px;'>" +
      markers[i].getAttribute('address') +
      '</div>' +
      '</div>'

    var lat = markers[i].getAttribute('lat')
    var lng = markers[i].getAttribute('lng')
    // @ts-expect-error No types
    var point = new google.maps.LatLng(lat, lng)
    googleTabmarkers.push(createMarker(point, markers[i].getAttribute('name'), html, category))
  }

  showMarkerForCategory(sl1CategoryCode)
  showMarkerForCategory(sl2CategoryCode)
  showMarkerForCategory(sl3CategoryCode)
}

function getMarkerImage(iconColor: any) {
  if (!iconColor) {
    iconColor = 'default'
  }
  if (!googleMarkerIcons[iconColor]) {
    // @ts-expect-error No types
    googleMarkerIcons[iconColor] = new google.maps.MarkerImage(
      slColorDict[iconColor as keyof typeof slColorDict] || '',
      markerIconSize,
      markerOrigin,
      markerAnchor
    )
  }
  return googleMarkerIcons[iconColor]
}

function getCssClassForCategory(category: any) {
  switch (category) {
    case sl2CategoryCode:
      return 'sl2-yellow'
    case sl3CategoryCode:
      return 'sl3-red'
    default:
      return 'sl1-purple'
  }
}

function createMarker(latlng: any, name: any, html: any, category: any) {
  var contentString = html
  // @ts-expect-error No types
  var marker = new google.maps.Marker({
    position: latlng,
    icon: googleMarkerIcons[category],
    shadow: iconShadow,
    map,
    title: name,
    zIndex: Math.round(latlng.lat() * -100000) << 5,
  })
  marker.mycategory = category
  marker.myname = name

  // @ts-expect-error No types
  google.maps.event.addListener(marker, 'click', function () {
    infowindow.close()
    infowindow.setContent(contentString)
    infowindow.open(map, marker)
  })

  return marker
}

function processGeocodeResult(results: any, status: string) {
  if (status == 'OK' && results.length > 0) {
    updateLocationOnMap(results[0].geometry.location)
  }
}

function updateLocationOnMap(latlng: any, dontGoToMap?: any) {
  setCurrentLocation(latlng)
  currentLocationMarker.setPosition(latlng)

  if (!dontGoToMap) {
    resizeGoogleMap()
    centreScreenOnMap()
    map.setCenter(latlng)
  }
}

function centreScreenOnMap(complete?: any) {
  var anchorOffset = jQuery('#anchor').offset()
  if (canCentreScreen && anchorOffset) {
    jQuery('html:not(:animated),body:not(:animated)').animate(
      { scrollTop: anchorOffset.top },
      'fast',
      undefined,
      complete
    )
  }
}

function resizeGoogleMap() {
  if (map) {
    // @ts-expect-error No types
    google.maps.event.trigger(map, 'resize')
  }
}

function showMarkerForCategory(category: string) {
  for (var i = 0; i < googleTabmarkers.length; i++) {
    if (googleTabmarkers[i].mycategory === category) {
      googleTabmarkers[i].setVisible(true)
    }
  }
  jQuery('#' + category + 'box').prop('checked', true)
}

function boxclick(category: any) {
  var isVisible = false
  for (var i = 0; i < googleTabmarkers.length; i++) {
    if (googleTabmarkers[i].mycategory === category) {
      isVisible = googleTabmarkers[i].getVisible() === false
      googleTabmarkers[i].setVisible(isVisible)
    }
  }
  jQuery('#' + category + 'box').prop('checked', isVisible)
  infowindow.close()
}

function initGoogleMapObjects() {
  // @ts-expect-error No types
  infowindow = new google.maps.InfoWindow({
    maxWidth: 300,
  })

  // @ts-expect-error No types
  google.maps.event.addListener(infowindow, 'clickclose', function () {
    infowindow.close()
  })

  // @ts-expect-error No types
  googleMarkerIcons['default'] = new google.maps.MarkerImage(
    sl1Purple,
    markerIconSize,
    markerOrigin,
    markerAnchor
  )
  /* Marker sizes are expressed as a Size of X,Y
      where the origin of the image (0,0) is located
      in the top left of the image.

      Origins, anchor positions and coordinates of the marker
      increase in the X direction to the right and in
      the Y direction down.*/

  // @ts-expect-error No types
  iconShadow = new google.maps.MarkerImage(
    pinShadow,
    /* The shadow image is larger in the horizontal dimension
      while the position and offset are the same as for the main image. */
    // @ts-expect-error No types
    new google.maps.Size(15, 20),
    // @ts-expect-error No types
    new google.maps.Point(0, 0),
    // @ts-expect-error No types
    new google.maps.Point(1, 15)
  )
}

function addWaterMark(id: string, text: string) {
  var textBox = jQuery('#' + id)
  var waterMarkClass = 'watermark-textbox'

  textBox.val(text).addClass(waterMarkClass)

  textBox.on('blur', () => {
    if (textBox.val().length !== 0) return
    textBox.val(text)
    textBox.addClass(waterMarkClass)
  })

  textBox.on('focus', () => {
    textBox.val('')
    textBox.removeClass(waterMarkClass)
  })
}

/**
 * This functions wraps XMLHttpRequest open/send function.
 * It lets you specify a URL and will call the callback if
 * it gets a status code of 200.
 * @param {String} url The URL to retrieve
 * @param {Function} callback The function to call once retrieved.
 */
export function downloadUrl(url: string, callback: (resText: string, status: number) => void) {
  var status = -1
  var request = new XMLHttpRequest()
  if (!request) {
    return
  }

  request.onreadystatechange = function () {
    if (request.readyState == 4) {
      try {
        status = request.status
      } catch (e) {
        // Usually indicates request timed out in FF.
      }
      if (status == 200 || status == 0) {
        callback(request.responseText, request.status)
        request.onreadystatechange = () => null
      }
    }
  }
  request.open('GET', url, true)
  try {
    request.send(null)
  } catch (e) {
    // changeStatus(e)
  }
}
