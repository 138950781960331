import React from 'react'
import { Observable } from 'rx'
import { recordify, TypedRecord } from 'typed-immutable-record'
import { isLoading, isErrored, LoadingInstance, ErroredInstance } from 'rwwa-data-access'
import { navigateToContactDetailsEdit } from '@classic/AppUtils/Framework/Intent/navigation'
import { ContactDetails as ContactDetailsRepo } from '../../Data/Account/contactDetails'
import { BetAccountHolder } from '@mobi/api-types'
import { connect } from '../../Components/HOCs/connect'
import { observeImmutable } from '../../Components/HOCs/observe'
import {
  PopupContainerStyled,
  PopupStyled,
  PopupTitleStyled,
} from '../../Components/Popup/Popup.styles'
import { ButtonBlock, CloseButton } from '@mobi/component-library/Common/Buttons'
import { Icon } from '@mobi/component-library/Common/Icon'
import { Grid, GridRow, GridCell } from '@mobi/component-library/Common/Grid'
import { Spinner } from '@mobi/component-library/Common/Spinner'
import { isHome } from '@classic/Foundation/Navigation/Nav'
import { ErrorMessage } from '../../Components/Messages'
import {
  buildName,
  buildDateOfBirth,
  buildPhoneNumber,
  buildAddress,
  isInternationalCustomer,
} from '../ContactDetails/data-transforms'
import {
  DescriptionStyled,
  DataStyled,
  DetailsListStyled,
  AdditionalLinksStyled,
  HorizontalDivider,
  NoticeBoxInternationalStyled,
} from './ReverificationPopup.styles'
import { DueMessage } from './Components/DueMessage'
import { ReverificationComplete } from './Components/ReverificationComplete'
import { ProvideExtraPersonalIdMessage } from './Components/ProvideExtraPersonalIdMessage'
import {
  state$ as reverificationState$,
  RemindMeLater,
  ConfirmReverificationDetails,
} from './driver'
import { state$ as userAccountState$ } from '../../State/UserAccount/userAccountDriver'
import { CustomerServiceTelephone } from '@mobi/component-library/Common/V2/'

export interface PopupState {
  hasAttemptedReverification: boolean
  reverificationDue: boolean
  numDaysRemaining: number
  isReverificationSuccess: boolean
  isReverificationFailed: boolean
  isFeatureActive: boolean
  requiresReverification: boolean
  remindMeLater: boolean
  userInitiatedReverification: boolean
  isLoggedIn: boolean
  isProcessing: boolean
  betAccountHolderNumber: number
  isReverificationCompleted: boolean
}

interface PopupStateRecord extends TypedRecord<PopupStateRecord>, PopupState {}

const popupState$: Observable<PopupStateRecord> = Observable.combineLatest(
  reverificationState$,
  userAccountState$,
  (reverification, userAccount) =>
    recordify({
      hasAttemptedReverification: reverification.hasAttemptedReverification,
      reverificationDue: reverification.reverificationDue,
      numDaysRemaining: reverification.numDaysRemaining,
      isReverificationSuccess: reverification.isReverificationSuccess,
      isReverificationFailed: reverification.isReverificationFailed,
      isFeatureActive: reverification.isFeatureActive,
      requiresReverification: reverification.requiresReverification,
      remindMeLater: reverification.remindMeLater,
      userInitiatedReverification: reverification.userInitiatedReverification,
      isLoggedIn: userAccount.isLoggedIn,
      isProcessing: reverification.isLoading,
      betAccountHolderNumber: reverification.betAccountHolderNumber,
      isReverificationCompleted: reverification.isReverificationCompleted,
    })
)

export const ReverificationPopup = (props: { isDesktop: boolean }) =>
  observeImmutable(popupState$, ({ record }) => {
    const {
      hasAttemptedReverification,
      reverificationDue,
      numDaysRemaining,
      isReverificationSuccess,
      isReverificationFailed,
      isProcessing,
      betAccountHolderNumber,
    } = record
    const showPopup = shouldShowPopup(record)

    return (
      <div>
        {isProcessing && <Spinner />}
        <ReverificationPopupComponent
          showPopup={showPopup}
          hasAttemptedReverification={hasAttemptedReverification}
          reverificationDue={reverificationDue}
          numDaysRemaining={numDaysRemaining}
          isReverificationSuccess={isReverificationSuccess}
          isReverificationFailed={isReverificationFailed}
          betAccountHolderNumber={betAccountHolderNumber}
          isDesktop={props.isDesktop}
        />
      </div>
    )
  })

export const ReverificationPopupMobi = ReverificationPopup({ isDesktop: false })

export function shouldShowPopup({
  isFeatureActive,
  isLoggedIn,
  requiresReverification,
  remindMeLater,
  userInitiatedReverification,
  betAccountHolderNumber,
}: PopupState) {
  return (
    isFeatureActive &&
    isLoggedIn &&
    requiresReverification &&
    ((isHome() && !remindMeLater) || userInitiatedReverification) &&
    !!betAccountHolderNumber
  )
}

interface ReverificationPopupComponentProps {
  showPopup: boolean
  hasAttemptedReverification: boolean
  reverificationDue: boolean
  numDaysRemaining: number
  isReverificationSuccess: boolean
  isReverificationFailed: boolean
  betAccountHolderNumber: number
  isDesktop: boolean
}

export function ReverificationPopupComponent({
  showPopup,
  hasAttemptedReverification,
  reverificationDue,
  numDaysRemaining,
  isReverificationSuccess,
  isReverificationFailed,
  betAccountHolderNumber,
  isDesktop,
}: ReverificationPopupComponentProps) {
  if (isReverificationSuccess || isReverificationFailed) {
    return <ReverificationComplete />
  }

  if (!showPopup) {
    return null
  }

  return (
    <ReverificationPopupContents
      numDaysRemaining={numDaysRemaining}
      reverificationDue={reverificationDue}
      hasAttemptedReverification={hasAttemptedReverification}
      betAccountHolderNumber={betAccountHolderNumber}
      isDesktop={isDesktop}
    />
  )
}

interface ReverificationPopupContentsProps {
  numDaysRemaining: number
  reverificationDue: boolean
  hasAttemptedReverification: boolean
  betAccountHolderNumber: number
  isDesktop: boolean
}

function ReverificationPopupContents({
  numDaysRemaining,
  reverificationDue,
  hasAttemptedReverification,
  betAccountHolderNumber,
  isDesktop,
}: ReverificationPopupContentsProps) {
  return (
    <PopupContainerStyled>
      <PopupStyled>
        <CloseButton onClick={RemindMeLater} icon={<Icon type='cross' />} />
        <PopupTitleStyled>Account Re-verification</PopupTitleStyled>
        <DueMessage
          hasAttemptedReverification={hasAttemptedReverification}
          numDaysRemaining={numDaysRemaining}
          reverificationDue={reverificationDue}
        />
        {hasAttemptedReverification ? (
          <ProvideExtraPersonalIdButton betAccountHolderNumber={betAccountHolderNumber} />
        ) : (
          <ReverificationDetails
            isDesktop={isDesktop}
            betAccountHolderNumber={betAccountHolderNumber}
          />
        )}
        <AdditionalLinks />
      </PopupStyled>
    </PopupContainerStyled>
  )
}

interface ReverificationDetailsProps {
  fullName?: string
  dateOfBirth?: string
  email?: string
  phoneMobile?: string
  addressResidential?: string | null
  isInternationalCustomer: boolean
  betAccountHolderNumber: number
  isDesktop: boolean
}

function navigateToEditContactDetails({
  isDesktop,
  betAccountHolderNumber,
}: {
  isDesktop?: boolean
  betAccountHolderNumber: number
}) {
  return () => {
    RemindMeLater()
    navigateToContactDetailsEdit({ isDesktop, betAccountHolderNumber })
  }
}

function ReverificationDetails({
  betAccountHolderNumber,
  isDesktop,
}: {
  betAccountHolderNumber: number
  isDesktop?: boolean
}) {
  // @ts-expect-error Legacy usage, needs refactor
  const ConnectReverificationDetails: React.ComponentClass<{
    contactDetails: string
    isDesktop?: boolean
  }> = connect({
    contactDetails: ContactDetailsRepo,
  })(ReverificationDetailsLoader)
  return (
    <ConnectReverificationDetails
      contactDetails={`${betAccountHolderNumber}`}
      isDesktop={isDesktop}
    />
  )
}

function ReverificationDetailsLoader({
  contactDetails,
  isDesktop,
}: {
  contactDetails: typeof LoadingInstance | typeof ErroredInstance | BetAccountHolder
  isDesktop: boolean
}) {
  if (isLoading(contactDetails)) {
    return <Spinner />
  }

  if (isErrored(contactDetails)) {
    return <ErrorMessage>An error occurred while loading your contact details ...</ErrorMessage>
  }

  const props: ReverificationDetailsProps = {
    fullName: contactDetails.NameDetails && buildName(contactDetails.NameDetails),
    dateOfBirth: buildDateOfBirth(contactDetails.DateOfBirth as Date),
    email: contactDetails.Contact && contactDetails.Contact.Email,
    phoneMobile:
      contactDetails.Contact &&
      contactDetails.Contact.Mobile &&
      buildPhoneNumber(contactDetails.Contact.Mobile),
    addressResidential:
      contactDetails.ResidentialAddress && buildAddress(contactDetails.ResidentialAddress),
    betAccountHolderNumber: contactDetails.BetAccountHolderNumber,
    isInternationalCustomer: isInternationalCustomer(contactDetails.ResidentialAddress),
    isDesktop: isDesktop,
  }

  return <ReverificationDetailsView {...props} />
}

export function ReverificationDetailsView({
  fullName,
  dateOfBirth,
  email,
  phoneMobile,
  addressResidential,
  betAccountHolderNumber,
  isInternationalCustomer: isInternational,
  isDesktop,
}: ReverificationDetailsProps) {
  const confirm = () => ConfirmReverificationDetails({ betAccountHolderNumber })

  return (
    <div>
      <DetailsListStyled>
        <DescriptionStyled>Full Name</DescriptionStyled>
        <DataStyled>{fullName}</DataStyled>

        <HorizontalDivider />

        <DescriptionStyled>Date of Birth</DescriptionStyled>
        <DataStyled>{dateOfBirth}</DataStyled>

        <HorizontalDivider />

        <DescriptionStyled>Email</DescriptionStyled>
        <DataStyled>{email}</DataStyled>

        <HorizontalDivider />

        <DescriptionStyled>Mobile</DescriptionStyled>
        <DataStyled>{phoneMobile}</DataStyled>

        <HorizontalDivider />

        <DescriptionStyled>Address - Residential</DescriptionStyled>
        <DataStyled>{addressResidential}</DataStyled>
      </DetailsListStyled>

      <Grid padding='0.4rem'>
        <GridRow>
          <GridCell>
            <ButtonBlock onClick={confirm} color='primary' uppercaseText={true}>
              Confirm
            </ButtonBlock>
          </GridCell>
        </GridRow>
        <GridRow>
          <GridCell>
            {isInternational ? (
              <NoticeBoxInternational />
            ) : (
              <UpdateDetailsButton
                isDesktop={isDesktop}
                betAccountHolderNumber={betAccountHolderNumber}
              />
            )}
          </GridCell>
        </GridRow>
      </Grid>
    </div>
  )
}

function NoticeBoxInternational() {
  return (
    <NoticeBoxInternationalStyled>
      If your name, address or date of birth are incorrect, please call us on{' '}
      <CustomerServiceTelephone isInternational={true} /> for assistance.
    </NoticeBoxInternationalStyled>
  )
}

function UpdateDetailsButton({
  isDesktop,
  betAccountHolderNumber,
}: {
  isDesktop?: boolean
  betAccountHolderNumber: number
}) {
  return (
    <ButtonBlock
      onClick={navigateToEditContactDetails({ isDesktop, betAccountHolderNumber })}
      color='secondary'
    >
      No, I need to update my details
    </ButtonBlock>
  )
}

function ProvideExtraPersonalIdButton({
  betAccountHolderNumber,
}: {
  betAccountHolderNumber: number
}) {
  return (
    <div>
      <ProvideExtraPersonalIdMessage />
      <ButtonBlock
        onClick={navigateToEditContactDetails({ betAccountHolderNumber })}
        color='primary'
      >
        Provide Extra Personal ID
      </ButtonBlock>
    </div>
  )
}

function AdditionalLinks() {
  return (
    <Grid>
      <GridRow>
        <GridCell align='left'>
          <AdditionalLinksStyled
            href='https://help.tabtouch.com.au/knowledge-base/aml-ctf-re-verification-learn-more/'
            target='_blank'
          >
            Learn more
          </AdditionalLinksStyled>
        </GridCell>
        <GridCell align='right'>
          <AdditionalLinksStyled onClick={RemindMeLater}>Remind Me Later</AdditionalLinksStyled>
        </GridCell>
      </GridRow>
    </Grid>
  )
}
