import React from 'react'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { colors, font, spacing } from '@mobi/component-library/Theme/Common'
import { TicketDisplayContext } from '@core/Areas/AccountActivity/context'
import type { Ticket, TicketStatus } from '@core/Areas/AccountActivity/types'
import { BonusType } from '@core/Areas/AccountActivity/types'
import { Money } from '@core/Components/Text/Money'
import { FixedOddsMarketDisplayNames } from '@core/Areas/RaceCard/constants'

export const FooterInvestment: React.FC<{
  status: TicketStatus
  ticket: Ticket
}> = ({ status, ticket }) => {
  const ticketDisplayContext = React.useContext(TicketDisplayContext)
  const isPendingBetsDrawerDisplay = ticketDisplayContext.type === 'pending-bets-drawer'

  const { investment, payValue: payout, return: projPayout, flexPercent } = ticket

  const hasPayoutFromScratchedOrAbandoned =
    payout > 0 &&
    (status === 'Abandoned' ||
      status === 'Refunded' ||
      status === 'Scratched' ||
      status === 'Partially Refunded')

  const isStartingPriceBet =
    ticket.legs?.length === 1 && ticket.legs[0].betType == FixedOddsMarketDisplayNames.SP

  return (
    <TicketInvestmentStyled
      bonusType={ticket.investment.bonusType}
      hasBonusAmount={(ticket.investment.bonusBet || 0) > 0}
      shouldInlineLabelStyle={isPendingBetsDrawerDisplay}
    >
      <div /* Aligned Left */>
        <span data-testid='bet-investment-label'>Cost</span>
        <strong data-testid='bet-investment'>
          <Money amount={investment.total} />
          {investment.bonusBet && (
            <span>
              {' '}
              {investment.bonusType === 'BonusBet'
                ? 'Bonus Bet'
                : investment.bonusBet === investment.total
                  ? 'Bonus Cash'
                  : '(incl. Bonus Cash)'}
            </span>
          )}
        </strong>
      </div>

      <div /* Aligned Right */>
        {flexPercent && (
          <div data-tid-flexi>
            <span data-tid-flexi-label>Flexi</span>
            <strong data-tid-flexi>{flexPercent}</strong>
          </div>
        )}

        {(() => {
          if (status === 'NoPayout') {
            return null
          }

          if (status === 'Won' || hasPayoutFromScratchedOrAbandoned) {
            return (
              <div>
                <span data-testid='summarypayout-label'>Payout</span>
                <strong data-testid='summarypayout'>
                  <Money amount={payout} />
                </strong>
              </div>
            )
          }

          if (status === 'Cancelled') {
            return (
              <div>
                <span data-testid='summarypayout-label'>Payout</span>
                <strong data-testid='summarypayout'>
                  <Money amount={investment.total} />
                </strong>
              </div>
            )
          }

          if (projPayout && !hasPayoutFromScratchedOrAbandoned && !isStartingPriceBet) {
            return (
              <div>
                <span data-testid='bet-projpayout-label'>Proj. Payout</span>
                <strong data-testid='bet-projpayout'>
                  <Money amount={projPayout} />
                </strong>
              </div>
            )
          }

          return null
        })()}
      </div>
    </TicketInvestmentStyled>
  )
}

// ======
// Styles
// ======

const TicketInvestmentStyled = styled.div<{
  hasBonusAmount: boolean
  bonusType: BonusType | null
  shouldInlineLabelStyle: boolean
}>(
  {
    display: 'flex',
    justifyContent: 'space-between',
    lineHeight: 1.2,

    '& > div:first-of-type': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      paddingRight: '1rem',
    },

    '& > div:last-of-type': {
      display: 'flex',
      justifyContent: 'flex-end',

      '& > div': {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'right',
        paddingLeft: '1.5rem',
      },
    },

    '> div, > div > div': {
      '> span': {
        color: hexColors.grey,
        fontSize: '1.2rem',
      },

      '> strong': {
        color: hexColors.nero,
        fontSize: '1.4rem',
      },
    },
  },
  ({ hasBonusAmount, bonusType, shouldInlineLabelStyle }) => ({
    '& > div:first-of-type > strong': {
      color:
        hasBonusAmount && bonusType
          ? bonusType === 'BonusBet'
            ? hexColors.tangerine
            : hexColors.cobalt
          : 'default',
    },

    ...(shouldInlineLabelStyle && {
      border: 0,

      '& > div:first-of-type': { flexDirection: 'row' },
      '& > div:last-of-type': { '& > div': { flexDirection: 'row' } },

      '> div, > div > div': {
        '> span': {
          color: colors.black,
          fontFamily: font.family.primary,
          fontSize: font.size.sm.fontSize,
          letterSpacing: font.size.sm.letterSpacing,
          lineHeight: font.size.sm.lineHeight,
          fontWeight: font.weight.regular,
          paddingRight: spacing.smx2,
        },

        '> strong': {
          color: colors.black,
          fontFamily: font.family.primary,
          fontSize: font.size.sm.fontSize,
          letterSpacing: font.size.sm.letterSpacing,
          lineHeight: font.size.sm.lineHeight,
          fontWeight: font.weight.semibold,
        },
      },
    }),
  })
)
