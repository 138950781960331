import React from 'react'
import { getHashFragment } from '@classic/Foundation/Navigation/Nav'
import { NavigatedTo } from '../driver'

export const useNavigationObserver = (): void => {
  React.useEffect(() => {
    const handleHashChange = () => {
      const url = getHashFragment()
      NavigatedTo({ url })
    }
    handleHashChange()
    window.addEventListener('hashchange', handleHashChange)

    return () => {
      window.removeEventListener('hashchange', handleHashChange)
    }
  })
}
