import React from 'react'
import { store } from '@core/Store'
import { setIsControlShowing, type SkyVideoPlayerState } from '@core/Areas/SkyVideoPlayer/Store'
import { useAppDispatch } from '@core/Store/hooks'

const enum LocalConstants {
  TimeToHideControlsSec = 5000,
}

export const useControlToggle = ({
  isPlaying,
  isMuted,
  isFullscreen,
  isControlShowing,
  selectedChannel,
  topBarElement,
  centerBarElement,
  bottomBarElement,
  isInteractingWithProgressBar,
}: {
  isPlaying: boolean
  isMuted: boolean
  isFullscreen: boolean
  isControlShowing: boolean
  selectedChannel: SkyVideoPlayerState['selectedChannel']
  topBarElement: HTMLDivElement | null
  centerBarElement: HTMLDivElement | null
  bottomBarElement: HTMLDivElement | null
  isInteractingWithProgressBar: boolean
}) => {
  const timerIdRef = React.useRef<number>()
  const dispatch = useAppDispatch()

  // remove queued hie control on unmount
  React.useEffect(() => () => clearTimeout(timerIdRef.current), [])

  // show controls when play is started (or paused/resumed in the case of replay)
  React.useEffect(() => {
    dispatch(setIsControlShowing(true))
  }, [isPlaying, dispatch])

  // reset queue hide controls timer when a button is clicked or progress bar is being used
  React.useEffect(() => {
    if (!isInteractingWithProgressBar) {
      queueHideControls(timerIdRef)
    }
  }, [isPlaying, isMuted, isFullscreen, selectedChannel, dispatch, isInteractingWithProgressBar])

  // queue hide control when the controls are shown (unless user is interacting), e,g, video clicked, window resize, drag, etc.
  React.useEffect(() => {
    if (isInteractingWithProgressBar) {
      clearTimeout(timerIdRef.current)
    } else if (isControlShowing) {
      queueHideControls(timerIdRef)
    }
  }, [isInteractingWithProgressBar, isControlShowing])

  // show/hide controls
  React.useEffect(() => {
    if (topBarElement)
      topBarElement.style.transform = `translateY(${isControlShowing ? '0' : '-100%'})`
    if (bottomBarElement)
      bottomBarElement.style.transform = `translateY(${isControlShowing ? '0' : '100%'})`
    if (centerBarElement)
      centerBarElement.style.transform = `translateX(${isControlShowing ? '0' : '-100%'})`
  }, [isControlShowing, topBarElement, centerBarElement, bottomBarElement])
}

// =============
// Local Helpers
// =============

function queueHideControls(timerIdRef: React.MutableRefObject<number | undefined>) {
  clearTimeout(timerIdRef.current)

  timerIdRef.current = window.setTimeout(hideControls, LocalConstants.TimeToHideControlsSec)
}

function hideControls() {
  store.dispatch(setIsControlShowing(false))
}
