import React from 'react'
import { toMoney } from '@mobi/utils/money'
import { useAppSelector } from '@mobi/betslip/Store/hooks'
import type { ToteSelection, BetSlipItem, AllUpSelection } from '@mobi/betslip/types'
import {
  isToteSelection,
  isNoveltyBetType,
  isAllUpSelection,
} from '@mobi/betslip/helpers/typeGuards'
import { Investment, InvestmentsWrapperStyled } from '@mobi/betslip/Components/Common/Investment'
import { SummaryLine } from '@mobi/betslip/Components/Common/SummaryLine'
import { selectActiveInvestment } from '@mobi/betslip/Store/Workflow/selectors'

export const ToteInvestment: React.FC<{ item: BetSlipItem }> = ({ item }) => {
  const { itemId, investmentType } = useAppSelector(selectActiveInvestment) || {}
  const workflowStatus = useAppSelector(state => state.betslip.workflow.currentStatus)
  const isBusy = useAppSelector(state => state.betslip.workflow.isBusy)

  const { investment, selection, shouldAllowPlaceInvestment } = item
  const shouldDisplayFlexi = isToteSelection(selection) && isNoveltyBetType(selection.betType)

  const isItemInputActive = itemId === item.id
  const isEditable = !item.receipt && workflowStatus === 'ready' && !isBusy

  const toteBetType = (item.selection as ToteSelection).betType

  let flexi
  if (shouldDisplayFlexi) {
    flexi = selection.numberOfCombinations
      ? Math.floor(((investment.win.value * 100) / selection.numberOfCombinations) * 100) / 100
      : 0
  }

  const isAllUp = isAllUpSelection(selection)
  const allUpCombinations = isAllUp ? getNumCombinationsSelected(selection.formulas) : 0
  const allUpTotalCost = isAllUp ? item.investment.win.value * allUpCombinations : 0

  return (
    <InvestmentsWrapperStyled isStacked={shouldAllowPlaceInvestment && isEditable}>
      <Investment
        value={investment.win.value || 0}
        itemId={item.id}
        label={toteBetType === 'Win & Place' ? 'Win' : 'Stake'}
        investmentType='Win'
        display={shouldAllowPlaceInvestment ? 'stacked' : 'inline'}
        isEditable={isEditable}
        isActive={isItemInputActive && investmentType === 'Win'}
      />

      {shouldAllowPlaceInvestment && (
        <Investment
          value={investment.place.value || 0}
          itemId={item.id}
          label='Place'
          investmentType='Place'
          display='stacked'
          isEditable={isEditable}
          isActive={isItemInputActive && investmentType === 'Place'}
        />
      )}

      {shouldDisplayFlexi && <SummaryLine left='Flexi' right={`${flexi}%`} />}

      {isAllUp && (
        <>
          <SummaryLine left='Combos' right={`${allUpCombinations}`} />

          {allUpTotalCost > 0 && <SummaryLine left='Total Cost' right={toMoney(allUpTotalCost)} />}
        </>
      )}
    </InvestmentsWrapperStyled>
  )
}

// =============
// Local Helpers
// =============

function getNumCombinationsSelected(selections: AllUpSelection['formulas']): number {
  return selections
    .filter(f => f.isSelected)
    .reduce((accumulator, currentValue) => accumulator + currentValue.numberOfCombinations, 0)
}
