import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useQueryClient } from 'react-query'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import { colors } from '@mobi/component-library/Theme/Common'
import { SpinnerInlineStyled } from '@mobi/component-library/Common/Spinner/Spinner.styles'
import { Button } from '@mobi/component-library/Common/V2/Buttons'
import { trackRaceReplayVideoLaunched } from '@core/Areas/Racing/Components/RaceReplay/analytics'
import type { Ticket } from '@core/Areas/AccountActivity/types'
import { getTicketLayoutType } from '@core/Areas/AccountActivity/Components/Ticket/helpers'
import { setActiveReplayTicketId } from '@core/Areas/AccountActivity/Store'
import { trackKey } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { keys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'
import { logError } from '@core/Utils'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { getReplayInfoFromApi } from '@core/Areas/Racing/Components/RaceReplay/api'
import type { ReplayInfo } from '@core/Areas/Racing/Components/RaceReplay/types'
import { RegisterToast } from '@core/Components/Toast/ToastDriver'

export const RaceReplayButton: React.FC<{
  ticket: Ticket
  onPlayTapped?: (replayInfo: ReplayInfo) => void
}> = ({ ticket, onPlayTapped }) => {
  const dispatch = useDispatch()

  const ticketType = getTicketLayoutType(ticket)
  const firstLeg = ticket.legs[0]
  const canReplay =
    (ticketType === 'single-single' || ticketType === 'same-race-multi') &&
    firstLeg &&
    firstLeg.fixtureId &&
    ticket.status === 'Resolved'

  if (!canReplay) {
    return null
  }

  const onClick = (replayInfo: ReplayInfo) => {
    onPlayTapped?.(replayInfo)
    dispatch(setActiveReplayTicketId(ticket.ticketSequence))
    trackKey(keys.raceReplayOpened)
  }

  return <ReplayButtonLocal ticket={ticket} onReady={info => onClick(info)} />
}

// ===============
// Local Component
// ===============

function ReplayButtonLocal({
  ticket,
  onReady,
}: {
  ticket: Ticket
  onReady?: (replayInfo: ReplayInfo) => void
}) {
  const [buttonState, setButtonState] = useState<'ready' | 'loading' | 'no_replay'>('ready')

  React.useEffect(() => {
    if (buttonState === 'no_replay') {
      RegisterToast({
        id: buttonState,
        type: 'error',
        message: 'No replay found',
      })
    }
  }, [buttonState])

  const queryClient = useQueryClient()

  const handleClick = useCallback(async () => {
    setButtonState('loading')
    try {
      const { fixtureId, fixtureDate, raceNumber } = ticket.legs[0]
      const data = await queryClient.fetchQuery({
        queryKey: queryKeys.raceReplayRequest(fixtureId, fixtureDate, +raceNumber),
        queryFn: () => getReplayInfoFromApi(fixtureId, fixtureDate, +raceNumber),
        staleTime: Infinity,
      })

      if (data) {
        setButtonState('ready')
        onReady && onReady(data)
      } else {
        setButtonState('no_replay')
      }

      trackRaceReplayVideoLaunched('resulted-bets', !!data)
    } catch (ex) {
      setButtonState('no_replay')
      logError('Could not load race replay')
    }
  }, [ticket, queryClient, onReady, setButtonState])

  if (!ticket.legs[0] || buttonState === 'no_replay') {
    return null
  }

  return (
    <Button
      size='xs'
      color='tertiary_grey'
      isIconOnlyButton
      onClick={handleClick}
      disabled={buttonState != 'ready'}
      data-testid='ticket-footer-replay-button'
    >
      {buttonState === 'ready' && <Icon name='SolidPlayCircle' color={colors.bondi[500]} />}
      {buttonState === 'loading' && <SpinnerInlineStyled color='dark' size={1.6} />}
    </Button>
  )
}
