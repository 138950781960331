import React from 'react'
import { SkyTvSvg } from '../../Common/Svg'
import { StatusBadge } from '../../Common/StatusBadge'
import { ChannelType } from '@mobi/component-library/Common/Svg/SkyTv/SkyTvSvg'

export const SkyTvBadge: React.FC<{
  channel: ChannelType
  size?: 'S' | 'M'
}> = ({ channel, size = 'S' }) => (
  <StatusBadge color={channel === 'Sky1' ? 'red' : channel === 'Sky2' ? 'blue' : 'gray'}>
    <SkyTvSvg channel={channel} width={size == 'S' ? '2rem' : '2.4rem'} />
  </StatusBadge>
)
