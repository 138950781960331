import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

const currentLocks = new Set<HTMLElement>()

export function registerBodyScrollLock(el: HTMLElement): void {
  currentLocks.add(el)
  disableBodyScroll(el, {
    allowTouchMove: el => el.getAttribute('data-js-allow-touch-move') !== null,
  })
}

export function deregisterBodyScrollLock(el: HTMLElement): void {
  currentLocks.delete(el)

  if (currentLocks.size === 0) {
    clearAllBodyScrollLocks()
  }
}
