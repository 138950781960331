import React from 'react'
import { isToteRacePushEvent, ToteRaceEventPushEvent } from 'tabtouch-push-contract'

import { ListUnstyled } from '@core/Components/List'
import { ErrorMessage, Message } from '@core/Components/Messages'
import { NextRace as NextRaceType } from '@core/Data/Racing/nextRaces'
import { event$ } from '@mobi/utils/awsiot'

import { NextRace } from './Components/NextRace/NextRace'
import { NextRacesFilter } from './Components/NextRacesFilter/NextRacesFilter'
import { NextRacesLoading } from './Components/NextRacesLoading/NextRaceLoading'
import {
  isToteRaceChangePushEvent,
  MaxNumberOfRaces,
  NextEventsFilter,
  nextEventsfilters,
  setNextEventFilters,
  useNextRaces,
} from './utils'

export const NextRaces: React.FC = () => {
  const { isLoading, error, data, refetch } = useNextRaces()
  const [filter, setFilter] = React.useState(nextEventsfilters)

  const filterIsValid = (newFilter: NextEventsFilter) =>
    !Object.values(newFilter).every(x => x === false)

  React.useEffect(() => {
    return () => setNextEventFilters(filter)
  }, [filter])

  React.useEffect(() => {
    const iotSubscription = event$
      .filter(x => isToteRacePushEvent(x.payload))
      .filter(x => isToteRaceChangePushEvent((x.payload as ToteRaceEventPushEvent).status))
      .subscribe(() => {
        refetch()
      })

    return () => iotSubscription.dispose()
  }, [refetch])

  if (!data || isLoading) {
    return <NextRacesLoading />
  }

  if (error) {
    return <ErrorMessage>Error loading next races.</ErrorMessage>
  }

  const nextRaces = filterNextRaces(data, filter)
  if (nextRaces.length === 0) {
    return <Message>No next races.</Message>
  }

  return (
    <>
      <NextRacesFilter
        filter={filter}
        setFilter={(f: NextEventsFilter) => filterIsValid(f) && setFilter(f)}
      />
      <ListUnstyled>
        {nextRaces.map(race => {
          const keyValue = `${race.MeetingDate.toString().substr(0, 10)}_${race.MeetingID}_${
            race.RaceNumber
          }`
          return <NextRace key={keyValue} keyValue={keyValue} race={race} />
        })}
      </ListUnstyled>
    </>
  )
}

export function filterNextRaces(
  nextRaces: NextRaceType[],
  filters: NextEventsFilter
): NextRaceType[] {
  let races = nextRaces

  if (races instanceof Array) {
    races = races.filter(x => filters[x.RaceType] === true).slice(0, MaxNumberOfRaces)
  }

  return races
}
