import React from 'react'
import countdown from 'countdown'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { now as getNow } from '@classic/Foundation/DateTimeProvider'
import {
  type CountdownColor,
  TimeSpanCalculator,
} from '@classic/Betting-v2/Utils/TimeSpanCalculator'

const bulbHexColors: Record<CountdownColor, string> = {
  green: hexColors.yellowGreen,
  orange: hexColors.tangerine,
  red: hexColors.cinnabar,
}

export const RaceCountdown: React.FC<{
  raceStartTime: Date
  displaySize?: string
}> = ({ raceStartTime, displaySize }) => {
  const timeRemainingRef = React.useRef<string>()
  const [timeRemaining, setTimeRemaining] = React.useState<string>()
  const [bulbColor, setBulbColor] = React.useState<CountdownColor>('green')

  timeRemainingRef.current = timeRemaining

  React.useEffect(() => {
    const updateTimer = () => {
      const timeSpan = TimeSpanCalculator.process(
        countdown(
          raceStartTime,
          getNow(),
          countdown.DAYS | countdown.HOURS | countdown.MINUTES | countdown.SECONDS
        ) as countdown.Timespan
      )

      if (timeSpan.text !== timeRemainingRef.current) {
        setTimeRemaining(timeSpan.text)
        setBulbColor(timeSpan.color)
      }
    }

    const timerId = window.setInterval(updateTimer, 1000)
    updateTimer()

    return () => window.clearInterval(timerId)
  }, [raceStartTime])

  const bulbHexColor = bulbHexColors[bulbColor]

  return (
    <RaceCountdownContainerStyled
      role='timer'
      aria-live='polite'
      aria-atomic='true'
      title='Countdown'
      data-testid='race-countdown'
      style={{ fontSize: displaySize || '1.4rem' }}
    >
      <svg viewBox='0 0 10 10' role='presentation' aria-hidden>
        <circle data-testid='bulb' r='5' cx='5' cy='5' fill={bulbHexColor} />
      </svg>

      <strong data-testid='time-remaining'>{timeRemaining}</strong>
    </RaceCountdownContainerStyled>
  )
}

// ======
// Styles
// ======

const RaceCountdownContainerStyled = styled.div({
  display: 'inline-flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  lineHeight: 1,

  svg: {
    marginRight: '0.5rem',
    height: '1em',
    width: '1em',
  },

  strong: {
    marginTop: '.1rem',
  },
})
