import React from 'react'
import dayjs from 'dayjs'
import styled from '@emotion/styled'
import { colors, font } from '@mobi/component-library/Theme/Common'
import type { Ticket, Transaction } from '@core/Areas/AccountActivity/types'

export const DATE_HEADER_DATA_SELECTOR = 'data-js-account-activity-date-header'

type DateHeaderProps = {
  latestDateRef: React.MutableRefObject<string | null>
  isFirstDate: boolean
} & (
  | {
      timestamp: Transaction['timestamp']
      isOrderByEvent?: never
      eventStartDateTime?: never
    }
  | {
      timestamp: Ticket['timestamp']
      eventStartDateTime: Ticket['eventStartDateTime']
      isOrderByEvent: boolean
    }
)

export const DateHeader: React.FC<DateHeaderProps> = ({
  latestDateRef,
  isOrderByEvent,
  timestamp,
  eventStartDateTime,
  isFirstDate,
}) => {
  const ticketDate = isOrderByEvent ? eventStartDateTime : timestamp
  const ticketDisplayDate = dayjs(ticketDate).isValid()
    ? dayjs(ticketDate).startOf('day').format('D MMMM YYYY')
    : ''

  const shouldHideDate = isFirstDate || latestDateRef.current === ticketDisplayDate

  if (!latestDateRef.current || latestDateRef.current !== ticketDisplayDate) {
    latestDateRef.current = ticketDisplayDate
  }

  if (shouldHideDate) return null

  const dateDataSelectorWithValue = { [DATE_HEADER_DATA_SELECTOR]: '' }

  return (
    <div data-tid-date-header>
      <DateHeaderLabelStyled data-tid-order-by-event-date={isOrderByEvent}>
        <span data-testid='date-value' {...dateDataSelectorWithValue}>
          {ticketDisplayDate}
        </span>
      </DateHeaderLabelStyled>
    </div>
  )
}

// ======
// Styles
// ======

const DateHeaderLabelStyled = styled.div({
  fontFamily: font.family.primary,
  fontSize: font.size.lg.fontSize,
  letterSpacing: font.size.lg.letterSpacing,
  lineHeight: font.size.lg.lineHeight,
  fontWeight: font.weight.medium,
  color: colors.neutral[900],
})
