import dayjs from 'dayjs'
import type { RacingBetType } from '@mobi/betslip/types'
import { PlanSeq } from '@mobi/api-types'
import { FeatureFlags } from '@mobi/settings'
import { isToteSelection } from '@mobi/betslip/helpers/typeGuards'
import { isActive } from '@core/State/LaunchDarklyFeatures/helpers/isActive'
import { trackLoadedBetBetslip } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { Betslip } from '@core/Areas/Betslip/Betslip'
import { state$, BetslipItem } from '@core/Areas/Betslip/driver'
import { AddSinglesToBetslip, RemoveSingleBet, ScrollTo } from '@core/Areas/Betslip/signals'
import { InvestmentType } from '@core/Areas/Quickbet/Components/BetInvestment/betInvestmentDriver'
import { SetActiveInvestment } from '@core/Areas/Quickbet/signals'
import { OverlayOpen } from '@core/Components/Overlay'
import { RegisterToast } from '@core/Components/Toast/ToastDriver'
import { logError } from '@core/Utils'
import { promptUserAndReturnDecision } from '@core/Utils/betting/loadBet/Components/PromptBetslipChange/helpers'
import {
  buildBetItem,
  getFOOEventType,
  isFOOBetDetails,
  isSportBetDetails,
} from './buildBetItem/buildBetItem'
import { BetDetails, FOOBetDetails, LoadBetDetails, LoadBetErrors } from './types'
import { FixedOddsMarketDisplayNames } from '@core/Areas/RaceCard/constants'
import { getToteBetType } from './buildBetItem/getToteBetType'
import { handleBetSelection } from '@core/Utils/betting/handleBetSelection'

const SUPPORTED_TOTE_BET_TYPES: RacingBetType[] = [
  'Win & Place',
  'Quinella',
  'Exacta',
  'Trifecta',
  'First 4',
  'Double',
  'Quaddie',
]

export const isLoadBetSupported = (planSeq: number): boolean => {
  if (planSeq === PlanSeq.FOBRacing) return true

  if (planSeq === PlanSeq.FOBSports) return true

  const betType = getToteBetType(planSeq)
  if (!betType) return false

  return SUPPORTED_TOTE_BET_TYPES.includes(betType)
}

export const loadBet = async (
  betPayload: LoadBetDetails[],
  source?: LoadBetSource
): Promise<void> => {
  try {
    if (betPayload.length === 1) {
      if (isSportBetDetails(betPayload[0])) {
        // TODO: Open sports bet in new BetSlip
        return
      } else {
        await addToQuickbet(betPayload[0] as BetDetails | FOOBetDetails, { betSource: source })
      }
      return
    }

    if (betPayload.length > 1) {
      const isNewBetSlipFeatureActive = await isActive(FeatureFlags.NEW_BET_SLIP_DESIGN.key)

      if (isNewBetSlipFeatureActive) {
        await addToBetSlip(betPayload, source)
      } else {
        const payload = betPayload.filter(bet => !isSportBetDetails(bet))
        await addToLegacyBetslip(payload as (BetDetails | FOOBetDetails)[], source)
      }
      return
    }

    throw new Error('No bets provided')
  } catch (error) {
    const message = getErrorMessage(error)

    RegisterToast({
      id: 'failed-bet-creation',
      timeout: 0,
      type: 'error',
      position: 'bottom',
      message,
    })

    if (message !== LoadBetErrors.BettingClosed) logError(error as Error, true)
  }
}

/** Quickbet: build item and open in Quickbet */
export async function addToQuickbet(
  bet: BetDetails | FOOBetDetails,
  opts: Partial<AddToQuickbetOptions> = {}
): Promise<void> {
  if (opts.shouldNavigateToRace) handleNavigateToRace(bet)

  handleBetSelection({
    betFlow: {
      location: 'Quickbet',
      options: {
        setUp: () => {
          SetActiveInvestment(
            bet.betType === 'Place - Fixed' ? InvestmentType.Place : InvestmentType.Win
          )
        },
        quickbetProps: {
          // setting this to false will force superpicks off, if true they will show only if
          // available. For now we force it off for starting price
          shouldShowSuperPicks: bet.betType !== FixedOddsMarketDisplayNames.SP,
        },
      },
    },
    selection: { ...(await buildBetItem(bet)), betSource: opts.betSource },
  })
}

/** NEW BetSlip: handle non-empty betslip, build items and open (Multi ONLY) */
export async function addToBetSlip(bets: LoadBetDetails[], source?: LoadBetSource) {
  // TODO: Set up new BetSlip addition with check for empty (see below)
  // eslint-disable-next-line no-console
  console.log('NEW addToBetSlip', { bets, source })
  return null
}

/** Betslip: handle non-empty betslip, build items and open in Betslip (Multi ONLY) */
export async function addToLegacyBetslip(
  bets: (BetDetails | FOOBetDetails)[],
  source?: LoadBetSource
): Promise<AddToBetslipReturn> {
  let whatToDoWithExisting = 'emptyBetslip'

  if (hasExistingBetslipItems()) {
    whatToDoWithExisting = await promptUserAndReturnDecision()

    switch (whatToDoWithExisting) {
      case 'cancel':
        return { total: bets.length, success: 0 }
      case 'add':
        break
      case 'replace': {
        removeFixedOddsBetslipItems()
        break
      }
    }
  }

  const createBetslipItemResults = await Promise.allSettled<BetslipItem>(
    bets.map(bet => buildBetItem(bet, 'betslip'))
  )
  const betsReadyForBetslip = createBetslipItemResults
    .filter(isFulfilled)
    .map(result => result.value)

  if (betsReadyForBetslip.length === 0) {
    const isBettingClosedOnBets = createBetslipItemResults
      .filter(isRejected)
      .map(result => result.reason)
      .some(err => getErrorMessage(err) === LoadBetErrors.BettingClosed)
    throw Error(isBettingClosedOnBets ? LoadBetErrors.BettingClosed : LoadBetErrors.Default)
  }

  AddSinglesToBetslip(betsReadyForBetslip)
  OverlayOpen(Betslip)
  ScrollTo('multi')

  source &&
    trackLoadedBetBetslip({
      source,
      action: whatToDoWithExisting,
      betsToAdd: betsReadyForBetslip.length,
    })

  return { total: bets.length, success: betsReadyForBetslip.length }
}

export function getErrorMessage(error: unknown): string {
  let errorMessage = LoadBetErrors.Default
  if (isErrorInstance(error) && error.message === LoadBetErrors.BettingClosed) {
    errorMessage = LoadBetErrors.BettingClosed
  }
  return errorMessage
}

// =============
// Local Helpers
// =============

function removeFixedOddsBetslipItems() {
  state$.take(1).subscribe(state => {
    state.items
      .filter(item => !isToteSelection(item.selection))
      .forEach(item => item && RemoveSingleBet(item))
  })
}

function hasExistingBetslipItems(): boolean {
  let hasBetslipItems = false
  state$.take(1).subscribe(state => {
    hasBetslipItems = state.items.filter(item => !isToteSelection(item.selection)).count() > 0
  })
  return hasBetslipItems
}

function isFulfilled<T>(input: PromiseSettledResult<T>): input is PromiseFulfilledResult<T> {
  return input.status === 'fulfilled'
}
function isRejected<T>(input: PromiseSettledResult<T>): input is PromiseRejectedResult {
  return input.status === 'rejected'
}

const isErrorInstance = (error: unknown): error is Error => error instanceof Error

async function handleNavigateToRace(bet: BetDetails | FOOBetDetails) {
  if (isFOOBetDetails(bet)) {
    const { competitionSeq, eventStartDateTime, sportName } = bet
    const event = getFOOEventType(sportName)
    const date = convertToFixtureDate(eventStartDateTime)
    window.location.href = `#fobracing/propositions/${event}/races/${competitionSeq}?selectionDate=${date}`
    return
  }

  const { fixtureId, races, fixtureDate } = bet
  window.location.href = `#tote/meetings/${fixtureId}/${races[0]}?date=${convertToFixtureDate(
    fixtureDate
  )}`
}

const convertToFixtureDate = (date: Date | string) => dayjs(date).format('YYYY-MM-DD')

// =====
// Types
// =====

interface AddToBetslipReturn {
  total: number
  success: number
}

type LoadBetSource = 'share-bet' | 'rebet' | 'blackbook'

interface AddToQuickbetOptions {
  shouldNavigateToRace: boolean
  betSource: LoadBetSource
}
