import React, { useEffect, useRef } from 'react'
import Rx from 'rx'
import { LoadingInstance, ErroredInstance, hasLoadedWithoutError, State } from 'rwwa-data-access'
import { observeImmutable } from '@core/Components/HOCs'
import { useObservableImmutable } from '@core/Utils/hooks'
import { state$ as userAccountState$ } from '@core/State/UserAccount/userAccountDriver'
import {
  FeaturedItemsRepo,
  FeaturedItem as ContentfulFeaturedItemData,
  FeaturedItemsKeys,
} from '@core/Data/Contentful/featuredItems'
import { FeaturedItemListElement } from './Item'
import {
  HomeScrollableContainerStyled,
  HomeScrollHeaderStyled as Header,
  HomeScrollListItemsStyled,
} from '@core/Areas/Home/Home.styles'
import {
  isReactNativeApp,
  sendToNative,
  AppVersion as reactNativeAppVersion,
} from '@mobi/web-native-comms/web'
import { useInducementService } from '@core/Utils/hooks/useInducementService'
import { trackFeatureItemClicked, trackFeaturedBannerViewed } from './analytics'

const enum LocalConstants {
  FeaturedTileClassName = 'js-feat-tile-feature-item',
}

interface FeaturedItemData extends ContentfulFeaturedItemData {
  featureItemAction?: () => void
}

export const FeaturedItems: React.FC = () => {
  const canShowInducements = useInducementService()

  return (
    <ConnectedFeaturedItems
      featuredItemsKey={
        canShowInducements
          ? FeaturedItemsKeys[FeaturedItemsKeys.WithInducements]
          : FeaturedItemsKeys[FeaturedItemsKeys.WithoutInducements]
      }
    />
  )
}

// Trigger re-render every 30 seconds so data access can check expiry
const state$ = Rx.Observable.timer(0, 30000).combineLatest(
  FeaturedItemsRepo.data$,
  (time, state) => {
    return { time, state }
  }
)

const ConnectedFeaturedItems = observeImmutable<
  { time: number; state: State<ContentfulFeaturedItemData[]> },
  { featuredItemsKey: string }
>(state$, ({ record, featuredItemsKey }) => {
  const featuredItems = FeaturedItemsRepo.getFrom(record.state, featuredItemsKey)
  return <FeaturedItemsComponent items={featuredItems} />
})

interface FeaturedItemsComponentProps {
  items: ContentfulFeaturedItemData[] | typeof LoadingInstance | typeof ErroredInstance
}

export function FeaturedItemsComponent({ items }: FeaturedItemsComponentProps): JSX.Element | null {
  if (!hasLoadedWithoutError(items) || !items.length) {
    return null
  }

  return <FeaturedItemsMapped items={items} />
}

const FeaturedItemsMapped: React.FC<{ items: FeaturedItemData[] }> = ({ items }) => {
  const { accountNumber } = useObservableImmutable(userAccountState$, ['accountNumber'])
  const reactNativeVersion = +(reactNativeAppVersion?.split('.', 1)[0] ?? '0')
  const trackedItemsRef = useRef<string[]>([])
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const trackGAEvent = (item: FeaturedItemData) => {
    if (!trackedItemsRef.current.includes(item.text.trim())) {
      trackFeaturedBannerViewed({
        featureItemTitle: item.text,
        featureItemPosition: items.indexOf(item),
        accountNumber,
        internalTrackingCode: item.internalTrackingCode,
        clickUrl: item.url,
      })
      trackedItemsRef.current = [...trackedItemsRef.current, item.text.trim()]
    }
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const index = entry.target.getAttribute('data-index')
            const item = index !== null ? items[parseInt(index, 10)] : undefined
            item && trackGAEvent(item)
          }
        })
      },
      {
        root: scrollContainerRef.current,
        threshold: 0.9,
      }
    )

    const elements = document.querySelectorAll(`.${LocalConstants.FeaturedTileClassName}`)
    elements.forEach((element, idx) => {
      element.setAttribute('data-index', idx.toString())
      observer.observe(element)
    })

    return () => {
      observer.disconnect()
    }
  }, [items, trackGAEvent])

  if (!hasLoadedWithoutError(items) || !items.length) {
    return null
  }

  // Add SMB tile if viable
  if (
    isReactNativeApp &&
    reactNativeVersion > 171 &&
    !items.find(item => item.text === 'Scan My Bet')
  ) {
    items.unshift({
      text: 'Scan My Bet',
      iconPathName: 'scanMyBet',
      url: '/#',
      internalTrackingCode: null,
      featureItemAction: () => sendToNative('SCAN_RETAIL_TICKET'),
    })
  }

  return (
    <>
      <Header data-testid='feature-item-header'>Featured</Header>
      <HomeScrollableContainerStyled ref={scrollContainerRef}>
        <HomeScrollListItemsStyled>
          {items.map((item, idx) => (
            <FeaturedItemListElement
              key={idx}
              {...item}
              className={LocalConstants.FeaturedTileClassName}
              featureItemAction={() => {
                trackFeatureItemClicked({
                  featureItemTitle: item.text,
                  featureItemPosition: idx,
                  accountNumber,
                  internalTrackingCode: item.internalTrackingCode,
                  clickUrl: item.url,
                })
                if (item.featureItemAction) item.featureItemAction()
              }}
            />
          ))}
        </HomeScrollListItemsStyled>
      </HomeScrollableContainerStyled>
    </>
  )
}
