import React from 'react'
import styled from '@emotion/styled'
import { useDispatch } from 'react-redux'
import { colors, font, spacing } from '@mobi/component-library/Theme/Common'
import { Toasts } from '@mobi/component-library/Feedback/Toast'
import { createToastsEventEmitter } from '@mobi/component-library/Feedback/Toast/helpers/events'
import { useBodyScrollLock } from '@mobi/utils/hooks/useBodyScrollLock'
import { useAppSelector } from './Store/hooks'
import { selectBetSlipItems } from './Store/Bets/selectors'
import { type BetSlipWorkflowState } from './Store/Workflow'
import { TabButtons, Singles, Multi, Footer, EmptyState } from './Components'
import { BetSlipContext } from './context'
import { refreshAllBets } from './Store/Workflow/asyncActions'
import { selectIsHostUserLoggedIn, selectWorkflowStatus } from './Store/Workflow/selectors'
import { useTabSectionObserver } from './hooks/useTabSectionObserver'

const enum LocalConstants {
  ScrollableClass = 'js-betslip__scrollable',
  FooterClass = 'js-betslip__footer',
}

export const BetSlip: React.FC = () => {
  const dispatch = useDispatch()
  const [toastEmitter] = React.useState(createToastsEventEmitter)

  const registeredInvestmentInputsRef = React.useRef<
    NonNullable<BetSlipWorkflowState['activeInvestment']>[]
  >([])

  const items = useAppSelector(selectBetSlipItems)
  const workflowStatus = useAppSelector(selectWorkflowStatus)
  const isLoggedIn = useAppSelector(selectIsHostUserLoggedIn)

  const scrollableContinerRef = useBodyScrollLock()

  const { singlesElRef, multiElRef } = useTabSectionObserver(scrollableContinerRef, items)

  React.useEffect(() => {
    if (workflowStatus === 'ready' && isLoggedIn) dispatch(refreshAllBets())
  }, [dispatch, workflowStatus, isLoggedIn])

  if (items.length === 0) {
    return (
      <BetslipContainerStyled>
        <EmptyState />
      </BetslipContainerStyled>
    )
  }

  return (
    <BetSlipContext.Provider value={{ registeredInvestmentInputsRef, toastEmitter }}>
      <BetslipContainerStyled>
        <div>
          <TabButtons
            containerRef={scrollableContinerRef}
            singlesRef={singlesElRef}
            multiRef={multiElRef}
          />
        </div>

        <div ref={scrollableContinerRef} className={LocalConstants.ScrollableClass}>
          <Singles items={items} ref={singlesElRef} />

          <Multi items={items} ref={multiElRef} />
        </div>

        <div className={LocalConstants.FooterClass}>
          <div>
            <Toasts toastEmitter={toastEmitter} isRelativeToParent />
          </div>

          <Footer />
        </div>
      </BetslipContainerStyled>
    </BetSlipContext.Provider>
  )
}

// ======
// Styles
// ======

const BetslipContainerStyled = styled.div({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: colors.surface[300],
  fontFamily: font.family.primary,

  ['.' + LocalConstants.ScrollableClass]: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: spacing.md,
    overflowY: 'auto',
    overflowX: 'hidden',
    scrollBehavior: 'smooth',
    overscrollBehavior: 'contain',
  },

  ['.' + LocalConstants.FooterClass]: {
    position: 'relative',
    marginTop: 'auto',

    '> div:first-of-type': {
      position: 'absolute',
      top: 0,
      width: '100%',
    },
  },
})
