import React from 'react'
import styled from '@emotion/styled'
import { colors, radius, shadow } from '@mobi/component-library/Theme/Common'

const enum LocalConstants {
  MediumSizeClass = 'js-toggle--md',
}

export const Toggle: React.FC<{
  id: string
  isOn: boolean
  onToggle: () => void
  size?: 'sm' | 'md'
  disabled?: boolean
}> = ({ id, isOn, onToggle, size = 'sm', disabled = false }) => (
  <ToggleFieldStyled className={size == 'md' ? LocalConstants.MediumSizeClass : ''}>
    <input
      type='checkbox'
      checked={isOn}
      onChange={() => !disabled && onToggle()}
      disabled={disabled}
      aria-checked={isOn}
      role='switch'
      id={id}
    />

    <label htmlFor={id} />
  </ToggleFieldStyled>
)

// ======
// Styles
// ======

const ToggleFieldStyled = styled.div({
  position: 'relative',
  display: 'inline-flex',
  '--parentWidth': '3.6rem',
  '--parentHeight': '2rem',
  width: 'var(--parentWidth)',
  height: 'var(--parentHeight)',

  [`&.${LocalConstants.MediumSizeClass}`]: {
    '--parentWidth': '4.4rem',
    '--parentHeight': '2.4rem',

    '> label': { borderRadius: 'calc(var(--parentHeight) / 2)' },
  },

  '> input[type=checkbox]': {
    height: 0,
    width: 0,
    visibility: 'hidden',
    appearance: 'none',
    position: 'absolute',
  },

  '> label': {
    display: 'flex',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: '0.2rem',
    WebkitTapHighlightColor: 'transparent',
    borderRadius: 'var(--parentHeight)',
    backgroundColor: colors.studio[50],
    transition: 'background-color 0.2s ease',
    cursor: 'pointer',

    '::before': {
      content: '""',
      height: 'calc(var(--parentWidth) / 2 - 0.2rem)',
      width: 'calc(var(--parentWidth) / 2 - 0.2rem)',
      borderRadius: radius.full,
      background: colors.white,
      boxShadow: shadow.sm,
      transition: 'transform 0.2s ease',
    },
  },

  '> input:checked + label': {
    backgroundColor: colors.studio[600],
    '::before': { transform: 'translateX(100%)' },
  },

  '> input:not(:checked):disabled + label': {
    backgroundColor: colors.surface[200],
  },

  '> input:checked:disabled + label': {
    backgroundColor: colors.studio[50],
  },

  '> input:disabled + label::before': {
    backgroundColor: colors.surface[200],
  },
})
