import React from 'react'
import styled from '@emotion/styled'
import { colors, font, radius, spacing } from '@mobi/component-library/Theme/Common'
import type { Ticket } from '@core/Areas/AccountActivity/types'
import type { NextSkyRace } from '@core/Areas/RaceCardSky/types'
import {
  getNumberFromString,
  getSingleTicketTitle,
  shouldUseFallbackIcon,
} from '@core/Areas/AccountActivity/Components/Ticket/helpers'
import { FixedOddsMarketDisplayNames } from '@core/Areas/RaceCard/constants'
import { SilkFallback } from '@core/Areas/AccountActivity/Components/Ticket/Components'
import { SilkImageContained } from '@core/Components/SilkImage/SilkImageContained'
import { meetingIdToContestType } from '@core/Areas/Racing/helpers'
import { SingleSilkStyled } from '@core/Areas/AccountActivity/Components/Ticket/Components/Header/Components/SelectionInfo/Components/SingleSingle/SingleSingle.styles'
import { FooterInvestment } from '@core/Areas/AccountActivity/Components/Ticket/Components/Investment'
import { TicketDisplayContext } from '@core/Areas/AccountActivity/context'
import { getMatchedSkyRaceForTicketLeg } from '../../helpers/getMatchedSkyRaceForTicketLeg'
import { SkyRaceHeader } from '../SkyRaceHeader'

export const MultiTicket: React.FC<{ ticket: Ticket; nextSkyRaces: NextSkyRace[] }> = ({
  ticket,
  nextSkyRaces,
}) => {
  return (
    <TicketDisplayContext.Provider
      value={{ type: 'pending-bets-drawer', data: { isSkyRaceMatch: false } }}
    >
      <WrapperStyled data-test='MultiTicket'>
        <header>
          <h3>Multi @ {getNumberFromString(ticket.combinedDividend?.toString() || '')}</h3>
        </header>

        <div>
          <ul>
            {ticket.legs.map(leg => {
              const legTitle =
                leg.betType === FixedOddsMarketDisplayNames.H2H
                  ? `${leg.betType} - ${leg.runners}`
                  : getSingleTicketTitle(leg, ticket.planSeq).title

              const shouldHideSilk = shouldUseFallbackIcon(leg, ticket.planSeq)
              const contestType = meetingIdToContestType(leg.fixtureId)
              const isDogsSilk = contestType === 'dogs'

              const cleanBetType = (leg.betType || '').split('-')[0].trim()
              const cleanLegReturn = (leg.return || '').split(',')[0].replaceAll(/[a-zA-Z|$]/gi, '')

              const matchedSkyRace = getMatchedSkyRaceForTicketLeg(leg, nextSkyRaces)

              return (
                <li key={legTitle}>
                  {!!matchedSkyRace && <SkyRaceHeader nextSkyRace={matchedSkyRace} />}

                  <section>
                    <div>
                      <SingleSilkStyled isDogs={!shouldHideSilk && isDogsSilk}>
                        {shouldHideSilk ? (
                          <SilkFallback contestType={contestType} />
                        ) : (
                          <SilkImageContained
                            fixtureDate={leg.fixtureDate}
                            fixtureId={leg.fixtureId}
                            raceNumber={leg.selections[0].raceNumber || leg.raceNumber}
                            acceptorNumber={
                              leg.selections[0].selectionDetails[0].acceptorDetails[0].number
                            }
                          />
                        )}
                      </SingleSilkStyled>
                    </div>

                    <div>
                      <h4>
                        {legTitle} {cleanLegReturn && `@ ${cleanLegReturn}`}
                      </h4>
                      {cleanBetType && <span>{cleanBetType}</span>}
                    </div>
                  </section>
                </li>
              )
            })}
          </ul>
        </div>

        <footer>
          <FooterInvestment ticket={ticket} status='Pending' />
        </footer>
      </WrapperStyled>
    </TicketDisplayContext.Provider>
  )
}

const WrapperStyled = styled.div({
  '> header': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem 0',
    margin: '0 1rem',

    '> h3': {
      display: 'flex',
      alignItems: 'center',
      background: 0,
      color: colors.black,
      margin: 0,
      padding: 0,
      fontFamily: font.family.primary,
      fontSize: font.size.sm.fontSize,
      letterSpacing: font.size.sm.letterSpacing,
      lineHeight: font.size.sm.lineHeight,
      fontWeight: font.weight.semibold,
    },
  },

  '> div:first-of-type': {
    padding: '0 1rem',

    '> ul': { listStyle: 'none', padding: 0, margin: 0 },

    '> ul > li': {
      margin: 0,
      marginBottom: spacing.smx1,
      borderRadius: radius.smx1,
      border: '0.5px solid ' + colors.neutral[200],

      '&:last-of-type': { marginBottom: 0 },

      '> section': {
        display: 'flex',
        alignItems: 'center',
        padding: spacing.smx1,

        '> div:last-of-type': {
          h4: {
            padding: 0,
            margin: 0,
            textAlign: 'left',
            fontFamily: font.family.primary,
            fontSize: font.size.sm.fontSize,
            letterSpacing: font.size.sm.letterSpacing,
            lineHeight: font.size.sm.lineHeight,
            fontWeight: font.weight.semibold,
          },

          span: {
            fontFamily: font.family.primary,
            fontSize: font.size.sm.fontSize,
            letterSpacing: font.size.sm.letterSpacing,
            lineHeight: font.size.sm.lineHeight,
            fontWeight: font.weight.regular,
          },
        },
      },
    },
  },

  '> footer': {
    padding: '0.5rem 1rem',
  },
})
