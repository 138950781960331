import React from 'react'
import * as TABLocatorVM from '@classic/Foundation/ViewModels/TabLocatorViewModel'
import sl1Image from './images/sl1-purple.png'

export const TABLocatorWeb: React.FC<{}> = () => {
  React.useEffect(() => {
    TABLocatorVM.initPage()
  }, [])

  return (
    <>
      <div className='section-container'>
        <div className='page-heading l-faux-table l-table-fixed w100 fz-12 lh1'>
          <a
            href='/#'
            className='l-faux-cell cell-icon pt10 pb10 fz-14 alignmid text-center'
            data-tid-home
          >
            <span className='tticon tticon-house fz-21'>
              <span className='accessible'>Home</span>
            </span>
          </a>

          <span className='l-faux-cell alignmid pt10 pb10 text-center'>
            <span className='text-ellipsis alignmid fz-18 faux-strong text-upper'>
              <h2 className='page-heading-copy fz-16 lh1' data-tid-page-title=''>
                Find My TAB
              </h2>
            </span>
          </span>

          <span className='l-faux-cell cell-icon fz-14 alignmid text-center'>
            <a className='refresh-button cell-icon pt10 pb10 fz-14'>
              <span className='tticon tticon-refresh fz-21'>
                <span className='accessible'>Refresh</span>
              </span>
            </a>
          </span>
        </div>
      </div>

      <div id='content-container' style={{ height: '100%' }}>
        <div id='content'>
          <div className='location-selection'>
            <div id='currentLocationWrapper'>
              <input
                id='currentLocation'
                className='go_button button'
                type='button'
                value='By Current Location'
              />
            </div>
            <div id='postcode'>
              <input type='text' size={4} id='address' />
              <input id='go_button' className='go_button button' type='button' value='Go' />
            </div>
          </div>
          <div id='tabTypeSelectionContainer'>
            <ol className='display-list' id='tabTypeSelectionList'>
              <li className='pinListItem' id='SL1Item'>
                <span id='sl1-purple' className='tabPin'></span>
                <div className='tabTypeSelector'>
                  <label className='TabType'>TAB Agency</label>
                  <input type='checkbox' id='SL1box' className='regular-checkbox' defaultChecked />
                </div>
              </li>
              <li className='pinListItem' id='SL2Item'>
                <span id='sl2-yellow' className='tabPin'></span>
                <div className='tabTypeSelector'>
                  <label className='TabType'>Pub TAB</label>
                  <input type='checkbox' id='SL2box' className='regular-checkbox' defaultChecked />
                </div>
              </li>
              <li className='pinListItem' id='SL3Item'>
                <span id='sl3-red' className='tabPin'></span>
                <div className='tabTypeSelector'>
                  <label className='TabType'>Pub TAB - Self Service</label>
                  <input type='checkbox' id='SL3box' className='regular-checkbox' defaultChecked />
                </div>
              </li>
            </ol>
          </div>
          <a id='anchor'></a>

          <div id='map_container'>
            <div id='map_canvas' className='map_default'></div>
          </div>
        </div>

        <div id='tabtouch-notification' className='popup' style={{ display: 'none' }}>
          <div className='overlay'>&nbsp;</div>
          <div id='notification' className='window' style={{ transform: 'translateY(2rem)' }}>
            <a className='black-x close'>Close Window</a>
            <h4 data-tid-notification-title=''>Find my TAB</h4>

            <p>Unable to detect your location you may need to enable location sharing.</p>
            <a className='button close continue' style={{ width: '50%' }}>
              OK
            </a>
          </div>
        </div>

        <div id='tablocator-intro-notification' className='popup' style={{ display: 'none' }}>
          <div className='overlay'>&nbsp;</div>
          <div id='notification' className='window' style={{ transform: 'translateY(2rem)' }}>
            <a className='black-x close'>Close Window</a>
            <h4 data-tid-notification-title=''>Welcome to Find My TAB</h4>

            <p>
              Click on the <img className='welcomeIcon' src={sl1Image} /> icons to get more
              information about the TAB at that location.
            </p>
            <a className='button close continue' style={{ width: '50%' }}>
              OK
            </a>

            <br />
            <input type='checkbox' id='dontShowNotification' name='dontShowNotification' />
            <label htmlFor='dontShowNotification' className='checkbox-text left-5'>
              Don't show me this again.
            </label>
          </div>
        </div>
      </div>
    </>
  )
}
