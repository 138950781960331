import { z } from 'zod'

const webAppVersionDetailsSchema = z.object({
  version: z.string(),
  releaseDate: z.string().datetime({ offset: true }),
  username: z.string(),
  gitRefName: z.string(),
  gitCommitSha: z.string(),
  gitPipelineId: z.string(),
  gitPipelineIid: z.string(),
  gitProject: z.string(),
  gitProjectUrl: z.string(),
  gitJobId: z.string(),
})

export type WebAppVersionDetails = z.infer<typeof webAppVersionDetailsSchema>

export const webAppVersionsSchema = z.object({
  versions: z.record(z.string(), webAppVersionDetailsSchema),
  versionsByReleaseDate: z.array(z.string()),
})

export type WebAppVersions = z.infer<typeof webAppVersionsSchema>
