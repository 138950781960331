import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SettingItemWithCheckbox } from '../Common/SettingItemWithCheckbox'
import { getNewBetSlipSetting } from '../../Store/selectors'
import { setNewBetSlipSetting } from '../../Store'

export const NewBetSlipSetting = () => {
  const dispatch = useDispatch()
  const isSettingEnabled = useSelector(getNewBetSlipSetting)

  return (
    <SettingItemWithCheckbox
      isEnabled={isSettingEnabled}
      label='New Bet Slip'
      desciption='Enables new Bet Slip feature'
      onToggle={() => {
        dispatch(setNewBetSlipSetting(!isSettingEnabled))
      }}
    />
  )
}
