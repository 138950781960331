import React from 'react'
import styled from '@emotion/styled'
import { colors, font, radius, shadow, spacing } from '@mobi/component-library/Theme/Common'
import type { BetSlipItem } from '@mobi/betslip/types'
import { isAllUpSelection } from '@mobi/betslip/helpers/typeGuards'
import { useDispatch } from 'react-redux'
import { toggleAllUpFormula } from '@mobi/betslip/Store/Bets'

export const AllUpFormula: React.FC<{ item: BetSlipItem }> = ({ item }) => {
  const dispatch = useDispatch()

  if (!isAllUpSelection(item.selection)) return null

  const handleClick = (formula: number) => {
    dispatch(toggleAllUpFormula({ id: item.id, formula }))
  }

  return (
    <SingleAllUpFormulaContainerStyled>
      {item.selection.formulas.map(({ formula, isSelected, numberOfCombinations }) => (
        <button key={formula} data-is-selected={isSelected} onClick={() => handleClick(formula)}>
          {FORMULA_NAME_MAP[formula as keyof typeof FORMULA_NAME_MAP]}
          <br />x {numberOfCombinations}
        </button>
      ))}
    </SingleAllUpFormulaContainerStyled>
  )
}

// =============
// Local Helpers
// =============

const FORMULA_NAME_MAP = {
  1: 'Singles',
  2: 'Doubles',
  3: 'Trebles',
  4: 'Pick 4',
  5: 'Pick 5',
  6: 'Pick 6',
}

// ======
// Styles
// ======

const SingleAllUpFormulaContainerStyled = styled.div({
  display: 'flex',
  gap: spacing.smx2,
  paddingTop: spacing.sm,
  overflowX: 'auto',
  scrollBehavior: 'smooth',
  WebkitOverflowScrolling: 'touch',

  '> button': {
    boxSizing: 'border-box',
    minWidth: 'calc(100% / 6)',
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: `${spacing.smx2} ${spacing.sm}`,
    minHeight: '5rem',
    fontSize: font.size.lg.fontSize,
    letterSpacing: font.size.lg.letterSpacing,
    lineHeight: 1.2,
    fontWeight: font.weight.medium,
    color: colors.neutral[800],
    background: colors.neutral[200],
    border: '0.1rem solid ' + colors.neutral[200],
    borderRadius: radius.sm,
    boxShadow: shadow.xs,
    touchAction: 'manipulation',

    '&[data-is-selected="true"]': {
      color: colors.white,
      background: colors.studio[600],
      borderColor: colors.studio[500],
    },
  },
})
