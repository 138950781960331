import React from 'react'
import styled from '@emotion/styled'
import { colors, font, shadow } from '@mobi/component-library/Theme/Common'

const enum LocalConstants {
  ActiveLabelClass = 'js-toggle-label--active',
}

export const ToggleWithLabels: React.FC<{
  id: string
  leftValue: string
  rightValue: string
  isLeftValueActive: boolean
  onToggle: () => void
  disabled?: boolean
}> = ({ id, isLeftValueActive, leftValue, rightValue, onToggle, disabled = false }) => {
  const elementId = `toggle-label-${id}`

  return (
    <ToggleFieldStyled>
      <input
        type='checkbox'
        checked={isLeftValueActive}
        onChange={() => !disabled && onToggle()}
        disabled={disabled}
        aria-checked={isLeftValueActive}
        role='switch'
        id={elementId}
      />

      <label htmlFor={elementId}>
        <span className={isLeftValueActive ? LocalConstants.ActiveLabelClass : ''}>
          {leftValue}
        </span>

        <span className={!isLeftValueActive ? LocalConstants.ActiveLabelClass : ''}>
          {rightValue}
        </span>
      </label>
    </ToggleFieldStyled>
  )
}

// ======
// Styles
// ======

const ToggleFieldStyled = styled.div({
  position: 'relative',
  display: 'inline-flex',
  '--parentHeight': '2.4rem',
  height: 'var(--parentHeight)',
  fontFamily: font.family.primary,

  '> input[type=checkbox]': {
    height: 0,
    width: 0,
    visibility: 'hidden',
    appearance: 'none',
    position: 'absolute',
  },

  '> label': {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    alignItems: 'center',
    padding: '0.2rem',

    userSelect: 'none',
    touchAction: 'manipulation',
    WebkitTapHighlightColor: 'transparent',
    borderRadius: 'calc(var(--parentHeight) / 2)',
    backgroundColor: colors.neutral[200],
    transition: 'background-color 0.2s ease',
    cursor: 'pointer',

    '> span': {
      position: 'relative',
      zIndex: 2,
      padding: `0 0.6rem`,
      textAlign: 'center',
      fontSize: font.size.md.fontSize,
      letterSpacing: font.size.md.letterSpacing,
      lineHeight: font.size.md.lineHeight,
      fontWeight: font.weight.medium,
      color: colors.neutral[700],
      transition: 'color 0.2s ease',

      [`&.${LocalConstants.ActiveLabelClass}`]: {
        color: colors.neutral[900],
      },
    },

    '::before': {
      content: '""',
      position: 'absolute',
      left: '0.2rem',
      top: '0.2rem',
      zIndex: 1,
      width: 'calc(50% - 0.2rem)',
      height: 'calc(var(--parentHeight) - 0.4rem)',
      borderRadius: 'calc(var(--parentHeight) / 1.5)',
      background: colors.white,
      boxShadow: shadow.sm,
      transform: 'translateX(100%)',
      transition: 'transform 0.2s ease',
    },
  },

  '> input:checked + label': {
    '::before': { transform: 'translateX(0%)' },
  },

  '> input:not(:checked):disabled + label': {
    backgroundColor: colors.surface[200],
  },

  '> input:disabled + label': {
    [`> span.${LocalConstants.ActiveLabelClass}`]: {
      color: colors.neutral[700],
    },

    '::before': {
      backgroundColor: colors.surface[200],
    },
  },
})
