import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const sportsRootInitialState: SportsRootState = {
  favouriteSports: [],
  favouriteTournaments: [],
}

const sportsRootSlice = createSlice({
  name: 'sports-root',
  initialState: sportsRootInitialState,
  reducers: {
    toggleSportsFavourite(state, { payload }: PayloadAction<string>) {
      if (state.favouriteSports.includes(payload)) {
        state.favouriteSports = state.favouriteSports.filter(item => item !== payload)
      } else {
        state.favouriteSports.push(payload)
      }
    },

    toggleTournamentsFavourite(state, { payload }: PayloadAction<string>) {
      if (state.favouriteTournaments.includes(payload)) {
        state.favouriteTournaments = state.favouriteTournaments.filter(item => item !== payload)
      } else {
        state.favouriteTournaments.push(payload)
      }
    },
  },
})

export const { toggleSportsFavourite, toggleTournamentsFavourite } = sportsRootSlice.actions

export default persistReducer(
  {
    key: 'sports-root',
    storage,
    debug: !PRODUCTION,
    whitelist: ['favouriteSports', 'favouriteTournaments'],
  },
  sportsRootSlice.reducer
)

// =====
// Types
// =====

export interface SportsRootState {
  favouriteSports: string[]
  favouriteTournaments: string[]
}
