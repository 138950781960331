import { colors } from '@mobi/component-library/Theme/Common'
import type { PromoTypeClass } from './types'

export const promoTypeColorThemes: Record<`&.${PromoTypeClass}`, { '--main-color': string }> = {
  '&.PromoType-Bonus': {
    '--main-color': colors.lavender[50],
  },

  '&.PromoType-Special': {
    '--main-color': colors.celery[50],
  },

  '&.PromoType-SuperPick': {
    '--main-color': colors.orange[50],
  },
}
