import { getToteRaceEventTopic } from 'tabtouch-push-contract'

export const getFobPriceChangeTopic = (marketId: string): string => {
  return `tabtouch/fob/market/${marketId}/#`
}

export const getFobSportsPriceChangeTopic = (
  marketId: number,
  outcomeId: number,
  marketTypeCode: string
): string => {
  return `tabtouch/sports/market/${marketId}/outcome/${outcomeId}/${marketTypeCode}/price-changed`
}

export const getFobMarketSettlementTopic = (marketId: string): string => {
  return `tabtouch/fob/market/${marketId}/settlement`
}

type ToteRaceStatus =
  | 'priceChanged'
  | 'statusChanged'
  | 'acceptorChanged'
  | 'poolCompleted'
  | 'resultsDelay'
  | 'abandonedChanged'
  | 'all'

export const getToteRaceTopic = (raceId: string, option: { type: ToteRaceStatus }): string => {
  if (option.type === 'priceChanged') {
    return `tabtouch/tote/race/${raceId}/price-changed`
  }

  if (option.type === 'statusChanged') {
    return `tabtouch/tote/race/${raceId}/status-changed`
  }

  if (option.type === 'resultsDelay') {
    return `tabtouch/tote/race/${raceId}/delay-results-changed`
  }

  if (option.type === 'poolCompleted') {
    return `tabtouch/tote/race/${raceId}/pool-completed`
  }

  if (option.type === 'acceptorChanged') {
    return `tabtouch/tote/race/${raceId}/acceptor/#`
  }

  if (option.type === 'abandonedChanged') {
    return `tabtouch/tote/race/${raceId}/abandon-changed`
  }

  return `tabtouch/tote/race/${raceId}/#`
}

export const getToteRaceTopics = (raceId: string, options: ToteRaceStatus[]): string[] => {
  return options.map(type => getToteRaceTopic(raceId, { type }))
}

export function getAccountTopic(accountNumber: number): string {
  return `tabtouch/account/${accountNumber}/#`
}

export const getFobPriceChangeByPropositionTopic = (propositionId: string | number): string => {
  return `tabtouch/fob/market/+/prop/${propositionId}/price-changed`
}

const getRaceBroadcast = (): string => {
  return getToteRaceEventTopic('+') //  Want to subscribe to all race broadcast events. '+' is the wildcard for this.
}

export const getGlobalPushSubscriptions = (): string[] => {
  // Array of all of the topics we wish to globally subscribe to at startup
  return [getRaceBroadcast()]
}
