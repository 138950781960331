import React, { type HTMLAttributes } from 'react'
import styled, { type CSSObject } from '@emotion/styled'
import { Icon } from '../Icon'
import { colors, font, radius, spacing } from '../../../Theme/Common'
import { gapX, gapY } from '../../../Utils/gap'

type Props = InfoBoxStyledProps & HTMLAttributes<HTMLDivElement>

export const InfoBox: React.FC<Props> = ({ children, color = 'grey', size = 'md', ...props }) => {
  return (
    <InfoBoxStyled role='alertdialog' color={color} size={size} {...props}>
      <Icon name='LineInfoCircle' />
      <MessageContainer>{children}</MessageContainer>
    </InfoBoxStyled>
  )
}

InfoBox.displayName = 'InfoBox'

// ==============
//     Styles
// ==============

const InfoBoxStyled = styled.div<InfoBoxStyledProps>(
  {
    alignItems: 'stretch',
    borderRadius: radius.md,
    display: 'flex',
    ...font.size.md,
    a: {
      color: 'inherit',
    },
    '> div': {
      flex: 1,
    },
  },
  ({ color = 'grey', size = 'md' }) => ({
    ...infoBoxSizing[size],
    ...infoBoxColors[color],
  }),
  gapX(spacing.sm)
)

const MessageContainer = styled.div(
  {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    '> span': {
      fontWeight: font.weight.semibold,
    },
  },
  gapY(spacing.smx2)
)

// ==============
//    Variants
// ==============

export const infoBoxSizing: Record<InfoBoxStyledProps['size'], CSSObject> = {
  sm: { padding: `${spacing.smx2} ${spacing.smx1}` },
  md: { padding: spacing.sm },
}

export const infoBoxColors: Record<InfoBoxStyledProps['color'], CSSObject> = {
  grey: { background: colors.neutral[100], color: colors.neutral[900] },
  orange: { background: colors.warning[50], color: colors.warning[800] },
  red: { background: colors.error[50], color: colors.error[700] },
  blue: { background: colors.mariner[50], color: colors.mariner[600] },
}

// ==============
//     Types
// ==============

export type InfoBoxStyledProps = {
  /**
   * The color variant of the info box
   */
  color: InfoBoxColor
  /**
   * The size of the info box
   */
  size: 'sm' | 'md'
}

export type InfoBoxColor = 'grey' | 'orange' | 'red' | 'blue'
