import React from 'react'
import styled from '@emotion/styled'
import { useDispatch } from 'react-redux'
import { colors } from '@mobi/component-library/Theme/Common'
import { Button } from '@mobi/component-library/Common/V2/Buttons'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import { useAppSelector } from '@mobi/betslip/Store/hooks'
import { removeItemFromBetSlipById } from '@mobi/betslip/Store/Bets'
import type { BetSlipItem } from '@mobi/betslip/types'

const enum LocalConstants {
  AutoRemoveCountdownSeconds = 120,
  AnimationDurationMs = 300,
}

export const RemoveButton: React.FC<
  Pick<BetSlipItem, 'id' | 'betErrorType'> & {
    wrapperRef: React.RefObject<HTMLDivElement>
  }
> = ({ id, betErrorType, wrapperRef }) => {
  const dispatch = useDispatch()
  const isBusy = useAppSelector(state => state.betslip.workflow.isBusy)
  const isBettingClosed = betErrorType === 'Closed'

  // store the event function so we can remove it from the element
  const removeItemDispatcher = React.useCallback(
    () => dispatch(removeItemFromBetSlipById({ id })),
    [dispatch, id]
  )

  const removeItemWithAnimationHandler = React.useCallback(() => {
    const wrapperEl = wrapperRef.current
    if (!wrapperEl) {
      removeItemDispatcher()
      return
    }

    wrapperEl.addEventListener('transitionend', removeItemDispatcher)

    wrapperEl.style.height = wrapperEl.offsetHeight + 'px'
    wrapperEl.style.overflow = 'hidden'
    wrapperEl.style.transition = `height ${LocalConstants.AnimationDurationMs}ms linear`

    // this happens synchro in ff so ensure it hapens in next frame with set timeout and not requestAnimationFrame
    setTimeout(() => {
      wrapperEl.style.height = '0px'
    })
  }, [wrapperRef, removeItemDispatcher])

  React.useEffect(() => {
    if (!isBettingClosed) return

    const timerId = setTimeout(() => {
      removeItemWithAnimationHandler()
    }, LocalConstants.AutoRemoveCountdownSeconds * 1_000)

    return () => clearTimeout(timerId)
  }, [isBettingClosed, removeItemWithAnimationHandler])

  // delete listener on unmount
  React.useEffect(() => {
    const wrapperEl = wrapperRef.current
    if (!wrapperEl) {
      return
    }

    return () => wrapperEl.removeEventListener('transitionend', removeItemDispatcher)
  }, [wrapperRef, removeItemDispatcher])

  return (
    <WrapperStyled>
      <Button
        size='xs'
        color='tertiary_grey'
        isIconOnlyButton
        onClick={removeItemWithAnimationHandler}
        disabled={isBusy}
      >
        <Icon
          name='LineTrash01'
          size='1.6rem'
          color={isBettingClosed ? colors.error[600] : colors.black}
        />
      </Button>

      {isBettingClosed && (
        <div>
          <svg viewBox='0 0 36 36'>
            <path
              stroke={colors.error[500]}
              strokeDasharray='0, 100'
              d='M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831'
            />
          </svg>
        </div>
      )}
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  position: 'relative',

  '> div': {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: 'none',

    '@keyframes progress': {
      from: { strokeDasharray: '0 100' },
      to: { strokeDasharray: '100 100' },
    },

    '> svg': {
      display: 'block',
      maxWidth: '100%',

      path: {
        fill: 'none',
        strokeWidth: 2,
        strokeLinecap: 'round',
        animation: `progress ${LocalConstants.AutoRemoveCountdownSeconds}s linear forwards`,
      },
    },
  },
})
