export const isHome = () => isHomeUrl(window.location.hash)

export function navChange(url: string) {
  location.hash = getHashFragment(url)
}

export function getHashFragment(url?: string) {
  url = url || window.location.href
  return url.replace(/^[^#]*#?(.*)$/, '$1')
}

export function isHomeUrl(url: string): boolean {
  return url === '' || url === '#' || url.search('^[?].*$') > -1
}
