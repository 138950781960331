import React, { useRef } from 'react'
import { useQuery } from 'react-query'
import { useObservableImmutable } from '@core/Utils/hooks'
import { state$ as userAccountState$ } from '@core/State/UserAccount/userAccountDriver'
import {
  FallBackBanner,
  LinkWrapper,
  BannerContainer as Container,
  CarouselWrapper,
  BannerPicture,
} from './Components'
import { trackBannerViewed } from './analytics'
import { filterBannersForArea, mapApiBanner } from './helpers'
import { BannerArea } from './types'
import { queryKeys } from '@core/Data/ReactQuery/constants'

export const Banner: React.FC<{ areaName: BannerArea }> = React.memo(({ areaName }) => {
  const { accountNumber } = useObservableImmutable(userAccountState$, ['accountNumber'])

  const { isLoading, error, data } = useQuery(
    [queryKeys.banner],
    () => fetch('/api/contentful/banners', { credentials: 'same-origin' }).then(res => res.json()),
    {
      refetchInterval: 1000 * 60 * 2, // 2min
      refetchOnMount: false,
      refetchOnWindowFocus: true,
    }
  )

  const viewedBannersRef = useRef<Set<number>>(new Set())
  const containerRef = useRef<HTMLDivElement>(null)

  const handleViewChange = (index: number) => {
    const bannerElement = containerRef.current
    const isInViewPort =
      bannerElement &&
      bannerElement.getBoundingClientRect().top > 0 &&
      bannerElement.getBoundingClientRect().top < window.innerHeight

    if (isInViewPort && !viewedBannersRef.current.has(index) && banners[index].name) {
      const bannerData = {
        bannerName: banners[index].name || '',
        internalTrackingCode: banners[index].internalTrackingCode,
        accountNumber: accountNumber,
        bannerLocation: areaName,
        bannerPosition: index,
        clickUrl: (areaName === 'Desktop' ? banners[index].desktopUrl : banners[index].url) || '',
      }

      trackBannerViewed(bannerData)
      viewedBannersRef.current.add(index)
    }
  }

  if (isLoading || accountNumber === undefined) {
    return <Container areaName={areaName} ref={containerRef} />
  }

  const hasData = data && data.length > 0
  const banners = hasData
    ? filterBannersForArea({ areaName, accountNumber, data }).map(mapApiBanner)
    : []

  if (error || banners.length === 0)
    return (
      <Container areaName={areaName} ref={containerRef}>
        <FallBackBanner areaName={areaName} />
      </Container>
    )

  return (
    <Container areaName={areaName} ref={containerRef}>
      <CarouselWrapper key={accountNumber} onViewChange={handleViewChange}>
        {banners.map((banner, index) => (
          <LinkWrapper
            key={`${banner.image}_${index}`}
            url={areaName === 'Desktop' ? banner.desktopUrl : banner.url}
            internalTrackingCode={banner.internalTrackingCode}
            abGroupingTokenPresent={banner.abGroupingTokenPresent}
            analyticsData={{
              bannerName: banner.name,
              internalTrackingCode: banner.internalTrackingCode,
              accountNumber: accountNumber,
              bannerLocation: areaName,
              bannerPosition: index,
              clickUrl: (areaName === 'Desktop' ? banner.desktopUrl : banner.url) || '',
            }}
          >
            <BannerPicture image={banner.image} imageWide={banner.imageWide} areaName={areaName} />
          </LinkWrapper>
        ))}
      </CarouselWrapper>
    </Container>
  )
})
