import { store } from '@core/Store'
import { setBetSlipNewIsOpen } from '@core/Areas/AppHeader/Store'
import { showLogin as showLoginHelper } from '@core/Areas/Login/helpers'

export const toggleBetSlipDrawer = (isOpen: boolean) => {
  store.dispatch(setBetSlipNewIsOpen(isOpen))
}

export const showLogin = () => {
  showLoginHelper({
    onLoginSuccessCallback: () => {
      store.dispatch(setBetSlipNewIsOpen(true))
    },
  })
}

export { postAllBetsToServer } from './postAllBetsToServer'

export { handleShareBet } from './shareBet/handleShareBet'
export { canShareBet } from './shareBet/canShareBet'
