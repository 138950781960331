import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export const DetailsSecondaryInfoStyled = styled.div({
  fontSize: '1rem',
  color: hexColors.dimGrey,
  lineHeight: 1.5,

  dl: { margin: 0 },

  'dl > div': {
    display: 'flex',

    dd: { marginLeft: '1rem', padding: 0 },
  },

  p: {
    fontSize: 'inherit',
    textAlign: 'left',
    margin: '0.5rem 0',
  },

  '& > *:not(:last-child)': { marginBottom: '1rem' },
})
