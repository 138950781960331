import React from 'react'
import dayjs from 'dayjs'
import { useAppSelector } from '@core/Store/hooks'
import { useFeature } from '@core/Utils/hooks'
import { type RaceStatus } from '@core/Areas/Racing/Hooks/useRaceList/types'
import {
  getVisionChannelForDisplay,
  isMatchingVideoPlaying,
} from '@core/Areas/SkyVideoPlayer/helpers'
import { SkyTvButton } from '@mobi/component-library/Racing/SkyTvIndicator'
import { toggleSkyVideoPlayer } from '@core/Areas/SkyVideoPlayer/helpers/toggleSkyVideoPlayer'
import {
  selectIsSkyVideoPlayerOpen,
  selectRaceReplay,
  selectVideoPlayerChannel,
  selectVideoPlayerStatus,
} from '@core/Areas/SkyVideoPlayer/Store/selectors'
import { RaceCodesLowerCase } from '@core/Areas/Racing/Types/MeetingInformation'
import { getOrdinalSuffix } from '@mobi/utils/number/ordinal'

const RACE_NOT_FINISHED_STATUSES: RaceStatus[] = ['Open', 'Closed', 'Pending']

export interface SkyVideoButtonRace {
  raceReplay?: {
    raceNumber: number
    trackName: string
    raceReplayKey: string
    raceCode: RaceCodesLowerCase
    meetingDate: string
    formReplay?: {
      starterName: string
      starterNumber: number
      racePosition: string
    }
  }
  advertisedStartTime: string
  raceStatus: RaceStatus
  skyTvChannelId?: string
}

export const SkyVideoButton: React.FC<{
  race: SkyVideoButtonRace
  isHeaderPinned: boolean
  isRacingWA?: boolean
  openLocation: 'form' | 'race-card' | 'sky-page'
}> = ({ race, isHeaderPinned, isRacingWA, openLocation }) => {
  const [showLiveIcon, setShowLiveIcon] = React.useState(false)

  const isSkyVideoPlayerOpen = useAppSelector(selectIsSkyVideoPlayerOpen)
  const activeRaceReplay = useAppSelector(selectRaceReplay)
  const activeChannel = useAppSelector(selectVideoPlayerChannel)
  const playerStatus = useAppSelector(selectVideoPlayerStatus)

  // deliberately not using the videoKey since it is not a constant
  const isSameRaceReplay =
    race.raceReplay?.trackName === activeRaceReplay.trackName &&
    race.raceReplay?.raceNumber === activeRaceReplay.raceNumber &&
    race.raceReplay?.meetingDate === activeRaceReplay.meetingDate

  const isRacingWAFeatureActive = useFeature('RACING_WA_VISION_CHANNEL')
  const hasRaceReplayImprovements = useFeature('RACE_REPLAY_IMPROVEMENTS')

  React.useEffect(() => {
    if (!race) return

    const checkRaceStartTime = () => {
      const ast = race.advertisedStartTime
      const now = dayjs()
      const lessThanMinute = dayjs(ast).isBefore(now.add(1, 'minute'))
      // Check to remove live button if race isn't settled after race completed
      // Only really an edge case in the Test Envs
      const isPastAstBuffer = dayjs(race?.advertisedStartTime).isBefore(now.subtract(1, 'hour'))
      setShowLiveIcon(lessThanMinute && !isPastAstBuffer)
    }
    checkRaceStartTime()
    const timerId = setInterval(checkRaceStartTime, 1000)
    return () => clearInterval(timerId)
  }, [race])

  const shouldDisplayRacingWA = isRacingWA && isRacingWAFeatureActive

  const channel = getVisionChannelForDisplay(
    race.skyTvChannelId as string,
    !!shouldDisplayRacingWA,
    hasRaceReplayImprovements && !!race.raceReplay?.raceReplayKey
  )

  const handleButtonClick = async () => {
    // close the video if the current video matches the button, for example:
    // - replay button clicked and the same race replay is showing
    // - sky1 button clicked and sky1 video showing
    if (isMatchingVideoPlaying(isSkyVideoPlayerOpen, activeChannel, channel, isSameRaceReplay))
      return toggleSkyVideoPlayer({ isPlayerOpen: false })

    const headerState = isHeaderPinned ? 'sticky' : 'default'

    // if a video is already playing, then re-use the current state (docked, floating, fullscreen)
    let desiredPlayerStatus = isSkyVideoPlayerOpen ? playerStatus : 'docked'

    toggleSkyVideoPlayer({
      isPlayerOpen: true,
      playerStatus: desiredPlayerStatus,
      selectedChannel: channel,
      analyticsData: {
        openLocation,
        headerState,
        channel,
        raceCode: race.raceReplay?.raceCode,
      },
      raceReplayKey: race.raceReplay?.raceReplayKey,
      ...(race.raceReplay && {
        raceReplayTitle: {
          raceNumber: race.raceReplay.raceNumber,
          trackName: race.raceReplay.trackName,
          ...(race.raceReplay.formReplay && {
            position:
              // This check is required for dogs as their position data already includes the suffix
              race.raceReplay.raceCode === 'dogs'
                ? race.raceReplay.formReplay.racePosition
                : race.raceReplay.formReplay.racePosition +
                  getOrdinalSuffix(race.raceReplay.formReplay.racePosition),
            starterName: race.raceReplay.formReplay.starterName,
            starterNumber: race.raceReplay.formReplay.starterNumber,
          }),
        },
      }),
      raceReplayMeetingDate: race.raceReplay?.meetingDate,
    })
  }

  let isPlaying = isMatchingVideoPlaying(
    isSkyVideoPlayerOpen,
    activeChannel,
    channel,
    isSameRaceReplay
  )

  const shouldShowLive = showLiveIcon && RACE_NOT_FINISHED_STATUSES.includes(race.raceStatus)

  return (
    <SkyTvButton
      channel={channel}
      isLive={shouldShowLive}
      isPlaying={isPlaying}
      onClick={handleButtonClick}
    />
  )
}
