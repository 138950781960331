import React from 'react'
import styled from '@emotion/styled'
import { toTitleCase } from '@mobi/utils/string/casing'
import { colors, font, radius, spacing } from '@mobi/component-library/Theme/Common'
import { Acceptor } from '@mobi/betslip/types'
import { SilkImage } from '@mobi/betslip/Components/Common/SilkImage/SilkImage'

export const SelectionInfoMultiple: React.FC<{
  selections: Map<string, Acceptor[] | undefined>
}> = ({ selections }) => {
  const labelElementsRef = React.useRef<HTMLDivElement[]>([])

  React.useLayoutEffect(() => {
    const widths = labelElementsRef.current.map(el => Math.ceil(el.getBoundingClientRect().width))
    const largestWidth = Math.max(...widths)
    labelElementsRef.current.forEach(el => (el.style.width = largestWidth + 'px'))
  }, [])

  const captureLabelEls = React.useCallback((el: HTMLDivElement) => {
    labelElementsRef.current.push(el)
  }, [])

  return (
    <WrapperStyled>
      {Array.from(selections).map(selection => {
        const label = selection[0]
        const acceptors = selection[1]

        if (!acceptors || acceptors.length === 0) return null

        return (
          <div key={label}>
            <div ref={captureLabelEls}>
              <span>{label}</span>
            </div>

            <ul>
              {acceptors.map(acceptor => (
                <li key={acceptor.key}>
                  <SilkImage acceptor={acceptor} size={18} />
                  {acceptor.number} - {toTitleCase(acceptor.name)}
                </li>
              ))}
            </ul>
          </div>
        )
      })}
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.smx2,
  fontFamily: font.family.primary,

  ':empty': { display: 'none' },

  '> div': {
    display: 'flex',
    gap: spacing.sm,
    padding: spacing.smx1,
    backgroundColor: colors.surface[200],
    borderRadius: radius.md,

    // Label
    '> div': {
      display: 'flex',
      flexDirection: 'column',

      '> span': {
        display: 'inline-flex',
        alignItems: 'center',
        paddingLeft: spacing.smx2,
        paddingRight: spacing.smx2,
        minHeight: '2rem',
        maxWidth: 'fit-content',
        fontWeight: font.weight.regular,
        fontSize: font.size.xs.fontSize,
        letterSpacing: font.size.xs.letterSpacing,
        color: colors.neutral[900],
        borderRadius: radius.smx1,
        backgroundColor: colors.surface[400],
      },
    },

    // Runner List
    '> ul': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      padding: 0,
      margin: 0,
      listStyle: 'none',

      '> li': {
        display: 'inline-flex',
        alignItems: 'center',
        gap: spacing.smx2,
        minHeight: '2rem',
        marginTop: spacing.smx2,
        paddingBottom: spacing.smx2,
        fontWeight: font.weight.regular,
        fontSize: font.size.md.fontSize,
        lineHeight: font.size.md.lineHeight,
        letterSpacing: font.size.md.letterSpacing,
        color: colors.black,
        borderBottom: `0.5px solid ${colors.neutral[300]}`,

        ':first-of-type': { marginTop: 0 },
        ':last-of-type': { border: 0, paddingBottom: 0 },
      },
    },
  },
})
