import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import type {
  StartingPriceFOOSelection,
  FobPropositionSelection,
  FobDetails,
} from '@mobi/betslip/types'
import { ContentHeader } from '@core/Components/ContentHeader'
import { MainContainer } from '@core/Components/Containers'
import { useInducementService } from '@core/Utils/hooks/useInducementService'
import type { RaceCodesLowerCase } from '@core/Areas/Racing/Types/MeetingInformation'
import { GridCell } from '@mobi/component-library/Common/Grid'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'
import { BettingDrawer } from '@core/Components/BettingDrawer'
import * as iconPaths from '@mobi/component-library/Common/Icon/iconPaths'
import { QuickbetSelection } from '@core/Areas/Quickbet/signals'
import { useDisableAppHeaderSticky } from '@core/Areas/AppHeader/hooks/useDisableAppHeaderSticky'
import { BettingInformationOverrides } from '@core/Areas/RaceCard/constants'
import { RaceNavigation } from '@core/Areas/Racing/Components/Navigation/RaceNavigation'
import { RaceProduct, RaceStatus } from './constants'
import { PropositionList } from './Components/PropositionList'
import { FieldToggle } from './Components/FieldToggle'
import { ExtraInfo } from './Components/ExtraInfo'
import type { FixedOddsEventDetailsResponse, SelectedFOOProposition } from './Data/types'
import type { Direction } from '../RaceCard/Components/RaceSwipeContainer/types'
import { useClearSelectionOnPlacement } from './Hooks/useClearSelectionOnPlacement'
import { InfoNoticeBoxStyled } from './RaceCardFixedOnly.styles'
import { EventInfo } from './Components/EventInfo'
import { useFixedOddsEventDetails } from '@core/Areas/RaceCardFixedOnly/Hooks/useFixedOddsEventDetails'
import { useRaceFieldSummaryData } from '@core/Areas/RaceCardFixedOnly/Hooks/useRaceFieldSummaryData'
import { useFeature } from '@core/Utils/hooks'
import { useDisableAndroidBackGesture } from '../RaceCard/Hooks/useDisableAndroidBackGesture'
import { SkeletonLoader as RaceCardSkeletonLoader } from '../RaceCard/Components/SkeletonLoader'
import { SkeletonLoader as RaceNavigationSkeletonLoader } from '../Racing/Components/Navigation/Components/SkeletonLoader'
import { useRaceList } from '../Racing/Hooks/useRaceList/useRaceList'
import { RaceSwipeContainer } from '../RaceCard/Components/RaceSwipeContainer/RaceSwipeContainer'
import { getRaceLink } from '../Racing/helpers'
import { handleBetSelection } from '@core/Utils/betting/handleBetSelection'

export const RaceCardFixedOnly: React.FC<{}> = () => {
  useDisableAppHeaderSticky()
  const canShowInducements = useInducementService()

  const {
    product,
    code,
    raceId: competitionNumber,
  } = useParams<{ product: string; code: RaceCodesLowerCase; raceId: string }>()
  const history = useHistory()
  const raceProduct = product.toUpperCase() as RaceProduct
  const raceCode = code?.toLowerCase() as RaceCodesLowerCase

  const selectionDate = useSearchParams().get('selectiondate') as string
  const meetingSeq = useSearchParams().get('meetingseq') as string

  const [selectedItem, setSelectedItem] = React.useState<SelectedFOOProposition | null>(null)
  const [fieldState, setFieldState] = React.useState<boolean>(false)

  const { isLoading, isError, data, refetch, isFetching } = useFixedOddsEventDetails({
    competitionNumber,
    raceProduct,
    raceCode,
  })

  useDisableAndroidBackGesture()
  const racecourseSeq = data?.racecourseSequence
  const raceNumber = data?.eventNumber
  const isFormSummaryFeatureActive = useFeature('FOO_FORM_SUMMARY')
  const { data: fieldData } = useRaceFieldSummaryData({
    racecourseSeq,
    selectionDate,
    raceNumber,
    isFormSummaryFeatureActive,
  })

  const { meeting } = useRaceList(selectionDate, '', meetingSeq, raceProduct)

  const races = meeting?.races ?? []
  const clearSelection = React.useCallback(() => setSelectedItem(null), [])
  useClearSelectionOnPlacement(clearSelection)

  const currentRace = races?.find(race => race.raceNumber === raceNumber)
  const prevRace = currentRace && races.find(race => race.raceNumber === currentRace.raceNumber - 1)

  const nextRace = currentRace && races.find(race => race.raceNumber === currentRace.raceNumber + 1)

  const lastRaceNumber = races.length > 0 ? races[races.length - 1].raceNumber : null
  const firstRaceNumber = races.length > 0 ? races[0].raceNumber : null

  React.useEffect(() => {
    if (isLoading || isFetching) {
      clearSelection()
    }
  }, [isLoading, isFetching, clearSelection])

  if (isLoading) {
    return (
      <div data-testid='fixed-racing-loading'>
        <RaceNavigationSkeletonLoader />
        <RaceCardSkeletonLoader />
      </div>
    )
  }

  if (isError || !data) {
    return (
      <GridCell padding='1rem'>
        <NoticeBox
          title='Unable to display event details at this time. Please try again later.'
          noticeBoxType={NoticeBoxTypes.Warning}
          hasBorder
        />
      </GridCell>
    )
  }

  const isStartingPriceAvailable = data.isSpAvailable

  const competitionName = getTitle(code, product)
  const shouldShowDeductions =
    (data.eventStatus == RaceStatus.Resulted || data.eventStatus == RaceStatus.Settled) &&
    data.propositions.filter(
      x => x.racingStatus == RaceStatus.Scratched && (x.winDeduction || x.placeDeduction)
    ).length > 0

  const handleQuickbetClick = () => {
    if (!selectedItem || !data) return
    handleBetSelection({
      betFlow: { location: 'Quickbet' },
      selection: convertItemToBetslipItem(
        selectedItem,
        competitionName,
        competitionNumber,
        data,
        raceProduct,
        raceCode
      ),
    })
  }

  const handleAddToBetslipClick = () => {
    if (!selectedItem || !data) return
    handleBetSelection({
      betFlow: { location: 'Betslip' },
      selection: convertItemToBetslipItem(
        selectedItem,
        competitionName,
        competitionNumber,
        data,
        raceProduct,
        raceCode,
        meetingSeq
      ),
    })

    clearSelection()
  }

  const toggleFieldState = () => setFieldState(curr => !curr)
  const selectItem = (item: SelectedFOOProposition) =>
    selectedItem &&
    selectedItem.sequenceNumber === item.sequenceNumber &&
    selectedItem.isStartingPrice === item.isStartingPrice
      ? clearSelection()
      : setSelectedItem(item)

  return (
    <MainContainer data-tid-fixedodds background='white'>
      {raceProduct === RaceProduct.FixedOddsOnly ? (
        <RaceNavigation
          meetingDate={selectionDate}
          raceNumber={data.eventNumber}
          meetingSeq={meetingSeq}
          meetingId=''
          product={raceProduct}
          fooPayIndicator={data.payIndicator}
        />
      ) : (
        <>
          <ContentHeader title={competitionName} refreshCallback={refetch} />
          <EventInfo
            icon={getBetSlipIcon(raceProduct, raceCode)}
            eventNumber={data.eventNumber}
            eventLocation={data.eventLocation}
            eventName={data.eventName}
            eventDate={data.eventStartTime}
            eventStatus={
              data.eventStatus === RaceStatus.Suspended && isStartingPriceAvailable
                ? RaceStatus.Open
                : data.eventStatus
            }
            priceLastUpdateTime={data.priceLastUpdateTime}
            raceProduct={raceProduct}
            raceCode={raceCode}
            hasSpecials={data.specials?.length > 0}
            payIndicator={data.payIndicator}
            fieldState={fieldState}
            onFieldClicked={toggleFieldState}
          />
        </>
      )}

      {isFetching ? (
        <div data-testid='fixed-racing-loading'>
          <RaceCardSkeletonLoader />
        </div>
      ) : (
        <RaceSwipeContainer
          onSwipeCallback={(direction: Direction) => {
            const raceToSwipeTo = direction === 'BACKWARD' ? prevRace : nextRace
            if (raceToSwipeTo && meeting) {
              const selectRaceLink = getRaceLink({
                meetingId: meeting.meetingId,
                raceNumber: raceToSwipeTo.raceNumber,
                meetingDate: meeting.meetingDate,
                code: meeting.meetingCode,
                meetingSeq: meeting.fixed?.competitionSequence,
                product: raceToSwipeTo.fixed?.product,
                raceSeq: raceToSwipeTo.fixed?.eventSequence,
                isFOO: true,
              })
              history.push(selectRaceLink)
            }
          }}
          isLastRace={!currentRace || lastRaceNumber === currentRace.raceNumber}
          isFirstRace={!currentRace || firstRaceNumber === currentRace.raceNumber}
        >
          {[RaceStatus.Resulted, RaceStatus.Settled].includes(data.eventStatus) &&
            raceProduct === RaceProduct.FixedOddsOnly && (
              <FieldToggle enabled={fieldState} toggleFieldState={toggleFieldState} />
            )}

          {raceProduct == RaceProduct.FixedOddsOnly && data.eventStatus == RaceStatus.Abandoned && (
            <InfoNoticeBoxStyled
              title='Race Abandoned - Refunds Available'
              noticeBoxType={NoticeBoxTypes.Warning}
              hasBorder={false}
            />
          )}

          <PropositionList
            raceStatus={data.eventStatus}
            fieldSummary={fieldData}
            raceProduct={raceProduct}
            raceCode={raceCode}
            payIndicator={data.payIndicator}
            items={data.propositions}
            selectedItem={selectedItem}
            onItemSelected={selectItem}
            isFieldSelected={fieldState}
            isStartingPriceAvailable={isStartingPriceAvailable}
            racecourseSeq={racecourseSeq}
            meetingDate={selectionDate}
            raceNumber={data.eventNumber}
            raceLocation={data.eventLocation}
          />

          <GridCell padding='0.5rem' />

          {shouldShowDeductions && (
            <ExtraInfo title='Fixed Odds Deductions' type='deductions' items={data.propositions} />
          )}

          {data.bettingInformation && (
            <ExtraInfo
              title='Betting Information - Fixed'
              type='info'
              items={[
                isStartingPriceAvailable
                  ? {
                      title:
                        BettingInformationOverrides.StartingPrice[0].title + ' (Starting Price)',
                      description: BettingInformationOverrides.StartingPrice[0].detail[0],
                    }
                  : { title: '', description: '' },
                { title: 'Betting Conditions', description: data.bettingInformation.conditions },
                { title: 'Betting Type Definition', description: data.bettingInformation.terms },
                { title: 'Betting Rules', description: data.bettingInformation.rules },
              ]}
            />
          )}

          {canShowInducements && data.specials?.length > 0 && (
            <ExtraInfo title='Specials Information' type='specials' items={data.specials} />
          )}

          <BettingDrawer
            open={!!selectedItem}
            handleAddToBetslipClick={handleAddToBetslipClick}
            handleQuickbetClick={handleQuickbetClick}
          />
        </RaceSwipeContainer>
      )}
    </MainContainer>
  )
}

// =============
// Local Helpers
// =============

function useSearchParams() {
  const params = new URLSearchParams()
  new URLSearchParams(useLocation().search).forEach((value, name) => {
    params.append(name.toLowerCase(), value)
  })
  return params
}

function convertItemToBetslipItem(
  selectedItem: SelectedFOOProposition,
  competitionName: string,
  competitionNumber: string,
  event: FixedOddsEventDetailsResponse,
  raceProduct: RaceProduct,
  raceCode: RaceCodesLowerCase,
  meetingSeq?: string
): QuickbetSelection {
  const isStartingPrice = selectedItem.isStartingPrice
  const isFixedOddsOnly = raceProduct == RaceProduct.FixedOddsOnly
  const propositionSeq = selectedItem.sequenceNumber?.toString()
  const shouldAllowPlaceInvestment = !isStartingPrice && isFixedOddsOnly
  const { eventName, eventNumber, eventLocation, eventStartTime } = event

  let selection: FobPropositionSelection | StartingPriceFOOSelection
  if (isStartingPrice) {
    selection = {
      type: 'fob-proposition',
      propositionSeq,
      winPrice: null,
      placePrice: null,
      marketCode: 'SP',
      marketName: 'Starting Price',
    }
  } else {
    selection = {
      type: 'fob-proposition',
      propositionSeq,
      priceSource: 'selection',
      winPrice: selectedItem.winReturn,
      winPriceLastSeen: selectedItem.winReturn,
      placePrice: selectedItem.placeReturn,
      placePriceLastSeen: selectedItem.placeReturn,
    }
  }

  const selectionDetails: FobDetails = {
    event: competitionNumber,
    competitionName: isFixedOddsOnly ? `${eventLocation}:R${eventNumber}` : competitionName,
    marketName: isStartingPrice ? 'Starting Price' : isFixedOddsOnly ? 'Win + Place' : eventName,
    propositionName: selectedItem.name,
    sportIconCode: getBetSlipIcon(raceProduct, raceCode),
    eventStartTime,
    meetingSeq,
  }

  const isEachWayAvailable = isFixedOddsOnly ? false : !!selection.placePrice

  return {
    bettingType: 'fixed-odds-racing',
    isEachWayAvailable,
    shouldAllowPlaceInvestment,
    selection,
    selectionDetails,
  }
}

const getBetSlipIcon = (
  raceProduct: RaceProduct,
  raceCode: RaceCodesLowerCase
): keyof typeof iconPaths => {
  if (raceProduct == RaceProduct.FixedOddsOnly) return raceCode
  if (raceProduct == RaceProduct.Challenge && raceCode == 'dogs') return 'greysboxchallenge'
  if (raceProduct == RaceProduct.Challenge && raceCode == 'races') return 'jockeychallenge'
  if (raceProduct == RaceProduct.FeatureRace) return 'featureraces'
  return 'tab'
}

const getTitle = (code: RaceCodesLowerCase, product: string): string => {
  product = product.toUpperCase()
  code = code.toLowerCase() as RaceCodesLowerCase
  if (product == RaceProduct.Challenge) {
    if (code == 'dogs') return 'Greys Box Challenge'
    if (code == 'races') return 'Jockey Challenge'
  }
  if (product == RaceProduct.FeatureRace) return 'Futures'
  if (product == RaceProduct.FutureFinalField || product == RaceProduct.FixedOddsOnly) {
    if (code == 'dogs') return 'Dogs'
    if (code == 'races') return 'Races'
    if (code == 'trots') return 'Trots'
  }
  return 'Unknown'
}
