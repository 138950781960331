import type { RootState } from '@core/Store'

export const selectIsSkyVideoPlayerOpen = ({ skyVideoPlayer }: RootState) =>
  skyVideoPlayer.isPlayerOpen

export const selectIsVideoPlayerFloating = ({ skyVideoPlayer }: RootState) =>
  skyVideoPlayer.playerStatus === 'floating'

export const selectIsDockedSkyVideoPlayerOpen = ({ skyVideoPlayer }: RootState) =>
  skyVideoPlayer.playerStatus === 'docked' && skyVideoPlayer.isPlayerOpen

export const selectDockedPlayerHeight = ({ skyVideoPlayer }: RootState) =>
  skyVideoPlayer.dockedPlayerHeight

export const selectCanRenderPendingBetsDrawer = ({ skyVideoPlayer }: RootState) =>
  skyVideoPlayer.canRenderPendingBetsDrawer

export const selectIsPendingBetsDrawerOpen = ({ skyVideoPlayer }: RootState) =>
  skyVideoPlayer.isPendingBetsDrawerOpen

export const selectVideoPlayerChannel = ({ skyVideoPlayer }: RootState) =>
  skyVideoPlayer.selectedChannel

export const selectVideoPlayerStatus = ({ skyVideoPlayer }: RootState) =>
  skyVideoPlayer.playerStatus

export const selectRaceReplay = ({ skyVideoPlayer }: RootState) => ({
  trackName: skyVideoPlayer.raceReplayTitle?.trackName,
  raceNumber: skyVideoPlayer.raceReplayTitle?.raceNumber,
  meetingDate: skyVideoPlayer.raceReplayMeetingDate,
})
