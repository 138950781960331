import React from 'react'
import { useDispatch } from 'react-redux'
import { useInducementService } from '@core/Utils/hooks/useInducementService'
import { setBetSlipCanShowInducementsSync } from '@mobi/betslip/Store/Workflow'

export const InducementSync: React.FC = () => {
  const dispatch = useDispatch()
  const canShowInducements = useInducementService()

  React.useEffect(() => {
    dispatch(setBetSlipCanShowInducementsSync(canShowInducements))
  }, [dispatch, canShowInducements])

  return null
}
