import React, { useEffect, useRef } from 'react'
import {
  trackRaceReplayVideoClosed,
  trackRaceReplayVideoFullScreen,
  trackRaceReplayVideoMute,
} from './analytics'

export function ReplayVideoPlayer({
  raceLocation,
  raceDate,
  videoSource,
  onCloseTapped,
  onFinished,
}: {
  raceLocation: string
  raceDate: string
  videoSource: string
  onCloseTapped?: () => void
  onFinished?: () => void
}) {
  const videoRef = useRef<HTMLVideoElement>(null)

  const handleMuteToggle = () => {
    if (!videoRef.current) return
    const videoEl = videoRef.current
    const isMuted = videoEl.volume < 0.1 || videoEl.muted
    trackRaceReplayVideoMute(isMuted)
  }

  useEffect(() => {
    const handleFullscreenChange = () => {
      trackRaceReplayVideoFullScreen(!!document.fullscreenElement)
    }

    document.addEventListener('fullscreenchange', handleFullscreenChange)
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange)
    const videoEl = videoRef.current as unknown as HTMLVideoElement
    if (videoEl.webkitSupportsPresentationMode) {
      videoEl.addEventListener('webkitpresentationmodechanged', handleFullscreenChange)
    }

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange)
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange)
      videoEl.removeEventListener('webkitpresentationmodechanged', handleFullscreenChange)
      trackRaceReplayVideoClosed()
    }
  }, [])

  return (
    <div className='video-container' data-tid-race-replay>
      <div>
        <header>
          <span className='race-location'>{raceLocation}</span>
          <span className='race-date'>{raceDate}</span>
          <a
            className='close-video'
            style={{ marginTop: '-7px' }}
            onClick={() => onCloseTapped && onCloseTapped()}
          >
            <i className='tticon tticon-cross'></i>
          </a>
        </header>

        <video
          ref={videoRef}
          style={{ width: '100%', height: 'auto', display: 'inline' }}
          controls={true}
          autoPlay={true}
          onEnded={() => onFinished && onFinished()}
          onVolumeChange={handleMuteToggle}
          data-testid='race-replay-video'
        >
          <source src={videoSource} />
        </video>
      </div>
    </div>
  )
}
