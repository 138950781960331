import styled from '@emotion/styled'
import { colors, font, radius, spacing } from '@mobi/component-library/Theme/Common'

export const VersionSelectorHeader = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  marginBottom: '1rem',
})

export const VersionSelectorListStyled = styled.ol<{ addSpacing?: boolean }>(
  {
    listStyle: 'none',
    margin: 0,
    padding: 0,

    ...font.size['lg'],
  },
  ({ addSpacing = false }) => {
    if (addSpacing) {
      return {
        '& li:not(:last-child)': {
          marginBottom: spacing.sm,
        },
      }
    }
    return {}
  }
)

type VersionSelectorListItemProps = {
  highlightItem?: boolean
}

export const VersionSelectorListItemStyled = styled.li<VersionSelectorListItemProps>(
  {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: radius.lgx1,
    boxShadow: 'none',
    backgroundColor: colors.neutral['100'],
    padding: '1.6rem',
  },
  ({ highlightItem = false }) => {
    if (highlightItem) {
      return {
        border: `0.1rem solid ${colors.lavender[500]}`,
      }
    }
    return {}
  }
)

export const BadgeStyled = styled.span({
  backgroundColor: colors.lavender[500],
  ...font.size['lg'],
  borderRadius: radius.lgx3,
  color: colors.neutral['100'],
  padding: '0.2rem 0.8rem',
})

export const AccordionStyled = styled.div({
  marginTop: '1rem',
})
