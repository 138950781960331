import React from 'react'
import ko from 'knockout'
import { Redirect, Link } from 'react-router-dom'
import { ToolboxViewModel } from './viewModel'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@core/Store'
import { Toggle } from '@mobi/component-library/Common/Input'
import { toggleReactQueryDevTools } from './Store'
import { VersionInfo } from './Components/VersionInfo'
import { CookieManagement } from './Components/CookieManagement'
import { LocalStorageManager } from './Components/LocalStorageManager/LocalStorageManager'
import { LastPaymentMethod } from '@core/Areas/Toolbox/Components/LastPaymentMethod/LastPaymentMethod'
import { Sentry } from '@core/Areas/Toolbox/Components/Sentry/Sentry'
import { useServerConfig } from '@core/Data/ServerConfig/useServerConfig'

export const Toolbox = () => {
  const toolboxElRef = React.useRef<HTMLDivElement>(null)
  const serverConfig = useServerConfig()

  const dispatch = useDispatch()
  const isReactQueryDevToolsEnabled = useSelector(
    (state: RootState) => state.toolbox.isReactQueryDevToolsEnabled
  )

  React.useEffect(() => {
    if (!serverConfig?.isInternalRequest || !toolboxElRef.current) return undefined

    const viewModel = new ToolboxViewModel()
    ko.applyBindings(viewModel, toolboxElRef.current)

    return () => {
      viewModel.cleanup()
    }
  }, [serverConfig?.isInternalRequest])

  if (!serverConfig) return null
  if (serverConfig.isInternalRequest === false) return <Redirect to='/' />

  return (
    <div ref={toolboxElRef}>
      <VersionInfo />

      <h3>
        <Link to='design-system' style={{ color: 'white' }}>
          Design System
        </Link>
      </h3>

      <h3>
        React-Query DevTools{' '}
        <Toggle
          onToggle={() => dispatch(toggleReactQueryDevTools())}
          toggledOn={isReactQueryDevToolsEnabled}
          isDisabled={false}
        />
      </h3>

      <CookieManagement />

      <h3>GPS and Location</h3>
      <p>
        <button
          data-bind='click: updateLastKnownLocation'
          className='fz-12 nue-button nue-secondary'
        >
          Get GPS Coords
        </button>
      </p>
      <p>
        GPS Coords: <span data-bind='text: lastKnownLocationText'></span>
        <br />
        <span data-bind='text: dateLastKnownLocationUpdated'></span>
      </p>
      <hr />
      <p>
        <button
          data-bind='click: getCurrentVenueLocation'
          className='fz-12 nue-button nue-secondary'
        >
          Get Venue/Location
        </button>
      </p>

      <p>
        Current Venue/Location: <span data-bind='text: currentVenueLocation'></span>
        <br />
        <span data-bind='text: currentVenueLocationUpdatedAt'></span>
      </p>

      <h3>Feature Toggles</h3>
      <dl className='fz-14 pl1em'>
        <dt>
          <strong>AppCapability</strong>
        </dt>
        <dd className='ml1em'>
          <span data-bind='text: appCapability'></span>
        </dd>
        <dt>
          <strong>Launch Darkly Features</strong>
        </dt>
        <div data-bind='foreach: ldFeatures'>
          <dd className='ml1em'>
            <span data-bind='text: $data[0]'></span>:
            <span data-bind="text: $data[1], style: { color: $data[1] ? 'green' : 'red' }"></span>
          </dd>
        </div>
      </dl>

      <h3>Notification</h3>
      <dl className='fz-14 pl1em'>
        <dt>authorization:</dt>
        <dd className='ml1em'>
          <span data-bind='text: authStatus'></span>
        </dd>
        <dt>fcmToken:</dt>
        <dd className='ml1em'>
          <span data-bind='text: fcmToken'></span>
        </dd>
        <button data-bind='click: getPushAuthStatus' className='fz-12 nue-button nue-secondary'>
          Get Auth Status
        </button>
      </dl>

      <LocalStorageManager />

      <LastPaymentMethod />

      <Sentry />
    </div>
  )
}
