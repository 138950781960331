import React from 'react'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { colors, radius, spacing, font } from '@mobi/component-library/Theme/Common'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import { Icon as LegacyIcon } from '@mobi/component-library/Common/Icon'
import { useExpandToggle } from '@mobi/utils/hooks/useExpandToggle'
import type { PromoSelectionObject, PromoType, PromoTypeClass } from './types'
import { PromoSelection } from './PromoSelection'
import { promoTypeColorThemes } from './constants'

const enum LocalConstants {
  AccordionExpandedClass = 'js-accordion--expanded',
  ChevronClass = 'js-accordion__chevron',
}

export const PromoSelector: React.FC<{
  itemId: string
  promoType: PromoType
  selections: PromoSelectionObject[]
  onToggle?: (isExpanded: boolean) => void
}> = ({ promoType, itemId, selections, onToggle }) => {
  const [buttonId] = React.useState<string>(() => `${promoType}.button`)
  const [regionId] = React.useState<string>(() => `${promoType}.region`)

  const [selectionHeight, setSelectionHeight] = React.useState(0)

  const hasActiveSelection = selections.some(selection => selection.isSelected)

  const { wrapperRef, contentRef, shouldRender, isExpanded, toggleExpanded } = useExpandToggle({
    timeoutMs: 400,
    collapsedHeight: hasActiveSelection ? selectionHeight : 0,
  })

  const promoTypeClass: PromoTypeClass = `PromoType-${promoType}`
  const classNames: string[] = [promoTypeClass]
  if (isExpanded) classNames.push(LocalConstants.AccordionExpandedClass)

  return (
    <ContainerStyled className={classNames.join(' ')}>
      <h2>
        <button
          onClick={() => {
            onToggle?.(isExpanded)
            toggleExpanded()
          }}
          aria-expanded={isExpanded}
          aria-controls={regionId}
          id={buttonId}
        >
          {promoType === 'SuperPick' && (
            <span>
              <LegacyIcon type='specials' color={hexColors.lightOrange} size='1.6rem' /> SuperPicks
            </span>
          )}

          <span className={LocalConstants.ChevronClass}>
            <Icon size='2rem' name='SolidChevronDown' color='inherit' />
          </span>
        </button>
      </h2>

      {(hasActiveSelection || isExpanded || shouldRender) && (
        <div ref={wrapperRef} aria-labelledby={buttonId} id={regionId} role='region'>
          <div ref={contentRef}>
            {selections.map((selection, i) => (
              <PromoSelection
                key={selection.title}
                itemId={itemId}
                promoType={promoType}
                title={selection.title}
                isSelected={selection.isSelected}
                hasActiveSelection={hasActiveSelection}
                onClick={selection.onClick}
                descriptionSlots={selection.descriptionSlots}
                setSelectionHeight={setSelectionHeight}
                isPromoSelectorExpanded={isExpanded}
                renderPosition={i}
              />
            ))}
          </div>
        </div>
      )}
    </ContainerStyled>
  )
}

// ======
// Styles
// ======

const ContainerStyled = styled.div(
  {
    overflow: 'hidden',
    backgroundColor: colors.white,
    borderRadius: radius.md,
    border: '0.1rem solid var(--main-color)',

    // Header
    '> h2': {
      margin: 0,

      '> button': {
        boxSizing: 'border-box',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: '4rem',
        padding: `${spacing.smx2} ${spacing.sm} ${spacing.smx2} ${spacing.md}`,
        background: 'var(--main-color)',
        border: 0,

        '&:hover': { cursor: 'pointer' },

        // Title Text
        '> span:first-of-type': {
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          gap: spacing.smx1,
          fontFamily: font.family.primary,
          fontSize: font.size.lg.fontSize,
          letterSpacing: font.size.lg.letterSpacing,
          lineHeight: font.size.lg.lineHeight,
          color: colors.black,
          fontWeight: font.weight.medium,
          textAlign: 'left',
        },

        // Chevron Icon
        ['> .' + LocalConstants.ChevronClass]: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          transform: 'rotate(0deg)',
          transition: 'all 400ms ease',
          color: colors.black,
        },
      },
    },

    // Wrapper
    '> div': {
      overflow: 'hidden',
      height: 0,
      transition: 'height 400ms ease, opacity 400ms ease',
      willChange: 'height',

      '> div': {
        display: 'grid',
        gridAutoRows: '1fr',
        padding: '0 ' + spacing.sm,
      },
    },

    ['&.' + LocalConstants.AccordionExpandedClass]: {
      ['> h2 > button > .' + LocalConstants.ChevronClass]: {
        transform: 'rotate(-180deg)',
      },
    },
  },
  promoTypeColorThemes
)
