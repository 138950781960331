import React from 'react'
import { useDispatch } from 'react-redux'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import { Button } from '@mobi/component-library/Common/V2/Buttons'
import { removeItemFromBetSlipById } from '@mobi/betslip/Store/Bets'

export const MultiLegClosed: React.FC<{ id: string }> = ({ id }) => {
  const dispatch = useDispatch()

  return (
    <>
      <Button
        onClick={() => dispatch(removeItemFromBetSlipById({ id }))}
        color='tertiary_grey'
        size='sm'
        isIconOnlyButton
      >
        <Icon name='LineTrash01' size='1.6rem' />
      </Button>
    </>
  )
}
