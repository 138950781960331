import { useEffect } from 'react'
import { isReactNativeApp } from '@mobi/web-native-comms/web'
import { fetchAccountBalanceAsync } from '@core/State/UserAccount/async-signals'
import { useLogon } from './useLogon'

let lastVisibilityChangeTime = Date.now()

function refreshBalanceIfInForeground() {
  if (
    Date.now() - lastVisibilityChangeTime > 5 * 60_000 &&
    document.visibilityState === 'visible'
  ) {
    fetchAccountBalanceAsync()
  }

  // this will get set both when the app is hidden and when it is foregrounded. We only want to
  // make the balance request if it has been in the background for a while, bet set it for both
  // state changes just in case this event gets triggered more often than expected - we don't want
  // to spam the balance endpoint
  lastVisibilityChangeTime = Date.now()
}

// The user balance is refreshed by push events, but if these happen on mobile while the
// app is backgrounded the users balance never gets updated, and it has to wait until they
// tap the pill menu or some other action causes a refresh. This hook will listen for the
// app to get foregrounded via the webview visibility change event and request the balance
// as long as it hasn't been refreshed too recently already
export function useRefreshBalanceOnForeground() {
  const { isLoggedIn } = useLogon()

  useEffect(() => {
    if (!isReactNativeApp || !isLoggedIn) {
      return
    }

    document.addEventListener('visibilitychange', refreshBalanceIfInForeground)
    return () => document.removeEventListener('visibilitychange', refreshBalanceIfInForeground)
  }, [isLoggedIn])
}
