import React from 'react'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { Button } from '@mobi/component-library/Common/Buttons'
import { NoticeBox } from '@core/Components/NoticeBox'
import { PromptBetslipChangeSelecton } from './helpers'

export const PromptBetslipChange: React.FC<{
  handleSelection: (choice: PromptBetslipChangeSelecton) => void
}> = ({ handleSelection }) => {
  return (
    <div>
      <NoticeBox
        title='Betslip Already Contains Bets'
        subtitle='What should we do with existing bets?'
        testId='non-empty-betslip-prompt'
      />
      <ButtonWrapperStyled>
        <Button onClick={() => handleSelection('cancel')} color='secondary'>
          Cancel
        </Button>

        <Button onClick={() => handleSelection('replace')} color='primary'>
          Replace Existing
        </Button>

        <Button onClick={() => handleSelection('add')} color='primary'>
          Add to Existing
        </Button>
      </ButtonWrapperStyled>
    </div>
  )
}

const ButtonWrapperStyled = styled.div({
  display: 'flex',
  padding: '0.5rem',
  background: hexColors.white,

  button: {
    flex: 1,
    minHeight: '4rem',
    margin: 0,

    '&:first-of-type': {
      marginRight: '0.5rem',
    },
    '&:last-of-type': {
      marginLeft: '0.5rem',
    },

    'svg:last-of-type': {
      marginLeft: '0.2rem',
    },
    'svg:first-of-type': {
      marginLeft: '0.2rem',
    },
  },
})
