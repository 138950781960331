import { BetSlipItem } from '@mobi/betslip/types'
import { isReactNativeApp } from '@mobi/web-native-comms/web'
import { isLoadBetSupported } from '@core/Utils/betting/loadBet'
import { getPlanSequence } from './helpers/getPlanSequence'
import { doesBrowserSupportShare } from './helpers/doesBrowserSupportShare'

export const canShareBet = (items: BetSlipItem[]) => {
  if (!isReactNativeApp && !doesBrowserSupportShare()) return false

  if (items.length > 1) return items.every(item => item.isInMulti)

  const planSeq = getPlanSequence(items[0])

  return isLoadBetSupported(planSeq)
}
