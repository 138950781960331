import React from 'react'
import { dayjs } from '@mobi/utils/date'
import { Icon } from '@mobi/component-library/Common/Icon'
import { BetCard, LinkInfo } from '@mobi/betslip/Components/Common/BetCard'
import { StatusBadge } from '@mobi/component-library/Common/StatusBadge/StatusBadge'
import { CountdownBadge } from '@mobi/component-library/Feedback/CountdownBadge'
import type { BetSlipItem, FobDetails, FobSelection } from '@mobi/betslip/types'
import { ShareBetContext } from '@mobi/betslip/Components/Common/ShareMyBet/context'
import { MultiLegCheckbox } from '@mobi/betslip/Components/Multi/Components/MultiLegCheckbox'
import { MultiLegTypeToggle } from '@mobi/betslip/Components/Multi/Components/MultiLegTypeToggle'
import type { BetInfoDisplayType } from '../../types'

export const FobBetInfo: React.FC<{
  item: BetSlipItem
  isMultiItem?: boolean
  displayMode?: BetInfoDisplayType
}> = ({ item, isMultiItem, displayMode }) => {
  const isShareDisplay = React.useContext(ShareBetContext)

  const {
    competitionName,
    eventName,
    handicapName,
    marketName,
    propositionName,
    sportIconCode,
    tournamentName,
    eventStartTime,
    urlPath,
  } = item.selectionDetails as SelectionDetails
  const selection = item.selection as FobSelection

  const selectionName = `${propositionName} ${handicapName ? ` ${handicapName}` : ''}`
  const price = isMultiItem
    ? item.multiLegBetType === 'W'
      ? selection.winPrice
      : selection.placePrice
    : undefined

  const marketNamePrefix = isMultiItem
    ? item.multiLegBetType === 'W'
      ? 'Win'
      : 'Place'
    : marketName
  const shouldDisplayCountdown =
    !isMultiItem || (isMultiItem && dayjs(eventStartTime).isSameOrBefore(dayjs().add(5, 'minutes')))

  const linkInfo: LinkInfo | undefined = !isMultiItem && urlPath ? { pathname: urlPath } : undefined

  return (
    <>
      {(displayMode === 'full' || displayMode === 'event') && (
        <BetCard.EventDetails
          linkInfoPrimary={linkInfo}
          heading={tournamentName ? `${competitionName} ${tournamentName}` : `${competitionName}`}
          subHeading={eventName && eventName !== marketName ? eventName : ''}
          statusComponent={
            <>
              {isShareDisplay ? (
                <StatusBadge color='gray' badgeSize='S'>
                  {dayjs(eventStartTime).format('D MMM | H:mm')}
                </StatusBadge>
              ) : (
                <>
                  {shouldDisplayCountdown && (
                    <CountdownBadge advertisedStartTime={new Date(eventStartTime)} />
                  )}
                </>
              )}

              {isMultiItem && (
                <MultiLegTypeToggle id={item.id} multiLegBetType={item.multiLegBetType} />
              )}
            </>
          }
          iconComponent={<Icon size='2rem' type={sportIconCode || 'tab'} />}
        />
      )}

      {(displayMode === 'full' || displayMode === 'selection') && (
        <BetCard.SelectionInfo
          key={`${item.id}${isMultiItem ? item.multiLegBetType : ''}`}
          heading={selectionName}
          subHeading={marketNamePrefix || ''}
          priceWin={price}
          rightAlignedComponent={isMultiItem && <MultiLegCheckbox item={item} />}
          linkInfo={linkInfo}
        />
      )}
    </>
  )
}

// =====
// Types
// =====

type SelectionDetails = Pick<
  FobDetails,
  | 'eventName'
  | 'competitionName'
  | 'sportIconCode'
  | 'tournamentName'
  | 'propositionName'
  | 'marketName'
  | 'handicapName'
  | 'eventStartTime'
  | 'urlPath'
>
