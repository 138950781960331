import type { BetSlipItem } from '@mobi/betslip/types'
import type { BetSlipWorkflowState } from '@mobi/betslip/Store/Workflow'
import { hasInvestment, hasErrors, hasBeenPlaced } from '@mobi/betslip/helpers/state'

export const filterItemsForWorkflow = (
  items: BetSlipItem[],
  workflowStatus: BetSlipWorkflowState['currentStatus']
): BetSlipItem[] => {
  switch (workflowStatus) {
    case 'proposed': {
      return items.filter(item => hasErrors(item) || hasInvestment(item))
    }

    case 'bets-placed': {
      return items.filter(item => hasErrors(item) || hasBeenPlaced(item))
    }

    default:
      return items
  }
}
