import { getFromLocalStorage, setInLocalStorage } from '@mobi/utils/storage'

export interface MysteryBetData {
  isQuickPick: boolean
  mysteryType: string
}

export type BetConstruction = 'single' | 'betslip' | 'quickbet' | null

export interface AnalyticsBetData {
  id: number | null
  productType: string
  construction: BetConstruction
  placementMethod: string
  isFirstBet: boolean | null
  racing: {
    tote: {
      cost: number
      numberOfTickets: number
      bonusCashUsed: boolean
      bonusCashAmount: number | null
    }
    fixed: {
      cost: number
      numberOfTickets: number
      bonusCashUsed: boolean
      bonusCashAmount: number | null
    }
    info: string
    selection: {} | null
  }
  sports: {
    tote: { cost: number; numberOfTickets: number }
    info: string
  }
  mixed: {
    fixed: {
      cost: number
      numberOfTickets: number
      bonusCashUsed: boolean
      bonusCashAmount: number | null
    }
  }
  specialOffers: string[] | null
  errorMessage: string | null
  betBatchId: string | null
  betType: string | null
  mystery: MysteryBetData | null
  tags: string[] | null
}

export interface BiometricStatus {
  present: boolean
  available: boolean
  reason: string
}

export interface DeviceInfoPayload {
  deviceModel: number
  deviceFamily: number
  deviceName: string
  deviceMajorVersion: number
  deviceMinorVersion: number
  deviceOSMajorVersion: number
  deviceOSMinorVersion: number
  deviceOSPatchVersion: number
  deviceRawInfo: string
  deviceDisplayName: string
  deviceDisplayPPI: number
  deviceNumCores: number
  deviceCPUFreq: number
}

export interface LocalAuthAccount {
  accountNumber: number
  isLocalAuthActive: boolean
  localAuthActivationDateTime: string
  localAuthLastLoginDateTime: string
  localAuthMethod: string
  localAuthMethodAttempted: string
  numberOfFailedLocalAuthAttempts: string
  uniqueUserId: string
}
export interface LocalAuthAnalyticsData {
  //AnalyticsData publish locally for Adobe Analytics
  msgBusName: string
  msgDispatched: string
  msgName: string
  msgType: string
  device: {
    bioMetricsStatus: BiometricStatus
    deviceInfo: DeviceInfoPayload
    isLocalCustomPinAvailable: boolean
    isLocalDevicePinAvailable: boolean
    localAuthAccounts: Array<LocalAuthAccount>
  }
  accountNumber?: number | string
  eventDateTime: string
  eventName: string
  // Error Data Structure
  errorCode?: number
  errorType?: string
  errorMessage?: string
}

export interface NavigationData {
  currentScreenName: string
  currentScreenCode: string
  modalDisplayed: boolean
  modalName: string
  previousScreenName: string
  previousScreenCode: string
}

export interface RacingData {
  code: string | null
  date: Date | null
  meetingId: string | null
  raceNumber: number | string
}

export interface StructuredSearchCriteria {
  contestType?: string[]
  date?: string[]
  wonLastStart?: boolean
  placedLastStart?: boolean
  gearChange?: boolean
  firstUp?: boolean
  secondUp?: boolean
  trackWinPercentage?: number
  careerWinPercentage?: number
  distanceWinPercentage?: number
  trackdistanceWinPercentage?: number
  barrierNumber?: string[]
  boxNumber?: string[]
  apprentice?: boolean
  favouriteLastStart?: boolean
  weightChanged?: string[]
  distanceChanged?: string[]
  daysSinceLastStart?: {
    min?: number
    max?: number
  }
  daysSinceLastWin?: {
    min?: number
    max?: number
  }
  startsSinceLastSpell?: {
    min?: number
    max?: number
  }
  fixedOddsPrice?: {
    min?: number
    max?: number
  }
  winTrackConditions?: string[]
  location?: {
    locationGroup: string[] | null
    trackIds: string[] | null
  }
}

export interface StructuredSearchData {
  criteria: StructuredSearchCriteria | null
}

export interface GiddyUpData {
  giddyUpViewed: boolean
  selectedTab: string
  quaddieValueSelected: boolean
}

export interface AnalyticsData {
  version: number
  content: { url: string }
  user: {
    accountNumber: string
    accountOperationType: string
    featureToggles: string
    isVisionOpen: boolean
    isHapticsEnabled: boolean
    isEnhancedBetslipEnabled: boolean
    isPayoutNotificationsEnabled?: boolean
    visionChannel?: string
    isBiometricsEnabled?: boolean | null
  }
  preferences: { cashBetting: string; debug: boolean }
  bet: AnalyticsBetData
  deposit: {
    amount: number
    openedWith: string
  }
  quickbet: {
    keypadState: string | null
    opened: { timestamp: Date | null }
    closed: { betPlaced: boolean; timestamp: Date | null }
  }
  structuredSearch: StructuredSearchData
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  specialOfferSelection: any
  localAuthAnalyticsData: LocalAuthAnalyticsData
  actionInvoked: string | null
  navigation: NavigationData
  giddyUp: GiddyUpData
}

var history = []

export const navigationDefaultData: NavigationData = {
  currentScreenName: '',
  currentScreenCode: '',
  modalDisplayed: false,
  modalName: '',
  previousScreenCode: '',
  previousScreenName: '',
}

export const giddyUpDefaultData: GiddyUpData = {
  giddyUpViewed: false,
  selectedTab: '',
  quaddieValueSelected: false,
}

// Setting up the base AnalyticsDataLayer in order to reuse it elsewhere.
// If you want to initialise a node using a default object rather than doing it inline, you need
// to spread the object to avoid deepMerge setting a reference to the default object in defaultData
export const defaultData: AnalyticsData = {
  version: 1.32,
  content: { url: '' },
  user: {
    accountNumber: '',
    accountOperationType: '',
    featureToggles: '',
    isVisionOpen: false,
    isHapticsEnabled: false,
    isEnhancedBetslipEnabled: false,
    isPayoutNotificationsEnabled: false,
    isBiometricsEnabled: null, //remains null for non-native app session
  },
  preferences: { cashBetting: '', debug: false },
  bet: {
    id: null,
    productType: '' /* single | multi */,
    construction: null /* single | betslip | betslipbeta | quickbet */,
    placementMethod: '' /* account | cash */,
    isFirstBet: null,
    racing: {
      tote: { cost: 0, numberOfTickets: 0, bonusCashUsed: false, bonusCashAmount: 0 },
      fixed: { cost: 0, numberOfTickets: 0, bonusCashUsed: false, bonusCashAmount: 0 },
      info: '',
      selection: null,
    },
    sports: {
      tote: { cost: 0, numberOfTickets: 0 },
      info: '',
    },
    mixed: { fixed: { cost: 0, numberOfTickets: 0, bonusCashUsed: false, bonusCashAmount: 0 } },
    specialOffers: null,
    errorMessage: null,
    betBatchId: null,
    betType: null,
    mystery: null,
    tags: null,
  },
  deposit: {
    amount: 0,
    openedWith: '',
  },
  quickbet: {
    keypadState: null,
    opened: {
      timestamp: null,
    },
    closed: {
      betPlaced: false,
      timestamp: null,
    },
  },
  specialOfferSelection: null,
  localAuthAnalyticsData: {
    msgBusName: '', //eg 'BettingVC'
    msgDispatched: '', //eg '2018-10-30 04:10:20 +0000'
    msgName: '', //eg 'ALYT_LA_MESSAGE'
    msgType: '', //eg 'Analytics'
    device: {
      bioMetricsStatus: {
        available: false,
        present: false,
        reason: '', //eg  'Authentication using BIOMetrics is not available'
      },
      deviceInfo: {
        deviceModel: 0,
        deviceFamily: 0,
        deviceName: '',
        deviceMajorVersion: 0,
        deviceMinorVersion: 0,
        deviceOSMajorVersion: 0,
        deviceOSMinorVersion: 0,
        deviceOSPatchVersion: 0,
        deviceRawInfo: '',
        deviceDisplayName: '',
        deviceDisplayPPI: 0,
        deviceNumCores: 0,
        deviceCPUFreq: 0,
      },
      isLocalCustomPinAvailable: false,
      isLocalDevicePinAvailable: false,
      localAuthAccounts: [
        {
          accountNumber: 0,
          isLocalAuthActive: false,
          localAuthActivationDateTime: '',
          localAuthLastLoginDateTime: '',
          localAuthMethod: '',
          localAuthMethodAttempted: '',
          numberOfFailedLocalAuthAttempts: '',
          uniqueUserId: '',
        },
      ],
    },
    accountNumber: 'UNKNOWN',
    eventDateTime: '', //eg '2018-10-30 04:10:20 +0000'
    eventName: '', //eg 'ALYT_LA_EventName_DisablePIN_Cancel'
  },
  actionInvoked: null,
  navigation: { ...navigationDefaultData },

  giddyUp: { ...giddyUpDefaultData },
  structuredSearch: {
    criteria: {},
  },
}

// CMS content areas and other modules modify this export directory :(
export var data = Object.assign(defaultData, {})

/*
 * GTM uses the following keys to identify specific steps during a bet placement:
 * "bet-account"            = Bet step 1    (pressing the "bet now"/"account" button)
 * "bet-account-review"     = Bet step 2    (arriving at the bet confirmation stage after a successful bet proposal)
 * "bet-account-processed"  = Bet step 3    (arriving at the bet receipt stage after a successful bet placement)
 *
 */
export const keys = {
  nav: 'nav-content-url',
  menu: 'nav-menu',
  loginForgotPassword: 'login-forgot-password',
  loginForgotAccountNumber: 'login-forgot-account-number',
  loginForgotAccountNumberSent: 'login-forgot-account-number-sent',
  loginResetPasswordSucces: 'login-reset-password-success',
  loginSuccess: 'login-success',
  loginFailed: 'login-failed',
  logout: 'logout',
  searchQuery: 'searchQuery',

  profileSettingsChange: 'profile-settings-change',

  accountDropDownOpened: 'account-dropdown-opened',
  accountDropDownClick: 'accountDropDownClick',

  navExit: 'nav.exit',
  navNav: 'nav.nav',
  exitToBaseKey: 'nav-exit-',
  exitToFacebook: 'nav-exit-facebook',
  exitToTwitter: 'nav-exit-twitter',
  exitToAndroid: 'nav-exit-android',
  exitToIos: 'nav-exit-ios',
  exitToWebsite: 'nav-exit-website',
  exitToAflHub: 'nav-exit-afl-hub',
  exitToAndroidInstructions: 'nav-android-installation-instructions',

  betSelected: 'bet-selected',
  betAccountSelected: 'bet-account',
  betAccountReview: 'bet-account-review',
  betAccountProcessed: 'bet-account-processed',

  quickbetOpen: 'quickbet-open',
  quickbetClosed: 'quickbet-closed',
  quickbetAccountSelected: 'bet-account',
  quickbetAccountReview: 'bet-account-review',
  quickbetAccountProcessed: 'bet-account-processed',

  quickbetBonusBetButtonShown: 'quickbet-bonus-bet-button-shown',
  quickbetBonusBetButtonActivated: 'quickbet-bonus-bet-button-activated',
  quickbetBonusBetButtonDeactivated: 'quickbet-bonus-bet-button-deactivated',
  quickbetBonusBetSelected: 'quickbet-bonus-bet-selected',
  quickbetBonusBetUnselected: 'quickbet-bonus-bet-unselected',
  quickbetBonusCashSelected: 'quickbet-bonus-cash-selected',
  quickbetBonusCashUnselected: 'quickbet-bonus-cash-unselected',
  quickbetBonusBetProposed: 'quickbet-bonus-bet-proposed',
  quickbetBonusBetAddedToBetslip: 'quickbet-bonus-bet-added-to-betslip',
  quickbetBonusCashRemoved: 'quickbet-bonus-cash-removed',
  quickbetBonusCashButtonActivated: 'quickbet-bonus-cash-button-activated',
  quickbetBonusCashButtonDeactivated: 'quickbet-bonus-cash-button-deactivated',
  mysteryBonusCashButtonActivated: 'mystery-bonus-cash-button-activated',
  mysteryBonusCashButtonDeactivated: 'mystery-bonus-cash-button-deactivated',

  betslipBetaSelected: 'bet-account',
  betslipBetaReview: 'bet-account-review',
  betslipBetaProcessed: 'bet-account-processed',

  betslipBetAdded: 'add-to-betslip',
  betslipBetRemoved: 'betslip-remove',
  betslipCleared: 'betslip-clear',
  betslipAccountSelected: 'bet-account',
  betslipAccountReview: 'bet-account-review',
  betslipAccountProcessed: 'bet-account-processed',
  betslipCashSelected: 'bet-cash',

  betslipBonusBetButtonShown: 'betslip-bonus-bet-button-shown',
  betslipBonusBetButtonActivated: 'betslip-bonus-bet-button-activated',
  betslipBonusBetButtonDeactivated: 'betslip-bonus-bet-button-deactivated',
  betslipBonusBetSelected: 'betslip-bonus-bet-selected',
  betslipBonusBetUnselected: 'betslip-bonus-bet-unselected',
  betslipBonusBetChosen: 'betslip-bonus-bet-chosen',
  betslipBonusBetConfirmed: 'betslip-bonus-bet-confirmed',
  betslipBonusCashRemoved: 'betslip-bonus-cash-removed',

  mysterySelected: 'bet-selected',
  mysteryAccountSelected: 'bet-account',
  mysteryReview: 'bet-account-review',
  mysteryReceipt: 'bet-account-processed',

  favNumbersSelected: 'bet-selected',
  favNumbersAccountSelected: 'bet-account',
  favNumbersAccountReview: 'bet-account-review',
  favNumbersAccountProcessed: 'bet-account-processed',

  tippingSelected: 'bet-selected',
  tippingAccountSelected: 'bet-account',
  tippingAccountReview: 'bet-account-review',
  tippingAccountProcessed: 'bet-account-processed',

  multiBetAdded: 'bet-add',
  multiBetRemoved: 'bet-remove',
  multiCleared: 'bet-clear',
  multiAccountSelected: 'bet-account',
  multiAccountReview: 'bet-account-review',
  multiAccountProcessed: 'bet-account-processed',
  multiToastClicked: 'multi_toast_clicked',

  accountProcessed: 'account-interaction',

  depositBaseKey: 'deposit-',
  checkInBaseKey: 'lbs-checkin-',
  activityBaseKey: 'activity-',

  visionOpened: 'vision-opened',
  visionClosed: 'vision-closed',
  visionPositionChanged: 'vision-position-changed',
  visionFullScreenOn: 'vision-full-screen-on',
  visionFullScreenOff: 'vision-full-screen-off',
  visionSky1Selected: 'vision-sky-1-selected',
  visionSky2Selected: 'vision-sky-2-selected',
  visionRacingWASelected: 'vision_racing_wa_selected',
  visionRaceReplaySelected: 'vision_race_replay_selected',
  visionWindowResized: 'vision-window-resized',
  visionMuted: 'vision-mute-on',
  visionUnMuted: 'vision-mute-off',

  skyPendingBetsOpen: 'sky_pending_bets_open',
  skyPendingBetsClosed: 'sky_pending_bets_closed',

  radioOn: 'radio-on',
  radioOff: 'radio-off',

  actionInvoked: 'action-invoked',

  trendingBetSelected: 'trending-bet-selected',
  trendingBetSortingChanged: 'trending-bet-sorting-changed',

  raceNotificationEnabled: 'race-notification-enabled',
  raceNotificationDisabled: 'race-notification-disabled',

  shareBet: 'share-bet',
  shareBetLinkOpen: 'share-bet-link-open',
  shareBetLinkLogin: 'share-bet-link-login',
  shareBetLinkAcquisition: 'share-bet-link-acquisition',
  shareBetLinkPlaced: 'share-bet-link-placed',

  shareBlackbook: 'share-blackbook',

  rebet: 're-bet',
  loadedBetBetslip: 'loaded-bet-betslip',

  takeABreakInformation: 'take-a-break-information',
  takeABreakConfirmation: 'take-a-break-confirmation',
  takeABreakAlreadyActive: 'take-a-break-already-active',
  takeABreakCancel: 'take-a-break-cancel',
  takeABreakSubmitSelection: 'take-a-break-submit-selection',
  takeABreakSubmitSucceeded: 'take-a-break-submit-succeeded',
  takeABreakSubmitFailed: 'take-a-break-submit-failed',

  activityStatementsSelected: 'activity-statements-selected',
  activityStatementRequestSubmitted: 'activity-statement-request-submitted',
  activityStatementRequestSuccess: 'activity-statement-request-success',
  activityStatementRequestFailure: 'activity-statement-request-failure',
  activityStatementsUpdateEmail: 'activity-statements-update-email',

  androidMigrationNotNow: 'android-migration-not-now',
  androidMigrationReinstall: 'android-migration-re-install',

  accountActivityTabChange: 'account-activity-tab-change',

  tooltipDismiss: 'tooltip-dismiss',

  productSelectionChanged: 'product-selection-changed',

  racePromotionLoaded: 'race-promotion-loaded',
  racePromotionOpened: 'race-promotion-opened',
  racePromotionClosed: 'race-promotion-closed',
  racePromotionTermsAndConditionsClicked: 'race-promotion-terms-and-conditions-clicked',

  racePreviewLoaded: 'race-preview-loaded',
  racePreviewOpened: 'race-preview-opened',
  racePreviewClosed: 'race-preview-closed',

  raceReplayOpened: 'race-replay-opened',
  raceReplayLaunched: 'race_replay_launched',
  raceReplayFullScreen: 'race_replay_fullscreen',
  raceReplayMute: 'race_replay_mute',
  raceReplayClosed: 'race_replay_closed',

  videoPreviewLoaded: 'video-preview-loaded',
  videoPreviewOpened: 'video-preview-opened',
  videoPreviewClosed: 'video-preview-closed',
  videoPreviewPlayed: 'video-preview-played',
  videoPreviewNotSupported: 'video-preview-not-supported',

  betInformerButtonPressed: 'bet-informer-button-pressed',
  betModifierButtonPressed: 'bet-modifier-button-pressed',

  meetingsCalendarNextMonth: 'meetings-calendar-next-month',
  meetingsCalendarPrevMonth: 'meetings-calendar-prev-month',
  meetingsCalendarSelectMonth: 'meetings-calendar-select-month',
  meetingsCalendarOpened: 'meetings-calendar-opened',
  meetingsCalendarSelectedDay: 'meetings-calendar-selected-day',
  meetingsFavouriteAdded: 'meetings-favourite-added',
  meetingsFavouriteRemoved: 'meetings-favourite-removed',
  meetingsFavouriteUsed: 'meetings-favourite-used',
  meetingsRaceCodeToggle: 'meetings-race-code-toggle',
  meetingsDayQuickSelect: 'meetings-day-quick-select',
  meetingsDayPrevArrow: 'meetings-day-prev-arrow',
  meetingsDayNextArrow: 'meetings-day-next-arrow',

  tipsBetClicked: 'tips-bet-clicked',
  racePreviewBetClicked: 'race-preview-bet-clicked',

  biometricsTogglePressed: 'biometrics-toggle-pressed',

  hamburgerMenuClicked: 'hamburger-menu-clicked',

  blackbookOddsClicked: 'blackbook-odds-clicked',
  blackbookAlertsUpdated: 'blackbook-alerts-updated',
  blackbookNavigateToRace: 'blackbook-navigate-to-race',
  blackbookEntryDeleteClicked: 'blackbook-entry-delete-clicked',
  blackbookEntryDeleted: 'blackbook-entry-deleted',
  blackbookEntryDeleteCanceled: 'blackbook-entry-delete-canceled',
  blackbookEntryExpanded: 'blackbook-entry-expanded',
  blackbookSortChanged: 'blackbook-sort-changed',
  blackbookCommentClicked: 'blackbook-comment-clicked',
  blackbookCommentCancel: 'blackbook-comment-cancel',
  blackbookCommentSave: 'blackbook-comment-save',
  blackbookAddRunner: 'blackbook-add-runner',
  blackbookGoToBlackbook: 'blackbook-go-to-blackbook',
  blackbookUnableToAdd: 'blackbook-unable-to-add',
  blackbookChangeView: 'blackbook-change-view',

  featureBannerViewed: 'view_promotion',
  featureItemBannerViewed: 'view_promotion',
  featureBannerClicked: 'select_promotion',
  featureItemClicked: 'select_promotion',

  categoryNavigation: 'category-navigation',

  quickDepositShown: 'quick-deposit-shown',
  quickDepositStarted: 'quick-deposit-started',
  quickDepositSucceeded: 'quick-deposit-succeeded',
  quickDepositFailed: 'quick-deposit-failed',
  quickDepositCancelled: 'quick-deposit-cancelled',
  quickDepositOtherDepositClicked: 'quick-deposit-other-deposit-clicked',
  quickDepositEditBetClicked: 'quick-deposit-edit-bet-clicked',
  quickDeposit3dSecure: 'quick-deposit-3d-secure',
  quickDepositNon3dSecure: 'quick-deposit-non-3d-secure',
  quickDeposit3dSecureNotShifted: 'quick-deposit-3d-secure-not-shifted',
  quickDeposit3dSecureError: 'quick-deposit-3d-secure-error',
  quickDeposit3dSecureUserDataSuccess: 'quick-deposit-3d-secure-user-data-success',
  quickDeposit3dSecureUserDataFail: 'quick-deposit-3d-secure-user-data-fail',

  signUpClicked: 'sign-up-clicked',
  signUpStart: 'sign-up-start',
  signUpJoin: 'sign-up-join',
  signUpVerifyAddress: 'sign-up-verify-address',
  signUpCreatePassword: 'sign-up-create-password',
  signUpDepositLimits: 'sign-up-deposit-limits',
  signUpUnsuccessful: 'sign_up_unsuccessful',
  signUpUnsuccessfulCallUs: 'sign_up_unsuccessful_call_us',
  signUpComplete: 'sign-up-complete',
  signUpDepositClick: 'sign-up-deposit-click',
  verificationSelectOption: 'verification-select-option',
  /**
   * Old, kebab-cased version is used in signup only. This has renamed to be different of the newer
   * version with underscores to avoid name collisions. This also sets up the newer one
   * to become the dominant version with the switch to snake_cased keys coming up
   */
  signUpVerificationEnterIdentification: 'verification-enter-identification',
  verificationAccountPending: 'verification-account-pending',
  signUpAlreadyHaveAccount: 'sign-up-already-have-account',
  signUpGoBackHomePage: 'sign-up-go-back-home-page',
  signUpNeedAssistance: 'sign-up-need-assistance',
  signUpError: 'sign-up-error',

  raceCardSwiped: 'race-card-swiped',

  raceCardPendingBetsOpen: 'race_card_pending_bets_open',
  raceCardPendingBetsViewAll: 'race_card_pending_bets_view_all',
  raceCardResultedBetsOpen: 'race_card_resulted_bets_open',
  raceCardResultedBetsViewAll: 'race_card_resulted_bets_view_all',

  raceCardSorting: 'race_card_sorting',

  skyRaceCardListViewOpen: 'sky_race_card_list_view_open',
  skyRaceCardFilterCodeUpdate: 'sky_race_card_filter_code_update',
  skyRaceCardFilterRegionUpdate: 'sky_race_card_filter_region_update',
  moreSkyRacesLoaded: 'sky_race_card_show_more',
  resetSkyScrollView: 'sky_race_card_return_first_race',

  verificationBannerLoaded: 'verification_banner_loaded',
  verificationCallUs: 'verification_call_us',
  verificationVerifyNow: 'verification_now',

  verificationStart: 'verification_start',
  verificationDetailsCorrect: 'verification_details_correct',
  verificationEditDetails: 'verification_edit_details',
  verificationEnterIdentification: 'verification_enter_identification',
  verificationSuccess: 'verification_success',
  verificationDepositClick: 'verification_deposit_click',
  verificationFail: 'verification_fail',
  verificationEditDetailsLocked: 'verification_edit_details_locked',
  verificationNeedAssistance: 'verification_need_assistance',
  verificationGoBackHomePage: 'verification_go_back_home_page',
  verificationError: 'verification_error',
  verificationContactUs: 'verification_contact_us',
  verificationCallUsLater: 'verification_call_us_later',
  verificationBetOffer: 'verification_bet_offer',
  verificationGetInTouch: 'verification_get_in_touch',
  verificationSavedDetails: 'verification_saved_details',

  withdrawal_error: 'withdrawal_error',
  withdrawal_cancel: 'withdrawal_cancel',
  withdrawal_success: 'withdrawal_success',

  optimoveTrack: 'optimove_track',

  formOpened: 'form_opened',
  formClosed: 'form_closed',
} as const

export const getHistory = () => {
  if (data.preferences.debug) {
    return `history : ${history.length} items`
  } else {
    return 'history : debugging is off. Activate debugging to capture and display history'
  }
}

export const addToHistory = (event: string, historyData: string) => {
  if (data.preferences.debug) {
    history.push({ timestamp: new Date(), event: event, data: historyData })
  }
}

export const resetBetData = () => {
  data.bet.id = null
  data.bet.productType = ''
  data.bet.construction = null
  data.bet.placementMethod = ''
  data.bet.isFirstBet = null
  data.bet.errorMessage = null

  data.bet.racing.tote.numberOfTickets = 0
  data.bet.racing.tote.cost = 0
  data.bet.racing.tote.bonusCashUsed = false
  data.bet.racing.tote.bonusCashAmount = 0
  data.bet.racing.fixed.numberOfTickets = 0
  data.bet.racing.fixed.cost = 0
  data.bet.racing.fixed.bonusCashUsed = false
  data.bet.racing.fixed.bonusCashAmount = 0
  data.bet.racing.info = ''
  data.bet.racing.selection = null

  data.bet.sports.tote.numberOfTickets = 0
  data.bet.sports.tote.cost = 0
  data.bet.sports.info = ''

  data.bet.mixed.fixed.numberOfTickets = 0
  data.bet.mixed.fixed.cost = 0
  data.bet.mixed.fixed.bonusCashUsed = false
  data.bet.mixed.fixed.bonusCashAmount = 0

  data.bet.specialOffers = []
  data.bet.betType = null
}

const enum LocalConstants {
  storageKey = '__amplify__AnalyticsDataLayer',
}

export const saveTrackData = () => {
  setInLocalStorage(LocalConstants.storageKey, JSON.stringify(data))
}

export const loadTrackData = () => {
  const storage = getFromLocalStorage(LocalConstants.storageKey) || ''
  const deserialisedStorage = JSON.parse(storage) as AnalyticsData

  if (deserialisedStorage) {
    if (deserialisedStorage.version >= data.version) {
      data = deserialisedStorage
    }
  }
}

export const activateDebug = () => {
  data.preferences.debug = true
  saveTrackData()
}

export const deactivateDebug = () => {
  data.preferences.debug = false
  saveTrackData()
}
