import React from 'react'
import styled from '@emotion/styled'
import { colors, font, spacing } from '@mobi/component-library/Theme/Common'
import { Icon } from '@mobi/component-library/Common/V2/Icon'

export const InlineError: React.FC<{
  text: string
}> = ({ text }) => {
  return (
    <WrapperStyled style={{ color: colors.error[600] }}>
      <Icon name='LineAlertCircle' size='2rem' /> {text}
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: spacing.smx2,
  color: colors.white,
  fontWeight: font.weight.regular,
  fontSize: font.size.md.fontSize,
  letterSpacing: font.size.md.letterSpacing,
  lineHeight: font.size.md.lineHeight,
})
