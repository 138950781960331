import { RacePageDataTransferObject } from '@mobi/api-types'
import { store } from '@core/Store'
import { setSelectedProposition } from '@core/Areas/RaceCard/Store'
import { handleBetSelection } from '@core/Utils/betting/handleBetSelection'
import { createStartingPriceBetSelection } from './createBetSelection'

export const betWithStartingPrice = (
  type: 'quickbet' | 'betslip',
  starterNumber: number,
  race: RacePageDataTransferObject
): void => {
  const selection = createStartingPriceBetSelection(starterNumber, race)

  if (type === 'quickbet') {
    handleBetSelection({
      betFlow: {
        location: 'Quickbet',
        options: { quickbetProps: { shouldShowSuperPicks: false } },
      },
      selection,
    })
    return
  }

  if (type === 'betslip') {
    handleBetSelection({ betFlow: { location: 'Betslip' }, selection })
    store.dispatch(setSelectedProposition(null))
    return
  }
}
