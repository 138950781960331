import React from 'react'
import styled from '@emotion/styled'
import { colors, radius } from '@mobi/component-library/Theme/Common'
import { Icon } from '@mobi/component-library/Common/V2/Icon'

const playerIcons: Record<string, IconPaths> = {
  close: 'SolidXClose',
  docked: 'SolidScale02',
  exitfullscreen: 'LineMinimize02',
  floating: 'CustomScale02',
  fullscreen: 'LineMaximize02',
  mute: 'LineVolumeX',
  play: 'SolidPlayCircle',
  volume: 'LineVolumeMax',
  replay: 'SolidRefreshCw01',
  pause: 'SolidPauseCircle',
}

export const PlayerButton: React.FC<{ type: ButtonTypes; onClick: () => void }> = ({
  type,
  onClick,
}) => (
  <PlayerControlButtonStyled
    data-testid={`PlayerButton.${type}`}
    onClick={e => {
      e.stopPropagation()
      onClick()
    }}
  >
    <Icon name={playerIcons[type]} size='inherit' color={colors.white} />
  </PlayerControlButtonStyled>
)

export const ResizeIcon = () => (
  <ResizeWrapperStyled>
    <Icon name='LineMaximize01' size='2.4rem' color={colors.white} />
  </ResizeWrapperStyled>
)

// ======
// Styles
// ======

export const PlayerControlButtonStyled = styled.button({
  zIndex: 2,
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '3.6rem',
  width: '3.6rem',
  padding: 0,
  border: 0,
  borderRadius: radius.md,
  fontSize: 'inherit',
  textDecoration: 'none',
  color: colors.white,
  background: 'rgba(0,0,0,0.6)',
  pointerEvents: 'auto',
})

const ResizeWrapperStyled = styled(PlayerControlButtonStyled)({
  transform: 'rotate(90deg)',
  pointerEvents: 'unset',
}).withComponent('span')

// =====
// Types
// =====

type ButtonTypes = keyof typeof playerIcons
type IconPaths = React.ComponentProps<typeof Icon>['name']
