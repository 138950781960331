import React from 'react'
import styled from '@emotion/styled'
import { useBodyScrollLock } from '@mobi/utils/hooks/useBodyScrollLock'
import { useRenderTimeoutControl } from '@mobi/utils/hooks/useRenderTimeoutControl'
import { colors, layering, shadow, measurements } from '@mobi/component-library/Theme/Common'

const enum LocalConstants {
  AnimationTimingMs = 500,
  DimmerClassName = 'js-drawer-dimmer',
  DrawerUnmountClassName = 'js-drawer--unmounting',
}

export const Drawer: React.FC<{
  isOpen: boolean
  children: React.ReactNode
  onDismiss?: () => void
  shouldUseBodyScrollLock?: boolean
}> = ({ isOpen, children, onDismiss, shouldUseBodyScrollLock = true }) => {
  const shouldRenderDrawer = useRenderTimeoutControl({
    shouldRender: isOpen,
    timeoutMs: LocalConstants.AnimationTimingMs as number,
  })
  const scrollContainerRef = useBodyScrollLock(shouldUseBodyScrollLock && shouldRenderDrawer)

  if (!shouldRenderDrawer) return null

  const wrapperClassNames: string[] = []
  if (!isOpen) wrapperClassNames.push(LocalConstants.DrawerUnmountClassName)

  return (
    <>
      <WrapperStyled
        aria-modal='true'
        data-testid='Overlay.Drawer'
        className={wrapperClassNames.join(' ')}
      >
        <article>
          <div ref={scrollContainerRef} data-testid='Overlay.Drawer.Content'>
            {children}
          </div>
        </article>
      </WrapperStyled>

      <div className={LocalConstants.DimmerClassName as string} onClick={onDismiss} />
    </>
  )
}

const WrapperStyled = styled.div({
  position: 'fixed',
  zIndex: layering.overlayHigh,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,

  overflow: 'hidden',
  pointerEvents: 'none',

  '> article': {
    position: 'fixed',
    top: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    maxWidth: '48rem',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',

    minWidth: measurements.mobi.minWidth,
    pointerEvents: 'all',
    touchAction: 'manipulation',
    background: colors.white,
    boxShadow: shadow.lg,

    animationName: 'drawerIn',
    animationFillMode: 'forwards',
    animationDuration: LocalConstants.AnimationTimingMs + 'ms',
    animationTimingFunction: 'cubic-bezier(0.32, 0.72, 0, 1)',

    // Children container
    '> div': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
    },
  },

  [`& + .${LocalConstants.DimmerClassName}`]: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: layering.overlayHigh - 1,
    backdropFilter: 'blur(1px)',
    backgroundColor: 'rgba(0,0,0,0.5)',
    animation: `fadeIn ${LocalConstants.AnimationTimingMs}ms ease forwards`,
  },

  [`&.${LocalConstants.DrawerUnmountClassName}`]: {
    '> article': {
      animationDuration: '300ms',
      animationTimingFunction: 'ease-out',
      animationName: 'drawerOut',
    },

    [`+ .${LocalConstants.DimmerClassName}`]: {
      animation: `fadeOut ${LocalConstants.AnimationTimingMs}ms ease forwards`,
    },
  },

  '@keyframes fadeIn': { from: { opacity: 0 }, to: { opacity: 1 } },
  '@keyframes fadeOut': { from: { opacity: 1 }, to: { opacity: 0 } },
  '@keyframes drawerIn': {
    from: { transform: 'translateX(100%)' },
    to: { transform: 'translateX(0)' },
  },
  '@keyframes drawerOut': {
    from: { transform: 'translateX(0)' },
    to: { transform: 'translateX(100%)' },
  },
})
