import React from 'react'
import styled from '@emotion/styled'
import { colors, font, spacing } from '@mobi/component-library/Theme/Common'

export const BetCardTitle: React.FC<{ title: string; rightComponent?: React.ReactNode }> = ({
  title,
  rightComponent,
}) => {
  return (
    <WrapperStyled>
      <span>{title}</span>

      {rightComponent && <span>{rightComponent}</span>}
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: spacing.smx1,
  borderBottom: `0.5px solid ${colors.neutral[200]}`,
  fontFamily: font.family.primary,
  fontSize: font.size.lg.fontSize,
  letterSpacing: font.size.lg.letterSpacing,
  lineHeight: font.size.lg.lineHeight,
  fontWeight: font.weight.medium,
  color: colors.black,
})
