import { useSelector } from 'react-redux'
import { useFeature } from '@core/Utils/hooks/useFeature'
import { getNewSportsSetting } from '@core/Areas/Settings/Store/selectors'

export const useIsFobSportsFeatureActive = () => {
  const isNewSportsFeatureSettingActive = useSelector(getNewSportsSetting)
  const isNewSportsFeatureActive = useFeature('FIXED_ODDS_SPORTS')

  return isNewSportsFeatureActive && isNewSportsFeatureSettingActive
}
