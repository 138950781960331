import React from 'react'
import styled from '@emotion/styled'
import { Icon } from '@mobi/component-library/Common/Icon'
import { useAddToBlackbook } from '@core/Areas/Blackbook/Hooks/useAddToBlackbook'
import { SpinnerInlineStyled } from '@mobi/component-library/Common/Spinner/Spinner.styles'
import { getIconName } from '@core/Areas/Blackbook/helpers'
import { Button } from '@mobi/component-library/Common/V2/Buttons'
import { spacing } from '@mobi/component-library/Theme/Common'

export const BlackbookButton: React.FC<BlackbookParams> = ({
  starterName,
  starterType,
  entryType,
  source,
  isReady,
}) => {
  const { matchedRunnerInBlackbook, isLoading, clickHandler } = useAddToBlackbook({
    starterName,
    starterType,
    source,
  })

  const isRunnerInBlackbook = Boolean(matchedRunnerInBlackbook)

  const iconName = isRunnerInBlackbook ? 'tickCircleSolid' : getIconName(starterType)

  return (
    <Button
      color={isRunnerInBlackbook ? 'primary' : 'secondary_color'}
      size='xs'
      isFullWidth
      onClick={clickHandler}
      disabled={isLoading || !isReady}
    >
      <InnerButtonStyled>
        {!isReady || isLoading ? (
          <SpinnerInlineStyled color='dark' size={2} />
        ) : (
          <>
            <Icon
              type={iconName}
              size={entryType === 'Jockey' || isRunnerInBlackbook ? '1.6rem' : '2rem'}
              color='currentColor'
            />

            {isRunnerInBlackbook ? ` See ${entryType}` : ` Add ${entryType}`}
          </>
        )}
      </InnerButtonStyled>
    </Button>
  )
}

// ======
// Styles
// ======

const InnerButtonStyled = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: spacing.smx1,
})

// =====
// Types
// =====

type BlackbookParams = Parameters<typeof useAddToBlackbook>[0] & {
  isReady: boolean
  entryType: 'Runner' | 'Jockey'
}
