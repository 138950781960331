import React from 'react'
import styled from '@emotion/styled'
import { dayjs } from '@mobi/utils/date'
import { hexColors } from '@mobi/settings'
import { Icon } from '@mobi/component-library/Common/Icon'
import { toTitleCase } from '@mobi/utils/string'
import { useExpandToggle } from '@mobi/utils/hooks/useExpandToggle'
import { RaceCountdown } from '@core/Components/RaceCountdown'
import type { BlackbookEntry } from '@core/Areas/Blackbook/types'
import { useIncomingIndicator } from '@core/Areas/Blackbook/Hooks/useIncomingIndicator'
import { getIconName } from '@core/Areas/Blackbook/helpers'
import { RunnerCardStyled, BlackbookEntryDetails } from '../Common'

export const SlimEntryExpandable: React.FC<{
  id: number
  name: string
  code: BlackbookEntry['Code']
  hasComments: boolean
  incomingIdRef: React.MutableRefObject<string | undefined>
  children?: React.ReactChild
  ast?: string
  subheading?: string
  onExpand?: () => void
}> = ({ name, id, code, ast, subheading, hasComments, incomingIdRef, onExpand, children }) => {
  const { wrapperRef, contentRef, shouldRender, isExpanded, toggleExpanded } = useExpandToggle({
    timeoutMs: 400,
  })

  const incomingElRef = useIncomingIndicator(incomingIdRef, id)

  return (
    <RunnerCardStyled data-testid='blackbook-entry' ref={incomingElRef}>
      <SlimEntryHeaderStyled
        onClick={() => {
          toggleExpanded()
          if (!isExpanded) {
            onExpand && onExpand()
          }
        }}
        data-testid='blackbook-entry-header'
      >
        <div>
          <Icon type={getIconName(code)} size='2.5rem' />
        </div>

        <div>
          <div>
            <h3 data-testid='blackbook-entry-name'>
              {toTitleCase(name)} {subheading && <span>{subheading}</span>}
            </h3>
          </div>
        </div>

        <div>
          {ast && dayjs(ast).isToday() && (
            <RaceCountdown displaySize='1.2rem' raceStartTime={dayjs(ast).toDate()} />
          )}
          <Icon type={isExpanded ? 'arrowup' : 'arrowdown'} size='1.4rem' />
        </div>
      </SlimEntryHeaderStyled>

      {(isExpanded || shouldRender) && (
        <BlackbookEntryDetails
          isJockey
          id={id}
          type={code}
          name={name}
          wrapperRef={wrapperRef}
          contentRef={contentRef}
          hasComments={hasComments}
        >
          {children}
        </BlackbookEntryDetails>
      )}
    </RunnerCardStyled>
  )
}

// ======
// Styles
// ======

const SlimEntryHeaderStyled = styled.div({
  display: 'flex',
  alignItems: 'center',
  minHeight: '4rem',

  // Code Icon
  '& > div:nth-of-type(1)': {
    flexShrink: 0,
    display: 'flex',
    width: '3.5rem',
    svg: { alignSelf: 'center' },
  },

  // Runner Info
  '& > div:nth-of-type(2)': {
    flex: 1,

    h3: {
      padding: 0,
      margin: 0,
      background: 0,
      color: hexColors.nero,
      fontSize: '1.4rem',
      fontWeight: 'bold',
      lineHeight: 1,
      textTransform: 'none',

      span: {
        marginLeft: '0.5rem',
        fontSize: '1.2rem',
        fontWeight: 'normal',
        color: hexColors.grey2,
      },
    },
  },

  // Icon
  '& > div:nth-of-type(3)': {
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '4rem',

    // Countdown
    '> div': {
      marginTop: 0,
      marginRight: '0.5rem',
    },

    '> svg': {
      minWidth: '4rem',
    },

    button: {
      width: '4rem',
      height: '4rem',
      color: hexColors.darkGrey,
    },
  },
})
