import React from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import { useExpandToggle } from '@mobi/utils/hooks/useExpandToggle'
import { colors, radius, spacing } from '@mobi/component-library/Theme/Common'
import { selectActiveInvestment } from '@mobi/betslip/Store/Workflow/selectors'
import { Keypad } from './Components/Keypad'
import { ButtonsSection } from './Components/ButtonsSection'
import { SummarySection } from './Components/SummarySection'

const enum LocalConstants {
  ExpandTimingMs = 250,
  ExpandableClass = 'js-footer__expandable',
  VisibleClass = 'js-footer__expandable--visible',
}

export const Footer: React.FC = () => {
  const shouldDisplayKeypad = !!useSelector(selectActiveInvestment)

  const { toggleExpanded: toggleKeypad, ...keypadExpand } = useExpandToggle({
    timeoutMs: LocalConstants.ExpandTimingMs as number,
  })
  const { toggleExpanded: toggleSummary, ...summaryExpand } = useExpandToggle({
    timeoutMs: LocalConstants.ExpandTimingMs as number,
    shouldStartExpanded: true,
  })

  React.useEffect(() => {
    toggleKeypad(shouldDisplayKeypad)
    toggleSummary(!shouldDisplayKeypad)
  }, [toggleKeypad, toggleSummary, shouldDisplayKeypad])

  React.useEffect(() => {
    if (!summaryExpand.wrapperRef.current) return
    summaryExpand.wrapperRef.current.style.height = 'auto'
    summaryExpand.wrapperRef.current.style.opacity = '1'
  }, [summaryExpand.wrapperRef])

  return (
    <WrapperStyled>
      {(keypadExpand.shouldRender || keypadExpand.isExpanded) && (
        <div
          ref={keypadExpand.wrapperRef}
          className={`${LocalConstants.ExpandableClass} ${keypadExpand.isExpanded ? LocalConstants.VisibleClass : ''}`}
        >
          <div ref={keypadExpand.contentRef}>
            <Keypad />
          </div>
        </div>
      )}

      {(summaryExpand.shouldRender || summaryExpand.isExpanded) && (
        <div
          ref={summaryExpand.wrapperRef}
          className={`${LocalConstants.ExpandableClass} ${summaryExpand.isExpanded ? LocalConstants.VisibleClass : ''}`}
        >
          <div ref={summaryExpand.contentRef}>
            <SummarySection />
          </div>
        </div>
      )}

      <ButtonsSection />
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  padding: spacing.md,
  paddingBottom: spacing.lg,
  borderTopLeftRadius: radius.lgx4,
  borderTopRightRadius: radius.lgx4,
  backgroundColor: colors.lavender[900],

  [`.${LocalConstants.ExpandableClass}`]: {
    height: 0,
    overflow: 'hidden',
    transition: `height ${LocalConstants.ExpandTimingMs}ms cubic-bezier(0.44, 0.1, 0.27, 0.98), opacity ${LocalConstants.ExpandTimingMs}ms ease`,
    willChange: 'height',
    opacity: 0,
  },

  [`.${LocalConstants.VisibleClass}`]: {
    opacity: 1,
  },
})
