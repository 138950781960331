import React from 'react'
import { useDispatch } from 'react-redux'
import type { BetSlipItem } from '../types'
import { setVisibleBettingSection } from '../Store/Workflow'
import { isSelectionAllowedInMulti } from '../helpers/state'
import { MIN_LEGS_IN_MULTI } from '../helpers/constants'

export const useTabSectionObserver = (
  containerElRef: React.RefObject<HTMLDivElement>,
  items: BetSlipItem[]
) => {
  const dispatch = useDispatch()

  const singlesElRef = React.useRef<HTMLDivElement>(null)
  const multiElRef = React.useRef<HTMLDivElement>(null)

  const multiItems = items.filter(item => isSelectionAllowedInMulti(item.selection))
  const hasEnoughMultiItems = multiItems.length >= MIN_LEGS_IN_MULTI

  React.useEffect(() => {
    if (!containerElRef.current) return

    const ratioMap = new WeakMap<HTMLDivElement, number>()

    const observer = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        entries.forEach(entry => {
          ratioMap.set(entry.target as HTMLDivElement, entry.intersectionRatio)
        })

        const singlesRatio = getRatioValue(singlesElRef.current, ratioMap)
        const multiRatio = getRatioValue(multiElRef.current, ratioMap)

        dispatch(setVisibleBettingSection(singlesRatio > multiRatio ? 'Singles' : 'Multi'))
      },
      { root: containerElRef.current, threshold: [0, 0.25, 0.5, 0.75, 1] }
    )

    singlesElRef.current && observer.observe(singlesElRef.current)
    multiElRef.current && observer.observe(multiElRef.current)

    return () => observer.disconnect()
  }, [dispatch, containerElRef, hasEnoughMultiItems])

  return { singlesElRef, multiElRef }
}

// =============
// Local Helpers
// =============

function getRatioValue(
  el: HTMLDivElement | null,
  ratioMap: WeakMap<HTMLDivElement, number>
): number {
  return (el && ratioMap.get(el)) ?? 0
}
