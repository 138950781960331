import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import type { BonusType } from '../../types'

export const TransactionCardStyled = styled.div({
  padding: '1rem',
  marginBottom: '1rem',
  fontSize: '1.2rem',
  background: hexColors.white,
  borderRadius: '0.5rem',
  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
})

export const TransactionStyled = styled('div')<{ bonusType: BonusType | null }>(
  {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '1.2rem',
    color: hexColors.nero,

    '& > div:first-of-type': {
      display: 'flex',
      paddingBottom: '0.5rem',
      marginBottom: '0.5rem',
      borderBottom: `0.1rem solid ${hexColors.gainsboro}`,
      fontSize: '1.4rem',
    },
  },
  ({ bonusType }) => ({
    ...(bonusType && {
      '& > div:first-of-type > div:nth-of-type(2), > div:first-of-type > div:nth-of-type(4)': {
        color: bonusType === 'BonusBet' ? hexColors.tangerine : hexColors.cobalt,
      },
    }),
  })
)

export const PendingStatusStyled = styled('div')({
  borderRadius: '10rem',
  textAlign: 'center',
  border: '1px solid',
  color: hexColors.grey,
  padding: '0.2rem 0.5rem',
  display: 'inline',
  fontSize: '1rem',
  margin: '0 0.5rem',
})
