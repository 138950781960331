import React from 'react'
import styled from '@emotion/styled'
import { newTheme } from '@mobi/component-library/Theme'
import type { Ticket } from '@core/Areas/AccountActivity/types'
import { StatusPillStyled } from '@core/Areas/AccountActivity/Components/Ticket/Components'
import { SelectionInfo } from '@core/Areas/AccountActivity/Components/Ticket/Components/Header/Components'
import { FooterInvestment } from '@core/Areas/AccountActivity/Components/Ticket/Components/Investment'
import {
  getStatusText,
  getTicketStatus,
} from '@core/Areas/AccountActivity/Components/Ticket/helpers'
import { spacing } from '@mobi/component-library/Theme/Common'

export const RaceCardTicket: React.FC<{ ticket: Ticket; isPending: boolean }> = ({
  ticket,
  isPending,
}) => {
  const status = getTicketStatus(ticket, isPending ? 'pending' : 'results')
  return (
    <WrapperStyled data-testid='race-card-ticket'>
      <header>
        <SelectionInfo ticket={ticket} />

        <StatusPillStyled status={status} size='large' data-testid='ticket-status'>
          {getStatusText(status)}
        </StatusPillStyled>
      </header>

      <footer>
        <FooterInvestment ticket={ticket} status={status} />
      </footer>
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.article({
  padding: spacing.sm,
  borderBottom: '0.1rem solid ' + newTheme.colors.neutral[300],

  '& > header': {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: spacing.smx1,
  },
})
