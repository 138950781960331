import React from 'react'
import styled from '@emotion/styled'
import { colors, font, spacing } from '@mobi/component-library/Theme/Common'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import { Button } from '@mobi/component-library/Common/V2/Buttons/Button'
import { useFeature } from '@core/Utils/hooks'
import type { Tabs, Ticket } from '@core/Areas/AccountActivity/types'
import { getLegStatus } from '@core/Areas/AccountActivity/Components/Ticket/helpers'
import { canAllowReBetForTicket } from './helpers/canAllowReBetForTicket'
import { createLoadBetDetails, loadBet } from '@core/Utils/betting/loadBet'
import { trackRebet } from '@classic/Foundation/Analytics/GoogleTagManagerService'

export const ReBetButton: React.FC<{ ticket: Ticket; activeTab: Tabs }> = ({
  ticket,
  activeTab,
}) => {
  const isReBetFeatureActive = useFeature('REBET_PENDING_TICKET')

  if (!isReBetFeatureActive || !canAllowReBetForTicket(activeTab, ticket)) return null

  return <ReBetButtonLocal ticket={ticket} source={activeTab} />
}

// ===============
// Local Component
// ===============

const ReBetButtonLocal: React.FC<{ ticket: Ticket; source: Tabs }> = ({ ticket, source }) => {
  return (
    <WrapperStyled>
      <h4>
        <Icon name='LineRepeat04' size='1.6rem' /> Re-Bet
      </h4>

      <Button
        size='xs'
        data-testid='rebet-button'
        color='secondary_color'
        onClick={() => {
          const pendingLegs = ticket.legs.filter(leg => getLegStatus(leg.result) === 'Pending')
          loadBet(createLoadBetDetails({ ...ticket, legs: pendingLegs }), 'rebet')

          const resultedCount = ticket.legs.length - pendingLegs.length
          trackRebet({ source, resultedCount, pendingCount: pendingLegs.length })
        }}
      >
        Bet Now
      </Button>
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  h4: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.smx2,
    margin: 0,
    padding: `${spacing.smx1} ${spacing.smx2}`,
    textAlign: 'unset',
    fontSize: font.size.md.fontSize,
    letterSpacing: font.size.md.letterSpacing,
    lineHeight: font.size.md.lineHeight,
    fontWeight: font.weight.regular,
    color: colors.black,
  },
})
