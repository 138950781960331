import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import { Checkbox } from '@mobi/component-library/Common/V2/Inputs/Checkbox'
import { ShareBetContext } from '@mobi/betslip/Components/Common/ShareMyBet/context'
import { toggleIsItemInMulti } from '@mobi/betslip/Store/Bets'
import { useAppSelector } from '@mobi/betslip/Store/hooks'
import type { BetSlipItem } from '@mobi/betslip/types'
import { isFatalErrorType, isFatalMultiBetLegError } from '@mobi/betslip/helpers/state'
import { MultiLegClosed } from './Components/MultiLegClosed'

export const MultiLegCheckbox: React.FC<{ item: BetSlipItem }> = ({ item }) => {
  const dispatch = useDispatch()

  const workflowStatus = useAppSelector(state => state.betslip.workflow.currentStatus)
  const isBusy = useAppSelector(state => state.betslip.workflow.isBusy)
  const receipt = useAppSelector(state => state.betslip.bets.multiReceipt)

  const isShareDisplay = useContext(ShareBetContext)

  const hasFatalError = isFatalErrorType(item.betErrorType)

  React.useEffect(() => {
    // Remove from active multi selection if fatal error (ie. betting closed)
    if (hasFatalError && item.isInMulti) dispatch(toggleIsItemInMulti({ id: item.id }))
  }, [hasFatalError, item.id, item.isInMulti, dispatch])

  if (hasFatalError) return <MultiLegClosed id={item.id} />

  if (workflowStatus !== 'ready' || isShareDisplay) return null

  const isDisabled = isBusy || receipt != null || isFatalMultiBetLegError(item)

  return (
    <WrapperStyled>
      <Checkbox label='' readOnly name={item.id} isDisabled={isDisabled} checked={item.isInMulti} />
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  'label [role="checkbox"]': { margin: 0 },
})
