import { useMemo } from 'react'
import type { WebAppVersionDetails as VersionDetails } from '@mobi/api-types'
import { dayjs } from '@mobi/utils/date'

export type LatestVersion = VersionDetails & {
  previousVersions: VersionDetails[]
}

/**
 * Provide a search function built off a precomputed index of the latest versions by git ref name
 */
export const useVersionsSearch = (webAppVersions: Record<string, VersionDetails>) => {
  const searchIndex = useMemo(() => {
    const versions = Object.values(webAppVersions)
    return getLatestVersionsByGitRefName(versions)
  }, [webAppVersions])

  return {
    searchLatest: (value: string) => {
      return filterBySearchTerm(searchIndex, value).sort(sortByReleaseDateDescending)
    },
  }
}

/**
 * Build search index of versions
 *
 * Example:
 * {
 *   "searchIndex": {
 *     "mobi-100-feature-1": {
 *       "version": "version-123.2-mobi-100-feature-1",
 *       "gitRefName": "mobi-100-feature-1",
 *       "previousVersions": ["version-123.2-mobi-100-feature-1"]
 *      },
 *      "mobi-200-feature-2": {
 *        "version": "version-123.3-mobi-200-feature-2",
 *        "gitRefName": "mobi-100-feature-2",
 *        "previousVersions": []
 *      }
 *    }
 *  }
 */
const getLatestVersionsByGitRefName = (versions: VersionDetails[]) => {
  const groups: Record<string, LatestVersion> = {}

  for (const item of versions) {
    const { gitRefName, releaseDate } = item
    const existing = groups[gitRefName]
    if (existing) {
      if (releaseDate > existing.releaseDate) {
        groups[gitRefName] = {
          ...item,
          previousVersions: existing.previousVersions.concat(existing).filter(v => v !== item),
        }
      } else {
        groups[gitRefName].previousVersions.push(item)
      }
    } else {
      groups[gitRefName] = { ...item, previousVersions: [] }
    }
  }

  return Object.values(groups)
}

const filterBySearchTerm = (versions: LatestVersion[], filter: string) => {
  if (filter === '') return versions
  return versions.filter(entry => {
    const { version, gitRefName, username } = entry
    const searchString = [version, gitRefName, username].join(' ').toLocaleLowerCase()
    return searchString.trim().includes(filter.toLocaleLowerCase())
  })
}

export const sortByReleaseDateDescending = (a: VersionDetails, b: VersionDetails) =>
  dayjs(b.releaseDate).diff(dayjs(a.releaseDate))
