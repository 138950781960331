import { Currency } from '@mobi/utils/money'
import type { BetSlipBetsState } from '@mobi/betslip/Store/Bets'
import type { BetSlipItem, MultiInvestment } from '@mobi/betslip/types'
import {
  getBetsInMulti,
  getBetsToPlace,
  hasTooFewMultiLegs,
  hasTooManyMultiLegs,
  isFatalErrorType,
} from '../state'
import { calclulateCombinedMultiInvestment, calculateMultiProjectedPay } from './multis'
import { calculateBetCostSingleItem, calculateEstReturnForSingles } from './singles'

export const calculateTotalStake = (
  betslipItems: BetSlipItem[],
  multiInvestment: MultiInvestment,
  multiError: BetSlipBetsState['multiBetError'] | null
) => {
  const combinedSingleCost = getBetsToPlace(betslipItems).reduce(
    (total, item) => new Currency(calculateBetCostSingleItem(item)).add(total).value,
    0
  )

  const multiItems = getBetsInMulti(betslipItems)
  if (
    hasTooFewMultiLegs(multiItems) ||
    hasTooManyMultiLegs(multiItems) ||
    multiError?.betErrorType === 'DuplicateBonusBet' ||
    isFatalErrorType(multiError?.betErrorType) ||
    multiError?.betErrorType === 'Unspecified'
  ) {
    return combinedSingleCost
  }
  return combinedSingleCost + calclulateCombinedMultiInvestment(multiItems, multiInvestment)
}

export function calculateEstReturn(
  placeableItems: BetSlipItem[],
  placeableMultis: BetSlipItem[],
  multiInvestment: MultiInvestment
): number {
  const singlesEstRtn = calculateEstReturnForSingles(placeableItems) || 0
  const multiAndFormulaEstRtn = calculateMultiProjectedPay(placeableMultis, multiInvestment)
  return new Currency(singlesEstRtn).add(multiAndFormulaEstRtn).value
}
